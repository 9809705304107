export const DashboardData = {
    code: 200,
    message: 'The reports are as follows:',
    result: [
        {
            designSettings:
                '{"headerBackgroundColor":"teal","tablePaddingLeft":"10rem","tablePaddingRight":"10rem","tablePaddingTop":"10rem","tablePaddingBottom":"10rem","tableFontSize":11,"tableTitleFontSize":14,"tableFontFamily":"times New Roman, Times, serif","tableColor":"#ff0000","headerFontColor":"White","headerFontSize":"15px","highlightColor":"black","fontSize":"20","margin":"5px","numberOfRows":"10","rowSelectedBackgroundColor":"#fff","tableBackgroundColor":"#fefefe","tableTitleFontStyle":"serif","textAlign":"top","verticalAlign":"center"}',
            displayType: 'table',
            reportId: 3,
            refreshDuration: 5,
            columns: [
                {
                    sequence: '1',
                    parentSequence: '0',
                    level: 1,
                    displayTagName: 'Warehouse',
                    attributes: '',
                    tagName: 'Warehouse',
                },
                {
                    sequence: '2',
                    parentSequence: '0',
                    level: 1,
                    displayTagName: 'Locn Barcode',
                    attributes: '',
                    tagName: 'Locn Barcode',
                },
                {
                    sequence: '3',
                    parentSequence: '0',
                    level: 1,
                    displayTagName: 'Location Locked',
                    attributes: '',
                    tagName: 'Location Locked',
                },
                {
                    sequence: '4',
                    parentSequence: '0',
                    level: 1,
                    displayTagName: 'Style',
                    attributes: '',
                    tagName: 'Style',
                },
                {
                    sequence: '5',
                    parentSequence: '0',
                    level: 1,
                    displayTagName: 'Suffix',
                    attributes: '',
                    tagName: 'Suffix',
                },
                {
                    sequence: '5.1',
                    parentSequence: '5',
                    level: 2,
                    displayTagName: 'Date Last Modified',
                    attributes: '',
                    tagName: 'Date Last Modified',
                },
                {
                    sequence: '5.2',
                    parentSequence: '5',
                    level: 2,
                    displayTagName: 'Assign Zone',
                    attributes: '',
                    tagName: 'Assign Zone',
                },
                {
                    sequence: '5.3',
                    parentSequence: '5',
                    level: 2,
                    displayTagName: 'To Be Filled',
                    attributes: '',
                    tagName: 'To Be Filled',
                },
                {
                    sequence: '5.4',
                    parentSequence: '5',
                    level: 2,
                    displayTagName: 'Actual Inventory',
                    attributes: '',
                    tagName: 'Actual Inventory',
                },
                {
                    sequence: '5.5',
                    parentSequence: '5',
                    level: 2,
                    displayTagName: 'Warehouse',
                    attributes: '',
                    tagName: 'Warehouse',
                },
                {
                    sequence: '5.4.1',
                    parentSequence: '5.4',
                    level: 3,
                    displayTagName: 'Locn Barcode',
                    attributes: '',
                    tagName: 'Locn Barcode',
                },
                {
                    sequence: '5.4.2',
                    parentSequence: '5.4',
                    level: 3,
                    displayTagName: 'Location Locked',
                    attributes: '',
                    tagName: 'Location Locked',
                },
                {
                    sequence: '5.4.3',
                    parentSequence: '5.4',
                    level: 3,
                    displayTagName: 'Program ID',
                    attributes: '',
                    tagName: 'Program ID',
                },
                {
                    sequence: '5.4.3.1',
                    parentSequence: '5.4.3',
                    level: 4,
                    displayTagName: 'To Be Filled',
                    attributes: '',
                    tagName: 'To Be Filled',
                },
                {
                    sequence: '5.4.3.2',
                    parentSequence: '5.4.3',
                    level: 4,
                    displayTagName: 'Location Locked',
                    attributes: '',
                    tagName: 'Location Locked',
                },
                {
                    sequence: '5.4.3.3',
                    parentSequence: '5.4.3',
                    level: 4,
                    displayTagName: 'Locn Barcode',
                    attributes: '',
                    tagName: 'Locn Barcode',
                },
                {
                    sequence: '5.4.3.1.1',
                    parentSequence: '5.4.3.1',
                    level: 5,
                    displayTagName: 'Locn Barcode',
                    attributes: '',
                    tagName: 'Locn Barcode',
                },
                {
                    sequence: '5.4.3.1.2',
                    parentSequence: '5.4.3.1',
                    level: 5,
                    displayTagName: 'Warehouse',
                    attributes: '',
                    tagName: 'Warehouse',
                },
                {
                    sequence: '5.4.3.1.3',
                    parentSequence: '5.4.3.1',
                    level: 5,
                    displayTagName: 'Style',
                    attributes: '',
                    tagName: 'Style',
                },
                {
                    sequence: '5.4.3.1.3.1',
                    parentSequence: '5.4.3.1.3',
                    level: 6,
                    displayTagName: 'Date Last Modified',
                    attributes: '',
                    tagName: 'Date Last Modified',
                },
                {
                    sequence: '5.4.3.1.3.2',
                    parentSequence: '5.4.3.1.3',
                    level: 6,
                    displayTagName: 'Assign Zone',
                    attributes: '',
                    tagName: 'Assign Zone',
                },
            ],
            transactionName: 'LOCN',
            list: [
                {
                    Warehouse: '785',
                    Suffix: [
                        {
                            'Date Last Modified': '7164',
                            'Assign Zone': '2FG',
                            'To Be Filled': '.0000',
                            'Actual Inventory': [
                                {
                                    'Locn Barcode': '.001',
                                    'Location Locked': true,
                                    'Program ID': [
                                        {
                                            'To Be Filled': '7164',
                                            'Location Locked': false,
                                            'Locn Barcode': 'SL63M2RQ',
                                        },
                                        {
                                            'To Be Filled': [
                                                {
                                                    'Locn Barcode': '.0000',
                                                    Warehouse: '20200127',
                                                    Style: [
                                                        {
                                                            'Date Last Modified':
                                                                '04386450',
                                                            'Assign Zone':
                                                                '2FG',
                                                        },
                                                        {
                                                            'Date Last Modified':
                                                                '730',
                                                            'Assign Zone':
                                                                '.012',
                                                        },
                                                    ],
                                                },
                                            ],
                                            'Location Locked': true,
                                            'Locn Barcode': 'SL63M1EQ',
                                        },
                                    ],
                                },
                                {
                                    'Locn Barcode': '.002',
                                    'Location Locked': false,
                                    'Program ID': 'SL63M2RQ',
                                },
                                {
                                    'Locn Barcode': '.003',
                                    'Location Locked': false,
                                    'Program ID': 'SL63M1TE',
                                },
                                {
                                    'Locn Barcode': '.004',
                                    'Location Locked': true,
                                    'Program ID': 'SL63QCRI',
                                },
                            ],
                            Warehouse: '500',
                        },
                        {
                            'Date Last Modified': '20200127',
                            'Assign Zone': '2FG',
                            'To Be Filled': '.0000',
                            'Actual Inventory': [
                                {
                                    'Locn Barcode': '.005',
                                    'Location Locked': true,
                                    'Program ID': 'SL63M211',
                                },
                                {
                                    'Locn Barcode': '.006',
                                    'Location Locked': false,
                                    'Program ID': 'SL63Q1OQ',
                                },
                                {
                                    'Locn Barcode': '.007',
                                    'Location Locked': false,
                                    'Program ID': 'KMFWMEO1',
                                },
                                {
                                    'Locn Barcode': '.008',
                                    'Location Locked': true,
                                    'Program ID': 'PADMQI1A',
                                },
                            ],
                            Warehouse: '500',
                        },
                        {
                            'Date Last Modified': '11385',
                            'Assign Zone': '2FG',
                            'To Be Filled': '.0000',
                            'Actual Inventory': [
                                {
                                    'Locn Barcode': '.009',
                                    'Location Locked': true,
                                    'Program ID': 'SL63M2RP',
                                },
                                {
                                    'Locn Barcode': '.010',
                                    'Location Locked': false,
                                    'Program ID': 'SL63M2RQ',
                                },
                                {
                                    'Locn Barcode': '.011',
                                    'Location Locked': false,
                                    'Program ID': 'SL63M1TE',
                                },
                                {
                                    'Locn Barcode': '.012',
                                    'Location Locked': true,
                                    'Program ID': 'SL63QCRI',
                                },
                            ],
                            Warehouse: '500',
                        },
                    ],
                    'Location Locked': false,
                    Style: '04386375',
                    'Locn Barcode': 'A02010101A',
                },
                {
                    Warehouse: '500',
                    Suffix: '500',
                    'Location Locked': true,
                    Style: '04386450',
                    'Locn Barcode': 'A02010101C',
                },
                {
                    Warehouse: '620',
                    Suffix: '320',
                    'Location Locked': false,
                    Style: '04386421',
                    'Locn Barcode': 'A02010201A',
                },
                {
                    Warehouse: '130',
                    Suffix: '730',
                    'Location Locked': true,
                    Style: '04386450',
                    'Locn Barcode': 'A02010201C',
                },
                {
                    Warehouse: '520',
                    Suffix: [
                        {
                            'Date Last Modified': '20200127',
                            'Assign Zone': '2FG',
                            'To Be Filled': '.0000',
                            'Actual Inventory': 'WCOAMEIMA',
                            Warehouse: '500',
                        },
                    ],
                    'Location Locked': false,
                    Style: '04386389',
                    'Locn Barcode': 'A02010301A',
                },
                {
                    Warehouse: '90',
                    Suffix: [
                        {
                            'Date Last Modified': '9790',
                            'Assign Zone': '2FG',
                            'To Be Filled': '.0000',
                            'Actual Inventory': [
                                {
                                    'Locn Barcode': '.013',
                                    'Location Locked': true,
                                    'Program ID': 'SL63M2RP',
                                },
                                {
                                    'Locn Barcode': '.014',
                                    'Location Locked': false,
                                    'Program ID': 'SL63M2RQ',
                                },
                                {
                                    'Locn Barcode': '.015',
                                    'Location Locked': false,
                                    'Program ID': 'SL63M1TE',
                                },
                                {
                                    'Locn Barcode': '.016',
                                    'Location Locked': true,
                                    'Program ID': 'SL63QCRI',
                                },
                            ],
                            Warehouse: '500',
                        },
                    ],
                    'Location Locked': false,
                    Style: '04386359',
                    'Locn Barcode': 'A02010301C',
                },
                {
                    Warehouse: '20',
                    Suffix: [
                        {
                            'Date Last Modified': '11385',
                            'Assign Zone': '2FG',
                            'To Be Filled': '.0000',
                            'Actual Inventory': 'WEFQ',
                            Warehouse: '500',
                        },
                    ],
                    'Location Locked': false,
                    Style: '04386450',
                    'Locn Barcode': 'A02010401A',
                },
                {
                    Warehouse: '260',
                    Suffix: '120',
                    'Location Locked': true,
                    Style: '04386425',
                    'Locn Barcode': 'A02010401C',
                },
            ],
            displayMode: 'dashboard',
        },
    ],
}
