import React, { useState } from 'react'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { CSVDownload, CSVLink } from 'react-csv'

const ExportFile = (props) => {
    const [csv, setCSV] = useState(false)
    console.log('propsExport', props)
    const headers = [
        { label: 'First Name', key: 'firstname' },
        { label: 'Last Name', key: 'lastName' },
        { label: 'User name', value: 'userName' },
        { label: 'email', key: 'email' },
        { label: 'Address', key: 'address' },
        { label: 'Phone', key: 'phone' },
        { label: 'Gender', key: 'gender' },
        { label: 'GroupName', key: 'groupName' },
    ]
    const pdfheaders = [
        [
            'First Name',
            'Last Name',
            'User name',
            'email',
            'Address',
            'Phone',
            'Gender',
            'GroupName',
        ],
    ]
    const excelheaders = [
        'First Name',
        'Last Name',
        'User name',
        'email',
        'Address',
        'Phone',
        'Gender',
        'GroupName',
    ]
    const details =
        props.details &&
        props.details.map((elt) => [
            elt.firstName,
            elt.lastName,
            elt.userName,
            elt.email,
            elt.address,
            elt.phone,
            elt.gender,
            elt.groupName,
        ])
    const exportPDF = () => {
        const unit = 'pt'
        const size = 'A4' // Use A1, A2, A3 or A4
        const orientation = 'portrait' // portrait or landscape

        const marginLeft = 40
        const doc = new jsPDF(orientation, unit, size)

        doc.setFontSize(15)

        const title = 'Reports'

        let content = {
            startY: 50,
            head: pdfheaders,
            body: details,
        }
        console.log('Contenttt', content)
        doc.text(title, marginLeft, 40)
        doc.autoTable(content)
        doc.save('report.pdf')
    }
    return (
        <div style={{ float: 'right' }} class="dropdown">
            <button
                class="btn btn btn-outline-dark dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
            >
                Exports
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a onClick={() => exportPDF()} class="dropdown-item" href="#">
                    Export as PDF
                </a>
                <a onClick={() => setCSV(true)} class="dropdown-item" href="#">
                   
                    {/* {csv === true && ( */}
                        <CSVLink
                            filename={'report.csv'}
                            data={details}
                            headers={excelheaders}
                            target="_blank"
                        >Export as CSV</CSVLink>
                    {/* )} */}
                </a>
            </div>
        </div>
    )
}

export default ExportFile
