import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'

const PagingTable = Loadable(lazy(() => import('./PagingTable')));
const MaterialTables = Loadable(lazy(() => import('./MaterialTables')));
const New = Loadable(lazy(() => import('./New')));
const PagingRoutes = [
    {
        path: '/PagingTable',
        element: <PagingTable />,
        //menuId: 5,
    },{
        path: '/MaterialTables',
        element: <MaterialTables />,
        //menuId: 5,
    },
    {
        path: '/New',
        element: <New />,
        //menuId: 5,
    },
 

]



export default PagingRoutes
