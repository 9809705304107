import {reportInnerTableDetails} from '../../constant/ActionTypes'
import {
    GET_ALL_TABLE_REPORT,
    REPORT_INPUT_CHANGE,
    GET_REPORT_REQUEST,
} from '../../constant/ErrorConstant'
import 'react-notifications/lib/notifications.css'
import { NotificationManager, NotificationContainer } from 'react-notifications'
export const reportTableConstant = {
    reportInputChange,
  
//innerTable SKU
passFirstLevelValues,
    passOnChangeDragValue,
    passSequenceInnerTableStatus,
    columSequenceTableInputChange,
    openSecondPopup
}

function reportInputChange(props) {
    return {
        type: reportInnerTableDetails.REPORT_INPUT_CHANGE,
        payload: props,
    }
}

//inner table SKU data
function passFirstLevelValues(props){
    return{
        type:reportInnerTableDetails.GET_INNERTABLE_DATA,
        payload:props
    }
}
function passOnChangeDragValue(props){
    return{
        type:reportInnerTableDetails.ON_DRAG_INNER_TABLE,
        payload:props
    }
}
function passSequenceInnerTableStatus(props){
    return{
        type:reportInnerTableDetails.PASS_SEQUENCE_INNER_TABLE,
        payload:props
    }
}
function columSequenceTableInputChange(props){
    return{
        type:reportInnerTableDetails.COLUMN_DISPLAY_TABLE_INPUTCHANGE,
        payload:props
    }
}
function openSecondPopup(props){
    return{
        type:reportInnerTableDetails.OPEN_SECOND_POPUP,
        payload:props
    }
}