import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'

const NotFound = Loadable(lazy(() => import('./NotFound')))
const ForgotPassword = Loadable(lazy(() => import('./ForgotPassword')))
const JwtLogin = Loadable(lazy(() => import('./login/JwtLogin')))
const JwtRegister = Loadable(lazy(() => import('./register/JwtRegister')))
const ConfirmPage = Loadable(lazy(() => import('./ConformCode/ConformPage')))
const ForceChangePassword = Loadable(lazy(() => import('./ForceChangePassword/ForceChangePassword')))
const ForceCreatePassword=Loadable(lazy(()=>import('./ForceChangePassword/ForceCreatePassword')))
const sessionRoutes = [
    {
        path: '/verify/:token',
        element: <ForceCreatePassword />,
    },
    {
        path: '/session/signup',
        element: <JwtRegister />,
    },
    {
        path: '/session/signin',
        element: <JwtLogin />,
    },
    {
        path: '/session/forgot-password',
        element: <ForgotPassword />,
    },
    {
        path: '/session/404',
        element: <NotFound />,
    },
    { path: '/session/conform-email', element: <ConfirmPage /> },
    { path: '/session/forceChangePassword', element: <ForceChangePassword/> },
]

export default sessionRoutes
