import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'

const CardComponent = Loadable(lazy(() => import('./CardComponent')));
const CardModelComponent = Loadable(lazy(() => import('./CardModelComponent')));

const CardRoutes = [
    {
        path: '/CardComponent',
        element: <CardComponent />,
    },
    {
        path:'/CardModelComponent',
        element:<CardModelComponent/>
    },

]

export default CardRoutes
