import { all, call, put, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { NotificationManager } from 'react-notifications'
import Moment from 'moment'
import {
    commonComponentType,
    reportUserDetails,
} from '../../constant/ActionTypes'
import {
    FORCE_CHANGE_PSWD,
    UPDATE_BASE_URL,
    FILE_UPLOAD,
    JSON_FILE_UPLOAD,
    ADD_CORS,
    JSON_UPDATE_UPLOAD,
    JSON_GET_UPLOAD,
    DASHBOARD_REPORT,
    DISPLAY_MODE,
    JSON_DATA,
    DASHBOARD_URL,
    USER_DASBOARD,
    DISPLAY_MODE_NEW,
    REFRESH_DURATION_API,
    GET_DASHBOARD_REPORT_BY_USERID,
    SINGLE_DASHBOARD_DATA,
    DASHBOARD_MAIN_URL,
} from '../../constant/Common'
import { DashboardData } from 'app/views/dashboard/DashboardHardCoded'

function changeDateFormat(dates) {
    const newDate = new Date(dates)
    return Moment(newDate).format('YYYY-MM-DD')
}
function getJwtToken() {
    //  const sessionLoginToken = useSelector((state)=>state.login);
    var abc = localStorage.getItem('accessToken')
    //  console.log("Getting the Token",sessionLoginToken);
    //sessionStorage.getItem("tokenId")
    return abc
}
// //   ======================================================Save Force Change Password ====================================
function* getAllData(payload) {
    try {
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        }
        var res = yield axios({
            method: 'GET',
            url: UPDATE_BASE_URL + FILE_UPLOAD,

            params: {
                fileName: `JsonFillittoFile.json`,
            },
            headers: headers,
        })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        if (res.status === 403) {
            NotificationManager.error('Unauthorized Access')

            yield put({
                type: commonComponentType.COMMON_ERROR_UNAUTH,
                payload: { error: 'Internal application error. Please contact system administrator.' },
            })
            return
        }
        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')

            yield put({
                type: commonComponentType.COMMON_ERROR_UNAUTH,
                payload: { error: 'Internal application error. Please contact system administrator.' },
            })
            return
        }

        if (res.status === 200) {
            // console.log('ressssss', res)
            if (res.data.status == 400) {
                NotificationManager.error(res.data.message)
            } else if (res.data != '') {
                // console.log('ressssss.data',res.data)
                yield put({
                    type: commonComponentType.DISPLAY_ALL_DATA,
                    payload: res.data,
                })
            }
        } else {
            NotificationManager.error('Internal application error. Please contact system administrator.')
        }
    } catch (error) {
        console.log(error)
    }
}

function* getAllCommonData() {
    yield takeEvery(commonComponentType.GET_ALL_DATA, getAllData)
}

function* getJsonData(payload) {
    // console.log("userId ",payload)
    var pay = payload.payload
    try {
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        }
        var res = yield axios({
            method: 'GET',
            url: DASHBOARD_URL + pay.userId,
            //UPDATE_BASE_URL + JSON_DATA + pay.userId,
            // JSON_GET_UPLOAD + pay.userId,
            //  params: {
            //     fileName:`JsonFillittoFile.json`,
            // },
            headers: headers,
        })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        if (res.status === 403) {
            NotificationManager.error('Unauthorized Access')

            // yield put({
            //     type: commonComponentType.COMMON_ERROR_UNAUTH,
            //     payload: { error: 'Internal application error. Please contact system administrator.' },
            // })
            return
        }
        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')

            yield put({
                type: commonComponentType.COMMON_ERROR_UNAUTH,
                payload: { error: 'Internal application error. Please contact system administrator.' },
            })
            return
        }

        if (res.status === 200) {
            // console.log('ressssss', res.data)

            if (res.status == 400) {
                NotificationManager.error(res.data.message)
            } else if (res.status == 200) {
                // console.log('ressssss.data123', res.data)
                yield put({
                    type: commonComponentType.DISPLAY_JSON_ALL_DATA,
                    payload: res.data,
                })
            } else {
                NotificationManager.error('Internal application error. Please contact system administrator.')
            }
        } else {
            NotificationManager.error('Internal application error. Please contact system administrator.')
        }
    } catch (error) {
        console.log(error)
    }
}

function* getJsonCommonData() {
    yield takeEvery(commonComponentType.GET_JSON_TEXT_DATA, getJsonData)
}

function* saveJsonData(payload) {
    // console.log('saga', payload)
    // console.log('saveFormPayload', payload.payload)
    let formData = new FormData()
    formData.append('file', JSON.stringify(payload.payload.jsonChangeData))
    formData.append('userId', payload.payload.userId.generatedIDLogin)
    formData.append('userName', payload.payload.userId.name)
    // formData.append('reportId', 66)
    formData.append('transactionName', 'fff')

    try {
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        }
        var res = yield axios({
            method: 'POST',
            url: ADD_CORS + UPDATE_BASE_URL + JSON_FILE_UPLOAD,
            headers: headers,
            data: formData,
        })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        if (res.status === 403) {
            NotificationManager.error('Unauthorized Access')

            yield put({
                type: commonComponentType.COMMON_ERROR_UNAUTH,
                payload: { error: 'Internal application error. Please contact system administrator.' },
            })
            return
        }
        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')

            yield put({
                type: commonComponentType.COMMON_ERROR_UNAUTH,
                payload: { error: 'Internal application error. Please contact system administrator.' },
            })
            return
        }

        if (res.status === 200) {
            console.log('ressssss', res)
            if (res.data.status == 400) {
                NotificationManager.error(res.data.message)
            } else if (res.data != '') {
                NotificationManager.success(res.data)
                console.log('ressssss.data', res.data)
                yield put({
                    type: commonComponentType.GET_JSON_TEXT_DATA,
                    payload: {
                        userId: payload.payload.userId.generatedIDLogin,
                    },
                })
                // yield put({
                //     type: commonComponentType.RESPONSE_SAVE_NEW_JSONDATA,
                //     payload: res.data,
                // })
            }
        } else {
            NotificationManager.error('Internal application error. Please contact system administrator.')
        }
    } catch (error) {
        console.log(error)
    }
}

function* saveJson() {
    yield takeEvery(commonComponentType.REQUEST_SAVE_NEW_JSONDATA, saveJsonData)
}

//====================================put json update data============================================================
function* saveUpadateJsonData(payload) {
    // console.log('saga', payload)
    // console.log('saveupdatePayload', payload.payload)
    let formData = new FormData()
    formData.append('file', JSON.stringify(payload.payload.jsonChangeData))
    formData.append('userId', payload.payload.userId.generatedIDLogin)
    formData.append('userName', payload.payload.userId.name)

    try {
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        }
        var res = yield axios({
            method: 'PUT',
            url:
                ADD_CORS +
                UPDATE_BASE_URL +
                JSON_UPDATE_UPLOAD +
                payload.payload.updateJsonId,
            headers: headers,
            data: formData,
        })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        if (res.status === 403) {
            NotificationManager.error('Unauthorized Access')

            yield put({
                type: commonComponentType.COMMON_ERROR_UNAUTH,
                payload: { error: 'Internal application error. Please contact system administrator.' },
            })
            return
        }
        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')

            yield put({
                type: commonComponentType.COMMON_ERROR_UNAUTH,
                payload: { error: 'Internal application error. Please contact system administrator.' },
            })
            return
        }

        if (res.status === 200) {
            // console.log('ressssss', res)
            if (res.data.status == 400) {
                NotificationManager.error(res.data.message)
            } else if (res.data != '') {
                NotificationManager.success(res.data)
                //  console.log('ressssss.data', res.data)
                yield put({
                    type: commonComponentType.GET_JSON_TEXT_DATA,
                    payload: {
                        userId: payload.payload.userId.generatedIDLogin,
                    },
                })
                yield put({
                    type: commonComponentType.RESPONSE_SAVE_UPDATE_JSONDATA,
                    payload: res.data,
                })
            }
        } else {
            NotificationManager.error('Internal application error. Please contact system administrator.')
        }
    } catch (error) {
        console.log(error)
    }
}

function* saveUpdateJson() {
    yield takeEvery(
        commonComponentType.REQUEST_SAVE_UPDATE_JSONDATA,
        saveUpadateJsonData
    )
}

//=======================================refresh duration table data==============================================

function* getRefreshTable(payload) {
    var props = payload.payload

    try {
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        }
        var res = yield axios({
            method: 'GET',
            url:
                REFRESH_DURATION_API +
                props.timerSec.reportId +
                '/' +
                props.timerSec.transactionName,
            params: {
                includeColumns: true,
            },
            headers: headers,
        })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        if (res.status === 403) {
            NotificationManager.error('Unauthorized Access')
            return
        }
        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')

            yield put({
                type: commonComponentType.COMMON_ERROR_UNAUTH,
                payload: { error: 'Internal application error. Please contact system administrator.' },
            })
            return
        }

        if (res.status === 200) {
            if (res.status.code == 400) {
                NotificationManager.error(res.data.message)
            } else if (res.status == 200) {
                yield put({
                    type: commonComponentType.RESPONSE_REFRESH_DURATION_RESULT,
                    payload: res.data.result,
                })
            } else {
                NotificationManager.error('Internal application error. Please contact system administrator.')
            }
        } else {
            NotificationManager.error('Internal application error. Please contact system administrator.')
        }
    } catch (error) {
        console.log(error)
    }
}

function* getRefreshDuration() {
    yield takeEvery(commonComponentType.REFRESH_DASHBOARD_DATA, getRefreshTable)
}
//=======================================refresh duration donut data==============================================

function* getRefreshTimerDonut(payload) {
    var props = payload.payload
    try {
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        }
        var res = yield axios({
            method: 'GET',
            url:
                REFRESH_DURATION_API +
                props.timerSec.reportId +
                '/' +
                props.timerSec.transactionName,
            params: {
                includeColumns: true,
            },
            headers: headers,
        })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        if (res.status === 403) {
            NotificationManager.error('Unauthorized Access')
            return
        }
        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')

            yield put({
                type: commonComponentType.COMMON_ERROR_UNAUTH,
                payload: { error: 'Internal application error. Please contact system administrator.' },
            })
            return
        }

        if (res.status === 200) {
            if (res.status == 400) {
                NotificationManager.error(res.data.message)
            } else if (res.status == 200) {
                yield put({
                    type: commonComponentType.RESPONSE_REFRESH_DASHBOARD_DONUT,
                    payload: res.data.result,
                })
            } else {
                NotificationManager.error('Internal application error. Please contact system administrator.')
            }
        } else {
            NotificationManager.error('Internal application error. Please contact system administrator.')
        }
    } catch (error) {
        console.log(error)
    }
}

function* getRefreshDurationDoughnut() {
    yield takeEvery(
        commonComponentType.REFRESH_DASHBOARD_DONUT,
        getRefreshTimerDonut
    )
}
//=======================================refresh duration bar data==============================================

function* getRefreshTimerBar(payload) {
    var props = payload.payload
    try {
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        }
        var res = yield axios({
            method: 'GET',
            url:
                REFRESH_DURATION_API +
                props.timerSec.reportId +
                '/' +
                props.timerSec.transactionName,
            params: {
                includeColumns: true,
            },
            headers: headers,
        })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        if (res.status === 403) {
            NotificationManager.error('Unauthorized Access')
            return
        }
        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')

            yield put({
                type: commonComponentType.COMMON_ERROR_UNAUTH,
                payload: { error: 'Internal application error. Please contact system administrator.' },
            })
            return
        }

        if (res.status === 200) {
            if (res.status == 400) {
                NotificationManager.error(res.data.message)
            } else if (res.status == 200) {
                yield put({
                    type: commonComponentType.RESPONSE_REFRESH_DASHBOARD_BAR,
                    payload: res.data.result,
                })
            } else {
                NotificationManager.error('Internal application error. Please contact system administrator.')
            }
        } else {
            NotificationManager.error('Internal application error. Please contact system administrator.')
        }
    } catch (error) {
        console.log(error)
    }
}

function* getRefreshDurationBar() {
    yield takeEvery(
        commonComponentType.REFRESH_DASHBOARD_BAR,
        getRefreshTimerBar
    )
}
//=======================================refresh duration card data==============================================

function* getRefreshTimerCard(payload) {
    var props = payload.payload
    try {
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        }
        var res = yield axios({
            method: 'GET',
            url:
                REFRESH_DURATION_API +
                props.timerSec.reportId +
                '/' +
                props.timerSec.transactionName,
            params: {
                includeColumns: true,
            },
            headers: headers,
        })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        if (res.status === 403) {
            NotificationManager.error('Unauthorized Access')
            return
        }
        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')

            yield put({
                type: commonComponentType.COMMON_ERROR_UNAUTH,
                payload: { error: 'Internal application error. Please contact system administrator.' },
            })
            return
        }

        if (res.status === 200) {
            if (res.status == 400) {
                NotificationManager.error(res.data.message)
            } else if (res.status == 200) {
                yield put({
                    type: commonComponentType.RESPONSE_REFRESH_DASHBOARD_CARD,
                    payload: res.data.result,
                })
                yield put({
                    type: reportUserDetails.CARD_REFRESH_RESPONSE,
                    payload: res.data,
                })
            } else {
                NotificationManager.error('Internal application error. Please contact system administrator.')
            }
        } else {
            NotificationManager.error('Internal application error. Please contact system administrator.')
        }
    } catch (error) {
        console.log(error)
    }
}

function* getRefreshDurationCard() {
    yield takeEvery(
        commonComponentType.REFRESH_DASHBOARD_CARD,
        getRefreshTimerCard
    )
}

//====================================put json update data============================================================
// function* saveUpadateData(payload) {
//     console.log('saga', payload)
//     console.log('saveupdatePayload', payload.payload)
//     let formData = new FormData()
//     formData.append('file', JSON.stringify(payload.payload.jsonChangeData))
//     formData.append('userId', payload.payload.userId.generatedIDLogin)
//     formData.append('userName', payload.payload.userId.name)

//     try {
//         let headers = {
//             'Content-Type': 'application/json;charset=UTF-8',
//             Accept: 'application/json',
//             Authorization: `Bearer ${getJwtToken()}`,
//         }
//         var res = yield axios({
//             method: 'PUT',
//             url:
//                 ADD_CORS +
//                 UPDATE_BASE_URL +
//                 JSON_UPDATE_UPLOAD +
//                 payload.payload.updateJsonId,
//             headers: headers,
//             data: formData,
//         })
//             .then((response) => {
//                 return response
//             })
//             .catch((err) => {
//                 return err.response
//             })
//         if (res.status === 403) {
//             NotificationManager.error('Unauthorized Access')

//             yield put({
//                 type: commonComponentType.COMMON_ERROR_UNAUTH,
//                 payload: { error: 'Internal application error. Please contact system administrator.' },
//             })
//             return
//         }
//         if (res.status === 401) {
//             NotificationManager.error('Unauthorized Access')

//             yield put({
//                 type: commonComponentType.COMMON_ERROR_UNAUTH,
//                 payload: { error: 'Internal application error. Please contact system administrator.' },
//             })
//             return
//         }

//         if (res.status === 200) {
//            // console.log('ressssss', res)
//             if (res.data.status == 400) {
//                 NotificationManager.error(res.data.message)
//             } else if (res.data != '') {
//                 NotificationManager.success(res.data)
//               //  console.log('ressssss.data', res.data)
//                 yield put({
//                     type: commonComponentType.GET_JSON_TEXT_DATA,
//                     payload: {userId:payload.payload.userId.generatedIDLogin}
//                 })
//                 yield put({
//                     type: commonComponentType.RESPONSE_SAVE_UPDATE_JSONDATA,
//                     payload: res.data,
//                 })
//             }
//         } else {
//             NotificationManager.error('Internal application error. Please contact system administrator.')
//         }
//     } catch (error) {
//         console.log(error)
//     }
// }

// function* saveUpdateTableJson() {
//     yield takeEvery(
//         commonComponentType.SAVE_UPDATED_JSON_TABLE,
//         saveUpadateData
//     )
// }

// ============================================Display Dashboard Data ===================================
function* getDashboardData(payload) {
    //console.log('mode', payload)
    try {
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        }
        var res = yield axios({
            method: 'GET',
            // url:
            //     USER_DASBOARD +
            //     payload.payload.userId +
            //     DISPLAY_MODE_NEW +
            //     'dashboard',
            url: DASHBOARD_MAIN_URL + payload.payload.userId,
            headers: headers,
        })
            .then((response) => {
                return response
            })
            .catch((error) => {
                return error.message
            })
        if (res.status === 403) {
            NotificationManager.error('Unauthorized Access')
            return
        }
        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: commonComponentType.COMMON_ERROR_UNAUTH,
                payload: { error: 'Internal application error. Please contact system administrator.' },
            })
            return
        }
        //console.log(res.status)
        if (res.status === 200) {
            if (
                res.data.code === 400 ||
                res.data.code === 404 ||
                res.data.code === 500
            ) {
                NotificationManager.error(res.data.message)
                yield put({
                    type: commonComponentType.DASHBOARD_STOP_LOADING,
                    payload: { error: 'Internal application error. Please contact system administrator.' },
                })
            } else if (res.data.code === 403) {
                NotificationManager.error(res.data.message)
                yield put({
                    type: commonComponentType.DASHBOARD_STOP_LOADING,
                    payload: { error: 'Internal application error. Please contact system administrator.' },
                })
            } else if (res.status == 200) {
                // yield put({
                //     type: commonComponentType.DISPLAY_DASHBOARD_DATA,
                //     payload: res.data.result,
                // })
                yield put({
                    type: commonComponentType.TEMP_DISPLAY_DASHBOARD_DATA,
                    payload: res.data.result,
                })
            }
        } else {
            NotificationManager.error('Internal application error. Please contact system administrator.')
            yield put({
                type: commonComponentType.DASHBOARD_STOP_LOADING,
                payload: { error: 'Internal application error. Please contact system administrator.' },
            })
        }
    } catch (error) {
        NotificationManager.error(error)
        yield put({
            type: commonComponentType.DASHBOARD_STOP_LOADING,
            payload: { error: 'Internal application error. Please contact system administrator.' },
        })
    }
}
function* dashboardDisplay() {
    yield takeEvery(commonComponentType.GET_DASHBOARD_DATA, getDashboardData)
}

//==========================================get Module id ================================================
function* getModuleDashboard(payload) {
    var props = payload.payload
    console.log('desfult', props)

    try {
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        }
        var res = yield axios
            .request({
                method: 'get',
                url:
                    GET_DASHBOARD_REPORT_BY_USERID +
                    props.userId.generatedIDLogin +
                    '/modules',
                headers: headers,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })

        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: commonComponentType.COMMON_ERROR_UNAUTH,
                payload: { error: 'Internal application error. Please contact system administrator.' },
            })
            return
        }
        if (res.status === 403) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: commonComponentType.COMMON_ERROR_UNAUTH,
                payload: { error: 'Internal application error. Please contact system administrator.' },
            })
            return
        }

        if (res.status === 200) {
            if (res.data.status === 404) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: commonComponentType.REPORT_STOP_LOADING,
                    payload: {},
                })
            } else if (res.data.status >= 500) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: commonComponentType.REPORT_STOP_LOADING,
                    payload: {},
                })
            } else {
                // NotificationManager.info('Please set the design details')
                yield put({
                    type: commonComponentType.DISPLAY_MAIN_DASHBOARD_DATA,
                    payload: res.data.result,
                })
            }
        } else {
            if (res.data.error) {
                NotificationManager.error(res.data.error)
            } else {
                NotificationManager.error('Internal application error. Please contact system administrator.')
            }
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: commonComponentType.COMMON_STOP_LOADING,
            payload: {},
        })
    }
}

function* getModuleDashboardData() {
    yield takeEvery(
        commonComponentType.GET_MAIN_DASHBOARD_DATA,
        getModuleDashboard
    )
}

//==========================================get Single ReportModule================================================
function* getSingleReportModule(payload) {
    var props = payload.payload
    console.log('desfult', props.transactionName, props.reportId)

    try {
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        }
        var res = yield axios
            .request({
                method: 'get',
                url:
                    SINGLE_DASHBOARD_DATA +
                    props.reportId +
                    '/' +
                    props.transactionName,
                params: {
                    includeColumns: `true`,
                },
                headers: headers,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })

        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: commonComponentType.COMMON_ERROR_UNAUTH,
                payload: { error: 'Internal application error. Please contact system administrator.' },
            })
            return
        }
        if (res.status === 403) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: commonComponentType.COMMON_ERROR_UNAUTH,
                payload: { error: 'Internal application error. Please contact system administrator.' },
            })
            return
        }

        if (res.status === 200) {
            if (res.data.status === 404) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: commonComponentType.REPORT_STOP_LOADING,
                    payload: {},
                })
            } else if (res.data.status >= 500) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: commonComponentType.REPORT_STOP_LOADING,
                    payload: {},
                })
            } else {
                // NotificationManager.info('Please set the design details')
                yield put({
                    type: commonComponentType.DISPLAY_SINGLE_DASHBOARD_DATA,
                    payload: res.data.result,
                })
            }
        } else {
            if (res.data.error) {
                NotificationManager.error(res.data.error)
            } else {
                NotificationManager.error('Internal application error. Please contact system administrator.')
            }
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: commonComponentType.COMMON_STOP_LOADING,
            payload: {},
        })
    }
}

function* getSingleReport() {
    yield takeEvery(
        commonComponentType.GET_SINGLE_DASHBOARD_DATA,
        getSingleReportModule
    )
}

export default function* rootSaga() {
    yield all([
        getAllCommonData(),
        saveJson(),
        getJsonCommonData(),
        saveUpdateJson(),
        dashboardDisplay(),
        getRefreshDuration(),
        getRefreshDurationDoughnut(),
        getRefreshDurationBar(),
        getRefreshDurationCard(),
        getModuleDashboardData(),
        getSingleReport(),
        // saveUpdateTableJson()
    ])
}
