import { forceChangePasswordConstant } from '../../constant/ActionTypes'
const INITIAL_STATE = {
    loader: false,
    changePasswordUsername: '',
    tempPassword: '',
    NewPassword: '',
    ConfirmNewPassword: '',
    loginUserDetails: '',
    showPassword: false,
    changePasswordUsernameError: '',
    tempPasswordError: '',
    NewPasswordError: '',
    ConfirmNewPasswordError: '',
}
export default (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case forceChangePasswordConstant.VALIDATE_TOKEN:
            return {
                ...state,
                loading: true,
                loginUserDetails: '',
            }
        case forceChangePasswordConstant.VALIDATE_TOKEN_SUCCESS:
            return {
                ...state,
                loading: false,
                loginUserDetails: payload.userDetails,
                tempPassword: payload.userDetails.password,
                changePasswordUsername: payload.userDetails.userName,
            }
        case forceChangePasswordConstant.VALIDATE_TOKEN_FAILED:
            return {
                ...state,
                loading: false,
                loginUserDetails: '',
            }
        case forceChangePasswordConstant.CHANGE_INPUT_PASSWORD:
            return {
                ...state,
                [payload.props]: payload.value,
                [payload.error]: '',
            }
        case forceChangePasswordConstant.SAVE_CHANGE_PASSWORD:
            return {
                ...state,
                loader: true,
            }
        case forceChangePasswordConstant.CHANGE_PASSWORD_ERROR:
            return {
                ...state,
                loader: false,
            }

        case forceChangePasswordConstant.CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                loader: false,
            }
        case forceChangePasswordConstant.USER_LOGIN_DETAILS:
            return {
                ...state,
                loader: false,
                loginUserDetails: payload,
                changePasswordUsername: payload.email,
            }
        case forceChangePasswordConstant.VISIBLE_PASSWORD:
            return {
                ...state,
                loader: false,
                showPassword: !state.showPassword,
            }

        default: {
            return {
                ...state,
            }
        }
    }
}
