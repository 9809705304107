export var value = 
[
	{
		"transactionName": "ORDER",
	  	"reportId": "1",
	  	"refreshDuration": 10,
	  	"displaytype": "table",
	  	"designsettings": {
		"tableFontSize": "10px",
		"tableColor": "#3E3C3C",
		"tableBackgroundColor": "#fcf6f6",
		"textAlign": "top",
		"verticalAlign": "center",
		"tableFontFamily": "verdana",
		"margin": "5px",
		"headerFontSize": "15px",
		"headerFontColor": "White",
		"headerBackgroundColor": "teal",
		"numberOfRows": 10,
		"tableTitleColor": "#ff0000",
		"tableTitleFontSize": "25px",
		"tableTitleFontStyle": "serif",
		"highlightColor": "black",
		"rowSelectedBackgroundColor": "#fff",
		"tablePaddingLeft": "0rem",
		"tablePaddingBottom": "0rem",
		"tablePaddingTop": "0rem",
		"tablePaddingRight": "0rem",
		"tableFontType": "Arial",
		"headerFontFamily": "Arial"
	  },
	  "columns": [
		{
		  "name": "Warehouse",
		  "displayname": "Warehouse",
		  "attributes": "sort"
		},
		{
		  "name": "Locn Barcode",
		  "displayname": "Barcode",
		  "attributes": "highlight"
		},
		{
		  "name": "Assign Zone",
		  "displayname": "Zone",
		  "attributes": null
		},
		{
		  "name": "Cycle Count Date",
		  "displayname": "CycleDate",
		  "attributes": null
		},
		{
		  "name": "Inventory Lock Code",
		  "displayname": "LockCode",
		  "attributes": null
		},
		{
		  "name": "Work Group",
		  "displayname": "WrkGrp",
		  "attributes": null
		},
		{
		  "name": "Date Last Modified",
		  "displayname": "Time",
		  "attributes": null
		},
		{
		  "name": "Work Area",
		  "displayname": "WrkArea",
		  "attributes": null
		},
		{
		  "name": "Program ID",
		  "displayname": "PgmId",
		  "attributes": null
		},
		{
		  "name": "Style",
		  "displayname": "Style",
		  "attributes": null
		},
		{
		  "name": "User ID",
		  "displayname": "User ID",
		  "attributes": null
		},
		{
		  "name": "Suffix",
		  "displayname": "Suffix",
		  "attributes": null
		},
		{
		  "name": "Actual Inventory",
		  "displayname": "Actual Inventory",
		  "attributes": null
		},
		{
		  "name": "SKU Dedication Type",
		  "displayname": "SKU Dedication Type",
		  "attributes": null
		},
		{
		  "name": "To Be Picked",
		  "displayname": "To Be Picked",
		  "attributes": null
		},
		{
		  "name": "To Be Filled",
		  "displayname": "To Be Filled",
		  "attributes": null
		}
	  ],
	  "list": [
		{
		  "Warehouse": "787",
		  "Locn Barcode": "PJ2010908O",
		  "Assign Zone": "HSS",
		  "Cycle Count Date": "20200115",
		  "Inventory Lock Code": " ",
		  "Work Group": "ACTV",
		  "Date Last Modified": "20200115",
		  "Work Area": "BFJ ",
		  "Program ID": "SLG3G5RP",
		  "Style": "04386517",
		  "User ID": "JHUBXH1 ",
		  "Suffix": "37394",
		  "Actual Inventory": "46.0000",
		  "SKU Dedication Type": "T",
		  "To Be Picked": ".0000",
		  "To Be Filled": ".0000"
		},
		{
		  "Warehouse": "785",
		  "Locn Barcode": "PJ2010909O",
		  "Assign Zone": "HSS",
		  "Cycle Count Date": "20200115",
		  "Inventory Lock Code": " ",
		  "Work Group": "ACTV",
		  "Date Last Modified": "20200115",
		  "Work Area": "BFJ ",
		  "Program ID": "SLG3G5RP",
		  "Style": "04386517",
		  "User ID": "JHUBXH1",
		  "Suffix": "11525",
		  "Actual Inventory": "46.0000",
		  "SKU Dedication Type": "T",
		  "To Be Picked": ".0000",
		  "To Be Filled": ".0000"
		}
	  ]
	},
	{
	  "title": "card",
	  "styleText": {
		"cardFontSize": "11px",
		"cardColor": "#151414",
		"cardBackgroundColor": "#ffffff",
		"fontWeight": "15px",
		"cardFontFamily": "Times New Roman, Times, serif",
		"cardTitleColor": "#ed1c1c",
		"titleFontSize": "20px",
		"titleFontStyle": "serif",
		"titleMarginLeft": "2rem",
		"titleMarginRight": "2rem",
		"titleMarginTop": "2rem",
		"titleMarginBottom": "2rem",
		"margin": "0rem",
		"cardPaddingLeft": "1rem",
		"cardPaddingRight": "1rem",
		"cardPaddingTop": "1rem",
		"cardPaddingBottom": "1rem",
		"marginLeft": "0px",
		"marginRight": "0px",
		"marginTop": "0px",
		"marginBottom": "0px"
	  },
	  "value": {
		"paragraph1": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
		"paragraph2": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
	  },
	  "overAll": [
		"1Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
		"2Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
		"3Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
		"4Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
	  ]
	},
	{
	  "title": "graph",
	  "styleGraph": {
		"fontSize": "15px",
		"color": "blue",
		"barDatasetColor1": "purple",
		"barDatasetColor2": "black",
		"backgroundColor": "white",
		"fontWeight": "30px",
		"fontFamily": "Calibri",
		"headerFontSize": "20px",
		"headerFontColor": "red",
		"numberOfRows": "10",
		"titleColor": "black",
		"titleFontSize": "25px",
		"titleFontStyle": "serif",
		"titleMarginLeft": "0rem",
		"titleMarginRight": "2rem",
		"titleMarginTop": "2rem",
		"titleMarginBottom": "2rem",
		"margin": "2rem",
		"paddingLeft": "1rem",
		"height": "100%",
		"width": "100%",
		"paddingRight": "0px",
		"paddingTop": "0px",
		"paddingBottom": "0px",
		"marginLeft": "0px",
		"marginRight": "0px",
		"marginTop": "0px",
		"marginBottom": "0px",
		"donutFontFamily": "Times New Roman, Times, serif",
		"barTitleFontSize": "23px",
		"donutTitleFontSize": "26px",
		"barFontFamily": "Times New Roman, Times, serif"
	  },
	  "bothGraph": [
		{
		  "title": "barGraph",
		  "barValue": {
			"labels": [
			  "January",
			  "February",
			  "March",
			  "April",
			  "May",
			  "June",
			  "July",
			  "Augest",
			  "September",
			  "October"
			],
			"datasets": [
			  {
				"label": "Dataset 1",
				"data": [
				  5,
				  20,
				  25,
				  40,
				  45,
				  70,
				  75,
				  85,
				  95
				],
				"backgroundColor": "black"
			  },
			  {
				"label": "Dataset 2",
				"data": [
				  10,
				  15,
				  30,
				  35,
				  50,
				  60,
				  70,
				  80,
				  90
				],
				"backgroundColor": "purple"
			  }
			],
			"styleGraphBar": {
			  "fontSize": "15px",
			  "color": "blue",
			  "backgroundColor": "white",
			  "fontWeight": "30px",
			  "fontFamily": "serif",
			  "margin": "2rem",
			  "headerFontSize": "20px",
			  "headerFontColor": "red",
			  "numberOfRows": "10",
			  "titleColor": "black",
			  "titleFontSize": "25px",
			  "titleFontStyle": "serif",
			  "titleMarginLeft": "0rem",
			  "titleMarginRight": "2rem",
			  "titleMarginTop": "2rem",
			  "titleMarginBottom": "2rem",
			  "paddingLeft": "1rem",
			  "height": "100%",
			  "width": "100%",
			  "paddingRight": "0px",
			  "paddingTop": "0px",
			  "paddingBottom": "0px",
			  "marginLeft": "0px",
			  "marginRight": "0px",
			  "marginTop": "0px",
			  "marginBottom": "0px"
			}
		  }
		},
		{
		  "title": "pieGraph",
		  "pieValue": [
			{
			  "label": "Give you up",
			  "value": 25
			},
			{
			  "label": "Give you down",
			  "value": 15
			},
			{
			  "label": "Give you left",
			  "value": 35
			},
			{
			  "label": "Give you right",
			  "value": 15
			},
			{
			  "label": "Give you",
			  "value": 10
			}
		  ]
		}
	  ]
	}
]
  