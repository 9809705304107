import axios from 'axios'

const axiosInstance = axios.create()

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) =>
        Promise.reject(
            (error.response && error.response.data) || 'Internal application error. Please contact system administrator.!'
        )
)

export default axiosInstance
