import { combineReducers } from 'redux'
import ScrumBoardReducer from './ScrumBoardReducer'
import NotificationReducer from './NotificationReducer'
import EcommerceReducer from './EcommerceReducer'
import NavigationReducer from './NavigationReducer'
import VisibilityDetailsReducer from './VisibilityDetailsReducer'
import LookupReducer from './LookupReducer'
import VisibilityHeaderReducer from './VisibilityHeaderReducer'
import AdminUserReducer from './AdminUserReducer'
import TransactionReducer from './TransactionReducer'
import ConfirmReducers from './ConfirmReducers'
import RuleReducer from './RuleReducer';
import ForceChangePasswordReducer from './ForceChangePasswordReducer'
import ReportReducers from './ReportReducers'
import ProcessCriteriaReducers from './ProcessCriteriaReducers'
import TemplateReducer from './TemplateReducer'
import CommonReducers from './CommonReducers'
import ReportTableReducers from './ReportTableReducers'

const RootReducer = combineReducers({
    notifications: NotificationReducer,
    navigations: NavigationReducer,
    scrumboard: ScrumBoardReducer,
    ecommerce: EcommerceReducer,
    VisibilityDetailsReducer: VisibilityDetailsReducer,
    lookupReducer: LookupReducer,
    VisibilityHeaderReducer: VisibilityHeaderReducer,
    AdminUserReducer: AdminUserReducer,
    transaction: TransactionReducer,
    ConfirmReducers: ConfirmReducers,
    RuleReducer: RuleReducer,
    ForceChangePasswordReducer:ForceChangePasswordReducer,
    ReportReducers:ReportReducers,
    ProcessCriteriaReducers:ProcessCriteriaReducers,
    TemplateReducer:TemplateReducer,
    CommonReducers:CommonReducers,
    ReportTableReducers:ReportTableReducers
})

export default RootReducer
