import { all, call, put, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { NotificationManager } from 'react-notifications'
import Moment from 'moment'
import {
    reportUserDetails,
    adminUserConstant,
} from '../../constant/ActionTypes'

import {
    headers,
    USER_ROLES,
    REPORT_TEMPLATE,
    REPORT_MASTER,
    EXECUTION_GET_LIST,
    EXECUTION_DELETE_ID,
    EXECUTION_SAVE,
    COMMON_GRP_URL,
    POST_NEW_REPORT,
    POST_UPDATE_REPORT,
    EXECUTION_UPDATE_ID,
    UPDATE_BASE_URL,
    REPORT_ALL,
    SAVE_NEW_REPORT,
    REPORT_BY_ID,
    SERVER_ID,
    GET_SEQUENCE_NUMBER,
    ORDER,
    DEFAULT_SETTINGS,
    DEFAULT_SETTING_POST,
    DEFAULT_SETTINGS_ALL,
    SAVE_USER_AND_GRP_API,
    COLUMN_AND_DESIGN,
    UPLOAD_DESIGN_AND_TABLE,
    POST_DEFAULT_DESIGN,
    GENERATE_TAG,
    UPDATE_DESIGN_AND_COLUMN,
    DEFAULT_SETTINGS_DISPLAYTYPE,
    MODULE_ID,
    GET_DASHBOARD_REPORT_BY_USERID,
    GET_UNIQUE_GROUP_LIST,
    GET_GROUP_LIST_MULTI,
    GET_USERLIST_BY_GRP,
    ADD_AUTHENTICATION,
} from '../../constant/Common'
import { CoPresentOutlined } from '@mui/icons-material'

function changeDateFormat(dates) {
    const newDate = new Date(dates)
    return Moment(newDate).format('YYYY-MM-DD')
}

function getJwtToken() {
    //  const sessionLoginToken = useSelector((state)=>state.login);
    var abc = localStorage.getItem('accessToken')
    //  console.log("Getting the Token",sessionLoginToken);
    //sessionStorage.getItem("tokenId")
    return abc
}
//   ====================================================== Get Roles FOr the Admin User ====================================
function* getReportTableData(payload) {
    try {
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        }
        var res = yield axios
            .request({
                method: 'get',
                url: UPDATE_BASE_URL + EXECUTION_GET_LIST,
                headers: headers,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')

            yield put({
                type: reportUserDetails.REPORT_USER_ERROR_UNAUTH,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }

        if (res.status === 200) {
            //console.log('ressssss', res.data)

            if (res.data == '') {
                NotificationManager.error('Empty data')
            }
            if (res.data != '') {
                yield put({
                    type: reportUserDetails.GET_REPORT_RESPONSE,
                    payload: res.data,
                })
            }
        } else {
            NotificationManager.error(
                'Internal application error. Please contact system administrator.'
            )
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: reportUserDetails.REPORT_STOP_LOADING,
            payload: {},
        })
    }
}
function* executionDelete(payload) {
    try {
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        }
        var res = yield axios
            .request({
                method: 'delete',
                url: UPDATE_BASE_URL + EXECUTION_DELETE_ID + payload.payload,
                headers: headers,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')

            yield put({
                type: reportUserDetails.REPORT_USER_ERROR_UNAUTH,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }

        if (res.status === 200) {
            //console.log('ressssss', res.data)

            //  if(res.data.status.code == -1){
            //     NotificationManager.error("Internal application error. Please contact system administrator. -1")
            //  }

            yield put({
                type: reportUserDetails.EXECUTION_DELETE_RESPONSE,
                payload: {},
            })

            yield put({
                type: reportUserDetails.GET_REPORT_REQUEST,
                payload: {},
            })
        } else {
            NotificationManager.error(
                'Internal application error. Please contact system administrator.'
            )
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: reportUserDetails.REPORT_STOP_LOADING,
            payload: {},
        })
    }
}
function* getReportList() {
    yield takeEvery(reportUserDetails.GET_REPORT_REQUEST, getReportTableData)
}
function* executionRecordDelete() {
    yield takeEvery(reportUserDetails.EXECUTION_DELETE_REQUEST, executionDelete)
}

//=============================================save new report data to API=================================

function* saveReportNewExecution(payload) {
    var props = payload.payload
    console.log('newReport', props)

    var startTime =
        props.processDurationHours +
        ':' +
        props.processDurationMinute +
        ':' +
        props.processDurationSecond
    //console.log('startTime', startTime)
    var endTime =
        props.processDurationHoursEnd +
        ':' +
        props.processDurationMinuteEnd +
        ':' +
        props.processDurationSecondEnd
    console.log('endTime', endTime, startTime)
    try {
        var req = JSON.stringify({
            reportname: props.reportName,
            execution: props.execution,
            server: props.server,
            intervaltime: props.intervalTime,
            starttime: startTime,
            endTime: endTime,
            interactive: props.interactive,
        })
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        }
        var res = yield axios
            .request({
                method: 'post',
                url: COMMON_GRP_URL + POST_NEW_REPORT,
                headers: headers,
                data: req,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: reportUserDetails.REPORT_USER_ERROR_UNAUTH,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status === 200) {
            if (res.data.status === 400) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: reportUserDetails.REPORT_STOP_LOADING,
                    payload: {},
                })
            } else {
                NotificationManager.success('Successfully Created')
                yield call(getReportTableData)
                yield put({
                    type: reportUserDetails.DISPLAY_ADDED_NEW_DATA,
                    payload: res.data,
                })
            }
        } else {
            NotificationManager.error(
                'Internal application error. Please contact system administrator.'
            )
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: reportUserDetails.REPORT_STOP_LOADING,
            payload: {},
        })
    }
}

function* saveNewExecution() {
    yield takeEvery(
        reportUserDetails.REPORT_NEW_SAVE_SUBMIT,
        saveReportNewExecution
    )
}
//===================================================update edit data report================================
function* updateEditedDataReport(payload) {
    var props = payload.payload
    //console.log('prwegvs', props.interactive)

    var startTime =
        props.processDurationHours +
        ':' +
        props.processDurationMinute +
        ':' +
        props.processDurationSecond
    //console.log('startTime', startTime)
    var endTime =
        props.processDurationHoursEnd +
        ':' +
        props.processDurationMinuteEnd +
        ':' +
        props.processDurationSecondEnd
    //console.log('endTime', endTime)
    try {
        var req = JSON.stringify({
            reportid: props.reportId,
            reportname: props.reportName,
            execution: props.execution,
            server: props.server,
            intervaltime: props.intervalTime,
            starttime: startTime,
            endtime: endTime,
            interactive: props.interactive,
        })
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        }
        var res = yield axios
            .request({
                method: 'put',
                url: COMMON_GRP_URL + POST_UPDATE_REPORT,
                headers: headers,
                data: req,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: reportUserDetails.REPORT_USER_ERROR_UNAUTH,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status === 200) {
            if (res.data.status === 400) {
                // console.log("inside 400",res)
                NotificationManager.error(res.data.error)
                yield put({
                    type: reportUserDetails.REPORT_STOP_LOADING,
                    payload: {},
                })
            } else {
                NotificationManager.success('Successfully updated')
            }
        } else {
            NotificationManager.error(
                'Internal application error. Please contact system administrator.'
            )
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: reportUserDetails.REPORT_STOP_LOADING,
            payload: {},
        })
    }
}

function* updateEditedData() {
    yield takeEvery(reportUserDetails.POST_EDIT_REPORT, updateEditedDataReport)
}

//==========================================get all report master ===========================================
function* getReportMasterAll(payload) {
    try {
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        }
        var res = yield axios
            .request({
                method: 'get',
                url: UPDATE_BASE_URL + REPORT_ALL,
                //LATEST_BASE_URL + REPORT_ALL,
                headers: headers,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })

        if (res.status === 401) {
            //console.log('401')
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: reportUserDetails.REPORT_USER_ERROR_UNAUTH,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status === 403) {
            //console.log('401')
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: reportUserDetails.REPORT_USER_ERROR_UNAUTH,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }

        if (res.status === 200) {
            //.log('responseblak', res)
            if (res.data.status === 404) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: reportUserDetails.REPORT_STOP_LOADING,
                    payload: {},
                })
            } else if (res.data.status >= 500) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: reportUserDetails.REPORT_STOP_LOADING,
                    payload: {},
                })
            } else {
                yield put({
                    type: reportUserDetails.DISPLAY_EDITED_REPORTDATA,
                    payload: res.data,
                })
                yield put({
                    type: adminUserConstant.PASS_REPORT_MASTER,
                    payload: res.data,
                })
                yield put({
                    type: reportUserDetails.DISPLAY_EDITED_REPORT,
                    payload: res.data,
                })
            }
        } else {
            if (res.data.error) {
                NotificationManager.error(res.data.error)
            } else {
                NotificationManager.error(
                    'Internal application error. Please contact system administrator.'
                )
            }
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: reportUserDetails.REPORT_STOP_LOADING,
            payload: {},
        })
    }
}

function* getAllReportMaster() {
    yield takeEvery(reportUserDetails.GET_EDITED_REPORTDATA, getReportMasterAll)
}
//==============================================Save New Report =====================================================

function convert(str) {
    var date = new Date(str),
        mnth = ('0' + (date.getMonth() + 1)).slice(-2),
        day = ('0' + date.getDate()).slice(-2)
    return [date.getFullYear(), mnth, day].join('-')
}
function* saveNewReportMaster(payload) {
    var props = payload.payload
    console.log('report post master1', props)
    var Sdate = new Date(props.startTime).toISOString()
    var SendDate = new Date(props.endTime).toISOString()
    let desctiPara =
        props.destination == 'email'
            ? {
                  email_to: props.toEmail,
                  email_cc: props.ccEmail,
                  email_subject: props.subjectEmail,
                  email_filePrefix: props.prefixEmail,
                  email_fileSuffix: props.suffixEmail,
              }
            : props.destination == 'folder'
            ? {
                  folder_path: props.pathFolder,
                  folder_filePrefix: props.prefixFolder,
                  folder_fileSuffix: props.suffixFolder,
              }
            : {
                  ftp_ip: props.ipFtp,
                  ftp_userName: props.userNameFtp,
                  ftp_password: props.passwordFtp,
                  ftp_folderPath: props.folderPathFtp,
                  ftp_fileprefix: props.prefixFtp,
                  ftp_filesuffix: props.suffixFtp,
                  ftp_port: props.ftpPort,
              }
    let destinationParameter = desctiPara

    let outputParameter = {
        imageSuffixFile: props.suffixImages,
    }
    let interactiveParameter = {
        outputTableName: props.outputTableName,
        validity: props.validity,
    }
    let interactiveEmpty = {
        outputTableName: '',
        validity: '',
    }
    let interac =
        props.interactive == false ? interactiveParameter : interactiveEmpty

    function output() {
        if (props.pdfOutput) {
            return 'pdf'
        } else if (props.excelOutput) {
            return 'csv'
        } else {
            return 'image'
        }
    }

    function destinations() {
        if (props.emailDestination) {
            return 'email'
        } else if (props.folderDestination) {
            return 'folder'
        } else {
            return 'ftp'
        }
    }
    try {
        var req = JSON.stringify({
            moduleId: props.moduleId,
            reportName: props.reportName,
            execution: props.execution,
            server: props.server,
            startTime: props.startTime,
            endTime: props.endTime,
            serverParameters:
                Object.keys(props.parameterJsonView).length == 0
                    ? ''
                    : JSON.stringify(props.parameterJsonView),
            destination: props.destination,
            intervalTime: props.intervalTime,
            // destinations(),
            destinationParameters: JSON.stringify(destinationParameter),
            outputParameters: JSON.stringify(outputParameter),
            outputType: props.output,
            //output(),
            interactiveParameters: interac != '' ? JSON.stringify(interac) : '',
            interactive: props.interactive,
            description: props.description,
            transactionName: props.transactionName,
            parameters: props.parameters,
            displayType: props.component,
        })

        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        }
        var res = yield axios
            .request({
                method: 'post',
                url: UPDATE_BASE_URL + SAVE_NEW_REPORT,
                headers: headers,
                data: req,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: reportUserDetails.REPORT_USER_ERROR_UNAUTH,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status === 200) {
            if (res.data.status === 400) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: reportUserDetails.REPORT_STOP_LOADING,
                    payload: {},
                })
            } else {
                NotificationManager.success('Successfully Created')
                yield put({
                    type: reportUserDetails.GET_EDITED_REPORTDATA,
                    payload: {},
                })
                yield put({
                    type: reportUserDetails.SAVE_NEW_REPORT_RESPONSE,
                    payload: res.data,
                })
            }
        } else {
            NotificationManager.error(
                'Internal application error. Please contact system administrator.'
            )
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: reportUserDetails.REPORT_STOP_LOADING,
            payload: {},
        })
    }
}

function* saveNewReport() {
    yield takeEvery(
        reportUserDetails.SAVE_NEW_REPORT_REQUEST,
        saveNewReportMaster
    )
}

//==========================================get by id report master ===========================================
function* getReportMasterById(payload) {
    var props = payload.payload
    console.log('reportId', props)
    try {
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        }
        var res = yield axios
            .request({
                method: 'get',
                url: UPDATE_BASE_URL + REPORT_BY_ID + props,
                headers: headers,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })

        if (res.status === 401) {
            //console.log('401')
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: reportUserDetails.REPORT_USER_ERROR_UNAUTH,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status === 403) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: reportUserDetails.REPORT_USER_ERROR_UNAUTH,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }

        if (res.status === 200) {
            if (res.data.status === 404) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: reportUserDetails.REPORT_STOP_LOADING,
                    payload: {},
                })
            } else if (res.data.status >= 500) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: reportUserDetails.REPORT_STOP_LOADING,
                    payload: {},
                })
            } else {
                yield put({
                    type: reportUserDetails.DISPLAY_REPORT_MASTER_BYID,
                    payload: res.data,
                })
            }
        } else {
            if (res.data.error) {
                NotificationManager.error(res.data.error)
            } else {
                NotificationManager.error(
                    'Internal application error. Please contact system administrator.'
                )
            }
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: reportUserDetails.REPORT_STOP_LOADING,
            payload: {},
        })
    }
}

function* getReportById() {
    yield takeEvery(
        reportUserDetails.GET_REPORT_MASTER_BYID,
        getReportMasterById
    )
}

//==============================================Save edited Report =====================================================
function changeDate(dates) {
    var now = new Date(dates)
    return (
        now.getFullYear() +
        '-' +
        (now.getMonth() + 1) +
        '-' +
        now.getDate() +
        ' ' +
        now.getHours() +
        ':' +
        (now.getMinutes() < 10 ? '0' + now.getMinutes() : now.getMinutes()) +
        ':' +
        (now.getSeconds() < 10 ? '0' + now.getSeconds() : now.getSeconds())
    )
}
function* saveEditedReport(payload) {
    var props = payload.payload
    let desctiPara =
        props.destination == 'email'
            ? {
                  email_to: props.toEmail,
                  email_cc: props.ccEmail,
                  email_subject: props.subjectEmail,
                  email_filePrefix: props.prefixEmail,
                  email_fileSuffix: props.suffixEmail,
              }
            : props.destination == 'folder'
            ? {
                  folder_path: props.pathFolder,
                  folder_filePrefix: props.prefixFolder,
                  folder_fileSuffix: props.suffixFolder,
              }
            : {
                  ftp_ip: props.ipFtp,
                  ftp_userName: props.userNameFtp,
                  ftp_password: props.passwordFtp,
                  ftp_folderPath: props.folderPathFtp,
                  ftp_fileprefix: props.prefixFtp,
                  ftp_filesuffix: props.suffixFtp,
                  ftp_port: props.ftpPort,
              }
    let destinationParameter = desctiPara
    let outputParameter = {
        imageSuffixFile: props.suffixImages,
    }
    let interactiveParameter = {
        outputTableName: props.outputTableName,
        validity: props.validity,
    }
    let interactiveEmpty = {
        outputTableName: '',
        validity: '',
    }
    let interac =
        props.interactive == false ? interactiveParameter : interactiveEmpty

    function output() {
        if (props.pdfOutput) {
            return 'pdf'
        } else if (props.excelOutput) {
            return 'csv'
        } else {
            return 'image'
        }
    }

    function destinations() {
        if (props.emailDestination) {
            return 'email'
        } else if (props.folderDestination) {
            return 'folder'
        } else {
            return 'ftp'
        }
    }

    var date = new Date(props.startTime).toISOString()
    try {
        var req = JSON.stringify({
            moduleId: props.moduleId,
            reportName: props.reportName,
            execution: props.execution,
            server: props.server,
            description: props.description,
            serverParameters:
                Object.keys(props.parameterJsonView).length == 0
                    ? ''
                    : JSON.stringify(props.parameterJsonView),
            destination: props.destination,
            //destinations(),
            destinationParameters: JSON.stringify(destinationParameter),
            outputType: props.output,
            outputParameters: JSON.stringify(outputParameter),
            //output(),
            startTime: changeDate(props.startTime),
            interactive: props.interactive,
            intervalTime: props.intervalTime,
            transactionName: props.transactionName,
            interactiveParameters: interac != '' ? JSON.stringify(interac) : '',
            endTime:
                props.endTime == null
                    ? changeDate(props.startTime)
                    : changeDate(props.endTime),
            modifiedBy: props.modifiedBy.name,
            displayType: props.component,
        })
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        }
        var res = yield axios
            .request({
                method: 'put',
                url: UPDATE_BASE_URL + REPORT_BY_ID + props.reportId,
                headers: headers,
                data: req,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: reportUserDetails.REPORT_USER_ERROR_UNAUTH,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status === 200) {
            if (res.data.status === 400) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: reportUserDetails.REPORT_STOP_LOADING,
                    payload: {},
                })
            } else if (res.data.status === 500) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: reportUserDetails.REPORT_STOP_LOADING,
                    payload: {},
                })
            } else {
                NotificationManager.success('Successfully updated')
                yield put({
                    type: reportUserDetails.GET_EDITED_REPORTDATA,
                    payload: {},
                })
                yield put({
                    type: reportUserDetails.RESPONSE_SAVE_EDITED_REPORT,
                    payload: res.data,
                })
            }
        } else {
            NotificationManager.error(
                'Internal application error. Please contact system administrator.'
            )
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: reportUserDetails.REPORT_STOP_LOADING,
            payload: {},
        })
    }
}

function* saveEditedReportMaster() {
    yield takeEvery(
        reportUserDetails.REQUEST_SAVE_EDITED_REPORT,
        saveEditedReport
    )
}

//==============================================delete edited Report =====================================================

function* deleteReportMasterById(payload) {
    var props = payload.payload
    console.log('report post edited', props)

    try {
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        }
        var res = yield axios
            .request({
                method: 'delete',
                url: UPDATE_BASE_URL + REPORT_BY_ID + props,
                headers: headers,
                // data: req,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: reportUserDetails.REPORT_USER_ERROR_UNAUTH,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status === 200) {
            if (res.data.status === 400) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: reportUserDetails.REPORT_STOP_LOADING,
                    payload: {},
                })
            } else if (res.data.code === 409) {
                NotificationManager.error(res.data.message)
                yield put({
                    type: reportUserDetails.REPORT_STOP_LOADING,
                    payload: {},
                })
            } else {
                NotificationManager.success('Successfully deleted')
                yield put({
                    type: reportUserDetails.GET_EDITED_REPORTDATA,
                    payload: {},
                })
                yield put({
                    type: reportUserDetails.RESPONSE_DELETE_REPORT_BYID,
                    payload: res.data,
                })
            }
        } else {
            NotificationManager.error(
                'Internal application error. Please contact system administrator.'
            )
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: reportUserDetails.REPORT_STOP_LOADING,
            payload: {},
        })
    }
}

function* deleteReportById() {
    yield takeEvery(
        reportUserDetails.REQUEST_DELETE_REPORT_BYID,
        deleteReportMasterById
    )
}

//==========================================get by id report master ===========================================
function* getServerLinkById(payload) {
    var props = payload.payload
    console.log('reportId', props, props.regenerate)
    try {
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        }
        var res = yield axios
            .request({
                method: 'get',
                url:
                    GENERATE_TAG +
                    props.value.reportId +
                    '/' +
                    props.value.transactionName,
                headers: headers,
                params: {
                    regenerate: props.regenerate,
                },
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })

        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: reportUserDetails.REPORT_USER_ERROR_UNAUTH,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status === 403) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: reportUserDetails.REPORT_USER_ERROR_UNAUTH,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }

        if (res.status === 200) {
            if (res.data.status === 404) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: reportUserDetails.REPORT_STOP_LOADING,
                    payload: {},
                })
            } else if (res.data.status === 400) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: reportUserDetails.GET_DEFAULT_SETTINGS,
                    payload: {},
                })
            } else if (res.data.status == 500) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: reportUserDetails.REPORT_STOP_LOADING,
                    payload: {},
                })
            } else if (res.data.code == 200) {
                NotificationManager.info(res.data.message)
                yield put({
                    type: reportUserDetails.GET_EDITED_REPORTDATA,
                    payload: {},
                })
                yield put({
                    type: reportUserDetails.DISPLAY_SERVER_BYID,
                    payload: res.data,
                })
                yield put({
                    type: reportUserDetails.REPORT_STOP_LOADING,
                    payload: {},
                })
            }
        } else {
            if (res.data.error) {
                NotificationManager.error(res.data.error)
            } else {
                NotificationManager.error(
                    'Internal application error. Please contact system administrator.'
                )
            }
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: reportUserDetails.REPORT_STOP_LOADING,
            payload: {},
        })
    }
}

function* getServerById() {
    yield takeEvery(reportUserDetails.GET_SERVER_BYID, getServerLinkById)
}

//==========================================get by id report master ===========================================
function* getTableDesignById(payload) {
    var props = payload.payload

    try {
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        }
        var res = yield axios
            .request({
                method: 'get',
                url:
                    UPDATE_BASE_URL +
                    COLUMN_AND_DESIGN +
                    props.reportId +
                    '/' +
                    props.transactionName,
                headers: headers,
                params: {
                    activeRecordsOnly: 'false',
                },
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })

        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: reportUserDetails.REPORT_USER_ERROR_UNAUTH,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status === 403) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: reportUserDetails.REPORT_USER_ERROR_UNAUTH,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }

        if (res.status === 200) {
            if (res.data.status === 400) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: reportUserDetails.GET_DEFAULT_SETTINGS,
                    payload: {},
                })
            } else if (res.data.status === 500) {
                NotificationManager.info('Please set the design details')
                yield put({
                    type: reportUserDetails.REPORT_STOP_LOADING,
                    payload: {},
                })
            } else if (res.data.status === 404) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: reportUserDetails.GET_DEFAULT_SETTINGS,
                    payload: {},
                })
            } else if (res.data.code === 200) {
                if (res.data.result == null) {
                    yield put({
                        type: reportUserDetails.GET_DEFAULT_SETTINGS,
                        payload: res.data.result && res.data.result,
                    })
                } else {
                    // NotificationManager.success(res.data.message)
                    yield put({
                        type: reportUserDetails.DISPLAY_REPORT_TABLE_DESIGN,
                        payload: res.data.result && res.data.result,
                    })
                }
            }
        } else {
            if (res.data.error) {
                NotificationManager.error(res.data.error)
            } else {
                NotificationManager.error(
                    'Internal application error. Please contact system administrator.'
                )
            }
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: reportUserDetails.REPORT_STOP_LOADING,
            payload: {},
        })
    }
}

function* getTableDesignByRow() {
    yield takeEvery(
        reportUserDetails.GET_REPORT_TABLE_DESIGN,
        getTableDesignById
    )
}

//==========================================get by id report master ===========================================
function* getDefaultTableSttings(payload) {
    var props = payload.payload
    console.log('desfult', props)

    try {
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        }
        var res = yield axios
            .request({
                method: 'get',
                url: DEFAULT_SETTINGS_DISPLAYTYPE + props,
                headers: headers,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })

        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: reportUserDetails.REPORT_USER_ERROR_UNAUTH,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status === 403) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: reportUserDetails.REPORT_USER_ERROR_UNAUTH,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }

        if (res.status === 200) {
            if (res.data.status === 404) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: reportUserDetails.REPORT_STOP_LOADING,
                    payload: {},
                })
            } else if (res.data.status >= 500) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: reportUserDetails.REPORT_STOP_LOADING,
                    payload: {},
                })
            } else {
                // NotificationManager.info('Please set the design details')
                yield put({
                    type: reportUserDetails.DISPLAY_DEFAULT_SETTINGS,
                    payload: res.data.result,
                })
            }
        } else {
            if (res.data.error) {
                NotificationManager.error(res.data.error)
            } else {
                NotificationManager.error(
                    'Internal application error. Please contact system administrator.'
                )
            }
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: reportUserDetails.REPORT_STOP_LOADING,
            payload: {},
        })
    }
}

function* getDefaultSettings() {
    yield takeEvery(
        reportUserDetails.GET_DEFAULT_SETTINGS,
        getDefaultTableSttings
    )
}

///========================================post default design settings=========================================
function* postEditedDesignJson(payload) {
    var props = payload.payload
    var allColumns =
        props.ReportReducers &&
        props.ReportReducers.AllColumnValue.map((item) => {
            return {
                ...item,
                active: item.isActive == 0 ? false : true,
            }
        })

    let barDesign = {
        headerBarColor: props.ReportReducers.headerBarColor,
        barFontFamily: props.ReportReducers.barFontFamily,
        barHeightStyle: props.ReportReducers.barHeightStyle,
        barTitleFontSize: props.ReportReducers.barTitleFontSize,
        barWidthStyle: props.ReportReducers.barWidthStyle,
        refreshDuration: props.ReportReducers.refreshDuration,
        widgetWidth: props.ReportReducers.widgetWidth,
    }
    let donutDesign = {
        headerDonutColor: props.ReportReducers.headerDonutColor,
        donutFontFamily: props.ReportReducers.donutFontFamily,
        donutHeightStyle: props.ReportReducers.donutHeightStyle,
        donutWidthStyle: props.ReportReducers.donutWidthStyle,
        donutTitleFontSize: props.ReportReducers.donutTitleFontSize,
        gridDonut: props.ReportReducers.gridDonut,
        refreshDuration: props.ReportReducers.refreshDuration,
        widgetWidth: props.ReportReducers.widgetWidth,
    }
    let cardDesigns = {
        cardColor: props.ReportReducers.cardColor,
        cardBackgroundColor: props.ReportReducers.cardBackgroundColor,
        cardTitleColor: props.ReportReducers.cardTitleColor,
        cardPaddingLeft: props.ReportReducers.cardPaddingLeft,
        cardPaddingRight: props.ReportReducers.cardPaddingRight,
        cardPaddingRight: props.ReportReducers.cardPaddingRight,
        cardPaddingBottom: props.ReportReducers.cardPaddingBottom,
        cardFontSize: props.ReportReducers.cardFontSize,
        titleFontSize: props.ReportReducers.titleFontSize,
        cardFontFamily: props.ReportReducers.cardFontFamily,
        cardTitleFontFamily: props.ReportReducers.cardTitleFontFamily,
        cardHeaderFontSize: props.ReportReducers.cardHeaderFontSize,
        cardHeaderColor: props.ReportReducers.cardHeaderColor,
        cardHeaderFontFamily: props.ReportReducers.cardHeaderFontFamily,
        gridCount: props.ReportReducers.gridCount,
        widgetWidth: props.ReportReducers.widgetWidth,
    }
    let allDesignsPost = {
        headerBackgroundColor: props.ReportReducers.headerBackgroundColor,
        tablePaddingLeft: props.ReportReducers.tablePaddingLeft,
        tablePaddingRight: props.ReportReducers.tablePaddingRight,
        tablePaddingTop: props.ReportReducers.tablePaddingTop,
        tablePaddingBottom: props.ReportReducers.tablePaddingBottom,
        tableFontSize: props.ReportReducers.tableFontSize,
        tableTitleFontSize: props.ReportReducers.tableTitleFontSize,
        tableFontFamily: props.ReportReducers.tableFontFamily,
        tableColor: props.ReportReducers.tableColor,
        headerFontColor: props.ReportReducers.headerFontColor,
        headerFontSize: props.ReportReducers.headerFontSize,
        highlightColor: props.ReportReducers.highlightColor,
        margin: props.ReportReducers.margin,
        numberOfRows: props.ReportReducers.numberOfRows,
        fontSize: props.ReportReducers.fontSize,
        rowSelectedBackgroundColor:
            props.ReportReducers.rowSelectedBackgroundColor,
        tableBackgroundColor: props.ReportReducers.tableBackgroundColor,
        tableTitleFontStyle: props.ReportReducers.tableTitleFontStyle,
        textAlign: props.ReportReducers.textAlign,
        verticalAlign: props.ReportReducers.verticalAlign,
        widgetWidth: props.ReportReducers.widgetWidth,
    }

    try {
        var req = JSON.stringify({
            displayType: props.ReportReducers.component,
            designSettings:
                props.ReportReducers.component == 'DoughnutChart'
                    ? JSON.stringify(donutDesign)
                    : props.ReportReducers.component == 'Barchart'
                    ? JSON.stringify(barDesign)
                    : props.ReportReducers.component == 'Card'
                    ? JSON.stringify(cardDesigns)
                    : JSON.stringify(allDesignsPost),
            reportId: props.ReportReducers.getReportMaterById.reportId,
            reportName: props.ReportReducers.getReportMaterById.reportName,
            displayMode: props.allData.displayMode,
            transactionName:
                props.ReportReducers.getReportMaterById.transactionName,
            refreshDuration:
                props.ReportReducers.component == 'DoughnutChart'
                    ? props.ReportReducers.donutRefreshDuration
                    : props.ReportReducers.component == 'Card'
                    ? props.ReportReducers.cardRefreshDuration
                    : props.ReportReducers.component == 'Barchart'
                    ? props.ReportReducers.barRefreshDuration
                    : props.ReportReducers.refreshDuration,
            modifiedBy: props.ReportReducers.modifiedBy.name,
            uiReportColumnProfiles: allColumns,
        })

        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        }
        var res = yield axios
            .request({
                method: 'POST',
                url: UPDATE_BASE_URL + POST_DEFAULT_DESIGN,
                //DEFAULT_SETTINGS + props.ReportReducers.getReportMaterById.reportId,
                headers: headers,
                params: {
                    activeRecordsOnly: 'false',
                },
                data: req,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: reportUserDetails.REPORT_USER_ERROR_UNAUTH,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status === 200) {
            if (res.data.status === 400) {
                // console.log("inside 400",res)
                NotificationManager.error(res.data.error)
                yield put({
                    type: reportUserDetails.REPORT_STOP_LOADING,
                    payload: {},
                })
            } else if (res.data.status === 404) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: reportUserDetails.REPORT_STOP_LOADING,
                    payload: {},
                })
            } else if (res.data.code === 200) {
                NotificationManager.success(res.data.message)
                yield put({
                    type: reportUserDetails.RESPONSE_NEW_DESIGN_COLUMNS,
                    payload: {},
                })
            }
        } else {
            NotificationManager.error(
                'Internal application error. Please contact system administrator.'
            )
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: reportUserDetails.REPORT_STOP_LOADING,
            payload: {},
        })
    }
}

function* postDefaultSettings() {
    yield takeEvery(
        reportUserDetails.REQUEST_NEW_DESIGN_COLUMNS,
        postEditedDesignJson
    )
}
//========================================update the data=================================================
function* updateEditedDesignJson(payload) {
    var props = payload.payload

    var allColumn = props.ReportReducers.AllColumnValue.map((item) => {
        return {
            ...item,
            active: item.isActive == 0 ? false : true,
        }
    })
    let barDesign = {
        headerBarColor: props.ReportReducers.headerBarColor,
        barFontFamily: props.ReportReducers.barFontFamily,
        barHeightStyle: props.ReportReducers.barHeightStyle,
        barTitleFontSize: props.ReportReducers.barTitleFontSize,
        barWidthStyle: props.ReportReducers.barWidthStyle,
        refreshDuration: props.ReportReducers.refreshDuration,
        widgetWidth: props.ReportReducers.widgetWidth,
    }
    let cardDesigns = {
        cardColor: props.ReportReducers.cardColor,
        cardBackgroundColor: props.ReportReducers.cardBackgroundColor,
        cardTitleColor: props.ReportReducers.cardTitleColor,
        cardPaddingLeft: props.ReportReducers.cardPaddingLeft,
        cardPaddingRight: props.ReportReducers.cardPaddingRight,
        cardPaddingRight: props.ReportReducers.cardPaddingRight,
        cardPaddingBottom: props.ReportReducers.cardPaddingBottom,
        cardFontSize: props.ReportReducers.cardFontSize,
        titleFontSize: props.ReportReducers.titleFontSize,
        cardFontFamily: props.ReportReducers.cardFontFamily,
        cardTitleFontFamily: props.ReportReducers.cardTitleFontFamily,
        cardHeaderFontSize: props.ReportReducers.cardHeaderFontSize,
        cardHeaderColor: props.ReportReducers.cardHeaderColor,
        cardHeaderFontFamily: props.ReportReducers.cardHeaderFontFamily,
        gridCount: props.ReportReducers.gridCount,
        widgetWidth: props.ReportReducers.widgetWidth,
    }
    let donutDesign = {
        headerDonutColor: props.ReportReducers.headerDonutColor,
        donutFontFamily: props.ReportReducers.donutFontFamily,
        donutHeightStyle: props.ReportReducers.donutHeightStyle,
        donutWidthStyle: props.ReportReducers.donutWidthStyle,
        donutTitleFontSize: props.ReportReducers.donutTitleFontSize,
        gridDonut: props.ReportReducers.gridDonut,
        refreshDuration: props.ReportReducers.refreshDuration,
        widgetWidth: props.ReportReducers.widgetWidth,
    }
    let allDesigns = {
        headerBackgroundColor: props.ReportReducers.headerBackgroundColor,
        tablePaddingLeft: props.ReportReducers.tablePaddingLeft,
        tablePaddingRight: props.ReportReducers.tablePaddingRight,
        tablePaddingTop: props.ReportReducers.tablePaddingTop,
        tablePaddingBottom: props.ReportReducers.tablePaddingBottom,
        tableFontSize: props.ReportReducers.tableFontSize,
        tableTitleFontSize: props.ReportReducers.tableTitleFontSize,
        tableFontFamily: props.ReportReducers.tableFontFamily,
        tableColor: props.ReportReducers.tableColor,
        headerFontColor: props.ReportReducers.headerFontColor,
        headerFontSize: props.ReportReducers.headerFontSize,
        highlightColor: props.ReportReducers.highlightColor,
        fontSize: props.ReportReducers.fontSize,
        margin: props.ReportReducers.margin,
        numberOfRows: props.ReportReducers.numberOfRows,
        rowSelectedBackgroundColor:
            props.ReportReducers.rowSelectedBackgroundColor,
        tableBackgroundColor: props.ReportReducers.tableBackgroundColor,
        tableTitleFontStyle: props.ReportReducers.tableTitleFontStyle,
        textAlign: props.ReportReducers.textAlign,
        verticalAlign: props.ReportReducers.verticalAlign,
        widgetWidth: props.ReportReducers.widgetWidth,
    }

    try {
        var req = JSON.stringify({
            displayType: props.ReportReducers.component,
            designSettings:
                props.ReportReducers.component == 'DoughnutChart'
                    ? JSON.stringify(donutDesign)
                    : props.ReportReducers.component == 'Barchart'
                    ? JSON.stringify(barDesign)
                    : props.ReportReducers.component == 'Card'
                    ? JSON.stringify(cardDesigns)
                    : JSON.stringify(allDesigns),
            reportId: props.ReportReducers.designSettingsTableSequence.reportId,
            reportName:
                props.ReportReducers.designSettingsTableSequence.reportName,
            displayMode:
                props.ReportReducers.designSettingsTableSequence.displayMode,
            transactionName:
                props.ReportReducers.designSettingsTableSequence
                    .transactionName,
            refreshDuration:
                props.ReportReducers.component == 'DoughnutChart'
                    ? props.ReportReducers.donutRefreshDuration
                    : props.ReportReducers.component == 'Card'
                    ? props.ReportReducers.cardRefreshDuration
                    : props.ReportReducers.component == 'Barchart'
                    ? props.ReportReducers.barRefreshDuration
                    : props.ReportReducers.refreshDuration,
            modifiedBy: props.ReportReducers.modifiedBy.name,
            uiReportColumnProfiles: allColumn,
        })

        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        }
        var res = yield axios
            .request({
                method: 'put',
                // url:
                //     UPDATE_BASE_URL +
                //     UPLOAD_DESIGN_AND_TABLE +
                //     props.ReportReducers.getReportMaterById.reportId,

                url:
                    UPDATE_BASE_URL +
                    UPDATE_DESIGN_AND_COLUMN +
                    props.ReportReducers.getReportMaterById.reportId +
                    '/' +
                    props.ReportReducers.getReportMaterById.transactionName,

                //DEFAULT_SETTINGS + props.ReportReducers.getReportMaterById.reportId,
                headers: headers,
                params: {
                    activeRecordsOnly: 'false',
                },
                data: req,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: reportUserDetails.REPORT_USER_ERROR_UNAUTH,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status === 200) {
            if (res.data.status === 400) {
                // console.log("inside 400",res)
                NotificationManager.error(res.data.error)
                yield put({
                    type: reportUserDetails.REPORT_STOP_LOADING,
                    payload: {},
                })
            } else if (res.data.status === 404) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: reportUserDetails.REPORT_STOP_LOADING,
                    payload: {},
                })
            } else {
                NotificationManager.success('Successfully updated')
                yield put({
                    type: reportUserDetails.RESPONSE_DEFAULT_SETTINGS,
                    payload: {},
                })
            }
        } else {
            NotificationManager.error(
                'Internal application error. Please contact system administrator.'
            )
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: reportUserDetails.REPORT_STOP_LOADING,
            payload: {},
        })
    }
}
function* updateDefaultDesign() {
    yield takeEvery(
        reportUserDetails.UPDATE_DEFAULT_SETTINGS,
        updateEditedDesignJson
    )
}
//=========================================delete records in design settings==============================
function* deleteDesignSettings(payload) {
    var props = payload.payload
    console.log('deleteDesignSettings', props)
    try {
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        }
        var res = yield axios
            .request({
                method: 'delete',
                url:
                    UPDATE_BASE_URL +
                    UPDATE_DESIGN_AND_COLUMN +
                    props.ReportReducers.getReportMaterById.reportId +
                    '/' +
                    props.ReportReducers.getReportMaterById.transactionName,
                headers: headers,
                params: {
                    activeRecordsOnly: 'false',
                },
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: reportUserDetails.REPORT_USER_ERROR_UNAUTH,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status === 200) {
            if (res.data.status === 400) {
                // console.log("inside 400",res)
                NotificationManager.error(res.data.error)
                yield put({
                    type: reportUserDetails.REPORT_STOP_LOADING,
                    payload: {},
                })
            } else if (res.data.status === 404) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: reportUserDetails.REPORT_STOP_LOADING,
                    payload: {},
                })
            } else {
                NotificationManager.success('Successfully deleted')
                yield put({
                    type: reportUserDetails.GET_EDITED_REPORTDATA,
                    payload: {},
                })
                yield put({
                    type: reportUserDetails.DELETE_RESPONSE_DEFAULT_SETTINGS,
                    payload: {},
                })
            }
        } else {
            NotificationManager.error(
                'Internal application error. Please contact system administrator.'
            )
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: reportUserDetails.REPORT_STOP_LOADING,
            payload: {},
        })
    }
}

function* deleteDefaultDesign() {
    yield takeEvery(
        reportUserDetails.DELETE_REQUEST_DEFAULT_SETTINGS,
        deleteDesignSettings
    )
}

//==========================================get Module id ================================================
function* getModuleIds(payload) {
    var props = payload.payload
    console.log('desfult', props)

    try {
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        }
        var res = yield axios
            .request({
                method: 'get',
                url: MODULE_ID,
                headers: headers,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })

        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: reportUserDetails.REPORT_USER_ERROR_UNAUTH,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status === 403) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: reportUserDetails.REPORT_USER_ERROR_UNAUTH,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }

        if (res.status === 200) {
            if (res.data.status === 404) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: reportUserDetails.REPORT_STOP_LOADING,
                    payload: {},
                })
            } else if (res.data.status >= 500) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: reportUserDetails.REPORT_STOP_LOADING,
                    payload: {},
                })
            } else {
                // NotificationManager.info('Please set the design details')
                yield put({
                    type: reportUserDetails.DISPLAY_MODULE_ID,
                    payload: res.data,
                })
            }
        } else {
            if (res.data.error) {
                NotificationManager.error(res.data.error)
            } else {
                NotificationManager.error(
                    'Internal application error. Please contact system administrator.'
                )
            }
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: reportUserDetails.REPORT_STOP_LOADING,
            payload: {},
        })
    }
}

function* getModuleById() {
    yield takeEvery(reportUserDetails.GET_MODULE_ID, getModuleIds)
}
function* getUniqueGroupList() {
    //console.log('head', headers)
    try {
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${getJwtToken()}`,
            Accept: 'application/json',
        }
        var res = yield axios
            .request({
                method: 'get',
                url: GET_UNIQUE_GROUP_LIST,
                headers: headers,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        if (res.status === 401) {
            //console.log('401')
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: reportUserDetails.REPORT_USER_ERROR_UNAUTH,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }

        if (res.status === 200) {
            console.log('responseblak', res)
            if (res.data.status === 404) {
                NotificationManager.error(res.data.error)
            } else if (res.data.status === 404) {
                NotificationManager.error(res.data.error)
            } else if (res.data.status === 500) {
                NotificationManager.error(res.data.error)
            } else if (res.data != '' || res.data.code === 200) {
                yield put({
                    type: reportUserDetails.DISPLAY_UNIQUE_GRP,
                    payload: res.data.result,
                })
            }
        } else {
            NotificationManager.error(
                'Internal application error. Please contact system administrator.'
            )
        }
    } catch (error) {
        console.log(error)

        yield put({
            type: reportUserDetails.REPORT_STOP_LOADING,
            payload: {},
        })
        return
    }
}

function* getUniqueGroup() {
    yield takeEvery(reportUserDetails.GET_UNIQUE_GRP, getUniqueGroupList)
}

//=======================================user and group list================================================
function* getGroupList(payload) {
    const props = payload.payload
    try {
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${getJwtToken()}`,
            Accept: 'application/json',
        }
        var res = yield axios
            .request({
                method: 'get',
                url:
                    GET_GROUP_LIST_MULTI +
                    props.reportId +
                    '/' +
                    props.transactionName +
                    '/reportcenter',
                headers: headers,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        if (res.status === 401) {
            //console.log('401')
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: reportUserDetails.REPORT_USER_ERROR_UNAUTH,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }

        if (res.status === 200) {
            // console.log('responseblak', res)
            if (res.data.status === 404) {
                NotificationManager.error(res.data.error)
            } else if (res.data.status === 404) {
                NotificationManager.error(res.data.error)
            } else if (res.data.status === 500) {
                NotificationManager.error(res.data.error)
            } else if (res.data.code == 200) {
                yield put({
                    type: reportUserDetails.DISPLAY_GROUP_LIST_USER,
                    payload: res.data.result,
                })
                if (res.data.result.groupList) {
                    yield put({
                        type: reportUserDetails.GET_USER_LIST_BY_GRP,
                        payload: res.data.result.groupList
                            ? res.data.result.groupList.split(',')
                            : null,
                    })
                }
            }
        } else {
            NotificationManager.error(
                'Internal application error. Please contact system administrator.'
            )
        }
    } catch (error) {
        console.log(error)

        yield put({
            type: reportUserDetails.REPORT_STOP_LOADING,
            payload: {},
        })
        return
    }
}

function* getGroupListMulti() {
    yield takeEvery(reportUserDetails.GET_GROUP_LIST_USER, getGroupList)
}
//=======================================from group list to user================================================
function* getGrpToUserList(payload) {
    console.log('getGrpToUserList', payload)

    try {
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${getJwtToken()}`,
            Accept: 'application/json',
        }
        var data = JSON.stringify(payload.payload)
        var res = yield axios
            .request({
                method: 'POST',
                url: GET_USERLIST_BY_GRP,
                headers: headers,
                data: data,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        if (res.status === 401) {
            //console.log('401')
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: reportUserDetails.REPORT_USER_ERROR_UNAUTH,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }

        if (res.status === 200) {
            if (res.data.status === 404) {
                NotificationManager.error(res.data.error)
            } else if (res.data.status === 404) {
                NotificationManager.error(res.data.error)
            } else if (res.data.status === 500) {
                NotificationManager.error(res.data.error)
            } else if (res.data.code == 200) {
                yield put({
                    type: reportUserDetails.DISPLAY_USER_LIST_BY_GRP,
                    payload: res.data.result,
                })
                // yield put({
                //     type: reportUserDetails.GET_GROUP_LIST_USER,
                //     payload: {
                //         reportId: payload.payload.ReportReducers.reportId,
                //         transactionName:
                //             payload.payload.ReportReducers.transactionName,
                //     },
                // })
            }
        } else {
            NotificationManager.error(
                'Internal application error. Please contact system administrator.'
            )
        }
    } catch (error) {
        console.log(error)

        yield put({
            type: reportUserDetails.REPORT_STOP_LOADING,
            payload: {},
        })
        return
    }
}

function* getUserListFromGrp() {
    yield takeEvery(reportUserDetails.GET_USER_LIST_BY_GRP, getGrpToUserList)
}

function* getUserLists(payload) {
    try {
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        }
        var res = yield axios
            .request({
                method: 'get',
                url: UPDATE_BASE_URL + ADD_AUTHENTICATION + USER_ROLES,
                headers: headers,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        if (res.status === 403) {
            NotificationManager.error('Unauthorized Access')

            yield put({
                type: reportUserDetails.REPORT_USER_ERROR_UNAUTH,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }

        if (res.status === 200) {
            if (res.data.status == 400) {
                NotificationManager.error(res.data.message)
            } else if (res.data.status === 500) {
                NotificationManager.error(res.data.error)
            } else if (res.data != '') {
                localStorage.setItem(
                    'AllUsers',
                    JSON.stringify(res.data.result)
                )
                yield put({
                    type: reportUserDetails.DISPLAY_USER_LIST,
                    payload: res.data.result,
                })
            }
        } else {
            NotificationManager.error(
                'Internal application error. Please contact system administrator.'
            )
        }
    } catch (error) {
        console.log(error)

        yield put({
            type: reportUserDetails.REPORT_STOP_LOADING,
            payload: {},
        })
        return
    }
}

function* getUserList() {
    yield takeEvery(reportUserDetails.GET_USER_LIST, getUserLists)
}

function* saveUserGrpRequest(payload) {
    let props = payload.payload
    let user =
        props &&
        props.userList.map((itm) => {
            return itm.id
        })
    let group =
        props &&
        props.grpList.map((itm) => {
            return itm.name
        })

    try {
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        }
        let data = JSON.stringify({
            reportId: props.reportUser.reportId,
            transactionName: props.reportUser.transactionName,
            displayMode: 'reportcenter',
            groupList: group.toString(),
            userList: user.toString(),
        })
        console.log('data', data, props.reportUser, props)
        var res = yield axios
            .request({
                method: 'POST',
                url: SAVE_USER_AND_GRP_API,
                headers: headers,
                data: data,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        if (res.status === 403) {
            NotificationManager.error('Unauthorized Access')

            yield put({
                type: reportUserDetails.REPORT_USER_ERROR_UNAUTH,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }

        if (res.status === 200) {
            if (res.data.status == 400) {
                NotificationManager.error(res.data.message)
            } else if (res.data.status === 500) {
                NotificationManager.error(res.data.error)
            } else if (res.data.code === 200) {
                NotificationManager.success(res.data.message)
                console.log('saveuserresponse', res.data.result)
                yield put({
                    type: reportUserDetails.SAVE_USER_AND_GRP_RESPONSE,
                    payload: res.data.result,
                })
                yield put({
                    type: reportUserDetails.GET_GROUP_LIST_USER,
                    payload: {
                        reportId: props.reportUser.reportId,
                        transactionName: props.reportUser.transactionName,
                    },
                })
            }
        } else {
            NotificationManager.error(
                'Internal application error. Please contact system administrator.'
            )
        }
    } catch (error) {
        console.log(error)

        yield put({
            type: reportUserDetails.REPORT_STOP_LOADING,
            payload: {},
        })
        return
    }
}

function* saveUserAndGroupList() {
    yield takeEvery(
        reportUserDetails.SAVE_USER_AND_GRP_REQUEST,
        saveUserGrpRequest
    )
}

//=========================================delete records in design settings==============================
function* deleteGroupUserReport(payload) {
    var props = payload.payload
    console.log('deleteDesignSettings', props)
    try {
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        }
        var res = yield axios
            .request({
                method: 'delete',
                url: '',
                headers: headers,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: reportUserDetails.REPORT_USER_ERROR_UNAUTH,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status === 200) {
            if (res.data.status === 400) {
                // console.log("inside 400",res)
                NotificationManager.error(res.data.error)
                yield put({
                    type: reportUserDetails.REPORT_STOP_LOADING,
                    payload: {},
                })
            } else if (res.data.status === 404) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: reportUserDetails.REPORT_STOP_LOADING,
                    payload: {},
                })
            } else {
                NotificationManager.success('Successfully deleted')
                yield put({
                    type: reportUserDetails.DELETE_GRP_USER_RESPONSE,
                    payload: {},
                })
            }
        } else {
            NotificationManager.error(
                'Internal application error. Please contact system administrator.'
            )
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: reportUserDetails.REPORT_STOP_LOADING,
            payload: {},
        })
    }
}

function* deleteGrpUser() {
    yield takeEvery(
        reportUserDetails.DELETE_GRP_USER_REQUEST,
        deleteGroupUserReport
    )
}

export default function* rootSaga() {
    yield all([
        getReportList(),
        executionRecordDelete(),
        saveNewExecution(),
        updateEditedData(),
        getAllReportMaster(),
        saveNewReport(),
        getReportById(),
        saveEditedReportMaster(),
        deleteReportById(),
        getServerById(),
        getTableDesignByRow(),
        getDefaultSettings(),
        postDefaultSettings(),
        updateDefaultDesign(),
        deleteDefaultDesign(),
        getModuleById(),
        getUniqueGroup(),
        getGroupListMulti(),
        getUserListFromGrp(),
        getUserList(),
        saveUserAndGroupList(),
        deleteGrpUser(),
    ])
}
