import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'

const ManageAdminPage = Loadable(lazy(() => import('./ManageAdminPage')));
const GroupListView = Loadable(lazy(() => import('./GroupListView')));
const AddGroupName = Loadable(lazy(()=> import('./AddGroupName')));
const UserTableList = Loadable(lazy(()=> import('./UserTableList')))
const AddNewRole = Loadable(lazy(() => import('./AddNewRole')))
const CreatedRoleList = Loadable(lazy(()=>import('./CreatedRoleList')))
// const UserListTableNew = Loadable(lazy(()=> import('./UserListTableNew')))
const GroupList = Loadable(lazy(()=> import('./GroupList')))
const RoleTable = Loadable(lazy(()=> import('./RoleTable')))
const RoleList = Loadable(lazy(()=> import('./RoleList')))
const MultiSelect = Loadable(lazy(() => import('./MultiSelect')))

const AdminUserRoutes = [
    {
        path: '/AdminUserList',
        element: <ManageAdminPage />,
        //menuId: 5,
    },
    {
        path: '/GroupListView',
        element: <GroupListView/>,
    },
    {
        path:'/AddGroupName',
        element: <AddGroupName/>
    },
    {
        path:'/UserTableList',
        element:<UserTableList/>
    },
    {
        path:'/AddNewRole',
        element:<AddNewRole/>
    },
    {
        path:'/CreatedRoleList',
        element:<CreatedRoleList/>
    },
    // {
    //     path:'/UserListTableNew',
    //     element:<UserListTableNew/>
    // },
    {
        path:'/GroupList',
        element:<GroupList/>
    },
    {
        path:'/RoleList',
        element:<RoleList/>
    },
    {
        path:'/RoleTable',
        element:<RoleTable/>
    },
    {
        path:'/MultiSelect',
         element:<MultiSelect/>
    }

]

// const menus = localStorage.getItem("menuId");
// if (menus != null){
// var VisiblityRoutes = VisiblityRoutes1.filter(function (element) {
//   return menus.includes(element.menuId);
// });
// }else{
//   var VisiblityRoutes= [];
// }

export default AdminUserRoutes
