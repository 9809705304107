import { adminUserConstant } from '../../constant/ActionTypes'
import {
    ADMIN_USER_NAME,
    ADMIN_EMAIL_ID,
    ADMIN_PASSWORD,
    ADMIN_CONFIRM_PASSWORD,
    ADMIN_PASSWORD_CORRECT_FORMAT,
} from '../../constant/ErrorConstant'
import 'react-notifications/lib/notifications.css'
import { NotificationManager, NotificationContainer } from 'react-notifications'
export const adminUserAction = {
    getAddNewUserPage,
    resetNewGroup,
    inputChangeAdmin,
    getAllUserRoles,
    enableUser,
    disableUser,
    getRoles,
    getAllGroups,
    selectedRoleAllocation,
    saveRegisterationUser,
    getEditUser,
    editedSaveUser,
    saveCreateGroupUser,
    deleteTheUser,
    deleteTableGrpUser,
    makeInitialAdminUserEmpty,
    saveEditGroupUser,
    getEditGroupUserData,
    sendGrpRowData,
    ownUserCantDelete,
    resetNewRole,
    getAllRoles,
    getAddNewGrpPage,
    closePopUp,
    getAddNewRolePage,
    getInitialRoleData,
    getInitialUserData,
    getInitialGroupData,
    getSingleGrpEditData,
    closePopUpGrp,
    selectedRoleAllocationEdit,
    saveNewRoles,
    editRoleRequest,
    editSaveRole,
    deleteRoleReq,
    closeUserPopup,
    popUserPopup,
    openPopupGrp,
    closePopupGrp,
    openPopupRole,
    closePopupRole,
    getUndoUserDetails,
    saveLoginProfile,
    adminInputChange,
    inputChangeProfile,
    getUniqueGroups,
    adminReportMultiChange,
    passReportMaster,
}
function makeInitialAdminUserEmpty() {
    return {
        type: adminUserConstant.MAKE_INITIAL_ADMINUSER_EMPTY,
        payload: {},
    }
}

function getAddNewUserPage() {
    return {
        type: adminUserConstant.GET_ADMIN_NEW_USER_PAGE,
        payload: {},
    }
}
function getAddNewGrpPage() {
    return {
        type: adminUserConstant.GET_NEWGRP_PAGE,
        payload: {},
    }
}
function getAddNewRolePage() {
    return {
        type: adminUserConstant.GET_NEW_ROLE_PAGE,
        payload: {},
    }
}
function closePopUp() {
    return {
        type: adminUserConstant.CLOSE_POPUP,
        payload: {},
    }
}
function getUniqueGroups() {
    return {
        type: adminUserConstant.GET_UNIQUE_GROUP_TABLE_REQUEST,
        payload: {},
    }
}
function getAllGroups() {
    return {
        type: adminUserConstant.GET_ALL_GROUP_TABLE,
        payload: {},
    }
}
function saveEditGroupUser(props) {
    //console.log('getEditGroupUser', props)
    let newRoleNameError = '',
        groupDescriptionError = '',
        groupUserFirstNameError = ''
    if (!props.groupUserFirstName) {
        groupUserFirstNameError = 'Enter Group Name'
    }
    if (!props.newRoleName) {
        newRoleNameError = 'Enter RoleName'
    }
    if (!props.groupDescription) {
        groupDescriptionError = 'Enter Description'
    }
    if (groupUserFirstNameError || newRoleNameError || groupDescriptionError) {
        return {
            type: adminUserConstant.VALIDATION_EDIT_GRP_ERROR,
            payload: {
                newRoleNameError,
                groupDescriptionError,
                groupUserFirstNameError,
            },
        }
    } else {
        return {
            type: adminUserConstant.SAVE_EDIT_USER_GROUP,
            payload: props,
        }
    }
}
function getEditGroupUserData(props) {
    //console.log('edited grps', props)
    return {
        type: adminUserConstant.DISPLAY_GROUP_EDIT,
        payload: props,
    }
}
function resetNewGroup(props) {
    return {
        type: adminUserConstant.MAKE_INITIAL_GRP_EMPTY,
        payload: props,
    }
}
function inputChangeAdmin(props) {
    return {
        type: adminUserConstant.ADMIN_INPUT_CHANGE,
        payload: props,
    }
}
function adminReportMultiChange(props) {
    return {
        type: adminUserConstant.ADMIN_REPORT_MULTI_INPUT_CHANGE,
        payload: props,
    }
}

function getAllUserRoles() {
    return {
        type: adminUserConstant.GET_ALL_USER_ROLES_LIST,
        payload: {},
    }
}

function enableUser(props) {
    return {
        type: adminUserConstant.GET_ENABLE_USER_LIST,
        payload: props,
    }
}
function disableUser(props) {
    return {
        type: adminUserConstant.MAKE_DISABLE_USER,
        payload: props,
    }
}

function getRoles() {
    return {
        type: adminUserConstant.GET_ROLES,
        payload: {},
    }
}

function selectedRoleAllocation(props) {
    return {
        type: adminUserConstant.SELECT_ROLE_ALLOCATED,
        payload: props,
    }
}
function selectedRoleAllocationEdit(props) {
    return {
        type: adminUserConstant.SELECT_ROLE_ALLOCATED_EDIT,
        payload: props,
    }
}
function saveNewRoles(props) {
    let newRoleNameError = '',
        newDescriptionError

    if (!props.newRoleName) {
        newRoleNameError = 'Enter RoleName'
    }
    if (!props.newDescription) {
        newDescriptionError = 'Enter Description'
    }
    if (newRoleNameError || newDescriptionError) {
        return {
            type: adminUserConstant.VALIDATION_ROLE_ERROR,
            payload: {
                newRoleNameError,
                newDescriptionError,
            },
        }
    } else {
        return {
            type: adminUserConstant.SAVE_NEW_ROLE_REQUEST,
            payload: props,
        }
    }
}

function saveRegisterationUser(props) {
    let userNameError = '',
        userEmailIdError = '',
        passwordError = '',
        confirmPasswordError = '',
        groupNameError = ''
    if (!props.userName) {
        userNameError = ADMIN_USER_NAME
    }
    if (!props.userEmailId) {
        userEmailIdError = ADMIN_EMAIL_ID
    }
    if (props.userEmailId) {
        var re =
            /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/
        var isValid = re.test(props.userEmailId)
        if (!isValid) {
            userEmailIdError = ADMIN_EMAIL_ID
        }
    }
    if (!props.password) {
        passwordError = ADMIN_PASSWORD
    }
    if (!props.groupNameSelection > 0) {
        groupNameError = 'Select Group '
    }
    if (props.password) {
        var re =
            /((^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,15}$))/
        var isValidPassword = re.test(props.password)
        if (!isValidPassword) {
            passwordError = ADMIN_PASSWORD_CORRECT_FORMAT
        }
    }
    if (!props.confirmPassword) {
        confirmPasswordError = ADMIN_CONFIRM_PASSWORD
    }
    if (props.password != props.confirmPassword) {
        confirmPasswordError = ADMIN_CONFIRM_PASSWORD
        // NotificationManager.error('Please Verify Your Password')
    }

    if (
        userNameError ||
        userEmailIdError ||
        passwordError ||
        confirmPasswordError ||
        groupNameError
    ) {
        return {
            type: adminUserConstant.VALIDATION_REGISTER,
            payload: {
                userNameError,
                userEmailIdError,
                passwordError,
                confirmPasswordError,
                groupNameError,
            },
        }
    } else {
        return {
            type: adminUserConstant.SAVE_REGISTER_USERS,
            payload: props,
        }
    }
}

function getEditUser(props) {
    console.log('single value', props)
    return {
        type: adminUserConstant.GET_EDIT_USER_LIST,
        payload: props,
    }
}
function closePopUpGrp(props) {
    return {
        type: adminUserConstant.CLOSE_POPUP_GRP,
        payload: props,
    }
}
function popUserPopup(props) {
    return {
        type: adminUserConstant.POPUP_USER_OPEN,
        payload: props,
    }
}

function editedSaveUser(props) {
    console.log('propsSingleUser', props)
    let userNameError = '',
        userEmailIdError = '',
        groupNameError = '',
        userFirstNameError = '',
        userLastNameError = '',
        phoneNumberError = '',
        addressError = '',
        genderError = '',
        groupNameSelectionError = ''

    if (!props.groupNameSelection > 0) {
        groupNameError = 'Select Group '
    }
    if (!props.userFirstName) {
        userFirstNameError = 'Enter first name'
    }
    // if (!props.userLastName) {
    //     userLastNameError = 'Enter last name'
    // }
    if (!props.userName) {
        userNameError = 'Enter User name'
    }
    if (props.userEmailId) {
        var re =
            /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/
        var isValid = re.test(props.userEmailId)
        if (!isValid) {
            userEmailIdError = ADMIN_EMAIL_ID
        }
    }

    if (props.phoneNumber) {
        var re = /^[ 0-9\_\.\-]*$/
        var isValid = re.test(props.phoneNumber)
        if (!isValid) {
            phoneNumberError = 'Enter phone number'
        }
    }
    // if (!props.address) {
    //     addressError = 'Enter address'
    // }
    if (!props.gender) {
        genderError = 'EnterGender'
    }

    if (
        userNameError ||
        userEmailIdError ||
        groupNameError ||
        userFirstNameError ||
        userLastNameError ||
        phoneNumberError ||
        addressError ||
        genderError ||
        groupNameSelectionError
    ) {
        return {
            type: adminUserConstant.VALIDATION_REGISTER_EDIT,
            payload: {
                userNameError,
                userEmailIdError,
                groupNameError,
                userFirstNameError,
                userLastNameError,
                phoneNumberError,
                addressError,
                genderError,
                groupNameSelectionError,
            },
        }
    } else {
        return {
            type: adminUserConstant.EDITED_SAVE_USER_SUBMIT,
            payload: props,
        }
    }
}
function saveCreateGroupUser(props) {
    let groupUserFirstNameError = '',
        groupDescriptionError = '',
        newRoleNameError = ''
    if (!props.groupUserFirstName > 0) {
        groupUserFirstNameError = 'Enter Groupname '
    }
    if (!props.groupDescription > 0) {
        groupDescriptionError = 'Enter Description '
    }
    if (!props.newRoleName > 0) {
        newRoleNameError = 'Enter Role Name '
    }
    if (groupUserFirstNameError || groupDescriptionError || newRoleNameError) {
        return {
            type: adminUserConstant.VALIDATION_GRP,
            payload: {
                groupUserFirstNameError,
                groupDescriptionError,
                newRoleNameError,
            },
        }
    } else {
        return {
            type: adminUserConstant.CREATE_GROUP_SAVE_SUBMIT,
            payload: props,
        }
    }
}
function deleteTheUser(props) {
    return {
        type: adminUserConstant.DELETE_THE_USER_REQUEST,
        payload: props,
    }
}
function deleteTableGrpUser(props) {
    return {
        type: adminUserConstant.DELETE_GROUP_ROW_REQUEST,
        payload: props,
    }
}
function getSingleGrpEditData(props) {
    console.log('generaetrd', props)
    return {
        type: adminUserConstant.GET_EDIT_GRP_DATA,
        payload: props,
    }
}
function sendGrpRowData(props) {
    //console.log('sendGrpRowData', props)

    return {
        type: adminUserConstant.PASS_ROW_DATA_GRP,
        payload: props,
    }
}
function ownUserCantDelete(props) {
    return {
        type: adminUserConstant.OWN_USER_CANT_DELETE,
        payload: props,
    }
}
function resetNewRole(props) {
    return {
        type: adminUserConstant.INITIAL_EMPTY_NEW_ROLE,
        payload: props,
    }
}
function getAllRoles(props) {
    return {
        type: adminUserConstant.GET_ALL_ROLES,
        payload: props,
    }
}
function getInitialRoleData(props) {
    console.log('userroleData', props)
    return {
        type: adminUserConstant.ROLE_INITIAL_PAGE,
        payload: props,
    }
}
function getInitialUserData(props) {
    console.log('userroleData', props)
    return {
        type: adminUserConstant.USER_INITIAL_DATA,
        payload: props,
    }
}
function getInitialGroupData(props) {
    return {
        type: adminUserConstant.GET_GROUP_INITIAL_DATA,
        payload: props,
    }
}
function editRoleRequest(props) {
    console.log('rolesedit', props)
    return {
        type: adminUserConstant.GET_EDIT_ROLES,
        payload: props,
    }
}
function editSaveRole(props) {
    console.log('EDIT_SAVE_ROLES', props)
    let newRoleNameError = '',
        newDescriptionError

    if (!props.newRoleName) {
        newRoleNameError = 'Enter RoleName'
    }
    if (!props.newDescription) {
        newDescriptionError = 'Enter Description'
    }
    if (newRoleNameError || newDescriptionError) {
        return {
            type: adminUserConstant.VALIDATION_ROLE_EDIT_ERROR,
            payload: {
                newRoleNameError,
                newDescriptionError,
            },
        }
    } else {
        return {
            type: adminUserConstant.EDIT_SAVE_ROLES_REQ,
            payload: props,
        }
    }
}
function deleteRoleReq(props) {
    return {
        type: adminUserConstant.DELETE_ROLE_REQ,
        payload: props,
    }
}
function closeUserPopup(props) {
    return {
        type: adminUserConstant.CLOSE_USER_POPUP,
        payload: props,
    }
}
function openPopupGrp(props) {
    return {
        type: adminUserConstant.POPUP_OPEN_GRP,
        payload: props,
    }
}
function closePopupGrp(props) {
    return {
        type: adminUserConstant.POPUP_CLOSE_GRP,
        payload: props,
    }
}
function openPopupRole(props) {
    return {
        type: adminUserConstant.POPUP_OPEN_ROLE,
        payload: props,
    }
}
function closePopupRole(props) {
    return {
        type: adminUserConstant.POPUP_CLOSE_ROLE,
        payload: props,
    }
}
function getUndoUserDetails(props) {
    return {
        type: adminUserConstant.GET_OLD_USERDETAILS,
        payload: props,
    }
}
function saveLoginProfile(props) {
    return {
        type: adminUserConstant.REQUEST_SAVE_LOGIN_PROFILE,
        payload: props,
    }
}
function adminInputChange(props) {
    return {
        type: adminUserConstant.ADMIN_INPUT_FILE_CHANGE,
        payload: props,
    }
}
function inputChangeProfile(props) {
    return {
        type: adminUserConstant.INPUT_CHANGE_PROFILE,
        payload: props,
    }
}
function passReportMaster(props) {
    return {
        type: adminUserConstant.PASS_REPORT_MASTER,
        payload: props,
    }
}
