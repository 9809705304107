import { visibilityChange } from '../../constant/ActionTypes'

const initialState = {
    loader: false,
    uploadPopup: false,
    popupOpenMode: false,
    secondDialogBox: false,
    userId: JSON.parse(localStorage.getItem('auth_user')),
    detailAlertInfoLabel: '',
    visibilityId: '',
    newStartStatus: '',
    startStatus: '',
    processDuration: '',
    sev1LowDuration: '',
    sev1midDuration: '',
    sev1highDuration: '',
    DetailsTemplateName: '',
    detailAlertMode: '',
    detailAlertInfo: '',
    saveChanges: [],
    endStatus: '',
    editChange: false,
    countId: '',

    processDurationSecond: '00',
    processDurationMinute: '00',
    processDurationHours: '00',
    sev1LowDurationSecond: '00',
    sev1LowDurationMinute: '00',
    sev1LowDurationHours: '01',
    sev1MidDurationSecond: '00',
    sev1MidDurationMinute: '00',
    sev1MidDurationHours: '02',
    sev1HighDurationSecond: '00',
    sev1HighDurationMinute: '00',
    sev1HighDurationHours: '03',
    endStatusError: '',
    sev1highDurationError: '',
    sev1midDurationError: '',
    sev1LowDurationError: '',
    startStatusError: '',
    visibilityIdError: '',
    processDurationError: '',
    createdBy: '',
    createdDate: '',
    modifiedBy: '',
    modifiedDate: '',
    startEndStatusDropdown: [
        { value: 'NotCreated', label: 'NotCreated' },
        { value: 'Created', label: 'Created' },
        { value: 'Released', label: 'Released' },
        { value: 'Picked', label: 'Picked' },
    ],
    alertInformation: [
        { value: 'email', label: 'Alert Email' },
        { value: 'text', label: 'Alert Text' },
        { value: 'both', label: 'Alert Both' },
    ],
    detailAlertInfoError: '',
    detailsTemplateNameError: '',
}
const VisibilityDetailsReducer = function (
    state = initialState,
    { type, payload }
) {
    switch (type) {
        case visibilityChange.EMPTY_INITIAL_DIALOG_CONTENT:
            return {
                ...state,
                visibilityId: '',
                startStatus: '',
                processDuration: '',
                sev1LowDuration: '',
                sev1midDuration: '',
                detailAlertInfoLabel: '',
                sev1highDuration: '',
                endStatus: '',
                editChange: false,
                uploadPopup: true,
                countId: '',
                detailAlertMode: '',
                detailAlertInfo: '',
                processDurationSecond: '00',
                processDurationMinute: '00',
                processDurationHours: '00',
                sev1LowDurationSecond: '00',
                sev1LowDurationMinute: '00',
                sev1LowDurationHours: '01',
                sev1MidDurationSecond: '00',
                sev1MidDurationMinute: '00',
                sev1MidDurationHours: '02',
                sev1HighDurationSecond: '00',
                sev1HighDurationMinute: '00',
                sev1HighDurationHours: '03',
                endStatusError: '',
                sev1highDurationError: '',
                sev1midDurationError: '',
                sev1LowDurationError: '',
                startStatusError: '',
                visibilityIdError: '',
                processDurationError: '',
                popupOpenMode: false,
                endStatusError: '',
                sev1highDurationError: '',
                sev1midDurationError: '',
                sev1LowDurationError: '',
                startStatusError: '',
                visibilityIdError: '',
                processDurationError: '',
                DetailsTemplateName: '',
            }
        case visibilityChange.DIALOG_POPUP_OPEN: {
            return {
                ...state,
                uploadPopup: true,
                popupOpenMode: payload.popupOpenMode == 1 ? true : false,

                visibilityId:
                    payload.popupOpenMode == 1 ? payload.visibilityId : '',
                startStatus: '',
                endStatus: '',
                processDurationSecond: '00',
                processDurationMinute: '00',
                processDurationHours: '00',
                sev1LowDurationSecond: '00',
                sev1LowDurationMinute: '00',
                sev1LowDurationHours: '01',
                sev1MidDurationSecond: '00',
                sev1MidDurationMinute: '00',
                sev1MidDurationHours: '02',
                sev1HighDurationSecond: '00',
                sev1HighDurationMinute: '00',
                sev1HighDurationHours: '03',
                editChange: false,
            }
        }
        case visibilityChange.DIALOG_POPUP_CLOSE: {
            return { ...state, uploadPopup: false, loader:false }
        }
        case visibilityChange.VISIBILITY_INPUT_CHANGE:
            return {
                ...state,
                [payload.props]: payload.value,
                [payload.error]: '',
            }
        case visibilityChange.SAVE_THE_VISIBILITY_DATA:
            return {
                ...state,
                loader: true,
                // saveChanges: [...state.saveChanges, payload],
                editChange: false,
                uploadPopup: false,
            }
        case visibilityChange.GET_EDIT_RECORDS:
            //console.log('getAlertMode', payload.alertMode)
            return {
                ...state,
                editChange: true,
                loader: true,
                uploadPopup: true,
                DetailsTemplateName: payload.templateName,
                detailAlertMode: payload.alertMode ? payload.alertMode : '',
                detailAlertInfo: payload.alertInfo ? payload.alertInfo : '',
                detailAlertInfoLabel:
                    payload.alertMode == 'email'
                        ? 'Alert Email'
                        : payload.alertMode == 'text'
                        ? 'Alert Text'
                        : 'Alert Both',
                endStatusError: '',
                sev1highDurationError: '',
                sev1midDurationError: '',
                sev1LowDurationError: '',
                startStatusError: '',
                visibilityIdError: '',
                processDurationError: '',
            }
        case visibilityChange.CLOSE_DIALOG_START:
            return {
                ...state,
                startStatus:
                    state.startStatus == 'openStart' ? '' : state.startStatus,
                endStatus:
                    state.endStatus == 'openStart' ? '' : state.endStatus,
            }
        case visibilityChange.DELETE_THE_VISIBILITY:
            return {
                ...state,
                loader: true,
                // saveChanges: state.saveChanges
                //     .filter((item) => item.id != payload)
                //     .map((item) => item),
            }

        case visibilityChange.EDIT_SAVE_DATA:
            return {
                ...state,
                uploadPopup: false,
                loader: true,
            }

        case visibilityChange.VISIBILITY_ERROR_DATA_DISABLED:
            return {
                ...state,
                loader: false,
            }
        case visibilityChange.VALIDATION_ERROR:
            return {
                ...state,
                visibilityIdError: payload.visibilityIdError
                    ? payload.visibilityIdError
                    : '',
                startStatusError: payload.startStatusError
                    ? payload.startStatusError
                    : '',
                processDurationError: payload.processDurationError
                    ? payload.processDurationError
                    : '',
                endStatusError: payload.endStatusError
                    ? payload.endStatusError
                    : '',
                sev1LowDurationError: payload.sev1LowDurationError
                    ? payload.sev1LowDurationError
                    : '',
                sev1midDurationError: payload.sev1midDurationError
                    ? payload.sev1midDurationError
                    : '',
                sev1highDurationError: payload.sev1highDurationError
                    ? payload.sev1highDurationError
                    : '',
                detailsTemplateNameError: payload.detailsTemplateNameError
                    ? payload.detailsTemplateNameError
                    : '',
                detailAlertInfoError: payload.detailAlertInfoError
                    ? payload.detailAlertInfoError
                    : '',
                detailAlertModeError: payload.detailAlertModeError
                    ? payload.detailAlertModeError
                    : '',
            }

        case visibilityChange.GET_VISIBILITY_DETAILS:
            return {
                ...state,
                loader: true,
            }
        case visibilityChange.VISIBILITY_DETAILS_ERROR_STATUS:
            localStorage.clear()
            window.location.reload()
            return {
                ...state,
                loader: false,
            }

        case visibilityChange.DISPLAY_VISIBILITY_DETAILS:
            return {
                ...state,
                loader: false,
                saveChanges: payload.sort(),
            }
        case visibilityChange.DISPLAY_EDIT_DETAILS_RECORD:
            var processSpliter = payload.processDuration.split(':')
            state.processDurationSecond = processSpliter[2]
            state.processDurationMinute = processSpliter[1]
            state.processDurationHours = processSpliter[0]
            var sevLow = payload.severity1Low.split(':')
            state.sev1LowDurationSecond = sevLow[2]
            state.sev1LowDurationMinute = sevLow[1]
            state.sev1LowDurationHours = sevLow[0]
            var sevMedium = payload.severity2Medium.split(':')
            state.sev1MidDurationSecond = sevMedium[2]
            state.sev1MidDurationMinute = sevMedium[1]
            state.sev1MidDurationHours = sevMedium[0]
            var sevHigh = payload.severity3High.split(':')
            state.sev1HighDurationSecond = sevHigh[2]
            state.sev1HighDurationMinute = sevHigh[1]
            state.sev1HighDurationHours = sevHigh[0]
            
            return {
                ...state,
                loader: false,
                visibilityId: payload.visibilityId,
                startStatus: payload.startStatus,
                endStatus: payload.endStatus,
                createdBy: payload.createdBy,
                createdDate: payload.createdDate,
                modifiedBy: payload.modifiedBy,
                modifiedDate: payload.modifiedDate,
                detailAlertInfo:
                    payload.alertMode == 'email'
                        ? payload.alertEmail
                        : payload.alertMode == 'text'
                        ? payload.alertText
                        : payload.alertMode == 'both'
                        ? payload.alertBoth
                        : '',
                // DetailsTemplateName:"",
                // detailAlertMode:"",
                // detailAlertInfo:"",
                popupOpenMode: true,
            }
        case visibilityChange.APPEND_ADDED_DETAILS:
            return {
                ...state,
                loader: false,
                editChange: false,
                saveChanges: [...state.saveChanges, payload],
            }
        case visibilityChange.DELETE_DETAILS_RECORD:
            return {
                ...state,
                loader: false,
                saveChanges: state.saveChanges.filter((item) => {
                    if (
                        item.visibilityId == payload.visibiltyID &&
                        item.startStatus == payload.startStatus
                    ) {
                        return item
                    } else {
                        return item
                    }
                }),
            }
        case visibilityChange.APPEND_EDITED_DATA:
            return {
                ...state,
                loader: false,
                editChange: false,
                saveChanges: [...state.saveChanges, payload],
            }
        case visibilityChange.ADD_OPEN_VISIBILITYDETAILS:
            //console.log('getting Reducers', payload)
            return {
                ...state,
                popupOpenMode: true,
                uploadPopup: true,
                visibilityId: payload.visibilityId,
                startStatus: '',
                processDuration: '',
                sev1LowDuration: '',
                sev1midDuration: '',
                detailAlertMode: payload.alertMode,
                detailAlertInfo: payload.alertInfo,
                DetailsTemplateName: payload.templateName,
                sev1highDuration: '',
                endStatus: '',
                editChange: false,
                countId: '',
                processDurationSecond: '00',
                processDurationMinute: '00',
                processDurationHours: '00',
                sev1LowDurationSecond: '00',
                sev1LowDurationMinute: '00',
                sev1LowDurationHours: '01',
                sev1MidDurationSecond: '00',
                sev1MidDurationMinute: '00',
                sev1MidDurationHours: '02',
                sev1HighDurationSecond: '00',
                sev1HighDurationMinute: '00',
                sev1HighDurationHours: '03',
                endStatusError: '',
                sev1highDurationError: '',
                sev1midDurationError: '',
                sev1LowDurationError: '',
                startStatusError: '',
                visibilityIdError: '',
                processDurationError: '',
            }

        case visibilityChange.CHANGES_PROCESS_DURATION:
            // console.log(
            //     'gbfv',
            //     state.sev1LowDurationHours - 1 == state.processDurationHours
            // )
            return {
                ...state,
                processDurationHours: payload.value,
                
                sev1LowDurationHours:
                    state.sev1LowDurationHours - 1 == state.processDurationHours
                        ? Number(payload.value) + Number(1) < 10
                            ? '0' + (Number(payload.value) + Number(1))
                            : Number(payload.value) + Number(1)
                        : state.sev1LowDurationHours,

                sev1MidDurationHours:
                    state.sev1MidDurationHours - 2 == state.processDurationHours
                        ? Number(payload.value) + Number(2) < 10
                            ? '0' + (Number(payload.value) + Number(2))
                            : Number(payload.value) + Number(2)
                        : state.sev1MidDurationHours,
                sev1HighDurationHours:
                    state.sev1HighDurationHours - 3 ==
                    state.processDurationHours
                        ? Number(payload.value) + Number(3) < 10
                            ? '0' + (Number(payload.value) + Number(3))
                            : Number(payload.value) + Number(3)
                        : state.sev1HighDurationHours,
            }
        case visibilityChange.VISIBILITY_DETAIL_OFF_LOADER:
            return {
                ...state,
                loader: false,
            }
        case visibilityChange.ADD_NEW_START_STATUS:
            return {
                ...state,
                startEndStatusDropdown: [
                    ...state.startEndStatusDropdown,
                    { value: payload, label: payload },
                ],
                startStatus:
                    state.startStatus == 'openStart'
                        ? payload
                        : state.startStatus,
                endStatus:
                    state.endStatus == 'openStart' ? payload : state.endStatus,
            }
        case visibilityChange.INITIAL_START_END_EMPTY:
            return {
                ...state,
                newStartStatus: '',
            }
        default: {
            return {
                ...state,
            }
        }
    }
}
export default VisibilityDetailsReducer
