import { template } from 'lodash'
import { templateActionTypes } from '../../constant/ActionTypes'
import { templateManagementAction } from '../actions'
import _ from 'lodash'

const INITIAL_STATE = {
    loader: false,
    menuPopupOpen: false,
    openHeaderPopup: false,
    templateMiscField1: '',
    templateMiscField1Error: '',
    templateMiscField2: '',
    templateMiscField2Error: '',
    templateMiscField3: '',
    templateMiscField3Error: '',
    templateMiscField4: '',
    templateMiscField4Error: '',
    templateMiscFlag1error: '',
    templateMiscFlag1: false,
    templateMiscFlag2: false,
    templateMiscFlag2error: '',
    templateMiscFlag3: false,
    templateMiscFlag3error: '',
    templateMiscFlag4: false,
    templateMiscFlag4error: '',
    templateMiscFlag5: false,
    templateMiscFlag5error: '',
    fileName: '',
    templateName: '',
    templateNameError: '',
    fileNameError: '',
    templateTableData: [],
    getTemplatePagination: [],
    templateFileUpload: '',
    templateFileUploadError: '',
    getTemplatePaginations: [],
    singleTempleRowEdit: '',
    openEditPop: false,
    id: '',
    userId: JSON.parse(localStorage.getItem('auth_user')),
    setLoading: false,
}
export default (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case templateActionTypes.TEMPLATE_INPUT_CHANGE: {
            return {
                ...state,
                [payload.props]: payload.value,
                [payload.error]: '',
                setLoading: false,
            }
        }
        case templateActionTypes.CLOSE_HEADER_PANEL:
            return {
                ...state,
                loading: true,
                menuPopupOpen: false,
            }
        case templateActionTypes.OPEN_MENU_POPUP:
            return {
                ...state,
                loading: true,
                menuPopupOpen: payload,
            }
        case templateActionTypes.OPEN_HEADER_OPENPOPUP:
            return {
                ...state,
                loading: true,
                openHeaderPopup: true,
                templateMiscField1: '',
                templateMiscField1Error: '',
                templateMiscField2: '',
                templateMiscField2Error: '',
                templateMiscField3: '',
                templateMiscField3Error: '',
                templateMiscField4: '',
                templateMiscField4Error: '',
                templateMiscFlag1error: '',
                templateMiscFlag1: '',
                templateMiscFlag2: '',
                templateMiscFlag2error: '',
                templateMiscFlag3: '',
                templateMiscFlag3error: '',
                templateMiscFlag4: '',
                templateMiscFlag4error: '',
                templateMiscFlag5: '',
                templateMiscFlag5error: '',
                fileName: '',
                templateName: '',
                templateFileUpload: '',
                templateFileUploadError: '',
                templateNameError: '',
                fileNameError: '',
                menuPopupOpen: false,
                setLoading: false,
            }
        case templateActionTypes.CLOSE_TEMPLATE_POPUP:
            return {
                ...state,
                loading: false,
                openHeaderPopup: false,
            }
        case templateActionTypes.CLOSE_MENUPOPUP:
            return {
                ...state,
                loading: true,
                menuPopupOpen: false,
            }
        case templateActionTypes.TEMPLATE_AUTH_ERROR:
            localStorage.clear()
            window.location.reload()
            return {
                ...state,
                loading: false,
            }
        case templateActionTypes.GET_TEMPLATE_TABLE_DATA:
            return {
                ...state,
                loading: true,
            }
        case templateActionTypes.DISPLAY_TEMPLATE_TABLE_DATA:
            return {
                ...state,
                loading: false,
                templateTableData: payload,
            }
        case templateActionTypes.GET_TEMPLATE_LIST:
            return {
                ...state,
                loading: false,
                getTemplatePaginations: _(payload)
                    .slice(0)
                    .take(payload.length)
                    .value(),
            }
        case templateActionTypes.DISPLAY_TEMPLATE_TABLE_LIST:
            return {
                ...state,
                loading: false,
                getTemplatePaginations: _(payload).slice(0).take(10).value(),
            }
        case templateActionTypes.REQUEST_SAVE_NEW_TEMPLATE:
            return {
                ...state,
                loading: true,
                setLoading: true,
            }
        case templateActionTypes.RESPONSE_SAVE_NEW_TEMPLATE:
            return {
                ...state,
                loading: false,
                openHeaderPopup: false,
            }
        case templateActionTypes.DELETE_TEMPLATE_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case templateActionTypes.DELETE_TEMPLATE_RESPONSE:
            return {
                ...state,
                loading: false,
            }
        case templateActionTypes.EDIT_TEMPLATE_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case templateActionTypes.EDIT_TEMPLATE_RESPONSE:
            return {
                ...state,
                loading: false,
                id: payload.id,
                singleTempleRowEdit: payload,
                templateMiscField1: payload.miscField1,
                templateMiscField1Error: '',
                templateMiscField2: payload.miscField2,
                templateMiscField2Error: '',
                templateMiscField3: payload.miscField3,
                templateMiscField3Error: '',
                templateMiscField4: payload.miscField4,
                templateMiscField4Error: '',
                templateMiscFlag1error: '',
                templateMiscFlag1:
                    payload.miscFlag1 == '' || payload.miscFlag1 == null
                        ? false
                        : payload.miscFlag1,
                templateMiscFlag2:
                    payload.miscFlag2 == '' || payload.miscFlag2 == null
                        ? false
                        : payload.miscFlag2,
                templateMiscFlag2error: '',
                templateMiscFlag3:
                    payload.miscFlag3 == '' || payload.miscFlag3 == null
                        ? false
                        : payload.miscFlag3,
                templateMiscFlag3error: '',
                templateMiscFlag4:
                    payload.miscFlag4 == '' || payload.miscFlag4 == null
                        ? false
                        : payload.miscFlag4,
                templateMiscFlag4error: '',
                templateMiscFlag5:
                    payload.miscFlag5 == '' || payload.miscFlag5 == null
                        ? false
                        : payload.miscFlag5,
                templateMiscFlag5error: '',
                fileName: payload.fileName,
                templateName: payload.templateName,
                templateFileUpload: '',
                templateNameError: '',
                fileNameError: '',
            }
        case templateActionTypes.OPEN_EDIT_POPUP:
            return {
                ...state,
                loading: true,
                openEditPop: true,
                setLoading: false,
                templateNameError: '',
                templateFileUploadError: '',
                templateMiscField3Error: '',
                templateMiscField4Error: '',
            }
        case templateActionTypes.CLOSE_EDIT_POPUP:
            return {
                ...state,
                loading: true,
                openEditPop: false,
            }
        case templateActionTypes.REQUEST_SAVE_EDIT_TEMPLATE:
            return {
                ...state,
                loading: true,
                setLoading: true,
            }
        case templateActionTypes.TEMPLATE_LOADING_STOP:
            return {
                ...state,
                loader: false,
                setLoading: false,
            }
        case templateActionTypes.RESPONSE_SAVE_EDIT_TEMPLATE:
            return {
                ...state,
                loading: false,
                openEditPop: false,
                // templateMiscField1,
                // templateMiscField1Error: '',
                // templateMiscField2,
                // templateMiscField2Error,
                // templateMiscField3,
                // templateMiscField3Error,
                // templateMiscField4,
                // templateMiscField4Error,
                // templateMiscFlag1error,
                // templateMiscFlag1: false,
                // templateMiscFlag2: false,
                // templateMiscFlag2error,
                // templateMiscFlag3: false,
                // templateMiscFlag3error,
                // templateMiscFlag4: false,
                // templateMiscFlag4error,
                // templateMiscFlag5: false,
                // templateMiscFlag5error,
                // fileName,
                // templateName,
                // templateNameError,
                // fileNameError,
            }
        case templateActionTypes.VALIDATION_TEMPLATE_NEW:
            return {
                ...state,
                templateNameError: payload.templateNameError,
                templateFileUploadError: payload.templateFileUploadError,
                templateMiscField3Error: payload.templateMiscField3Error,
                templateMiscField4Error: payload.templateMiscField4Error,
            }
        case templateActionTypes.VALIDATION_TEMPLATE_EDIT:
            return {
                ...state,
                templateNameError: payload.templateNameError,
                templateFileUploadError: payload.templateFileUploadError,
                templateMiscField3Error: payload.templateMiscField3Error,
                templateMiscField4Error: payload.templateMiscField4Error,
            }
        default:
            return { ...state }
    }
}
