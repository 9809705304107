import { commonComponentType } from '../../constant/ActionTypes'
export const CommonAction = {
    getAllData,
    commonInputChange,
    passTimerValue,
    saveNewJsonData,
    getJsonEditedData,
    saveUpdatedJson,
    upDateJsonData,
    showEditJson,
    editJsonData,
    dashboardDisplay,
    childDataId,
    refreshDashboardTable,
    refreshDashboardDonut,
    refreshDashboardBar,
    refreshDashboardCard,
    showComponent,
    clearShowComponent,
    passAllColumnValues,
    passAllColumnDonutValues,
    getMainDashboardReport,
    getSingleReportData
    //  saveUpdatedJsonTable
}
function getSingleReportData(props){
    return{
        type:commonComponentType.GET_SINGLE_DASHBOARD_DATA,
        payload:props
    }
}
function getMainDashboardReport(props){
    return{
        type:commonComponentType.GET_MAIN_DASHBOARD_DATA,
        payload:props
    }
}
function getAllData() {
    return {
        type: commonComponentType.GET_ALL_DATA,
        payload: {},
    }
}
function commonInputChange(props) {
    return {
        type: commonComponentType.COMMON_INPUT_CHANGE,
        payload: props,
    }
}
function passTimerValue(props) {
    return {
        type: commonComponentType.PASS_TIMER_VALUE,
        payload: props,
    }
}
function saveNewJsonData(props) {
    return {
        type: commonComponentType.REQUEST_SAVE_NEW_JSONDATA,
        payload: props,
    }
}
function getJsonEditedData(props) {
    return {
        type: commonComponentType.GET_JSON_TEXT_DATA,
        payload: props,
    }
}
function saveUpdatedJson(props) {
    return {
        type: commonComponentType.REQUEST_SAVE_UPDATE_JSONDATA,
        payload: props,
    }
}
function upDateJsonData(props) {
    return {
        type: commonComponentType.UPDATE_JSON_DATA,
        payload: props,
    }
}
function showEditJson(props) {
    return {
        type: commonComponentType.SHOW_EDIT_JSON,
        payload: props,
    }
}
function editJsonData(props) {
    return {
        type: commonComponentType.EDITED_JSON_DATA,
        payload: props,
    }
}
function dashboardDisplay(props) {
    return {
        type: commonComponentType.GET_DASHBOARD_DATA,
        payload: props,
    }
}
function childDataId(props) {
    return {
        type: commonComponentType.CHILD_DATA_ID,

        payload: props,
    }
}
function refreshDashboardTable(props){
    return{
        type:commonComponentType.REFRESH_DASHBOARD_DATA,
        payload:props
    }
}
function refreshDashboardDonut(props){
    return{
        type:commonComponentType.REFRESH_DASHBOARD_DONUT,
        payload:props
    }
}
function refreshDashboardBar(props){
    return{
        type:commonComponentType.REFRESH_DASHBOARD_BAR,
        payload:props
    }
}
function refreshDashboardCard(props){
    return{
        type:commonComponentType.REFRESH_DASHBOARD_CARD,
        payload:props
    }
}
function showComponent(props){
    return{
        type:commonComponentType.SHOW_COMPONENT_SPECIFIC,
        payload:props
    }
}
function clearShowComponent(props){
    return{
        type:commonComponentType.RESET_SHOW_COMPONENT,
        payload:props
    }
}
function passAllColumnValues(props){
    return{
        type:commonComponentType.PASS_ALL_COLUMN_VALUES,
        payload:props
    }
}
function passAllColumnDonutValues(props){
    return{
        type:commonComponentType.PASS_ALL_DONUT_COLUMN_VALUES,
        payload:props
    }
}
// function saveUpdatedJsonTable(props){
//     return{
//         type:commonComponentType.SAVE_UPDATED_JSON_TABLE,
//         payload:props
//     }
// }
