import AuthGuard from 'app/auth/AuthGuard'
import NotFound from 'app/views/sessions/NotFound'
import chartsRoute from 'app/views/charts/ChartsRoute'
import materialRoutes from 'app/views/material-kit/MaterialRoutes'
import dashboardRoutes from 'app/views/dashboard/DashboardRoutes'
import sessionRoutes from 'app/views/sessions/SessionRoutes'
import MatxLayout from '../components/MatxLayout/MatxLayout'
import { Navigate } from 'react-router-dom'
import VisiblityRoutes from '../views/VisiblityDetails/VisiblityRoutes'
import TransactionRoutes from 'app/views/Transaction'
import LookupRoutes from 'app/views/LookupDetails'
import AdminUserRoutes from 'app/views/AdminUser/AdminUserRoutes'
import ReportRoutes from 'app/views/Report/reportRoutes'
import AddProcessCriteria from 'app/views/VisiblityDetails/AddProcess'
import ProcessCriteriaRoutes from 'app/views/ProcessCriteria/ProcessCriteriaRoutes'
import UserProfileRoutes from 'app/views/UserProfile/UserProfileRoutes'
import PagingRoutes from 'app/views/Paging/PagingRoutes'
import VisibilityRoutes from 'app/views/Visibility/VisibilityRoutes'
import TemplateManagementRoutes from 'app/views/TemplateManagement/TemplateManagementRoutes'
import CardRoutes from 'app/views/CommonComponents/CardComponent/CardRoutes'
import CommonComponentRoutes from 'app/views/CommonComponents/CommonComponentRoutes'
import JsonFileRoutes from 'app/views/JsonFormat/JsonFileRoutes'
import MultipleTableRoutes from 'app/views/Tables/MultipleTableRoutes'
import ReportMasterRoutes from 'app/views/ReportMaster/ReportMasterRoutes'
import DragAndDropRoutes from 'app/views/DragAndDrop/DragAndDropRoutes'

export const AllPages = () => {
    const all_routes = [
        {
            element: (
              
                    <AuthGuard forceRefresh={true}>
                        <MatxLayout />
                    </AuthGuard>
             
            ),
            children: [
                ...dashboardRoutes,
                ...chartsRoute,
                ...materialRoutes,
                ...VisiblityRoutes,
                ...LookupRoutes,
                ...AdminUserRoutes,
                ...TransactionRoutes,
                ...ReportRoutes,
                ...ProcessCriteriaRoutes,
                ...UserProfileRoutes,
                ...PagingRoutes,
                ...VisibilityRoutes,
                ...TemplateManagementRoutes,
                ...CardRoutes,
                ...CommonComponentRoutes,
                ...JsonFileRoutes,
                ...MultipleTableRoutes,
                ...ReportMasterRoutes,
                ...DragAndDropRoutes,
            ],
        },
        ...sessionRoutes,
        {
            path: '/',
            element: <Navigate to="dashboard/default" />,
        },
        {
            path: '*',
            element: <NotFound />,
        },
        {
            path: '/AddProcessCriteria',
            element: <AddProcessCriteria />,
        },
    ]

    return all_routes
}
