export const LOGIN_ERROR = 'error.LOGIN_ERROR'
export const DESIGNATION_ERROR = 'Choose Any Designation'
export const CONTACTNO_ERROR = 'Contact Number is Required'
export const CONATCTALTNO_ERROR = 'error.CONATCTALTNO_ERROR'
export const CONTACTINVALID_ERROR = 'Enter Valid Contact Number'
export const ADDRESS1_ERROR = 'Enter valid Address 1'
export const ADDRESS2_ERROR = 'Enter valid Address 2'
export const COUNTRY_ERROR = 'Select Country'
export const STATE_ERROR = 'Select State'
export const CITY_ERROR = 'Select City'
export const POSTBOX_ERROR = 'Enter valid PINCODE'
export const INVALIDIMAGE_ERROR = 'error.INVALIDIMAGE_ERROR'
export const EMAILID_ERROR = 'error.EMAILID_ERROR'
export const EMPLOYEENAME_ERROR = 'please Enter Employee Name'
export const GENDER_ERROR = 'Select Gender'
export const DOB_ERROR = 'Select DOB'
export const EMPLOYEEIMAGE_ERROR = 'error.EMPLOYEEIMAGE_ERROR'
export const EMAILIDFORMAT_ERROR = 'Enter Valid Email Address'
export const EMPLOYEEDETAILSFILE_ERROR = 'error.EMPLOYEEDETAILSFILE_ERROR'
export const LISTSTATUS_ERROR = 'error.LISTSTATUS_ERROR'
export const ACCESS_COUNTRY_ERROR = 'Select Access Country'

export const GROUPID_ERROR = "Group id can't empty" //"Group id can't empty"
export const VISIBLITYID_ERROR = "Visiblility id can't empty" //"Visiblility id can't empty"
export const INVALID_VISIBLITYID_ERROR = 'error.INVALID_VISIBLITYID_ERROR' //"Invalid Visiblility id can't empty"
export const TAGNAME_ERROR = "TagName can't Empty"
export const TAGNAME_MINMAX_ERROR = 'error.TAGNAME_MINMAX_ERROR'
export const TAGPOSITION_ERROR = "Tag Position can't Empty"
export const TEMPLATE_NAME_ERROR = 'Create Template Name'
export const TEMPLATE_FILE_ERROR = 'Select Template file'
export const TRANS_FILE_ERROR = 'Select Valid file'
export const ADMIN_USER_NAME = 'Enter the User Name'
export const ADMIN_EMAIL_ID = 'Enter the Valid EmailId'
export const ADMIN_PASSWORD = 'Enter the password'
export const ADMIN_CONFIRM_PASSWORD = 'Enter the Confirm Password'
export const ADMIN_PASSWORD_CORRECT_FORMAT =
    'Password should Contain atleast 8 character includes 1-Uppercase 1- LowerCase 1-specialChar 1-Numericals'

export const CONFIRM_NEW_PASSWORD_ERROR = 'CONFIRM_NEW_PASSWORD_ERROR'

export const TAG_VALUE_ERROR = 'Tag Value is reqired'
export const DESCRIPTION_ERROR = 'Description Field is Required'
export const RULE_NAME_ERROR = 'Enter Rule Name'
export const TAG_VALUE_ERROR_ONLY_NUMERIC =
    'Enter date YYYY-MM-DDT00:00:00 or Numeric'
export const TAG_VALUE_ERROR_ONLY_STRING =
    'Pattern Support Like \n abc,\nabc,zyx*,\nabc*,\n*abc,\nabc*xyz'
export const MISCFIELD_4_ERROR = 'Enter valid MiscField 4 error'