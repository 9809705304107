import { lookupType } from '../../constant/ActionTypes'
import {
    VISIBLITYID_ERROR,
    GROUPID_ERROR,
    INVALID_VISIBLITYID_ERROR,
    TAGNAME_ERROR,
    TAGNAME_MINMAX_ERROR,
    TAGPOSITION_ERROR,
    TEMPLATE_NAME_ERROR,
    TEMPLATE_FILE_ERROR,
} from '../../constant/ErrorConstant'
export const lookupAction = {
    lookupInputChange,
    closeHeaderPopUp,
    openHeaderPopUp,
    openDetailsPopUp,
    closeDetailsPopUp,
    saveLookup,
    saveLookupTemplate,
    getLookUpList,
    getLookUpEdit,
    lookupHeaderDelete,
    openDetailPanel,
    makeTemplateEmpty,

    //=================== Look Up Details ====================
    getLookUpDetailsList,
    getlookupEditById,
    saveLookupDetails,
    deleteLookupDetails,
    editLookupDetails,

    //==================== Template Action =====================
    getAllTemplateView,
    getEditableTemplate,
    deleteTemplateDetails,
    editSaveLookUpTemplate,

    //==========================design template======================
    setTrueForCard,
    setCloseCard

}
function lookupHeaderDelete(props) {
    return {
        type: lookupType.LOOKUP_HEADER_DELETE_REQUEST,
        payload: { props: props },
    }
}
function getLookUpEdit(props) {
    return {
        type: lookupType.LOOKUP_EDIT_REQUEST,
        payload: { props: props },
    }
}

function getLookUpList(props) {
    return {
        type: lookupType.LOOKUP_LIST_REQUEST,
        payload: { props: props },
    }
}
function saveLookupTemplate(props) {
    let templateFileError = '',
        templateNameError = ''

    if (!props.templateName) {
        templateNameError = TEMPLATE_NAME_ERROR
    }
    if (!props.templateFile) {
        templateFileError = TEMPLATE_FILE_ERROR
    }
    if (templateNameError || templateFileError) {
        return {
            type: lookupType.LOOKUP_TEMPLATE_ERROR,
            payload: {
                templateNameError,
                templateFileError,
            },
        }
    } else {
        return {
            type: lookupType.SAVE_TEMPLATE_DATA,
            payload: { props: props },
        }
    }
}
function openDetailPanel(props) {
    return {
        type: lookupType.OPEN_DETAIL_LOOKUP_PANEL,
        payload: { props },
    }
}
function setTrueForCard(props) {
    return {
        type: lookupType.SET_TRUE_CARD,

        payload: props,
    }
}

function setCloseCard(props) {
    return {
        type: lookupType.SET_CLOSE_CARD,

        payload: props,
    }
}

function saveLookup(mode, props) {
    let groupIdError = '',
        visibilityIdError = '',
        tagNameError = '',
        tagPositionError = ''
    if (mode == 1) {
        //Lookup Header
        if (!props.visibilityId) {
            if (props.visibilityId.match(/^[ A-Za-z0-9\_\.\-]*$/)) {
                //valid integer (positive or negative)
                visibilityIdError = INVALID_VISIBLITYID_ERROR
            } else {
                //not valid number
                visibilityIdError = VISIBLITYID_ERROR
            }
        }
        if (props.visibilityId) {
            var re = /^[ A-Za-z0-9\_\.\-]*$/
            var isValid = re.test(props.visibilityId)
            if (!isValid) {
                visibilityIdError = 'Accepts Only AlphaNumeric'
            }
            if (
                props.visibilityId.length < 5 ||
                props.visibilityId.length > 25
            ) {
                visibilityIdError = 'Visibility Id must be min 5 max 25'
            }
        }
        if (!props.groupId) {
            groupIdError = GROUPID_ERROR
        }
        if (props.groupId) {
            if (props.groupId.length < 3 || props.groupId.length > 5) {
                groupIdError = 'Visibility Id must be min 3 max 5'
            }
        }
        //console.log(groupIdError, visibilityIdError)
    } else if (mode == 2) {
        //Lookup Details
        if (!props.groupId) {
            groupIdError = GROUPID_ERROR
        }
        if (!props.tagName) {
            tagNameError = TAGNAME_ERROR
        } else if (props.tagName.length <= 4 || props.tagName.length > 500) {
            tagNameError = TAGNAME_MINMAX_ERROR
        }
        if (!props.tagPosition) {
            tagPositionError = TAGPOSITION_ERROR
        }
    }
    if (groupIdError || visibilityIdError || tagNameError || tagPositionError) {
        return {
            type: lookupType.LOOKUP_ERROR,
            payload: {
                groupIdError,
                visibilityIdError,
                tagNameError,
                tagPositionError,
            },
        }
    } else {
        return {
            type: lookupType.LOOK_UP_SAVE,
            payload: { props: props, mode: mode },
        }
    }
}
function openHeaderPopUp() {
    return {
        type: lookupType.LOOK_UP_HEADER_OPEN,
        payload: {},
    }
}
function closeHeaderPopUp() {
    return {
        type: lookupType.LOOK_UP_HEADER_CLOSE,
        payload: {},
    }
}

function openDetailsPopUp() {
    return {
        type: lookupType.LOOK_UP_DETAILS_OPEN,
        payload: {},
    }
}
function closeDetailsPopUp() {
    return {
        type: lookupType.LOOK_UP_DETAILS_CLOSE,
        payload: {},
    }
}
function lookupInputChange({ props, value, error }) {
    return {
        type: lookupType.LOOKUP_INPUT_CHANGE,
        payload: { props, value, error },
    }
}

//================================== look Up details Action ======================================
function getLookUpDetailsList() {
    return {
        type: lookupType.GET_LOOKUP_DETAILS_LIST,
        payload: {},
    }
}

function getlookupEditById(props) {
    return {
        type: lookupType.GET_LOOKUP_EDIT_DETAILS,
        payload: props,
    }
}

function saveLookupDetails(mode, props) {
    let tagNameError = ''
    let groupIdError = ''
    let tagPositionError = ''
    //console.log(props)
    if (!props.groupId) {
        groupIdError = 'Select Valid GroupId'
    }
    if (!props.tagPosition) {
        tagPositionError = 'Select Valid Tag Position'
    }
    if (!props.tagName) {
        tagNameError = "Tag Name can't empty"
    }
    if (props.tagName) {
        if (props.tagName.length < 1) {
            tagNameError = 'Tag Name must be min 1 character'
        }
    }
    if (tagNameError || groupIdError || tagPositionError) {
        return {
            type: lookupType.LOOKUP_ERROR,
            payload: { tagNameError, groupIdError, tagPositionError },
        }
    } else {
        return {
            type: lookupType.ADD_LOOKUP_DETAILS,
            payload: { props: props, mode: mode },
        }
    }
}
function deleteLookupDetails(props) {
    return {
        type: lookupType.DELETE_LOOKUP_DETAILS,
        payload: props,
    }
}
function editLookupDetails(props) {
    //console.log(props)
    let tagNameError = ''
    let groupIdError = ''
    let tagPositionError = ''

    if (!props.groupId || props.groupId == 0) {
        groupIdError = 'Select Valid GroupId'
    }
    if (props.tagPosition.toString() == '') {
        //console.log(props.tagPosition)
        tagPositionError = 'Select Valid Tag Position'
    }
    if (!props.tagName) {
        tagNameError = "Tag Name can't empty"
    }
    if (props.tagName) {
        if (props.tagName.length < 1) {
            tagNameError = 'Tag Name must be min 1 character'
        }
    }
    //console.log(tagNameError, tagPositionError, groupIdError)
    if (tagNameError || tagPositionError || groupIdError) {
        return {
            type: lookupType.LOOKUP_ERROR,
            payload: { tagNameError, groupIdError, tagPositionError },
        }
    } else {
        return {
            type: lookupType.SAVE_EDIT_LOOKUP_DETAILS,
            payload: props,
        }
    }
}

//==================================Template aaction ==============================================

function getAllTemplateView() {
    return {
        type: lookupType.GET_ALL_TEMPLATE_DETAILS,
        payload: {},
    }
}

function getEditableTemplate(props) {
    return {
        type: lookupType.GET_EDITABLE_TEMPLATE_BY_ID,
        payload: props,
    }
}

function deleteTemplateDetails(props) {
    return {
        type: lookupType.DELETE_TEMPLATE_DATA,
        payload: props,
    }
}

function editSaveLookUpTemplate(props) {
    let templateFileError = '',
        templateNameError = ''

    if (!props.templateFile) {
        templateFileError = TEMPLATE_FILE_ERROR
    }
    if (templateNameError || templateFileError) {
        return {
            type: lookupType.LOOKUP_TEMPLATE_ERROR,
            payload: {
                templateFileError,
            },
        }
    } else {
        return {
            type: lookupType.EDITED_SAVE_TEMPLATE_DATA,
            payload: props,
        }
    }
}

function makeTemplateEmpty() {
    return {
        type: lookupType.INITIAL_EMPTY_TEMPLATE_DATA,
        payload: {},
    }
}
