import { transType } from '../../constant/ActionTypes'
const INITIAL_STATE = {
    loader: false,
    transactionList: [],
    transactionJson: {},
    transactionJsonFileName: '',
    transactionFileUploadError: '',
    uploadPopup:false
}
export default (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case transType.TRANSACTION_UPLOAD_INVALIDFILE: {
            return {
                ...state,
                loader: false,
                transactionFileUploadError: payload.fileError,
            }
        }
        case transType.TRANSACTION_UPLOAD_REQUEST: {
            return { ...state, loader: true, uploadPopup: false }
        }
        case transType.TRANSACTION_UPLOAD_RESPONSE: {
            return { ...state, loader: false, transactionJsonFileName: '' }
        }
        case transType.GET_TRANSACTION_LIST: {
            return { ...state, loader: true }
        }
        case transType.TRANSACTION_INPUT_CHANGE: {
            return {
                ...state,
                [payload.props]: payload.value,
                [payload.error]: '',
                loader: false,
            }
        }
        case transType.TRANSACTION_LIST_SUCCESS: {
            return { 
                ...state, 
                transactionList: payload, 
                loader: false,
                uploadPopup: false
         }
        }
        case transType.TRANSACTION_LIST_ERROR: {
            return { ...state, loader: false }
        }
        case transType.EMPTY_CONTENT:{
            return{
                ...state,
                loading:false,
                uploadPopup:true,
                transactionFileUploadError:''

            }
        }
        case transType.CLOSE_POPUP:{
            return{
                ...state,
                loading:false,
                uploadPopup:false,

            }
        }

        default:
            return { ...state }
    }
}
