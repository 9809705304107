import thunk from 'redux-thunk'
import { createStore, applyMiddleware, compose } from 'redux'
import RootReducer from './reducers/RootReducer'
import rootSaga from '../redux/saga/index'
import createSagaMiddleware from 'redux-saga'
import { createLogger, LoggerPredicate } from 'redux-logger'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import Reactotron from '../../ReactotronConfig'
const routeMiddleware = routerMiddleware(history)
const initialState = {}

const logger = createLogger({
    // ...options
    predicate: LoggerPredicate,
})
let devtools = (x) => x
const history = createBrowserHistory()
const sagaMiddleware = createSagaMiddleware({ context: history })
const middlewares = [
    logger,
    sagaMiddleware,
    thunk,
    routeMiddleware,
]
if (
    process &&
    process.env.NODE_ENV !== 'production' &&
    process.browser &&
    window.__REDUX_DEVTOOLS_EXTENSION__
) {
    devtools = window.__REDUX_DEVTOOLS_EXTENSION__()
   
}
if (process.env.NODE_ENV === 'production') {
    console.log = () => {}
    console.error = () => {}
    console.debug = () => {}
  }

export const Store = createStore(
    RootReducer,
    initialState,
    compose(
        applyMiddleware(...middlewares),
        devtools,
        Reactotron.createEnhancer()
    )
)
sagaMiddleware.run(rootSaga)
export { history }
