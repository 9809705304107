import Table from 'react-bootstrap/Table'
import React from 'react'
// import Tab from 'react-bootstrap/Tab';
// import Tabs from 'react-bootstrap/Tabs';

//import Sonnet from '../../components/Sonnet';

export default function DetailMenuTabs() {
    return (
        <>
            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                    <button
                        class="nav-link active"
                        id="home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#home-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="home-tab-pane"
                        aria-selected="true"
                    >
                        Home
                    </button>
                </li>
                <li class="nav-item" role="presentation">
                    <button
                        class="nav-link"
                        id="profile-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#profile-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="profile-tab-pane"
                        aria-selected="false"
                    >
                        Profile
                    </button>
                </li>
                <li class="nav-item" role="presentation">
                    <button
                        class="nav-link"
                        id="contact-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#contact-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="contact-tab-pane"
                        aria-selected="false"
                    >
                        Contact
                    </button>
                </li>
                <li class="nav-item" role="presentation">
                    <button
                        class="nav-link"
                        id="disabled-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#disabled-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="disabled-tab-pane"
                        aria-selected="false"
                        disabled
                    >
                        Disabled
                    </button>
                </li>
            </ul>
            <div class="tab-content" id="myTabContent">
                <div
                    class="tab-pane fade show active"
                    id="home-tab-pane"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                    tabindex="0"
                >
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining
                    essentially unchanged. It was popularised in the 1960s with
                    the release of Letraset sheets containing Lorem Ipsum
                    passages, and more recently with desktop publishing software
                    like Aldus PageMaker including versions of Lorem Ipsum. Why
                    do we use it? It is a long established fact that a reader
                    will be distracted by the readable content of a page when
                    looking at its layout. The point of using Lorem Ipsum is
                    that it has a more-or-less normal distribution of letters,
                    as opposed to using 'Content here, content here', making it
                    look like readable English. Many desktop publishing packages
                    and web page editors now use Lorem Ipsum as their default
                    model text, and a search for 'lorem ipsum' will uncover many
                    web sites still in their infancy. Various versions have
                    evolved over the years, sometimes by accident, sometimes on
                    purpose (injected humour and the like). Various versions
                    have evolved over the years, sometimes by accident,
                    sometimes on purpose (injected humour and the like)
                </div>
                <div
                    class="tab-pane fade"
                    id="profile-tab-pane"
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                    tabindex="0"
                >
                    Лорем ипсум долор сит амет, инсоленс антиопам вел цу,
                    пертинах адиписцинг ет хас. Те вих легере сенсибус
                    цонтентионес, хис анциллае улламцорпер сигниферумяуе ид, при
                    еу граеци фацете яуаерендум. Нам хабео граеци елаборарет не.
                    Меи ад омиттам делицата, нец но долоре ерипуит. Ад яуи нисл
                    волуптуа, чоро адиписци сенсибус те цум, модо интегре
                    сапиентем ут яуи. Вис ид риденс яуалисяуе интерессет.
                    Вивендо сентентиае те вис, сит но цоммуне сапиентем
                    яуалисяуе, вис ессент моллис ратионибус ад. Сит те лаудем
                    солеат реферрентур, еи идяуе аперири пондерум сит. Ех вих
                    долоре убияуе алияуип, ут усу дицат нострум перицула. Вим ан
                    лорем дебет. Яуи ех воцент инцидеринт, ан яуем цонцептам
                    цонцлусионемяуе меа, вим еу нулла популо санцтус. Не
                    оффендит пробатус фацилиси нам. Синт симилияуе яуаерендум еу
                    вим. Еу цум ессент цоммодо яуаестио. Яуис тимеам ассентиор
                    ид вис, ерат аутем цонсеяуунтур меа еа. Ид ест натум аетерно
                    ерудити, нам лудус дицант те, меис видит перицулис сит еи.
                    Цум ан постеа лабитур цонцлудатуряуе, суммо сцаевола ех хас.
                    Ин саперет цонвенире диспутандо яуи, не мел фугит дицунт
                    ехплицари. Веритус омиттам сигниферумяуе яуо еу, ест ин
                    тритани либерависсе. Сеа не пробатус цонсететур, номинави
                    тинцидунт инцидеринт ин сеа. Еа синт евертитур еам.
                    Еффициантур инструцтиор ех сит, яуо мазим пхаедрум цу, омнис
                    идяуе фацилиси меа ех. Еу про оратио облияуе епицури. Еа
                    яуандо омниум интегре меа, еррем детерруиссет ут нец. Не хис
                    натум аперири. Ан еос апеириан демоцритум. Меа но прима
                    суавитате, ад мел одио иусто фацилис, тамяуам
                    цонцлусионемяуе еос но. Омниум делецтус импердиет те усу.
                    Цум ут нулла евертитур, ат усу тритани салутатус.
                </div>
                <div
                    class="tab-pane fade"
                    id="contact-tab-pane"
                    role="tabpanel"
                    aria-labelledby="contact-tab"
                    tabindex="0"
                >
                    Λορεμ ιπσθμ δολορ σιτ αμετ, εα ομνιθμ ηαβεμθσ ομιτταμ vιξ,
                    ετ εαμ vολθπτθα σενσεριτ cονcεπταμ. Τοτα ρεπθδιαρε λαβοραμθσ
                    δθο ατ, ηαβεο φαστιδιι σcαεvολα σεα τε, αδ ινδοcτθμ
                    εφφιcιαντθρ σιτ. Τε εοσ οδιο ζριλ, τε δεcορε vιvενδθμ ηισ.
                    Ετ μεα μαιορθμ δελεcτθσ προβατθσ, προ ειθσ αφφερτ ορνατθσ
                    εξ. Ηομερο εvερτι ρεπρεηενδθντ αδ cθμ. Ει vιδιτ vιvενδο
                    ανcιλλαε μει, αδηθc τιμεαμ εqθιδεμ αδ προ. Ηισ ομνισ vενιαμ
                    νο. Νε εστ vιρισ δεcορε φαcιλισισ. Ηισ ατ λαορεετ σθαvιτατε
                    δισσεντιασ, cθ σεα ομνεσ σcαεvολα περπετθα. Απεριαμ απεριρι
                    μαιορθμ qθι νο, vελ ιδ αεqθε σαπερετ. Cθμ cθ σολεατ cαθσαε
                    cομμοδο. Αλιqθιδ vιτθπερατοριβθσ τε νεc. Ιδ qθι vιvενδο
                    σενσιβθσ, τατιον οβλιqθε σαπερετ αν ναμ. Εθ αππαρεατ
                    cονσθλατθ μει. Vολθτπατ ελαβοραρετ ρεφερρεντθρ αδ ηασ. Εθμ
                    ετ αθδιρε δολορεσ. Τε εοσ αππαρεατ σαδιπσcινγ, ετ γραεcισ
                    πλαcερατ νεc, εα περ δοcενδι ελαβοραρετ. Εθμ αν vιδε
                    λαβορεσ, qθο τε ζριλ cομπρεηενσαμ, θτ qθι αθγθε cλιτα
                    σιγνιφερθμqθε. Εθμ νε ηαβεο vοcιβθσ, vελ νο πριμισ ελειφενδ
                    περcιπιτθρ. Ομνισ αθτεμ θσθ τε, νθλλαμ απεριρι νε μεα. Σεδ
                    νθλλα εξπετενδα εθ, εα σθασ vιταε vολθπτθα cθμ, ιν εθμ
                    λιβεραvισσε cονσεqθθντθρ. Σεα ετ ερθδιτι αβηορρεαντ. Νε
                    αθτεμ θταμθρ cονcλθσιονεμqθε μει, ηαβεο cομμοδο αβηορρεαντ
                    ατ ναμ, ει εστ νονθμεσ τινcιδθντ. Vισ ομνισ φαcιλισ θτ,
                    πορρο ιντεγρε εξπλιcαρι περ νε, ποπθλο αετερνο περσιθσ θτ
                    vελ. Εοσ αν μοvετ μοδερατιθσ. Μελ μοδθσ ποσσε vολθτπατ αδ,
                    εθμ εξ vερο πετεντιθμ, ατ παθλο σονετ cθμ. Θσθ αμετ αδηθc
                    θτ. Ηασ δεσερθισσε μνεσαρcηθμ ατ. Νεc ιν cασε ελειφενδ
                    ρεπθδιανδαε. Φαβθλασ cομμθνε σενσιβθσ εοσ ετ, εξ θσθ vιδερερ
                    ρεπθδιαρε τορqθατοσ, πηαεδρθμ σπλενδιδε ατ ιθσ. Cονσθλ
                    αδολεσcενσ vολθπτατιβθσ τε μελ. Σεα νε ομνεσqθε qθαλισqθε,
                    vιξ τε γραεcο διcτασ περτιναcια, vισ τε νονθμυ νεμορε
                    τεμποριβθσ. Ατ ηισ λαθδεμ δισσεντιετ, vιμ ει νιηιλ αλτερθμ
                    vιvενδθμ. Cαθσαε εξπετενδα δισσεντιθντ προ cθ. Τιμεαμ
                    εξπετενδισ cομπρεηενσαμ σιτ ιδ. Σεδ λθδθσ διcθντ νοστρθδ εξ,
                    σιτ αν διcθντ cονσετετθρ. Θτ πρι σcριπτα φθισσετ
                    λιβεραvισσε, δθισ ιντερπρεταρισ cθμ τε. Νε cθμ cιvιβθσ
                    φαcιλισισ αλιqθανδο. Εξ μελ αφφερτ μθνερε σcριπτορεμ. Vελ
                    vελιτ ανcιλλαε θτ, σινγθλισ vθλπθτατε σcριπτορεμ εα δθο.
                    Σολθμ νεγλεγεντθρ αν qθι, cαθσαε προβατθσ cονvενιρε εα qθο,
                    ρεcθσαβο οπορτερε ηασ αδ. Ει θσθ νατθμ φεθγαιτ, σιμθλ
                    qθαεστιο ρεcτεqθε vελ αν. Vελ λαθδεμ cορρθμπιτ cθ, λεγερε
                    σαπερετ μαλθισσετ πρι νο, εθ σεα ιλλθδ νονθμυ.
                </div>
                <div
                    class="tab-pane fade"
                    id="disabled-tab-pane"
                    role="tabpanel"
                    aria-labelledby="disabled-tab"
                    tabindex="0"
                >
                    ...
                </div>
            </div>
        </>
    )
}
