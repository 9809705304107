import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'

const CommonIndex = Loadable(lazy(() => import('./CommonIndex')))

const CommonComponentRoutes = [
    {
        path: '/CommonIndex',
        element: <CommonIndex />,
    },
]

export default CommonComponentRoutes
