import React, { useEffect, useState } from 'react'
import { Container } from 'app/constant/Common'
import { Breadcrumb } from 'app/components'
import { SimpleCard } from 'app/components'
import IntlMessages from 'app/utils/IntlMessages'
import { rolesFilterForSidNav } from '../../constant/Common'
import { Link } from 'react-router-dom'
import MultiSelect from './MultiSelect'
import {
    FormControl,
    Checkbox,
    Grid,
    Avatar,
    Divider,
    Button,
    Icon,
    FormControlLabel,
    TextField,
    Box,
    CircularProgress,
    InputLabel,
    Select,
    MenuItem,
} from '@mui/material'
import { adminUserAction } from '../../redux/actions/AdminUserAction'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useNavigate } from 'react-router-dom'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { styled, useTheme } from '@mui/system'
import saveUserRegister from '../../redux/saga/AdminUserSaga'
const StyledProgress = styled(CircularProgress)(() => ({
    position: 'absolute',
    top: '6px',
    left: '25px',
}))
let widgetHeight1 = 300
export default function AddNewUser() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [showPassword, setShowPassword] = React.useState(false)
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState(false)

    const handleClickShowPassword = () => setShowPassword((show) => !show)

    const handleMouseDownPassword = (event) => {
        event.preventDefault()
    }
    useEffect(() => {
        dispatch(adminUserAction.getUniqueGroups())
    }, [])
    const adminInputChange = useSelector((state) => state.AdminUserReducer)
    //console.log('adminInputChangeReducers', adminInputChange.groupListTable)
    const [image, setImage] = useState({ profileFile: '', raw: '' })
    const handleChange = (e) => {
        if (e.target.files.length) {
            // setImage({
            //     profileFile: URL.createObjectURL(e.target.files[0]),
            //     raw: e.target.files[0],
            // })
            console.log('preview', URL.createObjectURL(e.target.files[0]))

            dispatch(
                adminUserAction.adminInputChange({
                    props: 'profileFileUsers',
                    value: URL.createObjectURL(e.target.files[0]),
                    raw: e.target.files[0],
                    error: 'profileFileUsersError',
                })
            )
        }
    }
    const numberkeyPressed = (evt) => {
        var charCode = evt.which ? evt.which : evt.keyCode
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            evt.preventDefault()
        }

        return true
    }
    const handleSave = () => {
        // setLoading(true)
        dispatch(adminUserAction.saveRegisterationUser(adminInputChange))
    }

    return (
        <>
            <Container>
                <Grid container spacing={1} style={{ marginTop: '1rem' }}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <FormControl
                            style={{ fontSize: '15px' }}
                            className=""
                            fullWidth={true}
                        >
                            <TextField
                                fullWidth
                                size="small"
                                InputLabelProps={{ shrink: true }}
                                label="First Name"
                                name="userName"
                                //disabled={adminInputChange.editUser == true}
                                className="mb-4 w-full"
                                style={{
                                    width: '100%',
                                    marginBottom: '10px',
                                }}
                                onChange={(value) => {
                                    dispatch(
                                        adminUserAction.inputChangeAdmin({
                                            props: 'userFirstName',
                                            value: value.target.value,
                                            error: 'userFirstNameError',
                                        })
                                    )
                                    setLoading(false)
                                }}
                                required
                                SelectProps={{ native: true }}
                                value={adminInputChange.userFirstName}
                                variant="outlined"
                            ></TextField>
                            <div className="mb-2 form_error">
                                {/* {adminInputChange.userNameError} */}
                            </div>
                        </FormControl>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <FormControl
                            style={{ fontSize: '15px' }}
                            className=""
                            fullWidth={true}
                        >
                            <TextField
                                fullWidth
                                size="small"
                                InputLabelProps={{ shrink: true }}
                                //disabled={adminInputChange.editUser == true}
                                label="Last Name"
                                name="visibilityId"
                                className="mb-4 w-full"
                                style={{
                                    width: '100%',
                                    marginBottom: '10px',
                                }}
                                onChange={(value) => {
                                    dispatch(
                                        adminUserAction.inputChangeAdmin({
                                            props: 'userLastName',
                                            value: value.target.value,
                                            error: 'userLastNameError',
                                        })
                                    )
                                    setLoading(false)
                                }}
                                required
                                value={adminInputChange.userLastName}
                                variant="outlined"
                            ></TextField>
                            <div className="mb-2 form_error">
                                {/* {adminInputChange.userEmailIdError} */}
                            </div>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <FormControl
                            style={{ fontSize: '15px' }}
                            className=""
                            fullWidth={true}
                        >
                            <TextField
                                fullWidth
                                size="small"
                                InputLabelProps={{ shrink: true }}
                                label="User Name"
                                name="userName"
                                // disabled={adminInputChange.editUser == true}
                                className="mb-4 w-full"
                                style={{
                                    width: '100%',
                                    marginBottom: '10px',
                                }}
                                onChange={(value) => {
                                    dispatch(
                                        adminUserAction.inputChangeAdmin({
                                            props: 'userName',
                                            value: value.target.value,
                                            error: 'userNameError',
                                        })
                                    )
                                    setLoading(false)
                                }}
                                required
                                SelectProps={{ native: true }}
                                value={adminInputChange.userName}
                                variant="outlined"
                            ></TextField>
                            <div className="mb-2 form_error">
                                {adminInputChange.userNameError}
                            </div>
                        </FormControl>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <FormControl
                            style={{ fontSize: '15px' }}
                            className=""
                            fullWidth={true}
                        >
                            <TextField
                                fullWidth
                                size="small"
                                InputLabelProps={{ shrink: true }}
                                // disabled={adminInputChange.editUser == true}
                                label="Email Id"
                                name="visibilityId"
                                className="mb-4 w-full"
                                style={{
                                    width: '100%',
                                    marginBottom: '10px',
                                }}
                                onChange={(value) => {
                                    dispatch(
                                        adminUserAction.inputChangeAdmin({
                                            props: 'userEmailId',
                                            value: value.target.value,
                                            error: 'userEmailIdError',
                                        })
                                    )
                                    setLoading(false)
                                }}
                                required
                                value={adminInputChange.userEmailId}
                                variant="outlined"
                            ></TextField>
                            <div className="mb-2 form_error">
                                {adminInputChange.userEmailIdError}
                            </div>
                        </FormControl>
                    </Grid>
                </Grid>
                {adminInputChange.editUser != true && (
                    <Grid container spacing={1}>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <FormControl
                                style={{ fontSize: '15px' }}
                                className=""
                                fullWidth={true}
                            >
                                <TextField
                                    fullWidth
                                    size="small"
                                    type={showPassword ? 'text' : 'password'}
                                    InputLabelProps={{ shrink: true }}
                                    label="Password"
                                    name="visibilityId"
                                    className="mb-4 w-full"
                                    style={{
                                        width: '100%',
                                        marginBottom: '10px',
                                    }}
                                    onChange={(value) => {
                                        dispatch(
                                            adminUserAction.inputChangeAdmin({
                                                props: 'password',
                                                value: value.target.value,
                                                error: 'passwordError',
                                            })
                                        )
                                        setLoading(false)
                                    }}
                                    required
                                    SelectProps={{ native: true }}
                                    value={adminInputChange.password}
                                    variant="outlined"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={
                                                        handleClickShowPassword
                                                    }
                                                    onMouseDown={
                                                        handleMouseDownPassword
                                                    }
                                                    edge="end"
                                                >
                                                    {showPassword ? (
                                                        <VisibilityOff />
                                                    ) : (
                                                        <Visibility />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                ></TextField>
                                <div className="mb-2 form_error">
                                    {adminInputChange.passwordError}
                                </div>
                            </FormControl>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <FormControl
                                style={{ fontSize: '15px' }}
                                className=""
                                fullWidth={true}
                            >
                                <TextField
                                    fullWidth
                                    size="small"
                                    type={showPassword ? 'text' : 'password'}
                                    InputLabelProps={{ shrink: true }}
                                    label="Confirm Password"
                                    name="visibilityId"
                                    className="mb-4 w-full"
                                    style={{ width: '100%' }}
                                    onChange={(value) => {
                                        dispatch(
                                            adminUserAction.inputChangeAdmin({
                                                props: 'confirmPassword',
                                                value: value.target.value,
                                                error: 'confirmPasswordError',
                                            })
                                        )
                                        setLoading(false)
                                    }}
                                    required
                                    SelectProps={{ native: true }}
                                    value={adminInputChange.confirmPassword}
                                    variant="outlined"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={
                                                        handleClickShowPassword
                                                    }
                                                    onMouseDown={
                                                        handleMouseDownPassword
                                                    }
                                                    edge="end"
                                                >
                                                    {showPassword ? (
                                                        <VisibilityOff />
                                                    ) : (
                                                        <Visibility />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                ></TextField>
                                <div className=" form_error">
                                    {adminInputChange.confirmPasswordError}
                                </div>
                            </FormControl>
                        </Grid>
                    </Grid>
                )}
                <Grid container spacing={1}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <FormControl
                            style={{ fontSize: '15px' }}
                            className=""
                            fullWidth={true}
                        >
                            <TextField
                                fullWidth
                                size="small"
                                InputLabelProps={{ shrink: true }}
                                label="Phone Number"
                                name="phoneNumber"
                                onKeyPress={numberkeyPressed}
                                // disabled={adminInputChange.editUser == true}
                                className="mb-4 w-full"
                                style={{
                                    width: '100%',
                                    marginBottom: '10px',
                                }}
                                onChange={(value) => {
                                    dispatch(
                                        adminUserAction.inputChangeAdmin({
                                            props: 'phoneNumber',
                                            value: value.target.value,
                                            error: 'phoneNumberError',
                                        })
                                    )
                                    setLoading(false)
                                }}
                                SelectProps={{ native: true }}
                                value={adminInputChange.phoneNumber}
                                variant="outlined"
                            ></TextField>
                            <div className="mb-2 form_error">
                                {adminInputChange.phoneNumberError}
                            </div>
                        </FormControl>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <FormControl
                            style={{ fontSize: '15px' }}
                            className=""
                            fullWidth={true}
                        >
                            <TextField
                                fullWidth
                                size="small"
                                InputLabelProps={{ shrink: true }}
                                // disabled={adminInputChange.editUser == true}
                                label="Address"
                                name="address"
                                className="mb-4 w-full"
                                style={{
                                    width: '100%',
                                    marginBottom: '10px',
                                }}
                                onChange={(value) => {
                                    dispatch(
                                        adminUserAction.inputChangeAdmin({
                                            props: 'address',
                                            value: value.target.value,
                                            error: 'addressError',
                                        })
                                    )
                                    setLoading(false)
                                }}
                                value={adminInputChange.address}
                                variant="outlined"
                            ></TextField>
                            <div className="mb-2 form_error">
                                {adminInputChange.addressError}
                            </div>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <FormControl
                            style={{ fontSize: '15px' }}
                            className=""
                            fullWidth={true}
                        >
                            <TextField
                                fullWidth
                                size="small"
                                InputLabelProps={{ shrink: true }}
                                label="Gender"
                                name="gender"
                                className="mb-4 w-full"
                                style={{
                                    width: '100%',
                                    marginBottom: '10px',
                                }}
                                onChange={(value) => {
                                    dispatch(
                                        adminUserAction.inputChangeAdmin({
                                            props: 'gender',
                                            value: value.target.value,
                                            error: 'genderError',
                                        })
                                    )
                                    setLoading(false)
                                }}
                                required
                                select
                                SelectProps={{ native: true }}
                                value={adminInputChange.gender}
                                variant="outlined"
                            >
                                <option value="">Select Gender</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                            </TextField>
                            <div className="mb-2 form_error">
                                {adminInputChange.genderError}
                            </div>
                        </FormControl>
                    </Grid>

                    {/* {adminInputChange.roleSelection == '2' && ( 
                             adminInputChange.getRolesAllocate.map( 
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <FormControl
                                    style={{ fontSize: '15px' }}
                                    className=""
                                    fullWidth={true}
                                >
                                    <TextField
                                        fullWidth
                                        size="small"
                                        InputLabelProps={{ shrink: true }}
                                        label="Role List"
                                        name="visibilityId"
                                        className="mb-4 w-full"
                                        style={{
                                            width: '100%',
                                            marginBottom: '20px',
                                        }}
                                        onChange={(value) => {dispatch(adminUserAction.selectedRoleAllocation(
                                        {
                                                        props: item,
                                                        value: value
                                                            .target
                                                            .checked,
                                                    }
                                                )
                                            )
                                        }}
                                        required
                                        select
                                        SelectProps={{ native: true }}
                                        // value={adminInputChange.roleSelection}
                                        variant="outlined"
                                    >
                                        {rolesFilterForSidNav.map((option) => (
                                            <option
                                                key={option.value}
                                                value={option.value}
                                            >
                                                {option.label}
                                            </option>
                                        ))}
                                    </TextField>
                                    <div className="mb-2 form_error"></div>
                                </FormControl>
                            </Grid>
                             )
                       
                     )}  */}
                    {/* {adminInputChange.roleSelection == '2' && ( 
                             adminInputChange.getRolesAllocate.map(  */}

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <FormControl
                            required
                            style={{ fontSize: '15px' }}
                            className=""
                            fullWidth={true}
                        >
                            <InputLabel id="demo-simple-select-required-label">
                                Widget Height
                            </InputLabel>
                            <Select
                                fullWidth
                                size="small"
                                labelId="demo-simple-select-required-label"
                                InputLabelProps={{ shrink: true }}
                                label="widgetHeight"
                                name="widgetHeight"
                                className="mb-2 w-full"
                                inputProps={{
                                    style: {
                                        height: '25px',
                                    },
                                }}
                                style={{
                                    width: '100%',
                                }}
                                onChange={(value) => {
                                    // setWidgetHeight(
                                    //     value.target.value
                                    // )
                                    dispatch(
                                        adminUserAction.inputChangeAdmin({
                                            props: 'widgetHeight',
                                            value: value.target.value,
                                            error: 'widgetHeightError',
                                        })
                                    )
                                }}
                                SelectProps={{ native: true }}
                                value={adminInputChange.widgetHeight}
                                variant="outlined"
                            >
                                {Array.from({ length: 13 }, (_, index) => {
                                    if (index * 50 >= widgetHeight1) {
                                        return (
                                            <MenuItem value={index * 50}>
                                                {index * 50}
                                            </MenuItem>
                                        )
                                    }
                                })}
                            </Select>
                        </FormControl>
                    </Grid>

                    {/* )
                       
                     )} 
                         */}
                </Grid>
                <Grid container spacing={2} style={{ marginBottom: '2rem' }}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <FormControl
                            style={{ fontSize: '15px' }}
                            className=""
                            fullWidth={true}
                        >
                            <TextField
                                fullWidth
                                size="small"
                                InputLabelProps={{ shrink: true }}
                                label="User Group"
                                name="User group"
                                className="mb-4 w-full"
                                // disabled={
                                //     adminInputChange.editUser == true &&
                                //     adminInputChange.userId.name ==
                                //         adminInputChange.userEmailId
                                // }
                                style={{
                                    width: '100%',
                                    marginBottom: '20px',
                                }}
                                onChange={(value) => {
                                    dispatch(
                                        adminUserAction.inputChangeAdmin({
                                            props: 'groupNameSelection',
                                            value: value.target.value,
                                            error: 'groupNameSelectionError',
                                        })
                                    )
                                    setLoading(false)
                                }}
                                required
                                select
                                SelectProps={{ native: true }}
                                value={adminInputChange.groupNameSelection}
                                variant="outlined"
                            >
                                <option value={0}>Select Group</option>
                                {adminInputChange.uniqueGroupList &&
                                    adminInputChange.uniqueGroupList.map(
                                        (option) => (
                                            <option key={option} value={option}>
                                                {option}
                                            </option>
                                        )
                                    )}
                            </TextField>
                            <div className="mb-2 form_error">
                                {adminInputChange.groupNameSelectionError}
                            </div>
                        </FormControl>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                        <MultiSelect />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <span style={{ marginLeft: '2rem', fontWeight: 'bold' }}>
                        Profile picture
                    </span>
                    <Grid
                        item
                        lg={3}
                        md={3}
                        sm={3}
                        xs={3}
                        style={{
                            marginLeft: '-10rem',
                            marginTop: '3rem',
                        }}
                    >
                        <input
                            style={{
                                marginLeft: '2rem',
                                marginTop: ' 1rem',
                                fontSize: 'small',
                            }}
                            type="file"
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid
                        item
                        lg={8}
                        md={8}
                        sm={8}
                        xs={8}
                        style={{
                            marginLeft: '1rem',
                            marginTop: '0rem',
                        }}
                    >
                        <img
                            style={{
                                height: '160px',
                                width: '160px',
                                marginLeft: '7rem',
                                padding: '8px',
                                borderRadius: '55%',
                                marginTop: '0rem',
                            }}
                            src={adminInputChange.profileFileUsers}
                        />
                    </Grid>
                </Grid>

                {/* <label htmlFor="upload-button" type="file">
                    <p style={{ fontSize: '15px' }}>Upload picture</p>
                    {image.profileFile ? (
                        <img
                            src={image.profileFile}
                            alt="dummy"
                            style={{
                                height: '66px',
                                width: '66px',

                                padding: '8px',
                                borderRadius: '55%',
                            }}
                            type="file"
                            accept="image/x-png,image/jpeg"
                        />
                    ) : (
                        <>
                            <Avatar
                                style={{
                                    height: '66px',
                                    width: '66px',

                                    padding: '8px',
                                    borderRadius: '55%',
                                }}
                                // onChange={handleChange}
                                src={adminInputChange.profileFileUsers}
                                sx={{ cursor: 'pointer' }}
                            />
                        </>
                    )}
                </label>
                <input
                    type="file"
                    id="upload-button"
                    style={{ display: 'none' }}
                    onChange={handleChange}
                />
                <br /> */}

                {/* <button onClick={handleUpload}></button> */}

                {/* text I Hidded */}

                {/*             
                    {adminInputChange.roleSelection == '2' && (
                        <Grid>
                            <h4> ROLES ALLOCATION</h4>
                            <Divider />
                            <Grid container spacing={0.5}>
                                {adminInputChange.getRolesAllocate.map(
                                    (item) => (
                                        <Grid item lg={6} md={6} sm={6} xs={6}>
                                            <FormControl
                                                style={{ fontSize: '15px' }}
                                                className=""
                                                fullWidth={true}
                                            >
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            // checked={ console.log("WelcomeBuddies",adminInputChange.saveRole)}
                                                            checked={adminInputChange.saveRole.includes(
                                                                item
                                                            )}
                                                            onChange={(
                                                                value
                                                            ) => {
                                                                dispatch(
                                                                    adminUserAction.selectedRoleAllocation(
                                                                        {
                                                                            props: item,
                                                                            value: value
                                                                                .target
                                                                                .checked,
                                                                        }
                                                                    )
                                                                )
                                                            }}
                                                        />
                                                    }
                                                    label={item}
                                                />
                                            </FormControl>
                                        </Grid>
                                    )
                                )}
                            </Grid>
                        </Grid>
                    
                    )} */}

                {/* text I Hidded */}
                <Box
                    display="flex"
                    justifyContent="flex-end"
                    m={1}
                    p={1}
                    bgcolor="background.paper"
                >
                    <Button
                        className="buttonaddEmployee"
                        color="inherit"
                        variant="contained"
                        type="button"
                        style={{ paddingRight: '10px', margin: '10px' }}
                        onClick={() =>
                            dispatch(adminUserAction.getAddNewUserPage())
                        }
                    >
                        <Icon>close</Icon>
                        <span
                            className="ml-2 capitalize"
                            style={{ marginLeft: '10px' }}
                        >
                            Cancel
                        </span>
                    </Button>
                    <Button
                        className="buttonaddEmployee"
                        color="success"
                        variant="contained"
                        type="submit"
                        disabled={adminInputChange.setLoading}
                        style={{ paddingRight: '10px', margin: '10px' }}
                        onClick={() => handleSave(adminInputChange)}
                    >
                        <Icon>save</Icon>
                        <span
                            className="ml-2 capitalize"
                            style={{ marginLeft: '10px' }}
                        >
                            Save
                        </span>
                        {adminInputChange.setLoading && (
                            <StyledProgress
                                size={24}
                                className="buttonProgress"
                            />
                        )}
                    </Button>
                </Box>
                {/* </SimpleCard> */}
            </Container>
        </>
    )
}
