// import React, { createContext, useEffect, useReducer } from 'react'
// import jwtDecode from 'jwt-decode'
// import axios from 'axios.js'
// import { MatxLoading } from 'app/components'
// import 'react-notifications/lib/notifications.css'
// import { useNavigate } from 'react-router-dom'
// import {
//     API_URL,
//     headers,
//     loginheaders,
//     LOGIN_AUTH,
//     newApi,
//     LOGIN_AUTH_SIGNUP,
//     LOGIN_API_NEW,
//     loginBaseUrl,
//     FORCE_CHANGE_PASSWORD,
// } from '../constant/Common'
// import { NotificationManager, NotificationContainer } from 'react-notifications'
// const initialState = {
//     isAuthenticated: false,
//     isInitialised: false,
//     user: null,
// }

// const isValidToken = (accessToken) => {
//     if (!accessToken) {
//         return false
//     }
//     return true
//     // const decodedToken = jwtDecode(accessToken)
//     // const currentTime = Date.now() / 1000
//     // return decodedToken.exp > currentTime
// }

// const setSession = (accessToken) => {
//     if (accessToken) {
//         localStorage.setItem('accessToken', accessToken)
//         axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
//     } else {
//         localStorage.removeItem('accessToken')
//         delete axios.defaults.headers.common.Authorization
//     }
// }

// const reducer = (state, action) => {
//     switch (action.type) {
//         case 'INIT': {
//             const { isAuthenticated, user } = action.payload

//             return {
//                 ...state,
//                 isAuthenticated,
//                 isInitialised: true,
//                 user,
//             }
//         }
//         case 'LOGIN': {
//             const { user, code, authenticate } = action.payload
//             return {
//                 ...state,
//                 isAuthenticated: authenticate,
//                 user,
//                 code: code,
//             }
//         }
//         case 'LOGOUT': {
//             return {
//                 ...state,
//                 isAuthenticated: false,
//                 user: null,
//             }
//         }
//         case 'REGISTER': {
//             const { user } = action.payload

//             return {
//                 ...state,
//                 isAuthenticated: true,
//                 user,
//             }
//         }
//         default: {
//             return { ...state }
//         }
//     }
// }

// const AuthContext = createContext({
//     ...initialState,
//     method: 'JWT',
//     login: () => Promise.resolve(),
//     logout: () => {},
//     register: () => Promise.resolve(),
//     ChangePassword: () => Promise.resolve(),
// })
// export const AuthProvider = ({ children }) => {
//     const [state, dispatch] = useReducer(reducer, initialState)
//     const navigate = useNavigate()

//     const login = async (email, password) => {
//         let form = { userName: email, password: password }
//         let urls = loginBaseUrl + LOGIN_API_NEW
//         let reqData = form
//         //console.log(reqData)
//         let headers = {
//             'x-api-key': '9rI5cNUPdMCbjvby0YxthKqpBky5KS7DTkskSX20',
//         }
//         var response = await axios
//             .request({
//                 method: 'post',
//                 url: urls,
//                 headers: headers,
//                 data: reqData,
//             })

//             .then((res) => res)
//             .catch((e) => e)

//         if (response.status == 200) {
//             // console.log("loginResponse",response)
//             if (response.data.code == 200) {
//                 var finalResp = {}

//                 var ele = response.data.result.forEach((element, index) => {
//                     if (index == 0) {
//                         const idToken = element.token
//                         setSession(idToken)
//                         localStorage.setItem('accessToken', idToken)
//                         finalResp = { ...finalResp, idToken: idToken }
//                     }
//                     if (index == 1) {
//                         let users = JSON.stringify({
//                             name: element.userName,
//                             firstName: element.firstName,
//                             lastName: element.lastName,
//                             avatar: '',
//                             email: element.email,
//                             active: element.active,
//                             address: element.address,
//                             admin: element.admin,
//                             gender: element.gender,
//                             phoneNumber: element.phoneNumber,
//                             status: element.status,
//                             password: element.password,
//                         })
//                         localStorage.setItem('auth_user', users)
//                         localStorage.setItem(
//                             'loginDetails',
//                             JSON.stringify(element)
//                         )
//                         finalResp = { ...finalResp, userDetails: element }
//                         navigate('/dashboard/default')
//                         window.location.reload()
//                     }
//                     if (index == 2) {
//                         localStorage.setItem(
//                             'loginRoles',
//                             JSON.stringify(element.roles)
//                         )
//                         finalResp = { ...finalResp, loginRoles: element.roles }
//                     }
//                 })
//                 if (finalResp.idToken != '') {
//                     navigate('/dashboard/default')
//                     dispatch({
//                         type: 'LOGIN',
//                         payload: {
//                             user: finalResp,
//                         },
//                     })
//                     /*
//                 if (response.data.idToken != null) {
//                     const {
//                         accessToken,
//                         idToken,
//                         expiresIn,
//                         tokenType,
//                         userDetails,
//                     } = response.data
//                     setSession(idToken)
//                     let users = JSON.stringify({
//                         name: userDetails.userName,
//                         firstName: userDetails.first_name,
//                         lastName: userDetails.last_name,
//                         avatar: '',
//                         email: userDetails.email,
//                     })
//                     localStorage.setItem(
//                         'loginRoles',
//                         JSON.stringify(
//                             userDetails.group.roles.map((item) => item.roleName)
//                         )
//                     )
//                     localStorage.setItem(
//                         'loginRolesProfile',
//                         JSON.stringify(userDetails.group.roles)
//                     )
//                     localStorage.setItem(
//                         'loginDetails',
//                         JSON.stringify(userDetails)
//                     )

//                     //localStorage.setItem('RoleId', '1')
//                     //localStorage.setItem('menuId', [2, 3, 4, 5, 6, 7, 8, 9])
//                     localStorage.setItem('auth_user', users)
//                     localStorage.setItem('accessToken', response.data.idToken)
//                     // navigate('/dashboard/default')
//                     // window.location.reload()

//                     dispatch({
//                         type: 'LOGIN',
//                         payload: {
//                             user: response.data,
//                         },
//                     })*/
//                 } else {
//                     NotificationManager.error('Please Verify Your Details')
//                 }
//             } else if (response.data.status.code == -1) {
//                 NotificationManager.error(response.data.status.details)
//             } else if (response.data.status.code == -2) {
//                 dispatch({
//                     type: 'LOGIN',
//                     payload: {
//                         authenticate: false,
//                         user: '',
//                         code: response.data.status.code,
//                     },
//                 })
//             } else {
//                 NotificationManager.error(`${response.data.status.details}`)
//             }
//         } else {
//             NotificationManager.error('Internal application error. Please contact system administrator., please Try again')
//         }
//     }
//     const register = async (email, username, password) => {
//         const reg =
//             /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/

//         const re = reg.test(password)

//         if (!re) {
//             NotificationManager.error(
//                 'Min 8 characters & Max 30 char, at least one letter, one number and one special character'
//             )
//             return
//         }
//         /* const response = await axios.post('/api/auth/register', {
//             email,
//             username,
//             password,
//         })*/
//         let form = {
//             email: email,
//             username: username,
//             password: password,
//         }
//         let urls = API_URL.api + LOGIN_AUTH_SIGNUP

//         //  let urls="https://admin.samug.in/V1/loginChk"
//         let reqData = form
//         //console.log(reqData)
//         let headers = {
//             'x-api-key': '9rI5cNUPdMCbjvby0YxthKqpBky5KS7DTkskSX20',
//         }
//         var response = await axios
//             .request({
//                 method: 'post',
//                 url: urls,
//                 headers: headers,
//                 data: reqData,
//             })

//             .then((res) => res)
//             .catch((e) => e)
//         if (response.status == 200) {
//             if (response.data.status.code == 200) {
//                 NotificationManager.info(response.data.status.message)
//                 navigate('/')
//             } else {
//                 NotificationManager.error(response.data.status.details)
//             }
//         } else {
//             NotificationManager.error('Please Provide Valid Details')
//         }
//     }

//     const logout = () => {
//         setSession(null)
//         localStorage.clear()
//         dispatch({ type: 'LOGOUT' })
//     }
//     const ChangePassword = async (payload) => {
//         var props = payload
//         try {
//             var req = JSON.stringify({
//                 username: props.changePasswordUsername,
//                 password: props.tempPassword,
//                 newPassword: props.ConfirmNewPassword,
//             })
//             let headers = {
//                 'Content-Type': 'application/json;charset=UTF-8',
//                 Accept: 'application/json',
//             }
//             var response = await axios
//                 .request({
//                     method: 'post',
//                     url: newApi + FORCE_CHANGE_PASSWORD,
//                     headers: headers,
//                     data: req,
//                 })
//                 .then((response) => {
//                     return response
//                 })
//                 .catch((err) => {
//                     return err.response
//                 })

//             if (response.status == 200) {

//                     if (response.data.code == 200) {
//                         var finalResp = {}

//                         var ele = response.data.result.forEach((element, index) => {
//                             if (index == 0) {
//                                 const idToken = element.token
//                                 setSession(idToken)
//                                 localStorage.setItem('accessToken', idToken)
//                                 finalResp = { ...finalResp, idToken: idToken }
//                             }
//                             if (index == 1) {
//                                 let users = JSON.stringify({
//                                     name: element.userName,
//                                     firstName: element.firstName,
//                                     lastName: element.lastName,
//                                     avatar: '',
//                                     email: element.email,
//                                     active: element.active,
//                                     address: element.address,
//                                     admin: element.admin,
//                                     gender: element.gender,
//                                     phoneNumber: element.phoneNumber,
//                                     status: element.status,
//                                     password: element.password,
//                                 })
//                                 localStorage.setItem('auth_user', users)
//                                 localStorage.setItem(
//                                     'loginDetails',
//                                     JSON.stringify(element)
//                                 )
//                                 finalResp = { ...finalResp, userDetails: element }
//                                 navigate('/dashboard/default')
//                                 window.location.reload()
//                             }
//                             if (index == 2) {
//                                 localStorage.setItem(
//                                     'loginRoles',
//                                     JSON.stringify(element.roles)
//                                 )
//                                 finalResp = { ...finalResp, loginRoles: element.roles }
//                             }
//                         })
//                         if (finalResp.idToken != '') {
//                             navigate('/dashboard/default')
//                             dispatch({
//                                 type: 'LOGIN',
//                                 payload: {
//                                     user: finalResp,
//                                 },
//                             })

//                     /*
//                     if (response.data.idToken != null) {
//                         const {
//                             accessToken,
//                             idToken,
//                             expiresIn,
//                             tokenType,
//                             userDetails,
//                         } = response.data
//                         setSession(idToken)
//                         let users = JSON.stringify({
//                             name: userDetails.userName,
//                             firstName: userDetails.first_name,
//                             lastName: userDetails.last_name,
//                             avatar: '',
//                             email: userDetails.email,
//                         })
//                         localStorage.setItem(
//                             'loginRoles',
//                             JSON.stringify(
//                                 userDetails.group
//                                     ? userDetails.group.roles.map(
//                                           (item) => item.roleName
//                                       )
//                                     : []
//                             )
//                         )
//                         //localStorage.setItem('RoleId', '1')
//                         //localStorage.setItem('menuId', [2, 3, 4, 5, 6, 7, 8, 9])
//                         localStorage.setItem('auth_user', users)
//                         localStorage.setItem(
//                             'accessToken',
//                             response.data.idToken
//                         )
//                         navigate('/dashboard/default')
//                         window.location.reload()
//                         dispatch({
//                             type: 'LOGIN',
//                             payload: {
//                                 user: response.data,
//                             },
//                         })*/
//                     } else {
//                         NotificationManager.error('Please Verify Your Details')
//                     }
//                 } else if (response.data.status.code == -1) {
//                     NotificationManager.error(response.data.status.details)
//                 } else {
//                     NotificationManager.error(`${response.data.status.details}`)
//                 }
//             } else {
//                 NotificationManager.error(
//                     'Internal application error. Please contact system administrator., please Try again'
//                 )
//             }
//         } catch (error) {
//             //console.log(error)
//         }
//     }

//     useEffect(() => {
//         ;(async () => {
//             try {
//                 const accessToken = localStorage.getItem('accessToken')

//                 if (accessToken && isValidToken(accessToken)) {
//                     setSession(accessToken)
//                     //const response = await axios.get('/api/auth/profile')
//                     //const { user } = response.data
//                     const authUser = localStorage.getItem('auth_user')
//                     dispatch({
//                         type: 'INIT',
//                         payload: {
//                             isAuthenticated: true,
//                             user: authUser,
//                         },
//                     })
//                 } else {
//                     dispatch({
//                         type: 'INIT',
//                         payload: {
//                             isAuthenticated: false,
//                             user: null,
//                         },
//                     })
//                 }
//             } catch (err) {
//                 //console.error(err)
//                 dispatch({
//                     type: 'INIT',
//                     payload: {
//                         isAuthenticated: false,
//                         user: null,
//                     },
//                 })
//             }
//         })()
//     }, [])

//     if (!state.isInitialised) {
//         return <MatxLoading />
//     }

//     return (
//         <AuthContext.Provider
//             value={{
//                 ...state,
//                 method: 'JWT',
//                 login,
//                 logout,
//                 register,
//                 ChangePassword,
//             }}
//         >
//             {children}
//             <NotificationContainer />
//         </AuthContext.Provider>
//     )
// }

// export default AuthContext

import React, { createContext, useEffect, useReducer } from 'react'
import jwtDecode from 'jwt-decode'
import axios from 'axios.js'
import { MatxLoading } from 'app/components'
import 'react-notifications/lib/notifications.css'
import { useNavigate } from 'react-router-dom'
import {
    API_URL,
    LOGIN_AUTH_SIGNUP,
    UPDATE_BASE_URL,
    FORCE_CHANGE_PSWD,
    IMAGE_URL,
    URL,
    LOGIN_NEW,
} from '../constant/Common'
import { NotificationManager, NotificationContainer } from 'react-notifications'
const initialState = {
    isAuthenticated: false,
    isInitialised: false,
    user: null,
}
function getJwtToken() {
    //  const sessionLoginToken = useSelector((state)=>state.login);
    var abc = localStorage.getItem('accessToken')
    //  console.log("Getting the Token",sessionLoginToken);
    //sessionStorage.getItem("tokenId")
    return abc
}
const isValidToken = (accessToken) => {
    if (!accessToken) {
        return false
    }
    return true
    // const decodedToken = jwtDecode(accessToken)
    // const currentTime = Date.now() / 1000
    // return decodedToken.exp > currentTime
}

const setSession = (accessToken) => {
    if (accessToken) {
        localStorage.setItem('accessToken', accessToken)
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
    } else {
        localStorage.removeItem('accessToken')
        delete axios.defaults.headers.common.Authorization
    }
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'INIT': {
            const { isAuthenticated, user } = action.payload

            return {
                ...state,
                isAuthenticated,
                isInitialised: true,
                user,
            }
        }
        case 'LOGIN': {
            const { user, code, authenticate } = action.payload
            return {
                ...state,
                isAuthenticated: authenticate,
                user,
                code: code,
            }
        }
        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                user: null,
            }
        }
        case 'REGISTER': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        default: {
            return { ...state }
        }
    }
}

const AuthContext = createContext({
    ...initialState,
    method: 'JWT',
    login: () => Promise.resolve(),
    logout: () => {},
    register: () => Promise.resolve(),
    ChangePassword: () => Promise.resolve(),
})
export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const navigate = useNavigate()

    const login = async (email, password) => {
        let form = { userName: email, password: password }
        // let urls = loginBaseUrl + LOGIN_API_NEW
        let urls = LOGIN_NEW
        let reqData = form
        //console.log(reqData)
        let headers = {
            'x-api-key': '9rI5cNUPdMCbjvby0YxthKqpBky5KS7DTkskSX20',
            Authorization: `Bearer ${getJwtToken()}`,
        }
        var response = await axios
            .request({
                method: 'post',
                url: urls,
                headers: headers,
                data: reqData,
            })

            .then((res) => res)
            .catch((e) => e)

        if (response.status == 200) {
            // console.log("loginResponse",response)
            if (response.data.code == 200) {
                var finalResp = {}

                var ele = response.data.result.forEach((element, index) => {
                    if (index == 0) {
                        const idToken = element.token
                        setSession(idToken)
                        localStorage.setItem('accessToken', idToken)
                        finalResp = { ...finalResp, idToken: idToken }
                    }
                    if (index == 1) {
                        let users = JSON.stringify({
                            name: element.userName,
                            firstName: element.firstName,
                            lastName: element.lastName,
                            avatar: element.profileFile,
                            image: element.profileFile,
                            email: element.email,
                            active: element.active,
                            address: element.address,
                            admin: element.admin,
                            gender: element.gender,
                            phoneNumber: element.phone,
                            status: element.status,
                            password: element.password,
                            groupName: element.groupName,
                            generatedIDLogin: element.generatedID,
                        })
                        localStorage.setItem('auth_user', users)
                        localStorage.setItem(
                            'loginDetails',
                            JSON.stringify(element)
                        )
                        finalResp = { ...finalResp, userDetails: element }
                        navigate('/dashboard/default')
                        window.location.reload()
                    }
                    if (index == 2) {
                        localStorage.setItem(
                            'loginRoles',
                            JSON.stringify(element.roles)
                        )
                        finalResp = { ...finalResp, loginRoles: element.roles }
                    }
                })
                if (finalResp.idToken != '') {
                    navigate('/dashboard/default')
                    console.log('finalResp', finalResp)
                    localStorage.setItem(
                        'loginimage',
                        JSON.stringify(
                            URL +
                                IMAGE_URL +
                                finalResp.userDetails.generatedID +
                                '/profilepic'
                        )
                    )

                    dispatch({
                        type: 'LOGIN',
                        payload: {
                            user: finalResp,
                        },
                    })
                    /*
                if (response.data.idToken != null) {
                    const {
                        accessToken,
                        idToken,
                        expiresIn,
                        tokenType,
                        userDetails,
                    } = response.data
                    setSession(idToken)
                    let users = JSON.stringify({
                        name: userDetails.userName,
                        firstName: userDetails.first_name,
                        lastName: userDetails.last_name,
                        avatar: '',
                        email: userDetails.email,
                    })
                    localStorage.setItem(
                        'loginRoles',
                        JSON.stringify(
                            userDetails.group.roles.map((item) => item.roleName)
                        )
                    )
                    localStorage.setItem(
                        'loginRolesProfile',
                        JSON.stringify(userDetails.group.roles)
                    )
                    localStorage.setItem(
                        'loginDetails',
                        JSON.stringify(userDetails)
                    )

                    //localStorage.setItem('RoleId', '1')
                    //localStorage.setItem('menuId', [2, 3, 4, 5, 6, 7, 8, 9])
                    localStorage.setItem('auth_user', users)
                    localStorage.setItem('accessToken', response.data.idToken)
                    // navigate('/dashboard/default')
                    // window.location.reload()

                    dispatch({
                        type: 'LOGIN',
                        payload: {
                            user: response.data,
                        },
                    })*/
                } else {
                    NotificationManager.error('Please Verify Your Details')
                }
            } else if (response.data.status == 400) {
                NotificationManager.error(response.data.error)
            } else if (response.data.status == 404) {
                NotificationManager.error(response.data.error)
            } else if (response.data.status.code == -1) {
                NotificationManager.error(response.data.status.details)
            } else if (response.data.status.code == -2) {
                dispatch({
                    type: 'LOGIN',
                    payload: {
                        authenticate: false,
                        user: '',
                        code: response.data.status.code,
                    },
                })
            } else {
                NotificationManager.error(`${response.data.status.details}`)
            }
        } else if (response.status >= 500) {
            NotificationManager.error('Server Error')
        } else {
            NotificationManager.error('Internal application error. Please contact system administrator., please Try again')
        }
    }
    const register = async (email, username, password) => {
        const reg =
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/

        const re = reg.test(password)

        if (!re) {
            NotificationManager.error(
                'Min 8 characters & Max 30 char, at least one letter, one number and one special character'
            )
            return
        }
        /* const response = await axios.post('/api/auth/register', {
            email,
            username,
            password,
        })*/
        let form = {
            email: email,
            username: username,
            password: password,
        }
        let urls = API_URL.api + LOGIN_AUTH_SIGNUP

        //  let urls="https://admin.samug.in/V1/loginChk"
        let reqData = form
        //console.log(reqData)
        let headers = {
            'x-api-key': '9rI5cNUPdMCbjvby0YxthKqpBky5KS7DTkskSX20',
        }
        var response = await axios
            .request({
                method: 'post',
                url: urls,
                headers: headers,
                data: reqData,
            })

            .then((res) => res)
            .catch((e) => e)
        if (response.status == 200) {
            if (response.data.status.code == 200) {
                NotificationManager.info(response.data.status.message)
                navigate('/')
            } else {
                NotificationManager.error(response.data.status.details)
            }
        } else {
            NotificationManager.error('Please Provide Valid Details')
        }
    }

    const logout = () => {
        console.log('moving logout')
        setSession(null)
        localStorage.clear()
        dispatch({ type: 'LOGOUT' })
    }
    const ChangePassword = async (payload) => {
        var props = payload
        try {
            var req = JSON.stringify({
                userName: props.changePasswordUsername,
                oldPassword: props.tempPassword,
                newPassword: props.ConfirmNewPassword,
            })
            let headers = {
                'Content-Type': 'application/json;charset=UTF-8',
                Accept: 'application/json',
            }
            var response = await axios
                .request({
                    method: 'post',
                    url: UPDATE_BASE_URL + FORCE_CHANGE_PSWD,
                    headers: headers,
                    data: req,
                })
                .then((response) => {
                    return response
                })
                .catch((err) => {
                    return err.response
                })

            if (response.status == 200) {
                if (response.data.code == 200) {
                    var finalResp = {}

                    var ele = response.data.result.forEach((element, index) => {
                        if (index == 0) {
                            const idToken = element.token
                            setSession(idToken)
                            localStorage.setItem('accessToken', idToken)
                            finalResp = { ...finalResp, idToken: idToken }
                        }
                        if (index == 1) {
                            let users = JSON.stringify({
                                name: element.userName,
                                firstName: element.firstName,
                                lastName: element.lastName,
                                avatar: element.profileFile,
                                image: element.profileFile,
                                email: element.email,
                                active: element.active,
                                address: element.address,
                                admin: element.admin,
                                gender: element.gender,
                                phoneNumber: element.phoneNumber,
                                status: element.status,
                                password: element.password,
                                generatedIDLogin: element.generatedID,
                            })
                            localStorage.setItem('auth_user', users)
                            localStorage.setItem(
                                'loginDetails',
                                JSON.stringify(element)
                            )
                            finalResp = { ...finalResp, userDetails: element }
                            navigate('/dashboard/default')
                            window.location.reload()
                        }
                        if (index == 2) {
                            localStorage.setItem(
                                'loginRoles',
                                JSON.stringify(element.roles)
                            )
                            finalResp = {
                                ...finalResp,
                                loginRoles: element.roles,
                            }
                        }
                    })
                    if (finalResp.idToken != '') {
                        navigate('/dashboard/default')
                        dispatch({
                            type: 'LOGIN',
                            payload: {
                                user: finalResp,
                                // imageurl: UPDATE_BASE_URL + IMAGE_URL + finalResp.userDetails.generatedID + '/profilepic',
                            },
                        })

                        /*
                    if (response.data.idToken != null) {
                        const {
                            accessToken,
                            idToken,
                            expiresIn,
                            tokenType,
                            userDetails,
                        } = response.data
                        setSession(idToken)
                        let users = JSON.stringify({
                            name: userDetails.userName,
                            firstName: userDetails.first_name,
                            lastName: userDetails.last_name,
                            avatar: '',
                            email: userDetails.email,
                        })
                        localStorage.setItem(
                            'loginRoles',
                            JSON.stringify(
                                userDetails.group
                                    ? userDetails.group.roles.map(
                                          (item) => item.roleName
                                      )
                                    : []
                            )
                        )
                        //localStorage.setItem('RoleId', '1')
                        //localStorage.setItem('menuId', [2, 3, 4, 5, 6, 7, 8, 9])
                        localStorage.setItem('auth_user', users)
                        localStorage.setItem(
                            'accessToken',
                            response.data.idToken
                        )
                        navigate('/dashboard/default')
                        window.location.reload()
                        dispatch({
                            type: 'LOGIN',
                            payload: {
                                user: response.data,
                            },
                        })*/
                    } else {
                        NotificationManager.error('Please Verify Your Details')
                    }
                } else if (response.data.status.code == -1) {
                    NotificationManager.error(response.data.status.details)
                } else {
                    NotificationManager.error(`${response.data.status.details}`)
                }
            } else {
                NotificationManager.error(
                    'Internal application error. Please contact system administrator., please Try again'
                )
            }
        } catch (error) {
            //console.log(error)
        }
    }

    useEffect(() => {
        ;(async () => {
            try {
                const accessToken = localStorage.getItem('accessToken')

                if (accessToken && isValidToken(accessToken)) {
                    setSession(accessToken)
                    //const response = await axios.get('/api/auth/profile')
                    //const { user } = response.data
                    const authUser = localStorage.getItem('auth_user')
                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: true,
                            user: authUser,
                        },
                    })
                } else {
                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: false,
                            user: null,
                        },
                    })
                }
            } catch (err) {
                //console.error(err)
                dispatch({
                    type: 'INIT',
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                })
            }
        })()
    }, [])

    if (!state.isInitialised) {
        return <MatxLoading />
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'JWT',
                login,
                logout,
                register,
                ChangePassword,
            }}
        >
            {children}
            <NotificationContainer />
        </AuthContext.Provider>
    )
}

export default AuthContext
