import { ConfirmConstant } from '../../constant/ActionTypes'
const INITIAL_STATE = {
    loader: false,
    setChangeDialog:false,
    confirmUsername:'',
    confirmCode:''
}
export default (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {

        case ConfirmConstant.OPEN_CHANGE_PASSWORD_DIALOG:
            return{
                ...state,
                setChangeDialog: !state.setChangeDialog
            }

            case ConfirmConstant.INPUT_CHANGE_CONFIRM:
                return{
                    ...state,
                    [payload.props]: payload.value,
                    [payload.error]: '',
                }
                case ConfirmConstant.SAVE_CONFIRM_CODE:
                    return{
                        ...state,
                        loader:true
                    }
                    case ConfirmConstant.CONFIRM_SUCCESS:
                        return{
                            ...state,
                            loader:false
                        }
                        case ConfirmConstant.CONFIRM_ERROR:
                        return{
                            ...state,
                            loader:false
                        }
        default: {
            return {
                ...state,
              
            }
        }
    }
}
