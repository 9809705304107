import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'

const ReportMaster = Loadable(lazy(() => import('./ReportMaster')))
const DragAndDrop = Loadable(lazy(() => import('./DragAndDrop')))
const ReportMasterRoutes = [
    
    {
        path:'/ReportMaster',
        element: <ReportMaster />
    },
    {
        path:'/DragAndDrop',
        element:<DragAndDrop/>
    }
]



export default ReportMasterRoutes
