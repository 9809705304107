import { templateActionTypes } from '../../constant/ActionTypes'
import {
    GET_ALL_TABLE_REPORT,
    REPORT_INPUT_CHANGE,
    GET_REPORT_REQUEST,
} from '../../constant/ErrorConstant'
import 'react-notifications/lib/notifications.css'
import { NotificationManager, NotificationContainer } from 'react-notifications'
export const templateManagementAction = {
    templateInputChange,
    closeHeaderPanel,
    menuPopupOpen,
    closeTemplateHeaderPopup,
    openTemplateHeaderPopup,
    closeMenuPopup,
    getTemplateTableData,
    getTemplateList,
    saveNewTemplate,
    deleteTemplateRow,
    editTemplateRow,
    openEditPopup,
    closeEditPopup,
    saveEditTemplate,
}
function templateInputChange(props) {
    return {
        type: templateActionTypes.TEMPLATE_INPUT_CHANGE,
        payload: props,
    }
}
function closeHeaderPanel(props) {
    return {
        type: templateActionTypes.CLOSE_HEADER_PANEL,
        payload: {},
    }
}
function menuPopupOpen(props) {
    return {
        type: templateActionTypes.OPEN_MENU_POPUP,
        payload: props,
    }
}
function closeTemplateHeaderPopup(props) {
    return {
        type: templateActionTypes.CLOSE_TEMPLATE_POPUP,
        payload: {},
    }
}
function openTemplateHeaderPopup(props) {
    return {
        type: templateActionTypes.OPEN_HEADER_OPENPOPUP,
        payload: {},
    }
}
function closeMenuPopup(props) {
    return {
        type: templateActionTypes.CLOSE_MENUPOPUP,
        payload: props,
    }
}
function getTemplateTableData() {
    return {
        type: templateActionTypes.GET_TEMPLATE_TABLE_DATA,
        payload: {},
    }
}
function getTemplateList(props) {
    return {
        type: templateActionTypes.GET_TEMPLATE_LIST,
        payload: props,
    }
}
function saveNewTemplate(props) {
    let templateNameError = '',
        templateFileUploadError = '',
        templateMiscField3Error = '',
        templateMiscField4Error = ''

    if (!props.templateName) {
        templateNameError = 'Enter Template Name'
    }
    if (!props.templateFileUpload) {
        templateFileUploadError = 'Select File to upload'
    }

    if (props.templateMiscField3) {
        var templateMiscField = /^[0-9\b]+$/

        var isValid = templateMiscField.test(props.templateMiscField3)
        if (!isValid) {
            templateMiscField3Error = 'Enter valid numerics'
        }
    }

    if (props.templateMiscField4) {
        var visibilityMiscField = /^[-+]?[0-9]+\.[0-9]+$/

        var isValidMiscField = visibilityMiscField.test(
            props.templateMiscField4
        )
        if (!isValidMiscField) {
            templateMiscField4Error = 'Enter valid decimals'
        }
    }
    if (
        templateNameError ||
        templateFileUploadError ||
        templateMiscField3Error ||
        templateMiscField4Error
    ) {
        return {
            type: templateActionTypes.VALIDATION_TEMPLATE_NEW,
            payload: {
                templateNameError,
                templateFileUploadError,
                templateMiscField3Error,
                templateMiscField4Error,
            },
        }
    } else {
        return {
            type: templateActionTypes.REQUEST_SAVE_NEW_TEMPLATE,
            payload: props,
        }
    }
}
function deleteTemplateRow(props) {
    return {
        type: templateActionTypes.DELETE_TEMPLATE_REQUEST,
        payload: props,
    }
}
function editTemplateRow(props) {
    return {
        type: templateActionTypes.EDIT_TEMPLATE_REQUEST,
        payload: props,
    }
}
function openEditPopup(props) {
    return {
        type: templateActionTypes.OPEN_EDIT_POPUP,
        payload: props,
    }
}
function closeEditPopup(props) {
    return {
        type: templateActionTypes.CLOSE_EDIT_POPUP,
        payload: props,
    }
}
function saveEditTemplate(props) {
    let templateNameError = '',
        templateFileUploadError = '',
        templateMiscField3Error = '',
        templateMiscField4Error = ''

    if (!props.templateName) {
        templateNameError = 'Enter Template Name'
    }
    if (!props.templateFileUpload) {
        templateFileUploadError = 'Select File to upload'
    }

    if (props.templateMiscField3) {
        var templateMiscField = /^[0-9\b]+$/

        var isValid = templateMiscField.test(props.templateMiscField3)
        if (!isValid) {
            templateMiscField3Error = 'Enter valid numerics'
        }
    }

    if (props.templateMiscField4) {
        var visibilityMiscField = /^[-+]?[0-9]+\.[0-9]+$/

        var isValidMiscField = visibilityMiscField.test(
            props.templateMiscField4
        )
        if (!isValidMiscField) {
            templateMiscField4Error = 'Enter valid decimals'
        }
    }
    if (
        templateNameError ||
        templateFileUploadError ||
        templateMiscField3Error ||
        templateMiscField4Error
    ) {
        return {
            type: templateActionTypes.VALIDATION_TEMPLATE_EDIT,
            payload: {
                templateNameError,
                templateFileUploadError,
                templateMiscField3Error,
                templateMiscField4Error,
            },
        }
    } else {
        return {
            type: templateActionTypes.REQUEST_SAVE_EDIT_TEMPLATE,
            payload: props,
        }
    }
}
