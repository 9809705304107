import React, { useState, useEffect } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import MaterialTable from 'material-table'
import { Link } from 'react-router-dom'
import { Container } from '@mui/material'
import { SimpleCard } from 'app/components'
import {
    FormControl,
    Checkbox,
    Grid,
    Divider,
    Button,
    Avatar,
    Icon,
    FormControlLabel,
    TextField,
    Box,
    Fab,
    InputLabel,
    Select,
    MenuItem,
} from '@mui/material'
import { adminUserAction } from '../../redux/actions/AdminUserAction'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useNavigate } from 'react-router-dom'
import { confirmAlert } from 'react-confirm-alert'
import MultiSelect from './MultiSelect'
import { hiddenTopElement } from '../../constant/Common'
import ReportListMultiSelect from './ReportListMultiSelect'
let widgetHeight1 = 300
export default function EditUserDetails(props) {
    const hiddenValue = hiddenTopElement('5', '6')
    const hideValue = hiddenValue.hideEditDelete
    const [open, setOpen] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [widgetHeight, setWidgetHeight] = useState(widgetHeight1)
    // useEffect(() => {
    //     dispatch(adminUserAction.getAllGroups())
    // }, [])
    const adminInputChange = useSelector((state) => state.AdminUserReducer)
    const handleSave = () => {
        // if (adminInputChange.editUser == true) {
        dispatch(adminUserAction.editedSaveUser(adminInputChange))
        // navigate('/AdminUserList')
        // } else {
        // dispatch(adminUserAction.saveRegisterationUser(adminInputChange))
        // navigate('/AdminUserList')
        // }
    }
    const [image, setImage] = useState({ profileFile: '', raw: '' })
    const handleChange = (e) => {
        if (e.target.files.length) {
            // setImage({
            //     profileFile: URL.createObjectURL(e.target.files[0]),
            //     raw: e.target.files[0],
            // })

            dispatch(
                adminUserAction.adminInputChange({
                    props: 'profileFileUsers',
                    value: URL.createObjectURL(e.target.files[0]),
                    raw: e.target.files[0],
                    error: 'profileFileUsersError',
                })
            )
        }
    }
    // const handleChange = (e) => {
    //     if (e.target.files.length) {
    //         setImage({
    //             profileFile: URL.createObjectURL(e.target.files[0]),
    //             raw: e.target.files[0],
    //         })
    //         console.log('preview',  URL.createObjectURL(e.target.files[0]))

    //             dispatch(
    //                 adminUserAction.inputChangeAdmin(
    //                     {
    //                         props: 'profileFile',
    //                         value:  e.target.files[0],
    //                         error: 'profileFileError',
    //                     }
    //                 )
    //             )

    //         const formData = new FormData()
    //         formData.append('image', e.target.files[0])
    //     }
    // }
    const handleDelete = () => {
        confirmAlert({
            title: (
                <p className="react-confirm">
                    <b>Delete User?</b>
                </p>
            ),
            message: (
                <p className="react-body">Are you sure want to Delete user?</p>
            ),
            // onKeypressEscape: true,
            closeOnClickOutside: true,
            closeOnEscape: true,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        dispatch(
                            adminUserAction.deleteTheUser(adminInputChange)
                        )
                    },
                },
                {
                    label: 'No',
                },
            ],
        })
    }

    const CloseForm = () => {
        setOpen(false)
    }
    const CloseForm2 = () => {
        setOpen(false)
    }
    const openForm = () => {
        setOpen(true)
    }
    const numberkeyPressed = (evt) => {
        var charCode = evt.which ? evt.which : evt.keyCode
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            evt.preventDefault()
        }

        return true
    }
    return (
        <>
            <Container>
                {/* <SimpleCard> */}
                <ul class="nav nav-tabs tabs" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button
                            class="nav-link tabs-link active"
                            id="home-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#home-tab-pane"
                            type="button"
                            role="tab"
                            aria-controls="home-tab-pane"
                            aria-selected="true"
                            style={{ width: '25rem' }}
                        >
                            Info
                        </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button
                            class="nav-link tabs-link"
                            id="profile-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#profile-tab-pane"
                            type="button"
                            role="tab"
                            aria-controls="profile-tab-pane"
                            aria-selected="false"
                            style={{ width: '25rem' }}
                        >
                            Dashboard Settings
                        </button>
                    </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                    <div
                        class="tab-pane fade show active"
                        id="home-tab-pane"
                        role="tabpanel"
                        aria-labelledby="home-tab"
                        tabindex="0"
                    >
                        {open === false ? (
                            <>
                                <SimpleCard style={{ marginTop: '50px' }}>
                                    <Grid
                                        container
                                        spacing={1}
                                        style={{ marginTop: '20px' }}
                                    >
                                        <Grid
                                            item
                                            lg={6}
                                            md={6}
                                            sm={12}
                                            xs={12}
                                        >
                                            <FormControl
                                                style={{ fontSize: '15px' }}
                                                className=""
                                                fullWidth={true}
                                            >
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    label="First Name"
                                                    name="userName"
                                                    //disabled={adminInputChange.editUser == true}
                                                    className="mb-4 w-full"
                                                    style={{
                                                        width: '100%',
                                                        marginBottom: '10px',
                                                    }}
                                                    onChange={(value) => {
                                                        dispatch(
                                                            adminUserAction.inputChangeAdmin(
                                                                {
                                                                    props: 'userFirstName',
                                                                    value: value
                                                                        .target
                                                                        .value,
                                                                    error: 'userFirstNameError',
                                                                }
                                                            )
                                                        )
                                                    }}
                                                    required
                                                    SelectProps={{
                                                        native: true,
                                                    }}
                                                    value={
                                                        adminInputChange.userFirstName
                                                    }
                                                    variant="outlined"
                                                ></TextField>
                                                <div className="mb-2 form_error">
                                                    {
                                                        adminInputChange.userFirstNameError
                                                    }
                                                </div>
                                            </FormControl>
                                        </Grid>
                                        <Grid
                                            item
                                            lg={6}
                                            md={6}
                                            sm={12}
                                            xs={12}
                                        >
                                            <FormControl
                                                style={{ fontSize: '15px' }}
                                                className=""
                                                fullWidth={true}
                                            >
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    //disabled={adminInputChange.editUser == true}
                                                    label="Last Name"
                                                    name="visibilityId"
                                                    className="mb-4 w-full"
                                                    style={{
                                                        width: '100%',
                                                        marginBottom: '10px',
                                                    }}
                                                    onChange={(value) => {
                                                        dispatch(
                                                            adminUserAction.inputChangeAdmin(
                                                                {
                                                                    props: 'userLastName',
                                                                    value: value
                                                                        .target
                                                                        .value,
                                                                    error: 'userLastNameError',
                                                                }
                                                            )
                                                        )
                                                    }}
                                                    required
                                                    value={
                                                        adminInputChange.userLastName
                                                    }
                                                    variant="outlined"
                                                ></TextField>
                                                <div className="mb-2 form_error">
                                                    {
                                                        adminInputChange.userLastNameError
                                                    }
                                                </div>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1}>
                                        <Grid
                                            item
                                            lg={6}
                                            md={6}
                                            sm={12}
                                            xs={12}
                                        >
                                            <FormControl
                                                style={{ fontSize: '15px' }}
                                                className=""
                                                fullWidth={true}
                                            >
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    label="User Name"
                                                    name="userName"
                                                    disabled={
                                                        adminInputChange.editUser ==
                                                        true
                                                    }
                                                    className="mb-4 w-full"
                                                    style={{
                                                        width: '100%',
                                                        marginBottom: '10px',
                                                    }}
                                                    onChange={(value) => {
                                                        dispatch(
                                                            adminUserAction.inputChangeAdmin(
                                                                {
                                                                    props: 'userName',
                                                                    value: value
                                                                        .target
                                                                        .value,
                                                                    error: 'userNameError',
                                                                }
                                                            )
                                                        )
                                                    }}
                                                    required
                                                    SelectProps={{
                                                        native: true,
                                                    }}
                                                    value={
                                                        adminInputChange.userName
                                                    }
                                                    variant="outlined"
                                                ></TextField>
                                                <div className="mb-2 form_error">
                                                    {
                                                        adminInputChange.userNameError
                                                    }
                                                </div>
                                            </FormControl>
                                        </Grid>
                                        <Grid
                                            item
                                            lg={6}
                                            md={6}
                                            sm={12}
                                            xs={12}
                                        >
                                            <FormControl
                                                style={{ fontSize: '15px' }}
                                                className=""
                                                fullWidth={true}
                                            >
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    disabled={
                                                        adminInputChange.editUser ==
                                                        true
                                                    }
                                                    label="Email Id"
                                                    name="visibilityId"
                                                    className="mb-4 w-full"
                                                    style={{
                                                        width: '100%',
                                                        marginBottom: '10px',
                                                    }}
                                                    onChange={(value) => {
                                                        dispatch(
                                                            adminUserAction.inputChangeAdmin(
                                                                {
                                                                    props: 'userEmailId',
                                                                    value: value
                                                                        .target
                                                                        .value,
                                                                    error: 'userEmailIdError',
                                                                }
                                                            )
                                                        )
                                                    }}
                                                    required
                                                    value={
                                                        adminInputChange.userEmailId
                                                    }
                                                    variant="outlined"
                                                ></TextField>
                                                <div className="mb-2 form_error">
                                                    {
                                                        adminInputChange.userEmailIdError
                                                    }
                                                </div>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    {/* {adminInputChange.editUser != true && ( */}
                                    {/* <Grid container spacing={1}>
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <FormControl
                                            style={{ fontSize: '15px' }}
                                            className=""
                                            fullWidth={true}
                                        >
                                            <TextField
                                                fullWidth
                                                size="small"
                                                type="password"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                label="Password"
                                                name="visibilityId"
                                                className="mb-4 w-full"
                                                style={{
                                                    width: '100%',
                                                    marginBottom: '10px',
                                                }}
                                                onChange={(value) => {
                                                    dispatch(
                                                        adminUserAction.inputChangeAdmin(
                                                            {
                                                                props: 'password',
                                                                value: value
                                                                    .target
                                                                    .value,
                                                                error: 'passwordError',
                                                            }
                                                        )
                                                    )
                                                }}
                                                required
                                                SelectProps={{ native: true }}
                                                value={
                                                    adminInputChange.password
                                                }
                                                variant="outlined"
                                            ></TextField>
                                            <div className="mb-2 form_error">
                                                {adminInputChange.passwordError}
                                            </div>
                                        </FormControl>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <FormControl
                                            style={{ fontSize: '15px' }}
                                            className=""
                                            fullWidth={true}
                                        >
                                            <TextField
                                                fullWidth
                                                size="small"
                                                type="password"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                label="Confirm Password"
                                                name="visibilityId"
                                                className="mb-4 w-full"
                                                style={{ width: '100%' }}
                                                onChange={(value) => {
                                                    dispatch(
                                                        adminUserAction.inputChangeAdmin(
                                                            {
                                                                props: 'confirmPassword',
                                                                value: value
                                                                    .target
                                                                    .value,
                                                                error: 'confirmPasswordError',
                                                            }
                                                        )
                                                    )
                                                }}
                                                required
                                                SelectProps={{ native: true }}
                                                value={
                                                    adminInputChange.confirmPassword
                                                }
                                                variant="outlined"
                                            ></TextField>
                                            <div className=" form_error">
                                                {
                                                    adminInputChange.confirmPasswordError
                                                }
                                            </div>
                                        </FormControl>
                                    </Grid>
                                </Grid> */}
                                    {/* )} */}

                                    <Grid container spacing={1}>
                                        <Grid
                                            item
                                            lg={6}
                                            md={6}
                                            sm={12}
                                            xs={12}
                                        >
                                            <FormControl
                                                style={{ fontSize: '15px' }}
                                                className=""
                                                fullWidth={true}
                                            >
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    label="Phone Number"
                                                    name="phoneNumber"
                                                    onKeyPress={
                                                        numberkeyPressed
                                                    }
                                                    disabled={
                                                        adminInputChange.editUser ==
                                                        true
                                                    }
                                                    className="mb-4 w-full"
                                                    style={{
                                                        width: '100%',
                                                        marginBottom: '10px',
                                                    }}
                                                    onChange={(value) => {
                                                        dispatch(
                                                            adminUserAction.inputChangeAdmin(
                                                                {
                                                                    props: 'phoneNumber',
                                                                    value: value
                                                                        .target
                                                                        .value,
                                                                    error: 'phoneNumberError',
                                                                }
                                                            )
                                                        )
                                                    }}
                                                    SelectProps={{
                                                        native: true,
                                                    }}
                                                    value={
                                                        adminInputChange.phoneNumber
                                                    }
                                                    variant="outlined"
                                                ></TextField>
                                                <div className="mb-2 form_error">
                                                    {
                                                        adminInputChange.phoneNumberError
                                                    }
                                                </div>
                                            </FormControl>
                                        </Grid>
                                        <Grid
                                            item
                                            lg={6}
                                            md={6}
                                            sm={12}
                                            xs={12}
                                        >
                                            <FormControl
                                                style={{ fontSize: '15px' }}
                                                className=""
                                                fullWidth={true}
                                            >
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    disabled={
                                                        adminInputChange.editUser ==
                                                        true
                                                    }
                                                    label="Address"
                                                    name="address"
                                                    className="mb-4 w-full"
                                                    style={{
                                                        width: '100%',
                                                        marginBottom: '10px',
                                                    }}
                                                    onChange={(value) => {
                                                        dispatch(
                                                            adminUserAction.inputChangeAdmin(
                                                                {
                                                                    props: 'address',
                                                                    value: value
                                                                        .target
                                                                        .value,
                                                                    error: 'addressError',
                                                                }
                                                            )
                                                        )
                                                    }}
                                                    value={
                                                        adminInputChange.address
                                                    }
                                                    variant="outlined"
                                                ></TextField>
                                                <div className="mb-2 form_error">
                                                    {
                                                        adminInputChange.addressError
                                                    }
                                                </div>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid
                                            item
                                            lg={6}
                                            md={6}
                                            sm={12}
                                            xs={12}
                                        >
                                            <FormControl
                                                style={{ fontSize: '15px' }}
                                                className=""
                                                fullWidth={true}
                                            >
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    label="Gender"
                                                    name="gender"
                                                    className="mb-4 w-full"
                                                    style={{
                                                        width: '100%',
                                                        marginBottom: '10px',
                                                    }}
                                                    onChange={(value) => {
                                                        dispatch(
                                                            adminUserAction.inputChangeAdmin(
                                                                {
                                                                    props: 'gender',
                                                                    value: value
                                                                        .target
                                                                        .value,
                                                                    error: 'genderError',
                                                                }
                                                            )
                                                        )
                                                    }}
                                                    select
                                                    SelectProps={{
                                                        native: true,
                                                    }}
                                                    value={
                                                        adminInputChange.gender
                                                    }
                                                    variant="outlined"
                                                >
                                                    <option value="">
                                                        Select Gender
                                                    </option>
                                                    <option value="Male">
                                                        Male
                                                    </option>
                                                    <option value="Female">
                                                        Female
                                                    </option>
                                                </TextField>
                                                <div className="mb-2 form_error">
                                                    {
                                                        adminInputChange.genderError
                                                    }
                                                </div>
                                            </FormControl>
                                        </Grid>

                                        <Grid
                                            item
                                            lg={6}
                                            md={6}
                                            sm={12}
                                            xs={12}
                                        >
                                            <FormControl
                                                style={{ fontSize: '15px' }}
                                                className=""
                                                fullWidth={true}
                                            >
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    label="User Group"
                                                    name="visibilityId"
                                                    className="mb-4 w-full"
                                                    // disabled={
                                                    //     adminInputChange.editUser == true &&
                                                    //     adminInputChange.userId.name ==
                                                    //         adminInputChange.userEmailId
                                                    // }
                                                    style={{
                                                        width: '100%',
                                                        marginBottom: '20px',
                                                    }}
                                                    onChange={(value) => {
                                                        dispatch(
                                                            adminUserAction.inputChangeAdmin(
                                                                {
                                                                    props: 'groupNameSelection',
                                                                    value: value
                                                                        .target
                                                                        .value,
                                                                    error: 'groupNameSelectionError',
                                                                }
                                                            )
                                                        )
                                                    }}
                                                    required
                                                    select
                                                    SelectProps={{
                                                        native: true,
                                                    }}
                                                    value={
                                                        adminInputChange.groupNameSelection
                                                    }
                                                    variant="outlined"
                                                >
                                                    <option value={0}>
                                                        Select Group
                                                    </option>
                                                    {adminInputChange.uniqueGroupList &&
                                                        adminInputChange.uniqueGroupList.map(
                                                            (option) => (
                                                                <option
                                                                    key={option}
                                                                    value={
                                                                        option
                                                                    }
                                                                >
                                                                    {option}
                                                                </option>
                                                            )
                                                        )}
                                                </TextField>
                                                <div className="mb-2 form_error">
                                                    {
                                                        adminInputChange.groupNameSelectionError
                                                    }
                                                </div>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    {/* <Grid
                                        container
                                        spacing={1}
                                        style={{ marginBottom: '2rem' }}
                                    >
                                        <Grid item lg={7} md={7} sm={7} xs={7}>
                                            <MultiSelect />
                                        </Grid>
                                    </Grid> */}
                                    <Grid container spacing={3}>
                                        <span
                                            style={{
                                                marginLeft: '2rem',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Profile picture
                                        </span>
                                        <Grid
                                            item
                                            lg={3}
                                            md={3}
                                            sm={3}
                                            xs={3}
                                            style={{
                                                marginLeft: '-8rem',
                                                marginTop: '3rem',
                                            }}
                                        >
                                            <input
                                                style={{
                                                    marginLeft: '0rem',
                                                    marginTop: ' -8rem',
                                                }}
                                                type="file"
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            lg={8}
                                            md={8}
                                            sm={8}
                                            xs={8}
                                            style={{
                                                marginLeft: '3rem',
                                                marginTop: '0rem',
                                            }}
                                        >
                                            {/* {adminInputChange.profileApi != '' ? (
                                            <img
                                                style={{
                                                    height: '160px',
                                                    width: '160px',
                                                    marginLeft: '7rem',
                                                    padding: '8px',
                                                    borderRadius: '55%',
                                                    marginTop: '0rem',
                                                }}
                                                
                                                src={
                                                    adminInputChange.profileApi
                                                }
                                            />
                                            ):( */}
                                            <img
                                                style={{
                                                    height: '160px',
                                                    width: '160px',
                                                    marginLeft: '7rem',
                                                    padding: '8px',
                                                    borderRadius: '55%',
                                                    marginTop: '0rem',
                                                }}
                                                src={
                                                    adminInputChange.profileFileUsers
                                                }
                                            />

                                            {/* )} */}
                                        </Grid>
                                    </Grid>

                                    <Box
                                        justifyContent="flex-end"
                                        m={-3}
                                        p={2}
                                        bgcolor="background.paper"
                                    >
                                        {adminInputChange.userId.name !=
                                            adminInputChange.userName && (
                                            <Grid>
                                                <Button
                                                    className="buttonaddEmployee"
                                                    color="error"
                                                    variant="contained"
                                                    type="submit"
                                                    disabled={hideValue}
                                                    style={{
                                                        float: 'left',
                                                        margin: '10px',
                                                    }}
                                                    onClick={() =>
                                                        handleDelete()
                                                    }
                                                >
                                                    <Icon>delete</Icon>
                                                    <span
                                                        className="ml-2 capitalize"
                                                        style={{
                                                            marginLeft: '10px',
                                                        }}
                                                    >
                                                        Delete
                                                    </span>
                                                </Button>
                                            </Grid>
                                        )}
                                        <Button
                                            className="buttonaddEmployee"
                                            color="success"
                                            variant="contained"
                                            type="submit"
                                            disabled={hideValue}
                                            style={{
                                                paddingRight: '10px',
                                                float: 'right',
                                                margin: '10px',
                                            }}
                                            onClick={() => handleSave()}
                                        >
                                            <Icon>save</Icon>
                                            <span
                                                className="ml-2 capitalize"
                                                style={{ marginLeft: '10px' }}
                                            >
                                                Save
                                            </span>
                                        </Button>
                                        <Button
                                            className="buttonaddEmployee"
                                            color="primary"
                                            variant="contained"
                                            type="button"
                                            disabled={hideValue}
                                            style={{
                                                paddingRight: '10px',
                                                float: 'right',
                                                margin: '10px',
                                            }}
                                            onClick={() =>
                                                dispatch(
                                                    adminUserAction.getEditUser(
                                                        adminInputChange
                                                    )
                                                )
                                            }
                                        >
                                            <Icon>undo</Icon>
                                            <span
                                                className="ml-2 capitalize"
                                                style={{ marginLeft: '10px' }}
                                            >
                                                undo
                                            </span>
                                        </Button>
                                        <Button
                                            className="buttonaddEmployee"
                                            color="inherit"
                                            variant="contained"
                                            type="button"
                                            style={{
                                                paddingRight: '10px',
                                                float: 'right',
                                                margin: '10px',
                                            }}
                                            onClick={() =>
                                                dispatch(
                                                    adminUserAction.closeUserPopup(
                                                        false
                                                    )
                                                )
                                            }
                                        >
                                            <Icon>close</Icon>
                                            <span
                                                className="ml-2 capitalize"
                                                style={{ marginLeft: '10px' }}
                                            >
                                                Cancel
                                            </span>
                                        </Button>
                                    </Box>
                                </SimpleCard>
                            </>
                        ) : null}
                    </div>

                    <div
                        class="tab-pane fade"
                        id="profile-tab-pane"
                        role="tabpanel"
                        aria-labelledby="profile-tab"
                        tabindex="0"
                    >
                        <SimpleCard>
                            <Grid
                                container
                                spacing={1}
                                style={{ marginBottom: '2rem' }}
                            >
                                <Grid item>
                                    <MultiSelect />
                                    {/* <ReportListMultiSelect /> */}
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                spacing={1}
                                style={{ marginBottom: '2rem' }}
                            >
                                <Grid item lg={7} md={7} sm={7} xs={7}>
                                    <FormControl
                                        required
                                        style={{ fontSize: '15px' }}
                                        className=""
                                        fullWidth={true}
                                    >
                                        <InputLabel id="demo-simple-select-required-label">
                                            Widget Height
                                        </InputLabel>
                                        <Select
                                            fullWidth
                                            size="small"
                                            labelId="demo-simple-select-required-label"
                                            InputLabelProps={{ shrink: true }}
                                            label="widgetHeight"
                                            name="widgetHeight"
                                            className="mb-2 w-full"
                                            inputProps={{
                                                style: {
                                                    height: '25px',
                                                },
                                            }}
                                            style={{
                                                width: '100%',
                                            }}
                                            onChange={(value) => {
                                                // setWidgetHeight(
                                                //     value.target.value
                                                // )
                                                dispatch(
                                                    adminUserAction.inputChangeAdmin(
                                                        {
                                                            props: 'widgetHeight',
                                                            value: value.target
                                                                .value,
                                                            error: 'widgetHeightError',
                                                        }
                                                    )
                                                )
                                            }}
                                            SelectProps={{ native: true }}
                                            value={
                                                adminInputChange.widgetHeight
                                            }
                                            variant="outlined"
                                        >
                                            {Array.from(
                                                { length: 13 },
                                                (_, index) => {
                                                    if (
                                                        index * 50 >=
                                                        widgetHeight1
                                                    ) {
                                                        return (
                                                            <MenuItem
                                                                value={
                                                                    index * 50
                                                                }
                                                            >
                                                                {index * 50}
                                                            </MenuItem>
                                                        )
                                                    }
                                                }
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Box
                                display="flex"
                                justifyContent="flex-end"
                                m={1}
                                p={1}
                                bgcolor="background.paper"
                                style={{ marginTop: '-1rem' }}
                            >
                                <Button
                                    className="buttonaddEmployee"
                                    color="inherit"
                                    variant="contained"
                                    type="button"
                                    style={{
                                        paddingRight: '10px',
                                        float: 'right',
                                        margin: '10px',
                                    }}
                                    onClick={() =>
                                        dispatch(
                                            adminUserAction.closeUserPopup(
                                                false
                                            )
                                        )
                                    }
                                >
                                    <Icon>close</Icon>
                                    <span
                                        className="ml-2 capitalize"
                                        style={{ marginLeft: '10px' }}
                                    >
                                        Cancel
                                    </span>
                                </Button>

                                <Button
                                    className="buttonaddEmployee"
                                    color="success"
                                    variant="contained"
                                    type="submit"
                                    disabled={hideValue}
                                    style={{
                                        paddingRight: '10px',
                                        float: 'right',
                                        margin: '10px',
                                    }}
                                    onClick={() => handleSave()}
                                >
                                    <Icon>save</Icon>
                                    <span
                                        className="ml-2 capitalize"
                                        style={{ marginLeft: '10px' }}
                                    >
                                        Save
                                    </span>
                                </Button>
                            </Box>
                        </SimpleCard>
                    </div>
                </div>
            </Container>
        </>
    )
}
