// import React from 'react';
// import { useDispatch, useSelector } from 'react-redux'

// export default function RoleList(){

//     const AdminUserReducer = useSelector((state) => state.AdminUserReducer)
//    // console.log("AdminUserReducer",AdminUserReducer)

//     return(
//         <div>
//         {/* {AdminUserReducer.roleListRowData.map((item) =>

//            <li><span style={{fontWeight:"normal"}}>{item}</span></li>
//         )} */}

//        </div>
//     )
// }

import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import ReactPaginate from 'react-paginate'
import { useSelector } from 'react-redux'
import { Container } from '@mui/system'

// Example items, to simulate fetching from another resources.
const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]

function Items({ currentItems }) {
    console.log('currentItems', currentItems)
    return (
        <>
            <Container>
                <table
                    className="table table-striped table-bordered"
                    style={{ width: 'auto', margin: '3%' }}
                >
                    <thead>
                        <tr className="tableheader">
                           

                            <th style={{ width: 300 }}>Name</th>
                            <th style={{ width: 300 }}>Generated Id</th>
                            <th style={{ width: 300 }}>Description</th>
                           
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems.allRoles &&
                            currentItems.allRoles.map((value) => {
                                return (
                                    <tr
                                        className={'BorderStyle'}
                                        //   key={value._id}
                                    >
                                        <td>
                                            <div id="Table Index Container">
                                                <a
                                                    className="text-decoration-none"
                                                    htef="#"
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                    //   onClick={() => {
                                                    //     //   popupHandle()
                                                    //       setCertain(
                                                    //           value.groupName
                                                    //       )
                                                    //   }}
                                                >
                                                    {value.name}
                                                </a>
                                            </div>
                                        </td>

                                        <>
                                            <td>{value.roleID}</td>
                                          
                                            <td>{value.description}</td>
                                           
                                        </>
                                    </tr>
                                )
                            })}
                    </tbody>
                </table>
            </Container>
            {/* {currentItems.allRoles &&
                currentItems.allRoles.map((item) => (
                    <div>
                        <h3>{item.roleID}</h3>
                        <h3>{item.name}</h3>
                        <h3>{item.description}</h3>
                    </div>
                ))} */}
        </>
    )
}

export default function RoleList({ itemsPerPage }) {
    console.log('items', itemsPerPage.allRoles && itemsPerPage.allRoles.length)
    // Here we use item offsets; we could also use page offsets

    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0)

    // Simulate fetching items from another resources.
    // (This could be items from props; or items loaded in a local state
    // from an API endpoint with useEffect and useState)
    const endOffset =
        itemOffset + itemsPerPage.allRoles && itemsPerPage.allRoles.length
    console.log(`Loading items from ${itemOffset} to ${endOffset}`)
    const currentItems = items.slice(itemOffset, endOffset)
    const pageCount = Math.ceil(
        items.length / itemsPerPage.allRoles && itemsPerPage.allRoles.length
    )

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const newOffset =
            (event.selected * itemsPerPage.allRoles &&
                itemsPerPage.allRoles.length) % items.length
        console.log(
            `User requested page number ${event.selected}, which is offset ${newOffset}`
        )
        setItemOffset(newOffset)
    }

    return (
        <>
            <Items currentItems={itemsPerPage} />
            <ReactPaginate
                breakLabel="..."
                nextLabel="next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="< previous"
                renderOnZeroPageCount={null}
            />
        </>
    )
}

// Add a <div id="container"> to your HTML to see the componend rendered.
// ReactDOM.render(
//   <RoleList itemsPerPage={4} />,
//   document.getElementById('container')
// )
