import React from 'react'
import { Container } from 'app/constant/Common'
import { Breadcrumb } from 'app/components'
import { SimpleCard } from 'matx'
import { makeStyles } from '@mui/styles'
import {
    Icon,
    Button,
    DialogContent,
    Fab,
    Box,
    List,
    Grid,
    ListItem,
    Popover,
    Typography,
} from '@mui/material'
import { OverlayTrigger } from 'react-bootstrap'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { Dialog, CircularProgress } from '@mui/material'
import { DialogTitle } from '@mui/material'
import MaterialTable from 'material-table'
import { connect, useDispatch, useSelector } from 'react-redux'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import Stack from '@mui/material/Stack'
import Table from 'react-bootstrap/Table'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { Routes } from 'react-router-dom'
import { lookupAction } from '../../redux/actions/LookupAction'
import { useEffect } from 'react'
import { useState } from 'react'
import { PopOver } from './PopOver'
import DetailMenuTabs from '../LookupDetails/Tabs'
import { positions } from '@mui/system'
import { AddBox, Api } from '@mui/icons-material'
import ExportOption from './sample'

export default function AddPrecessCriteria() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [api, getApi] = useState([])
    const [search, setSearch] = useState('')
    const [popUpOpen, setPopUpOpen] = useState(false)
    const lookupData = useSelector((state) => state.lookupReducer)
    const styles = [
        {
            fontSize: 10,
            fontWeignt: 20,
        },
    ]
    const [filteredData, setFilteredData] = useState([api])
    useEffect(() => {
        fetch('https://api.storerestapi.com/users')
            .then((res) => res.json())
            .then((result) => getApi(result.data))
            .catch((err) => console.log(err))
    }, [])
    const DetailOpen = () => {
        setPopUpOpen(!popUpOpen)
    }
    const detailClose = () => {
        setPopUpOpen(false)
    }
    const handleChange = () => {
        dispatch(lookupAction.setTrueForCard(true))
    }
    console.log('Before Filtering Data', filteredData)
    const searchHandling = (event) => {
        if (event.target.value != '') {
            setSearch(event.target.value)
            const searchresult = api.filter((data) =>
                data.name.toLowerCase().includes(search.toLowerCase())
            )
            setFilteredData(searchresult)
        } else {
            setSearch(event.target.value)
            setFilteredData(api)
        }
    }
    const handleClose = () => {
        dispatch(lookupAction.setCloseCard(false))
    }
    return (
        <>
            <React.Fragment>
                <Container>
                    <div>
                        <Breadcrumb
                            routeSegments={[
                                {
                                    name: 'process criteria',
                                },
                            ]}
                        />
                    </div>
                    {/* {list.loader && (
                <div className="loader-view  loader">
                    <CircularProgress size={54} />
                </div>
            )}
             */}
                    <Grid container spacing={2} style={{ marginTop: '20px' }}>
                        <Grid lg={3} md={3} sm={6} xs={6}>
                            <Button
                                sx={{ mx: 2, my: 2 }}
                                // sx={propStyle}
                                variant="contained"
                                color="primary"
                            >
                                Add Process criteria
                            </Button>
                        </Grid>
                        <Grid
                            sx={{ alignContent: 'flex-start' }}
                            item
                            lg={3}
                            md={3}
                            sm={6}
                            xs={6}
                        >
                            <div class="input-group">
                                <input
                                    type="search"
                                    class="form-control rounded"
                                    placeholder="Search"
                                    aria-label="Search"
                                    aria-describedby="search-addon"
                                    value={search}
                                    onChange={(event) => searchHandling(event)}
                                />
                            </div>
                        </Grid>
                        {/* <Grid
                                sx={{ alignContent: 'flex-end', marginLeft: '0px' }}
                                item
                                lg={3}
                                md={3}
                                sm={6}
                                xs={6}
                            >
                                <div>
                                    <ExportOption value={api} />
                                </div>
                            </Grid> */}
                    </Grid>
                    {/* <SimpleCard> */}
                    {/* <Grid container spacing={2} style={{ marginTop: '20px' }}>
                        <Grid lg={8} md={8} sm={12} xs={12}> */}
                    <Grid sx={{ display: 'flex' }}>
                        <Table
                            striped
                            bordered
                            hover
                            style={{ width: 'auto', alignItems: 'center' }}
                        >
                            <thead>
                                <tr>
                                    <th style={{ width: 300 }}>
                                        Process Criteria
                                    </th>
                                    {popUpOpen == false && (
                                        <>
                                            <th style={{ width: 300 }}>
                                                Process Type
                                            </th>
                                            <th style={{ width: 300 }}>
                                                Process Id
                                            </th>
                                            <th style={{ width: 300 }}>
                                                Status
                                            </th>
                                        </>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {search.length > 0
                                    ? filteredData.map((data) => {
                                          return (
                                              <tr>
                                                  <td>
                                                      <OverlayTrigger
                                                          trigger="click"
                                                          placement="right"
                                                          rootClose
                                                          overlay={PopOver}
                                                      >
                                                          <Button
                                                              onclick={
                                                                  () => DetailOpen()
                                                              }
                                                              variant="success"
                                                          >
                                                              {data.name}
                                                          </Button>
                                                      </OverlayTrigger>
                                                      {/* <a
                                                          href="#"
                                                          onClick={(event) =>
                                                              handleChange(
                                                                  event
                                                              )
                                                          }
                                                      >
                                                          {data.name}
                                                      </a> */}
                                                  </td>
                                                  {popUpOpen == false && (
                                                      <>
                                                          <td>{data.role}</td>
                                                          <td>{data.number}</td>
                                                          <td>{data.email}</td>
                                                      </>
                                                  )}
                                              </tr>
                                          )
                                      })
                                    : api.map((data) => {
                                          return (
                                              <tr key={data.id}>
                                                  <td>
                                                      <OverlayTrigger
                                                          trigger="click"
                                                          placement="right"
                                                          rootClose
                                                          overlay={PopOver}
                                                      >
                                                          <Button
                                                              onclick={
                                                                () => DetailOpen()
                                                              }
                                                              variant="success"
                                                          >
                                                              {data.name}
                                                          </Button>
                                                      </OverlayTrigger>
                                                      {/* <a
                                                          href="#"
                                                          class="text-decoration-none"
                                                          onClick={(event) =>
                                                              handleChange(
                                                                  event
                                                              )
                                                          }
                                                      >
                                                          {data.name}
                                                      </a> */}
                                                  </td>
                                                  {popUpOpen == false && (
                                                      <>
                                                          <td>{data.role}</td>
                                                          <td>{data.number}</td>
                                                          <td>{data.email}</td>
                                                      </>
                                                  )}
                                              </tr>
                                          )
                                      })}
                            </tbody>
                        </Table>
                        {/* </Grid> */}

                        <Grid
                            className="w3-border-color"
                            style={{
                                marginLeft: '-31.8rem',
                                borderRadius: '0px',
                                backgroundColor: 'smokyWhite',
                                // width: 300,
                            }}
                            lg={6}
                            md={6}
                            sm={12}
                            xs={12}
                        >
                            {lookupData.openCard == true && (
                                <Card
                                    className="w3-hover-border-color"
                                    style={{
                                        borderRadius: '0px',
                                        borderColor: 'black',
                                        backgroundColor: 'smokyWhite',
                                    }}
                                >
                                    <Icon onClick={handleClose}>close</Icon>
                                    <ul
                                        className="nav nav-tabs"
                                        id="myTab"
                                        role="tablist"
                                    >
                                        <li
                                            className="nav-item"
                                            role="presentation"
                                        >
                                            <button
                                                className="nav-link active primary"
                                                id="home-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#home-tab-pane"
                                                type="button"
                                                role="tab"
                                                aria-controls="home-tab-pane"
                                                aria-selected="true"
                                            >
                                                Informations
                                            </button>
                                        </li>
                                        <li
                                            className="nav-item"
                                            role="presentation"
                                        >
                                            <button
                                                className="nav-link"
                                                id="profile-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#profile-tab-pane"
                                                type="button"
                                                role="tab"
                                                aria-controls="profile-tab-pane"
                                                aria-selected="false"
                                            >
                                                Details
                                            </button>
                                        </li>
                                    </ul>
                                    <div class="tab-content" id="myTabContent">
                                        <div
                                            class="tab-pane fade show active"
                                            id="home-tab-pane"
                                            role="tabpanel"
                                            aria-labelledby="home-tab"
                                            tabindex="0"
                                        >
                                            <div
                                                style={{
                                                    marginTop: '50px',
                                                    paddingBottom: '20px',
                                                }}
                                            >
                                                <p>.</p>
                                                <p>.</p>
                                                <p>.</p>
                                                <p>.</p>
                                                <p>.</p>
                                                <p>.</p>
                                                <p>.</p>
                                                <p>.</p>
                                                <p>.</p>
                                            </div>
                                        </div>
                                        <div
                                            class="tab-pane fade"
                                            id="profile-tab-pane"
                                            role="tabpanel"
                                            aria-labelledby="profile-tab"
                                            tabindex="0"
                                        >
                                            Lorem Ipsum is simply dummy text of
                                            the printing and typesetting
                                            industry. Lorem Ipsum has been the
                                            industry's standard dummy text ever
                                            since the 1500s, when an unknown
                                            printer took a galley of type and
                                            scrambled it to make a type specimen
                                            book. It has survived not only five
                                            centuries, but also the leap into
                                            electronic typesetting, remaining
                                            essentially unchanged. It was
                                            popularised in the 1960s with the
                                            release of Letraset sheets
                                            containing Lorem Ipsum passages, and
                                            more recently with desktop
                                            publishing software like Aldus
                                            PageMaker including versions of
                                            Lorem Ipsum. Why do we use it? It is
                                            a long established fact that a
                                            reader will be distracted by the
                                            readable content of a page when
                                            looking at its layout. The point of
                                            using Lorem Ipsum is that it has a
                                            more-or-less normal distribution of
                                            letters, as opposed to using
                                            'Content here, content here', making
                                            it look like readable English. Many
                                            desktop publishing packages and web
                                            page editors now use Lorem Ipsum as
                                            their default model text, and a
                                            search for 'lorem ipsum' will
                                            uncover many web sites still in
                                            their infancy. Various versions have
                                            evolved over the years, sometimes by
                                            accident, sometimes on purpose
                                            (injected humour and the like).
                                            Various versions have evolved over
                                            the years, sometimes by accident,
                                            sometimes on purpose (injected
                                            humour and the like)
                                        </div>
                                    </div>
                                </Card>
                            )}
                        </Grid>
                    </Grid>
                    {/* </Grid> */}
                    {/* {popUpOpen == true && (
                        <Box
                            sx={{
                                top: 218,
                                left: 200,
                                right: 50,
                                position: 'fixed',
                            }}
                        >
                            <Card>
                                <DetailMenuTabs />
                            </Card>
                        </Box>
                    )} */}
                    {/* </Grid>  */}
                    {/* </SimpleCard> */}
                </Container>

                {/* <Popover
                    style={{ width: '80%', height: '90%', position: 'fixed' }}
                    open={popUpOpen}
                    onClose={detailClose}
                    anchorEl={anchor}
                    anchorOrigin={{vertical: "top", horizontal:"right"}}
                >
                    <Box>
                        <DetailMenuTabs />
                    </Box>
                </Popover> */}
            </React.Fragment>
        </>
    )
}
