import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'

const DragIndex = Loadable(lazy(() => import('./DragIndex')))

const DragAndDropRoutes = [
    
    {
        path:'/DragIndex',
        element: <DragIndex />
    },
]



export default DragAndDropRoutes
