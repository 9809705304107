import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'

const ReportExecution = Loadable(lazy(() => import('./ReportExecution')))
const NewExecutionReport = Loadable(lazy(() => import('./NewExecutionReport')))
const VisiblityRoutes = [
    {
        path: '/ReportExecution',
        element: <ReportExecution />,
        menuId: 1,
    },
    {
        path: '/NewExecutionReport',
        element: <NewExecutionReport />,
        menuId: 2,
    },
   
]

// const menus = localStorage.getItem("menuId");
// if (menus != null){
// var VisiblityRoutes = VisiblityRoutes1.filter(function (element) {
//   return menus.includes(element.menuId);
// });
// }else{
//   var VisiblityRoutes= [];
// }

export default VisiblityRoutes
