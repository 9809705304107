import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'

const TransactionDetails = Loadable(lazy(() => import('./TransactionDetails')))
const TransactionUpload = Loadable(lazy(() => import('./TransactionUpload')))
const TransactionRoutes = [
    {
        path: '/transDetail',
        element: <TransactionDetails />,
        menuId: 8,
    },
    {
        path: '/transUp',
        element: <TransactionUpload />,
        menuId: 9,
    },
]

// const menus = localStorage.getItem("menuId");
// if (menus != null){
// var TransactionRoutes = VisiblityRoutes1.filter(function (element) {
//   return menus.includes(element.menuId);
// });
// }else{
//   var TransactionRoutes= [];
// }

export default TransactionRoutes
