import { all, call, put, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { NotificationManager } from 'react-notifications'
import Moment from 'moment'
import { adminUserConstant } from '../../constant/ActionTypes'
import groupListView from '../../views/AdminUser/GroupListView'
import {
    headers,
    USER_ROLES,
    ADD_AUTH,
    ADD_AUTHENTICATION,
    ENABLE_USER,
    DELETE_GROUP_ROW,
    DISABLE_USER,
    ROLES,
    REGISTERATION,
    GET_SINGLE_USER_DATA,
    BULK_UPDATE,
    DELETE_USER,
    GET_GROUP_LIST,
    NEW_GROUP,
    GET_ALL_ROLE,
    SINGLE_ROLE,
    UPDATE_BASE_URL,
    IMAGE_URL,
    PROFILE_IMAGE_URL,
    GET_UNIQUE_GROUP_LIST,
    URL,
} from '../../constant/Common'
import _ from 'lodash'

function changeDateFormat(dates) {
    const newDate = new Date(dates)
    return Moment(newDate).format('YYYY-MM-DD')
}

function getJwtToken() {
    //  const sessionLoginToken = useSelector((state)=>state.login);
    var abc = localStorage.getItem('accessToken')
    //  console.log("Getting the Token",sessionLoginToken);
    //sessionStorage.getItem("tokenId")
    return abc
}
//console.log('head', headers)
//   ====================================================== Get Roles FOr the Admin User ====================================
function* getRoles(payload) {
    try {
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        }
        var res = yield axios
            .request({
                method: 'get',
                url: UPDATE_BASE_URL + ADD_AUTHENTICATION + USER_ROLES,
                headers: headers,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        if (res.status === 403) {
            NotificationManager.error('Unauthorized Access')

            yield put({
                type: adminUserConstant.ADMIN_USER_ERROR_UNAUTH,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }

        if (res.status === 200) {
            if (res.data.status == 400) {
                NotificationManager.error(res.data.message)
            } else if (res.data.status === 500) {
                NotificationManager.error(res.data.error)
            } else if (res.data != '') {
                //  console.log('ressssss',res.data)
                yield put({
                    type: adminUserConstant.DISPLAY_ADMIN_ROLES_DATA,
                    payload: res.data,
                })
                yield put({
                    type: adminUserConstant.DISPLAY_ALL_USER_DATA,
                    payload: res.data.result,
                })
            }
        } else {
            NotificationManager.error(
                'Internal application error. Please contact system administrator.'
            )
        }
    } catch (error) {
        console.log(error)

        yield put({
            type: adminUserConstant.CANCEL_LOADING_ADMIN,
            payload: {},
        })
        return
    }
}

function* getRolesList() {
    yield takeEvery(adminUserConstant.GET_ALL_USER_ROLES_LIST, getRoles)
}

//   ====================================================== Enable User  ====================================
function* enableUserList(payload) {
    var props = payload.payload
    try {
        var req = JSON.stringify({
            userName: props.userName.userName,
            lastModifiedBy: props.modifyBy.name,
        })
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
        }
        var res = yield axios
            .request({
                method: 'put',
                url: UPDATE_BASE_URL + ENABLE_USER,
                headers: headers,
                data: req,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: adminUserConstant.ADMIN_USER_ERROR_UNAUTH,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status === 200) {
            if (res.data.code == 0) {
                NotificationManager.success(res.data.message)
                yield call(getRoles)
                yield put({
                    type: adminUserConstant.DISPLAY_ENABLE_USER,
                    payload: props.userName,
                })
            } else if (res.data.status === 500) {
                NotificationManager.error(res.data.error)
            } else if (res.data.code == -1) {
                NotificationManager.error(res.data.details)
            }
        } else {
            NotificationManager.error(
                'Internal application error. Please contact system administrator.'
            )
        }
    } catch (error) {
        console.log(error)

        yield put({
            type: adminUserConstant.CANCEL_LOADING_ADMIN,
            payload: {},
        })
        return
    }
}

function* getEnableUser() {
    yield takeEvery(adminUserConstant.GET_ENABLE_USER_LIST, enableUserList)
}

//   ====================================================== Disable User  ====================================
function* disableUser(payload) {
    var props = payload.payload
    try {
        var req = JSON.stringify({
            userName: props.userName.userName,
            lastModifiedBy: props.modifyBy.name,
        })
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
        }
        var res = yield axios
            .request({
                method: 'put',
                url: UPDATE_BASE_URL + DISABLE_USER,
                headers: headers,
                data: req,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: adminUserConstant.ADMIN_USER_ERROR_UNAUTH,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status === 200) {
            if (res.data.code == 0) {
                NotificationManager.success(res.data.message)
                yield call(getRoles)
                yield put({
                    type: adminUserConstant.DISPLAY_DISABLE_USER,
                    payload: props.userName,
                })
            } else if (res.data.status === 500) {
                NotificationManager.error(res.data.error)
            } else if (res.data.code == -1) {
                NotificationManager.error(res.data.details)
            }
        } else {
            NotificationManager.error(
                'Internal application error. Please contact system administrator.'
            )
        }
    } catch (error) {
        console.log(error)

        yield put({
            type: adminUserConstant.CANCEL_LOADING_ADMIN,
            payload: {},
        })
        return
    }
}

function* getDisableUser() {
    yield takeEvery(adminUserConstant.MAKE_DISABLE_USER, disableUser)
}

//   ======================================================GET Roles Allocation====================================
function* getRolesAllocation(payload) {
    try {
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        }
        var res = yield axios
            .request({
                method: 'get',
                url: UPDATE_BASE_URL + ROLES,
                headers: headers,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: adminUserConstant.ADMIN_USER_ERROR_UNAUTH,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }

        if (res.status === 200) {
            console.log('roles', res)
            if (res.data != '') {
                yield put({
                    type: adminUserConstant.DISPLAY_ROLES_ALLOCATION,
                    payload: res.data,
                })
            } else if (res.data.status === 500) {
                NotificationManager.error(res.data.error)
            }
        } else if (res.data.status === 500) {
            NotificationManager.error(res.data.error)
        } else {
            NotificationManager.error(
                'Internal application error. Please contact system administrator.'
            )
        }
    } catch (error) {
        console.log(error)

        yield put({
            type: adminUserConstant.CANCEL_LOADING_ADMIN,
            payload: {},
        })
        return
    }
}

function* rolesUserAllocation() {
    yield takeEvery(adminUserConstant.GET_ROLES, getRolesAllocation)
}

//====================================================== Registrations ====================================
function* saveUserRegistration(payload) {
    var props = payload.payload
    var adminSetting = props.roleSelection == '1' ? true : false
    var roleSetting = props.roleSelection != '1' ? props.saveRole : []
    //console.log('prwegvs', props)
    let formData = new FormData()
    formData.append('firstName', props.userFirstName)
    formData.append('lastName', props.userLastName)
    formData.append('userName', props.userName)
    formData.append('password', props.password)
    formData.append('groupName', props.groupNameSelection)
    formData.append('email', props.userEmailId)
    formData.append('address', props.address)
    formData.append('gender', props.gender)
    formData.append('phone', props.phoneNumber)
    formData.append('userStatus', 'CONFIRMED')
    formData.append('lastModifiedBy', props.userId.name)
    formData.append('lastModifiedDate', null)
    formData.append('profilePic', props.profileApi)
    formData.append('report', JSON.stringify(props.reportMasterAllData))
    formData.append('widgetHeight',  props.widgetHeight)
    try {
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        }
        var res = yield axios({
            method: 'POST',
            url: UPDATE_BASE_URL + ADD_AUTHENTICATION + REGISTERATION,
            //url: 'http://Fillitto-1526566366.ap-south-1.elb.amazonaws.com:9093/fillitto/api/v1/user/details',
            headers: headers,
            data: formData,
        })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: adminUserConstant.ADMIN_USER_ERROR_UNAUTH,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status === 200) {
            if (res.data.status === 400) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: adminUserConstant.CANCEL_LOADING_ADMIN,
                    payload: {},
                })
            } else if (res.data.status === 500) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: adminUserConstant.CANCEL_LOADING_ADMIN,
                    payload: {},
                })
            } else if (res.data.code === 200) {
                NotificationManager.success(res.data.message)
                yield call(getRoles)
                yield put({
                    type: adminUserConstant.DISPLAY_ADDED_USER_SUCCESS,
                    payload: {},
                })
            }
        } else {
            NotificationManager.error(
                'Internal application error. Please contact system administrator.'
            )
        }
    } catch (error) {
        console.log(error)

        yield put({
            type: adminUserConstant.CANCEL_LOADING_ADMIN,
            payload: {},
        })
        return
    }
}

function* saveUserRegister() {
    yield takeEvery(adminUserConstant.SAVE_REGISTER_USERS, saveUserRegistration)
}

//====================================================== Get Single User ====================================
function* getUserEdit(payload) {
    var props = payload.payload
    console.log('Payload Propsuser edit', props)
    try {
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        }
        var res = yield axios
            .request({
                method: 'get',
                url: UPDATE_BASE_URL + GET_SINGLE_USER_DATA + props.generatedID,
                headers: headers,
                // data:req
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: adminUserConstant.ADMIN_USER_ERROR_UNAUTH,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.data.status === 400) {
            NotificationManager.error(res.data.error)
        } else if (res.data.status === 500) {
            NotificationManager.error(res.data.error)
        } else if (res.data.code === 200) {
            // NotificationManager.success(res.data.message)
            yield put({
                type: adminUserConstant.DISPLAY_SINGLE_USER,
                payload: {
                    data: res.data.result,
                    imageurl:
                        URL + IMAGE_URL + props.generatedID + '/profilepic',
                },
            })
            yield put({
                type: adminUserConstant.DISPLAY_OLD_USERDETAILS,
                payload: {
                    data: res.data.result,
                    imageurl:
                        URL + IMAGE_URL + props.generatedID + '/profilepic',
                },
            })
            // yield put({
            //     type: adminUserConstant.GET_PROFILE_PICTURE,
            //     payload: props,
            // })
        } else if (res.data.status === 404) {
            NotificationManager.error(res.data.error)
        } else {
            NotificationManager.error(
                'Internal application error. Please contact system administrator.'
            )
        }
    } catch (error) {
        console.log(error)

        yield put({
            type: adminUserConstant.CANCEL_LOADING_ADMIN,
            payload: {},
        })
        return
    }
}
function* getEditSingleUser() {
    yield takeEvery(adminUserConstant.GET_EDIT_USER_LIST, getUserEdit)
}
// ======================================================= profile Picture ======================================
// function* profileImage(payload) {
//     var props = payload.payload;
//     console.log('Payload ProfilePic', props)
//     try {
//         let headers = {
//             'Content-Type': 'image/jpeg;charset=UTF-8',
//             Accept: 'image/jpeg',
//             Authorization: `Bearer ${getJwtToken()}`,
//         }
//         var res = yield axios
//             .request({
//                 method: 'get',
//                 url:
//                     UPDATE_BASE_URL +
//                     IMAGE_URL +
//                     props.generatedID +
//                     '/profilepic',
//                 headers: headers,
//                 // data:req
//             })
//             .then((response) => {
//                 return response
//             })
//             .catch((err) => {
//                 return err.response
//             })
//         if (res.status === 401) {
//             NotificationManager.error('Unauthorized Access')
//             yield put({
//                 type: adminUserConstant.ADMIN_USER_ERROR_UNAUTH,
//                 payload: { error: 'Internal application error. Please contact system administrator.' },
//             })
//             return
//         }
//         if(res.status === 200){
//             yield put({
//                 type: adminUserConstant.DISPLAY_PROFILE_IMAGE,
//                 payload: UPDATE_BASE_URL +
//                 IMAGE_URL +
//                 props.generatedID +
//                 '/profilepic',
//             })
//         }
//         if (res.data.status === 400) {
//             NotificationManager.error(res.data.error)
//         } else if (res.data.status === 200) {
//             console.log("res", res)
//             yield put({
//                 type: adminUserConstant.DISPLAY_PROFILE_IMAGE,
//                 payload: res.data,
//             })
//         } else {
//             // NotificationManager.error('Internal application error. Please contact system administrator.')
//         }
//     } catch (error) {
//         console.log(error)
//     }
// }

// function* getProfilePicture() {
//     yield takeEvery(adminUserConstant.GET_PROFILE_PICTURE, profileImage)
// }
//====================================================== Update Bulk User ====================================
function* editedSaveUserSubmit(payload) {
    var props = payload.payload
    console.log('Payload Props', props)
    let formData = new FormData()
    formData.append('firstName', props.userFirstName)
    formData.append('lastName', props.userLastName)
    formData.append('groupName', props.groupNameSelection)
    formData.append('userName', props.userName)
    formData.append('email', props.userEmailId)
    formData.append('address', props.address)
    formData.append('gender', props.gender)
    formData.append('phone', props.phoneNumber)
    formData.append('userStatus', 'CONFIRMED')
    formData.append('lastModifiedBy', props.userId.name)
    formData.append('lastModifiedDate', null)
    formData.append('report', JSON.stringify(props.reportMasterAllData))
    formData.append('widgetHeight', props.widgetHeight)
    // formData.append(
    //     'profileFile',
    //     props.profileApi.name ? props.profileApi : props.profileFileUsers
    // )
    formData.append(
        'profilePic',
        props.profileApi.name ? props.profileApi : props.profileFileUsers
    )
    //formData.append('profileFile', props.profileApi)

    try {
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        }
        var res = yield axios({
            method: 'PUT',
            url: UPDATE_BASE_URL + GET_SINGLE_USER_DATA + props.generatedID,
            // UPDATE_BASE_URL + GET_SINGLE_USER_DATA + props.generatedID,
            headers: headers,
            data: formData,
        })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: adminUserConstant.ADMIN_USER_ERROR_UNAUTH,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        console.log('userLoading', res)
        if (res.status === 200) {
            if (res.data.code == 200) {
                NotificationManager.success(res.data.message)
                yield call(getRoles)
                yield put({
                    type: adminUserConstant.EDIT_SAVE_SUCCESS,
                    payload: {},
                })

                //props.navigate('/GroupListView')
            } else if (res.data.status === 500) {
                NotificationManager.error(res.data.error)  
                yield put({
                    type: adminUserConstant.CANCEL_LOADING_ADMIN,
                    payload: {},
                })
            } else if (res.data.status === 404) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: adminUserConstant.CANCEL_LOADING_ADMIN,
                    payload: {},
                })
            } else if (res.data.status == 400) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: adminUserConstant.CANCEL_LOADING_ADMIN,
                    payload: {},
                })
            }
        } else {
            NotificationManager.error(
                'Internal application error. Please contact system administrator.'
            )
        }
    } catch (error) {
        console.log(error)

        yield put({
            type: adminUserConstant.CANCEL_LOADING_ADMIN,
            payload: {},
        })
        return
    }
}

function* editSaveUser() {
    yield takeEvery(
        adminUserConstant.EDITED_SAVE_USER_SUBMIT,
        editedSaveUserSubmit
    )
}

//======================================================create new group list  ====================================

function* saveCreateGroup(payload) {
    var props = payload.payload
    //console.log('prwegvs', props)
    try {
        var req = JSON.stringify({
            groupName: props.groupUserFirstName,
            description: props.groupDescription,
            roleName: props.newRoleName,
            // roleIds: props.saveRole.map((item) => item.roleID),
        })
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        }
        var res = yield axios
            .request({
                method: 'post',
                url: NEW_GROUP,
                headers: headers,
                data: req,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: adminUserConstant.ADMIN_USER_ERROR_UNAUTH,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status === 200) {
            //console.log('ressss', res)
            if (res.data.status === 400) {
                NotificationManager.error(res.data.error)
            } else if (res.data.status === 500) {
                NotificationManager.error(res.data.error)
            } else if (res.data.status === 404) {
                NotificationManager.error(res.data.error)
            } else if (res.data.code === 200) {
                NotificationManager.success(res.data.message)
                yield call(getAllGroupList)
                yield put({
                    type: adminUserConstant.DISPLAY_CREATED_NEW_GROUP,
                    payload: {},
                })
                // } else if (res.data.code == -1) {
                //     NotificationManager.error(res.data.message)
            } else {
                NotificationManager.error(res.data.error)
            }
        } else {
            NotificationManager.error(
                'Internal application error. Please contact system administrator.'
            )
        }
    } catch (error) {
        console.log(error)

        yield put({
            type: adminUserConstant.CANCEL_LOADING_ADMIN,
            payload: {},
        })
        return
    }
}

function* saveNewGroup() {
    yield takeEvery(adminUserConstant.CREATE_GROUP_SAVE_SUBMIT, saveCreateGroup)
}

//======================================================Delete the User ====================================
function* deleteUserRequest(payload) {
    var props = payload.payload
    //console.log('Payload Props', props)
    try {
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        }
        var res = yield axios
            .request({
                method: 'delete',
                url: UPDATE_BASE_URL + DELETE_USER + props.generatedID,
                headers: headers,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: adminUserConstant.ADMIN_USER_ERROR_UNAUTH,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        //console.log('fvvdfvfdvd', res)
        if (res.status === 200) {
            if (res.data.status === 404) {
                NotificationManager.error(res.data.error)
            } else if (res.data.status === 500) {
                NotificationManager.error(res.data.error)
            } else if (res.data.code == 200) {
                NotificationManager.success(res.data.message)
                yield call(getRoles)
                yield put({
                    type: adminUserConstant.DELETE_USER_SUCCESS,
                    payload: {},
                })
            } else if (res.data.code >= 500) {
                NotificationManager.error(res.data.error)
            }
        } else {
            NotificationManager.error(
                'Internal application error. Please contact system administrator.'
            )
        }
    } catch (error) {
        console.log(error)

        yield put({
            type: adminUserConstant.CANCEL_LOADING_ADMIN,
            payload: {},
        })
        return
    }
}

function* deleteUser() {
    yield takeEvery(
        adminUserConstant.DELETE_THE_USER_REQUEST,
        deleteUserRequest
    )
}
// =================================================== Get all group List table  ====================================

function* getAllGroupList(payload) {
    //console.log('head', headers)
    try {
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${getJwtToken()}`,
            Accept: 'application/json',
        }
        var res = yield axios
            .request({
                method: 'get',
                url: GET_GROUP_LIST,
                headers: headers,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        if (res.status === 401) {
            //console.log('401')
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: adminUserConstant.ADMIN_USER_ERROR_UNAUTH,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }

        if (res.status === 200) {
            console.log('responseblak', res)
            if (res.data.status === 404) {
                NotificationManager.error(res.data.error)
            } else if (res.data.status === 500) {
                NotificationManager.error(res.data.error)
            } else if (res.data.status === 404) {
                NotificationManager.error(res.data.error)
            } else if (res.data != '' || res.data.code === 200) {
                yield put({
                    type: adminUserConstant.DISPLAY_ALL_GROUP_LIST,
                    payload: res.data,
                })
                yield put({
                    type: adminUserConstant.DISPLAY_GRP_DATA,
                    payload: res.data,
                })
            }
        } else {
            NotificationManager.error(
                'Internal application error. Please contact system administrator.'
            )
        }
    } catch (error) {
        console.log(error)

        yield put({
            type: adminUserConstant.CANCEL_LOADING_ADMIN,
            payload: {},
        })
        return
    }
}

function* groupList() {
    yield takeEvery(adminUserConstant.GET_ALL_GROUP_TABLE, getAllGroupList)
}

//====================================================== save group edited data====================================
function* saveGroupEditUser(payload) {
    var props = payload.payload
    console.log('propsGrp', props)
    try {
        var req = JSON.stringify({
            groupName: props.groupUserFirstName,
            description: props.groupDescription,
            //roleIds: props.saveEditRole,
            roleName: props.newRoleName,
        })
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        }
        var res = yield axios
            .request({
                method: 'put',
                url: DELETE_GROUP_ROW + props.generatedID,
                headers: headers,
                data: req,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: adminUserConstant.ADMIN_USER_ERROR_UNAUTH,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }

        if (res.status === 200) {
            //console.log('res', res)
            if (res.data.code === 200) {
                NotificationManager.success(res.data.message)
                yield put({
                    type: adminUserConstant.GET_ALL_GROUP_TABLE,
                    payload: {},
                })
                yield put({
                    type: adminUserConstant.DISPLAY_GROUP_EDIT_LIST,
                    payload: res.data,
                })
            } else if (res.data.status === 404) {
                NotificationManager.error(res.data.error)
            } else if (res.data.status === 500) {
                NotificationManager.error(res.data.error)
            } else if (res.data.status === 400) {
                NotificationManager.error(res.data.error)
            }
            //  props.navigate('/AddGroupName')
        } else {
            NotificationManager.success('Successfully Updated')
        }
    } catch (error) {
        console.log(error)

        yield put({
            type: adminUserConstant.CANCEL_LOADING_ADMIN,
            payload: {},
        })
        return
    }
}

function* saveEditGrpUser() {
    yield takeEvery(adminUserConstant.SAVE_EDIT_USER_GROUP, saveGroupEditUser)
}

//===============================================DELETE the grp row data================================================
function* deleteGrpRowData(payload) {
    var props = payload.payload
    //console.log('Payload Props', props.deleteGrpName)
    try {
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${getJwtToken()}`,
            Accept: 'application/json',
        }
        var res = yield axios
            .request({
                method: 'delete',
                url: DELETE_GROUP_ROW + props,
                headers: headers,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: adminUserConstant.ADMIN_USER_ERROR_UNAUTH,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        //console.log('fvvdfvfdvd', res)
        if (res.status === 200) {
            //console.log('fvvdfvfdvd', res)
            if (res.data.code == 200) {
                NotificationManager.success(res.data.message)
                yield call(getAllGroupList)
                yield put({
                    type: adminUserConstant.DELETE_GRP_SUCCESS,
                    payload: {},
                })
            } else if (res.data.status === 500) {
                NotificationManager.error(res.data.error)
            } else if (res.data.status == 400) {
                NotificationManager.error(res.data.error)
            } else if (res.data.status === 404) {
                NotificationManager.error(res.data.error)
            }
        } else {
            NotificationManager.error(
                'Internal application error. Please contact system administrator.'
            )
        }
    } catch (error) {
        console.log(error)
    }
}

function* deleteGrpRow() {
    yield takeEvery(
        adminUserConstant.DELETE_GROUP_ROW_REQUEST,
        deleteGrpRowData
    )
}
function* getAllRoles(payload) {
    //  console.log('head', headers)
    try {
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${getJwtToken()}`,
            Accept: 'application/json',
        }
        var res = yield axios
            .request({
                method: 'get',
                url: UPDATE_BASE_URL + GET_ALL_ROLE,
                headers: headers,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        if (res.status === 401) {
            //console.log('401')
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: adminUserConstant.ADMIN_USER_ERROR_UNAUTH,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }

        if (res.status === 200) {
            console.log('response roles', res)
            if (res.data.status === 404) {
                NotificationManager.error(res.data.error)
            } else if (res.data.status === 500) {
                NotificationManager.error(res.data.error)
            } else if (res.data.status === 404) {
                NotificationManager.error(res.data.error)
            } else if (res.data != '' || res.data.code === 200) {
                yield put({
                    type: adminUserConstant.DISPLAY_ALL_ROLES,
                    payload: res.data,
                })
                yield put({
                    type: adminUserConstant.DISPLAY_ROLE_INITIAL_PAGE,
                    payload: res.data.result,
                })
            }
        } else {
            NotificationManager.error(
                'Internal application error. Please contact system administrator.'
            )
        }
    } catch (error) {
        console.log(error)

        yield put({
            type: adminUserConstant.CANCEL_LOADING_ADMIN,
            payload: {},
        })
        return
    }
}

function* roleList() {
    yield takeEvery(adminUserConstant.GET_ALL_ROLES, getAllRoles)
}

//====================================================== Get Single User ====================================
function* getGrpEdit(payload) {
    var props = payload.payload
    console.log('Payload Propsuser edit', props)
    try {
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        }
        var res = yield axios
            .request({
                method: 'get',
                url: DELETE_GROUP_ROW + props,
                headers: headers,
                // data:req
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: adminUserConstant.ADMIN_USER_ERROR_UNAUTH,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.data.status === 400) {
            NotificationManager.error(res.data.error)
        } else if (res.data.status === 500) {
            NotificationManager.error(res.data.error)
        } else if (res.data.status === 404) {
            NotificationManager.error(res.data.error)
        } else if (res.data.code === 200) {
            // NotificationManager.success(res.data.message)
            yield put({
                type: adminUserConstant.DISPLAY_GROUP_EDIT,
                payload: res.data.result,
            })
            //props.navigate('/')
        } else {
            NotificationManager.error(
                'Internal application error. Please contact system administrator.'
            )
        }
    } catch (error) {
        console.log(error)
    }
}

function* getEditSingleGrpList() {
    yield takeEvery(adminUserConstant.GET_EDIT_GRP_DATA, getGrpEdit)
}

//============================================Create new roles and save ============================================

function* saveCreateRoles(payload) {
    var props = payload.payload
    // console.log('save roles data', props);
    try {
        var req = JSON.stringify({
            name: props.newRoleName,
            description: props.newDescription,
        })
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
        }
        var res = yield axios
            .request({
                method: 'post',
                url: UPDATE_BASE_URL + GET_ALL_ROLE,
                headers: headers,
                data: req,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: adminUserConstant.ADMIN_USER_ERROR_UNAUTH,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status === 403) {
            NotificationManager.error('Unauthorized Access')

            yield put({
                type: adminUserConstant.ADMIN_USER_ERROR_UNAUTH,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status === 200) {
            //  console.log('ressss', res)
            if (res.data.status === 400) {
                NotificationManager.error(res.data.error)
            } else if (res.data.status === 404) {
                NotificationManager.error(res.data.error)
            } else if (res.data.status === 500) {
                NotificationManager.error(res.data.error)
            } else if (res.data.code === 200) {
                NotificationManager.success(res.data.message)
                // yield call(getAllRoles)
                yield put({
                    type: adminUserConstant.GET_ALL_ROLES,
                    payload: {},
                })
                yield put({
                    type: adminUserConstant.DISPLAY_SAVED_ROLES,
                    payload: {},
                })
                // } else if (res.data.code == -1) {
                //     NotificationManager.error(res.data.message)
            } else {
                NotificationManager.error(res.data.error)
            }
        } else {
            NotificationManager.error(
                'Internal application error. Please contact system administrator.'
            )
        }
    } catch (error) {
        console.log(error)

        yield put({
            type: adminUserConstant.CANCEL_LOADING_ADMIN,
            payload: {},
        })
        return
    }
}

function* saveNewCreateRole() {
    yield takeEvery(adminUserConstant.SAVE_NEW_ROLE_REQUEST, saveCreateRoles)
}

function* getRoleEdit(payload) {
    var props = payload.payload
    console.log('Payload Propsuser edit', props.roleID)
    try {
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        }
        var res = yield axios
            .request({
                method: 'get',
                url: SINGLE_ROLE + props,
                headers: headers,
                // data:req
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: adminUserConstant.ADMIN_USER_ERROR_UNAUTH,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.data.status === 400) {
            NotificationManager.error(res.data.error)
        } else if (res.data.code === 200) {
            // NotificationManager.success(res.data.message)
            yield put({
                type: adminUserConstant.DISPLAY_EDIT_ROLES,
                payload: res.data.result,
            })
            //props.navigate('/')
        } else if (res.data.status === 500) {
            NotificationManager.error(res.data.error)
        } else if (res.data.status === 404) {
            NotificationManager.error(res.data.error)
        } else {
            NotificationManager.error(
                'Internal application error. Please contact system administrator.'
            )
        }
    } catch (error) {
        console.log(error)

        yield put({
            type: adminUserConstant.CANCEL_LOADING_ADMIN,
            payload: {},
        })
        return
    }
}

function* getEditRoleData() {
    yield takeEvery(adminUserConstant.GET_EDIT_ROLES, getRoleEdit)
}

//===============================edit roles data save=============================================
function* editSaveRolesData(payload) {
    var props = payload.payload
    console.log('Payloadsaga edit req', props)
    try {
        let req = JSON.stringify({
            name: props.newRoleName,
            description: props.newDescription,
        })
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        }
        var res = yield axios
            .request({
                method: 'put',
                url: SINGLE_ROLE + props.newRoleId,
                headers: headers,
                data: req,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: adminUserConstant.ADMIN_USER_ERROR_UNAUTH,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status === 403) {
            NotificationManager.error('Unauthorized Access')

            yield put({
                type: adminUserConstant.ADMIN_USER_ERROR_UNAUTH,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        //console.log('fvvdfvfdvd', res)
        if (res.status === 200) {
            console.log('response edit save', res)
            if (res.data.code == 200) {
                NotificationManager.success(res.data.message)
                yield call(getAllRoles)
                yield put({
                    type: adminUserConstant.EDIT_SAVE_ROLE_SUCCESS,
                    payload: {},
                })
                //props.navigate('/GroupListView')
            } else if (res.data.status === 500) {
                NotificationManager.error(res.data.error)
            } else if (res.data.status == 400) {
                NotificationManager.error(res.data.error)
            }
        } else {
            NotificationManager.error(
                'Internal application error. Please contact system administrator.'
            )
        }
    } catch (error) {
        console.log(error)

        yield put({
            type: adminUserConstant.CANCEL_LOADING_ADMIN,
            payload: {},
        })
        return
    }
}

function* editSaveRoles() {
    yield takeEvery(adminUserConstant.EDIT_SAVE_ROLES_REQ, editSaveRolesData)
}

//===============================================DELETE ROLE ROW DATA================================================
function* deleteRowRole(payload) {
    var props = payload.payload
    console.log('delete role', props)
    try {
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${getJwtToken()}`,
            Accept: 'application/json',
        }
        var res = yield axios
            .request({
                method: 'delete',
                url: SINGLE_ROLE + props.newRoleId,
                headers: headers,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: adminUserConstant.ADMIN_USER_ERROR_UNAUTH,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status === 403) {
            NotificationManager.error('Unauthorized Access')

            yield put({
                type: adminUserConstant.ADMIN_USER_ERROR_UNAUTH,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        //console.log('fvvdfvfdvd', res)
        if (res.status === 200) {
            console.log('roles delete', res)
            if (res.data.code == 200) {
                NotificationManager.success(res.data.message)
                yield call(getAllRoles)
                yield put({
                    type: adminUserConstant.DELETE_ROLE_SUCCESS,
                    payload: {},
                })
            } else if (res.data.status === 500) {
                NotificationManager.error(res.data.error)
            } else if (res.data.code == 400) {
                NotificationManager.error(res.data.error)
            }
        } else {
            NotificationManager.error(
                'Internal application error. Please contact system administrator.'
            )
        }
    } catch (error) {
        console.log(error)

        yield put({
            type: adminUserConstant.CANCEL_LOADING_ADMIN,
            payload: {},
        })
        return
    }
}

function* deleteRoleRowData() {
    yield takeEvery(adminUserConstant.DELETE_ROLE_REQ, deleteRowRole)
}
//====================================================== Registrations ====================================
function* saveLoginUser(payload) {
    var props = payload.payload
    console.log('propsuserProfile', props)
    let formData = new FormData()
    formData.append('firstName', props.firstName)
    formData.append('lastName', props.lastName)
    formData.append('groupName', props.groupName)
    formData.append('userName', props.userName)
    formData.append('email', props.emailId)
    formData.append('address', props.address)
    formData.append('gender', props.userGender)
    formData.append('phone', props.phoneNumber)
    formData.append(
        'profilePic',
        props.profileToApi.name ? props.profileToApi : props.profileFile
    )
    formData.append('report', JSON.stringify(props.reportMasterAllData))

    try {
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${getJwtToken()}`,
            Accept: 'application/json',
        }
        var res = yield axios({
            method: 'PUT',
            url:
                UPDATE_BASE_URL + GET_SINGLE_USER_DATA + props.loginGeneratedID,
            headers: headers,
            data: formData,
        })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: adminUserConstant.ADMIN_USER_ERROR_UNAUTH,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status === 200) {
            if (res.data.status === 400) {
                NotificationManager.error(res.data.error)
            } else if (res.data.status === 500) {
                NotificationManager.error(res.data.error)
            } else if (res.data.code === 200) {
                NotificationManager.success(res.data.message)
                yield call(getRoles)
                yield put({
                    type: adminUserConstant.RESPONSE_SAVE_LOGIN_PROFILE,
                    payload: {},
                })
            }
        } else {
            NotificationManager.error(
                'Internal application error. Please contact system administrator.'
            )
        }
    } catch (error) {
        console.log(error)

        yield put({
            type: adminUserConstant.CANCEL_LOADING_ADMIN,
            payload: {},
        })
        return
    }
}

function* saveLoginUserProfile() {
    yield takeEvery(adminUserConstant.REQUEST_SAVE_LOGIN_PROFILE, saveLoginUser)
}
function* getUniqueGroupList() {
    //console.log('head', headers)
    try {
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: `Bearer ${getJwtToken()}`,
            Accept: 'application/json',
        }
        var res = yield axios
            .request({
                method: 'get',
                url: GET_UNIQUE_GROUP_LIST,
                headers: headers,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        if (res.status === 401) {
            //console.log('401')
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: adminUserConstant.ADMIN_USER_ERROR_UNAUTH,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }

        if (res.status === 200) {
            console.log('responseblak', res)
            if (res.data.status === 404) {
                NotificationManager.error(res.data.error)
            } else if (res.data.status === 404) {
                NotificationManager.error(res.data.error)
            } else if (res.data.status === 500) {
                NotificationManager.error(res.data.error)
            } else if (res.data != '' || res.data.code === 200) {
                yield put({
                    type: adminUserConstant.GET_UNIQUE_GROUP_TABLE_SUCCESS,
                    payload: res.data.result,
                })
            }
        } else {
            NotificationManager.error(
                'Internal application error. Please contact system administrator.'
            )
        }
    } catch (error) {
        console.log(error)

        yield put({
            type: adminUserConstant.CANCEL_LOADING_ADMIN,
            payload: {},
        })
        return
    }
}

function* getUniqueGroup() {
    yield takeEvery(
        adminUserConstant.GET_UNIQUE_GROUP_TABLE_REQUEST,
        getUniqueGroupList
    )
}
export default function* rootSaga() {
    yield all([
        getRolesList(),
        getEnableUser(),
        getDisableUser(),
        rolesUserAllocation(),
        saveUserRegister(),
        getEditSingleUser(),
        editSaveUser(),
        deleteUser(),
        groupList(),
        saveNewGroup(),
        saveEditGrpUser(),
        deleteGrpRow(),
        roleList(),
        getEditSingleGrpList(),
        saveNewCreateRole(),
        getEditRoleData(),
        editSaveRoles(),
        deleteRoleRowData(),
        saveLoginUserProfile(),
        getUniqueGroup(),
        // getProfilePicture(),
    ])
}
