//import { reactLocalStorage } from 'reactjs-localstorage'
import { styled } from '@mui/system'
import { useNavigate } from 'react-router-dom'
import { FmdBadTwoTone } from '@mui/icons-material'
import Moment from 'moment'
import { useSelector } from 'react-redux'

const baseURL = 'https://xiywdhek1i.execute-api.ap-south-1.amazonaws.com/TST/'
//export var LOGIN_API_NEW1 = 'fillitto/authentication/user/login'
//export var LOGIN_API_NEW = 'fillitto/api/v1/user/details/login'
//****Admin Auth Base URL */
export var URL = baseURL
export var UPDATE_BASE_URL = baseURL
export var ADD_CORS = '' //'https://cors-anywhere.herokuapp.com/'
export var PROFILE_IMAGE_URL = baseURL
let api = baseURL // + 'api/v1/visibility/'
export var LOGIN_NEW = baseURL + '/fillitto/authentication/user/login'
export var FILE_UPLOAD = 'fillitto/dashboard/file'
export var IMAGE_TYPE = 'image/jpeg, image/png'
export var IMAGE_URL = 'fillitto/authentication/user/'
export var EMPLOYEE_IMAGE_SIZE = 300
export var API_URL = { api: api }
export var LOGIN_AUTH = 'auth/signin'
export var GET_PROCESS_API = 'fillitto/process/criteria'
export var SAVE_NEW_PROCESS = 'fillitto/process/criteria'
export var SAVE_EDIT_PROCESS = 'fillitto/process/criteria/'
export var GET_ID = '/id/'
export var DETAILS_PROCESS = '/details/'
export var ADD_RULES = '/rules/'
export var JSON_FILE_UPLOAD = 'fillitto/dashboard/filedata'
export var JSON_UPDATE_UPLOAD = 'fillitto/dashboard/filedata/'
export var JSON_GET_UPLOAD = 'fillitto/dashboard/filedata/userid/'
export var JSON_DATA = 'fillitto/reporting/display/userid/'

// export var LOGIN_API_NEW = 'fillitto/auth/user/login'
export var EDIT_SAVE_TRANSACTION_RULE = '/fillitto/process/rules'
export var PROCESS_DETAIL_ROLL = '/fillitto/process/rules'
export var LOGIN_AUTH_SIGNUP = 'auth/signup'
export var LOOKUP_GET = 'master/lookup/header'
export var LOOKUP_SAVE = 'master/lookup/header'
export var LOOKUP_HEADER_EDIT1 = 'master/lookup/header/id?'
export var LOOKUP_HEADER_EDIT = 'master/lookup/header/group/'
export var VISIBILITY_HEADER_API = ''
export var HEADER_ALL = 'master/header'
export var ADD_HEADER_DATA = 'master/header'
export var GET_HEADER_DATA_BY_ID = 'master/header/'
export var DELETE_HEADER_DATA = 'master/header/'
export var UPDATE_HEADER_DATA = 'master/header/'
export var DETAIL_SEARCH_BY_NAME =
    'fillitto/process/criteria/details/extended/name'
export var DETAIL_SEARCH_PROCESS = 'fillitto/process/criteria/'
export var ADD_DETAILS = '/details'
export var ADD_DETAIL = '/details/'
export var ADD_AUTH = 'fillitto/auth/'
export var UPDATE_DETAIL_RULE_BY_ID =
    'fillitto/process/criteria/details/extended'
export var UPDATE_DETAILS_AND_RULE = 'fillitto/process/criteria/details/'
export var ADD_AUTHENTICATION = 'fillitto/authentication/'
export var DETAILS_ALL = 'master/details'
export var GET_EDIT_VISIBILITY_DETAILS = 'master/details/id?'
export var ADD_VISIBILITY_DETAILS = 'master/details'
export var DELETE_DETAILS_DATA = 'master/details/id?'
export var UPDATE_DETAILS_DATA = 'master/details/id?'
export var DASHBOARD_REPORT = 'fillitto/reporting/display/userid/'
export var DISPLAY_MODE = '/displaymode/dashboard'

export var LOOKUP_DETAILS_LIST = 'master/lookup/details'
export var LOOKUP_DETAIL_BY_ID = 'master/lookup/details/'
export var ADD_LOOKUP_DETAILS_DATA = 'master/lookup/details'
export var DELETE_LOOKUP_DETAILS = 'master/lookup/details/'
export var EDIT_SAVE_LOOKUP_DETAILS = 'master/lookup/details/'
//**  visibility  */
export var VISI_HEADER_TABLE = 'fillitto/visibility/header'
export var SAVE_NEW_HEADER = 'fillitto/visibility/header/processcritera/'
export var SAVE_NEW_HEADER1 = 'fillitto/visibility/header'
export var GET_BY_ID_VISI_HEADER = 'fillitto/visibility/header/'
export var DELETE_SINGLE_VISI_HEADER1 =
    'fillitto/visibility/header/processcritera/'
export var DELETE_SINGLE_VISI_HEADER = 'fillitto/visibility/header/'
export var VISI_DETAILS_TABLE = 'fillitto/visibility/details'
export var SAVE_NEW_DETAILS = 'fillitto/visibility/'
export var GET_BY_ID_VISI_DETAILS = 'fillitto/visibility/details/'
export var DELETE_SINGLE_VISI_DETAIL = 'fillitto/visibility/'
export var VISI_TEMPLATE_TABLE = 'fillitto/template'
export var TEMPLATE = 'fillitto/template/'
export var TEMPLATE_LIST = 'fillitto/template'
export var TEMPLATE_EDIT_BY_ID = 'master/template/'
export var DELETE_TEMPLATE_DATA = 'master/template/'
export var ADD_TEMPLATE = 'master/template'
export var EDIT_SAVE_TEMPLATE_DATA = 'master/template/'
/***Transaction APIS */
export var TRANS_LIST = 'master/transaction'
export var TRANS_FILE_UPLOAD = 'master/transaction/file'
/*** Admin User Details */
export var CONFORM_CODE_API = 'user/confirmSignUp'
export var TOKEN_VERIFY =
    'fillitto/authentication/user/changestatus?verificationToken='
export var FORCE_CHANGE_PASSWORD1 = 'fillitto/auth/user/login/changePassword'
export var FORCE_CHANGE_PASSWORD =
    '/fillitto/auth/user/login/changeTempPassword'
export var FORCE_CHANGE_PSWD = 'fillitto/authentication/user/password'
export var USER_ROLES = 'user'
export var ENABLE_USER = 'fillitto/auth/user/enable'
export var DISABLE_USER = 'fillitto/auth/user/disable'
export var ROLES = 'fillitto/authentication/role'
export var SINGLE_ROLE = baseURL + 'fillitto/authentication/role/'
export var REGISTERATION = 'user'
export var GET_SINGLE_USER_DATA = 'fillitto/authentication/user/'
export var BULK_UPDATE = 'fillitto/authentication/user'
export var DELETE_USER = 'fillitto/authentication/user/'
export var GET_ALL_RULES = 'master/lookup/details/rules'
export var POST_NEW_RULE = 'master/lookup/details/rules'
export var GET_RULES_BY_ID = 'master/lookup/details/rules/id'
export var DELETE_RULE_BY_ID = 'master/lookup/details/rules/id'
export var UPDATE_THE_RULE_RECORD = 'master/lookup/details/rules/id'
export var GET_GROUP_LIST = baseURL + 'fillitto/authentication/group'
export var GET_UNIQUE_GROUP_LIST =
    baseURL + 'fillitto/authentication/group/name'
export var GET_ALL_ROLE = '/fillitto/authentication/role'
export var GET_DETAILS_AND_RULES_BY_ID =
    'fillitto/process/criteria/details/extended'
export var COMMON_GRP_URL = baseURL
export var NEW_GROUP = baseURL + 'fillitto/authentication/group'
export var DELETE_GROUP_ROW = baseURL + 'fillitto/authentication/group/'
export var REPORT_TEMPLATE = 'fillitto/visibility/master/template/'
export var REPORT_MASTER = 'api/v1/report/master'
export var POST_NEW_REPORT = '/fillitto/reporting/master'
export var POST_UPDATE_REPORT = '/fillitto/reporting/master/2'
export var REPORT_ALL = 'fillitto/reporting/master'
export var SAVE_NEW_REPORT = 'fillitto/reporting/master'
export var REPORT_BY_ID = 'fillitto/reporting/master/id/'
export var GET_SEQUENCE_NUMBER = 'fillitto/reporting/request/sequence/tags/'
export var ORDER = '/ORDER'
export var DEFAULT_SETTINGS = 'fillitto/reporting/request/ui/design/settings/'
export var DEFAULT_SETTING_POST =
    '/fillitto/reporting/request/ui/design/settings/'
export var DEFAULT_SETTINGS_ALL =
    'fillitto/reporting/request/ui/design/settings'
export var COLUMN_AND_DESIGN = 'fillitto/reporting/request/ui/report/profile/'
export var UPLOAD_DESIGN_AND_TABLE =
    'fillitto/reporting/request/ui/report/profile/id/'
export var POST_DEFAULT_DESIGN = 'fillitto/reporting/request/ui/report/profile'
/***Report Module *****/
//1.Execution Report
export var EXECUTION_SAVE = '/fillitto/reporting/master'
export var EXECUTION_GET_LIST = 'fillitto/reporting/master'
export var EXECUTION_GET_ID = '/fillitto/reporting/master/'
export var EXECUTION_DELETE_ID = '/fillitto/reporting/master/'
export var EXECUTION_UPDATE_ID = '/fillitto/reporting/master/'
export var UPDATE_DESIGN_AND_COLUMN =
    'fillitto/reporting/request/ui/report/profile/'
export var DISPLAY_MODE_NEW = '/displayMode/'
export var GENERATE_TAG = baseURL + 'fillitto/reporting/request/tags/'
export var DEFAULT_SETTINGS_DISPLAYTYPE =
    baseURL + 'fillitto/reporting/request/ui/design/settings?type='
export var DASHBOARD_URL = baseURL + 'fillitto/reporting/dashboard/user/'
//export var GET_GROUP_LIST_MULTI = baseURL + 'fillitto/reporting/user/link/'
export var GET_GROUP_LIST_MULTI =
    'http://Fillitto-1526566366.ap-south-1.elb.amazonaws.com:9097/fillitto/api/v1/user/report/link/'
export var USER_DASBOARD = baseURL + 'fillitto/reporting/dashboard/user/'
export var DASHBOARD_MAIN_URL = baseURL + 'fillitto/reporting/dashboard/user/'
export var VISIBILITY_ID = baseURL + 'fillitto/process/criteria/processType/'
export var REFRESH_DURATION_API = baseURL + 'fillitto/reporting/dashboard/'
export var MODULE_ID = baseURL + 'fillitto/staticvaraibles'
export var GET_DASHBOARD_REPORT_BY_USERID =
    baseURL + 'fillitto/reporting/dashboard/user/'
export var SINGLE_DASHBOARD_DATA = baseURL + 'fillitto/reporting/dashboard/'
export var GET_USERLIST_BY_GRP =
    baseURL + 'fillitto/authentication/user/groupNames'
export var SAVE_USER_AND_GRP_API = baseURL + 'fillitto/reporting/user/link'
export var VISIBILITY_START_END_STATUS_LIST =
    baseURL + 'fillitto/staticvaraibles'
export var VISIBILITY_START_END_STATUS_SAVE =
    baseURL + 'fillitto/staticvaraibles/1'
export var GET_UNIQUE_PROCESSED =
    baseURL + 'fillitto/process/criteria/processId/processType/visibility'
const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
]
function updateSequencyIndex(arrayValue, parentSequence, tempsequence) {
    // console.log('common', arrayValue, parentSequence, tempsequence)

    var tempArray = arrayValue.map((res, i) => {
        let idx = i + 1
        res.displaySequence = parentSequence + '.' + idx
        res.sequence = parentSequence + '.' + idx
        res.parentSequence = parentSequence
        res.tempsequence = tempsequence ? tempsequence + '.' + idx : ''
    })
    return arrayValue
}
function updateSequencyChildIndex(arrayValues, parentSequence, tempsequence) {
    console.log('common2', arrayValues, parentSequence, tempsequence)
    const newUsers = arrayValues.map((res, i) => {
        let idx = i + 1
        var uid = parentSequence + '.' + idx
        return {
            ...res,
            displaySequence: uid,
            sequence: uid,
            parentSequence: parentSequence,
            tempsequence: tempsequence + '.' + idx,
        }
    })
    console.log('arrayValues', arrayValues)
    return newUsers
}
function sleeps(delay = 5000) {
    return new Promise((resolve) => {
        setTimeout(resolve, delay)
    })
}
function changeChildStatus(arr, status) {
    console.log('commonSequence', arr, status)
    return arr.map((word, i) => {
        // let idx = i + 1
        // var uid = parentSequence + '.' + idx
        // let chek = status == 0 ? parentSequence + '.' + idx : '1'
        // console.log('chek',chek)
        return {
            ...word,
            isActive: status,
            // displaySequence: uid,
            // parentSequence: parentSequence,
            // sequence: uid,
        }
    })
}

function changeSequencyNumber(arr, newIndex) {
    console.log('newIndex', arr, newIndex, newIndex.length)

    return arr.map((word) => {
        console.log('beforeSlipt', word.displaySequence.split('.')[1])
        console.log(
            'newIndexarr',
            newIndex,
            word.displaySequence.slice(newIndex.length)
        )

        var disp = word.displaySequence.slice(newIndex.length)
        var seq = word.sequence.slice(newIndex.length)
        var parSeq = word.parentSequence.slice(newIndex.length)
        return {
            ...word,
            displaySequence: newIndex + disp,
            sequence: newIndex + seq,
            parentSequence: newIndex + parSeq,
        }
    })
}
// export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))
// await delay(1000)

// await new Promise(r => setTimeout(r, 2000));

function sleep(ms) {
    console.log('ms', ms)
    return new Promise((resolve) => setTimeout(resolve, ms))
}
function filterArrayData(parentArray, level, sequence) {
    console.log('filterArrayData', level, sequence)

    var res = parentArray.filter((el) => {
        return el.level == level && el.parentSequence == sequence
    })

    return res
}
function filterChildData(ilevel, parentSeq, AllColumnValue) {
    var tempArray = AllColumnValue.filter((el) => {
        return el.level == ilevel && el.tempParentSeq == parentSeq
    })
    return AllColumnValue, console.log('AllColumnValue', AllColumnValue)
}

const monthCode = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
]

export const rolesFilterForTopNav = [
    { value: '1', label: 'visibility.maintain' },
    { value: '2', label: 'visibility.view' },
    { value: '3', label: 'adminuser.execute' },
    { value: '4', label: 'adminuser.view' },
    { value: '5', label: 'template.master' },
    { value: '6', label: 'template.view' },
    { value: '7', label: 'report.maintain' },
    { value: '8', label: 'report.view' },
    { value: '9', label: 'processcriteria.view' },
    { value: '10', label: 'processcriteria.maintain' },
]

export const rolesFilterForSidNav = [
    { value: '1', label: 'adminuser.execute' },
    { value: '2', label: 'adminuser.view' },
    { value: '3', label: 'visibility.view' },
    { value: '4', label: 'visibility.execute' },
    { value: '5', label: 'Visibility.Lookup.Execute' },
    { value: '6', label: 'Visibility.Lookup.View' },
    { value: '7', label: 'Visibility.Template.Execute' },
    { value: '8', label: 'Visibility.Template.View' },
    { value: '9', label: 'Visibility.Transaction.Execute' },
    { value: '10', label: 'Visibility.Transaction.FileUpload.Execute' },
    { value: '11', label: 'Visibility.Transaction.View' },
    { value: '12', label: 'Visibility.Dashboard.Admin' },
    { value: '13', label: 'AdminUser.Execute' }, //Report Execute
    { value: '14', label: 'AdminUser.View' }, //Report View
]
export function changeTimeFormat(currentdate) {
    var now = new Date(currentdate)
    return (
        now.getHours() +
        ':' +
        (now.getMinutes() < 10 ? '0' + now.getMinutes() : now.getMinutes()) +
        ':' +
        (now.getSeconds() < 10 ? '0' + now.getSeconds() : now.getSeconds())
    )
}
export const NewRoles = [
    {
        roleID: 2,
        name: 'Lookup.Execute',
        description: 'Lookup Execution',
    },
    {
        roleID: 3,
        name: 'Visibility.Execute',
        description: 'Visibility Execution',
    },
    {
        roleID: 4,
        name: 'Transaction.Execute',
        description: 'Transaction Execution',
    },
    {
        roleID: 5,
        name: 'Template.Execute',
        description: 'Template Execution',
    },
    {
        roleID: 6,
        name: 'Reporting.Execute',
        description: 'Reporting Execution',
    },
    {
        roleID: 7,
        name: 'Administrator',
        description: 'Administrator role',
    },
    {
        roleID: 9,
        name: 'lookup Role',
        description: 'All lookup details',
    },
    {
        roleID: 17,
        name: 'Role',
        description: 'description',
    },
]

export const description = [
    { value: '1', label: 'description1' },
    { value: '2', label: 'description2' },
    { value: '3', label: 'description3' },
    { value: '4', label: 'description4' },
    { value: '5', label: 'description5' },
    { value: '6', label: 'description6' },
    { value: '7', label: 'description7' },
    { value: '8', label: 'description8' },
    { value: '9', label: 'description9' },
    { value: '10', label: 'description10' },
    { value: '11', label: 'description11' },
    { value: '12', label: 'description12' },
]
export const alertModeDropDown = [
    { value: '', label: 'None' },
    { value: 'email', label: 'Email' },
    { value: 'text', label: 'Text' },
    { value: 'both', label: 'Both' },
]
export const designType = [
    { value: 'Card', label: 'Card' },
    { value: 'table', label: 'Table' },
    { value: 'DoughnutChart', label: 'DoughnutChart' },
    { value: 'Barchart', label: 'Barchart' },
]
export const donutDefaultSettings = {
    designsettings: {
        donutFontFamily: 'arial',
        donutHeightStyle: 383,
        donutTitleFontSize: 14,
        donutWidthStyle: 71,
        headerDonutColor: '#BC1515',
    },
    displayMode: 'dashboard',
    displayType: 'DoughnutChart',
    list: [
        { value: 50, name: 'Google' },
        { value: 10, name: 'Microsoft' },
        { value: 40, name: 'Amazon' },
    ],

    refreshDuration: 5,
    reportId: 7,
    transactionName: 'DOUGHT_NUT',
}
export const tableDefault = {
    defaultSettings: {
        headerBackgroundColor: 'teal',
        headerFontColor: '#D33B3B',
        headerFontSize: '12',
        highlightColor: '#D33B3B',
        margin: '5',
        numberOfRows: '10',
        rowSelectedBackgroundColor: '#fff',
        tableBackgroundColor: '#D33B3B',
        tableColor: '#D33B3B',
        tableFontFamily: 'verdana',
        tableFontSize: '10',
        tablePaddingBottom: '0rem',
        tablePaddingLeft: '0rem',
        tablePaddingTop: '0rem',
        tablePaddingRight: '0rem',
        tableTitleFontSize: '15',
        tableTitleFontStyle: 'serif',
        textAlign: 'top',
        verticalAlign: 'center',
    },
    displayMode: 'dashboard',
    displayType: 'table',
    refreshDuration: 10,
}
export const defaultSettings = {
    headerBackgroundColor: 'teal',
    headerFontColor: '#D33B3B',
    headerFontSize: '12',
    highlightColor: '#D33B3B',
    margin: '5',
    numberOfRows: '10',
    rowSelectedBackgroundColor: '#fff',
    tableBackgroundColor: '#D33B3B',
    tableColor: '#D33B3B',
    tableFontFamily: 'verdana',
    tableFontSize: '10',
    tablePaddingBottom: '0rem',
    tablePaddingLeft: '0rem',
    tablePaddingTop: '0rem',
    tablePaddingRight: '0rem',
    tableTitleFontSize: '15',
    tableTitleFontStyle: 'serif',
    textAlign: 'top',
    verticalAlign: 'center',
}
export const cardDesigns = {
    designsettings: {
        cardColor: '#EA0303',
        cardBackgroundColor: '#fcfaf5',
        cardTitleColor: '#EA0303',
        cardPaddingLeft: '0rem',
        cardPaddingRight: '0rem',
        cardPaddingTop: '0rem',
        cardPaddingBottom: '0rem',
        cardFontSize: 10,
        titleFontSize: 15,
        cardFontFamily: 'arial',
        cardTitleFontFamily: 'arial',
        gridCount: 4,
    },
    displayMode: 'dashboard',
    displayType: 'card',
    overAll: [
        {
            name: '1Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s',
        },
        {
            name: '2Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s',
        },
        {
            name: '3Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s',
        },
        {
            name: '4Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s',
        },
        {
            name: '5Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s',
        },
    ],
    refreshDuration: 10,

    transactionName: 'CARD',
}
export const startEndStatusDropdown = [
    { value: 'NOTCREATED', label: 'NotCreated' },
    { value: 'CREATED', label: 'Created' },
    { value: 'RELEASED', label: 'Released' },
    { value: 'PICKED', label: 'Picked' },
    { value: 'DISPATCHED', label: 'Dispatched' },
]

export const operatorForRule = [
    { value: 'IS_EQUAL_TO', label: 'Equal To ' },
    { value: 'LESS_THAN', label: '< ' },
    { value: 'GREATER_THAN', label: '> ' },
    // { value: 'LESS_THAN_EQUAL_TO', label: '<= ' },
    // { value: 'GREATER_THAN_EQUAL_TO', label: '>= ' },
    { value: 'NOT_EQUAL_TO', label: 'Not Equal To ' },
    { value: 'CONTAINS', label: 'Contains ' },
]

export function changeOperator(operator) {
    var operatorFinal = operatorForRule
        .filter((item) => item.value == operator)
        .map((obj) => obj.label)
    return operatorFinal
}

export var TEMPLATE_FILE_SIZE = 10000
export var loginheaders = {
    'Content-Type': 'application/json;charset=UTF-8',
}
export var headers = {
    // 'access-control-allow-credentials': true,
    //  'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json;charset=UTF-8',
    Authorization: `Bearer ${getJwtToken()}`,
    Accept: 'application/json',
    //"Access-Control-Allow-Origin": "http://localhost:3000/"
    //  'Access-Control-Allow-Headers': 'Authorization',
}

export var headers1 = {
    'Content-Type': 'multipart/form-data; charset=UTF-8',
    'Access-Control-Allow-Origin': '*',
    Authorization: getJwtToken(),
}
// export const defaultArray = [{ value: "0", label: "Select All" }];
export const defaultStatusArraySelected = [{ value: '1', label: 'Active' }]
// export const defaultRecpHeader = "#3f51b5";
// export const defaultEmpHeader = "#3f51b5";
// export const devPasscode = "9876";
export const Small = styled('small')(({ bgcolor }) => ({
    height: 15,
    width: 50,
    color: '#fff',
    padding: '2px 8px',
    borderRadius: '4px',
    overflow: 'hidden',
    background: bgcolor,
    boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)',
}))
export const generalFunction = {
    changeTimezone,
    getHeader,
    getHeader1,
    getInitial,
    convertJsonParse,
    changeDateFormat,
    randomGeneratedString,
    formatDateTime,
    getAlertModeLabel,
    updateSequencyIndex,
    filterChildData,
    updateSequencyChildIndex,
    filterArrayData,
    changeSequencyNumber,
    sleep,
    changeChildStatus,
    sleeps,
    changeKeyArray,
    getRandomColor,
    getRandomSingleColor,
    validateTimeFormat,
    validateEmailFormat,
    tagPosition,
}

function tagPosition() {
    return Array.apply(null, { length: 14 }).map((_, i) => {
        return { value: i, label: i }
    })
}
function validateEmailFormat(emails) {
    var isValid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emails)
    return isValid
}
function validateTimeFormat(times) {
    var isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(
        times
    )
    return isValid
}
function changeKeyArray(originalKey, newKey, arr) {
    var newArr = []
    for (var i = 0; i < arr.length; i++) {
        var obj = arr[i]
        obj[newKey] = obj[originalKey]
        // delete(obj[originalKey]);
        newArr.push(obj)
    }
    return newArr
}
function getAlertModeLabel(alertMode) {
    if (alertMode == 'email') {
        return 'Alert Email'
    } else if (alertMode == 'text') {
        return 'Alert Text'
    } else if (alertMode == 'both') {
        return 'Alert Both'
    } else if (alertMode == 'none') {
        return 'Alert Information'
    }
    return 'Alert Information'
}

function formatDateTime(fromDate) {
    let date = new Date(fromDate)
    //month = date.getMonth() + 1, // months are zero indexed
    var year = date.getFullYear(),
        monthName = date.getMonth(),
        day = date.getDate(),
        hour = date.getHours(),
        minute = date.getMinutes(),
        second = date.getSeconds(),
        hourFormatted = hour % 12 || 12, // hour returned in 24 hour format
        minuteFormatted = minute < 10 ? '0' + minute : minute,
        morning = hour < 12 ? 'am' : 'pm'

    return (
        monthCode[monthName] +
        ' ' +
        day +
        ' ' +
        year +
        ' ' +
        hourFormatted +
        ':' +
        minuteFormatted +
        morning
    )
}
function changeDateFormat(date) {
    return Moment(date).format('DD-MM-YYYY')
}
function convertJsonParse(str) {
    let jsonData = ''
    try {
        jsonData = JSON.parse(str)
    } catch (e) {
        //return false;
        jsonData = str
    }
    //return true;
    return jsonData
}
function getRandomColor() {
    var letters = '0123456789ABCDEF'.split('')
    var color = '#'
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)]
    }
    return color
}
function getRandomSingleColor() {
    var randomColor = Math.floor(Math.random() * 16777215).toString(16)
    return randomColor
}
function getInitial(name = '') {
    var abc = name
        .replace(/\s+/, ' ')
        .split(' ')
        .slice(0, 2)
        .map((v) => v && v[0].toUpperCase())
        .join('')
    return abc
}
// function setLocalData(key, value) {
//     reactLocalStorage.set(key, value)
// }

// function getLocalData(key) {
//     return reactLocalStorage.get(key)
// }

function getHeader1() {
    let headers = {
        'Content-Type': 'multipart/form-data; charset=UTF-8',
        'Access-Control-Allow-Origin': '*',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: getJwtToken(),
    }
    return headers
}
function getHeader() {
    let headers = {
        'Content-Type': 'application/json;charset=UTF-8',
        // "Access-Control-Allow-Origin": "*",
        // "Access-Control-Allow-Credentials": "true",
        // "Access-Control-Allow-Headers": "Content-Type, Authorization, Content-Length, X-Requested-With, Accept",
        'Access-Control-Allow-Methods':
            'GET, POST, OPTIONS, PUT, DELETE,OPTIONS',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: getJwtToken(),
    }
    return headers
}
function getJwtToken() {
    //  const sessionLoginToken = useSelector((state)=>state.login);
    var abc = localStorage.getItem('accessToken')
    //  console.log("Getting the Token",sessionLoginToken);
    //sessionStorage.getItem("tokenId")
    return abc
}

function changeTimezone(date1, ianatz) {
    // suppose the date is 12:00 UTC
    var date = new Date(date1 * 1000)
    var invdate = new Date(
        date.toLocaleString('en-US', {
            timeZone: ianatz,
        })
    )

    // then invdate will be 07:00 in Toronto
    // and the diff is 5 hours
    var diff = date.getTime() - invdate.getTime()

    // so 12:00 in Toronto is 17:00 UTC
    var finalDate = new Date(date.getTime() + diff)
    var dateFormat =
        finalDate.getDate() +
        '-' +
        (finalDate.getMonth() + 1) +
        '-' +
        finalDate.getFullYear() //finalDate.toLocaleDateString();
    var timeFormat = finalDate.toLocaleTimeString()
    return dateFormat + ' ' + timeFormat
}

const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
function randomGeneratedString(length) {
    let result = ' '
    const charactersLength = characters.length
    for (let i = 0; i < length; i++) {
        result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
        )
    }

    return result
}
// function encryptedString(data) {
//     if (data != '' && data != null) {
//         const encryptedString = cryptr.encrypt(data)
//         return encryptedString
//     }
//     return ''
// }
// function decryptedString(data) {
//     if (data != '' && data != null) {
//         const decryptedString = cryptr.decrypt(data)
//         return decryptedString
//     }
//     return ''
// }

export const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: {
        margin: '16px',
    },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '16px',
        },
    },
}))
export const ContainerNew = styled('div')(({ theme }) => ({
    margin: '20px',
    [theme.breakpoints.down('sm')]: {
        margin: '12px',
    },
    '& .breadcrumb': {
        marginBottom: '20px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '12px',
        },
    },
}))

export const withNavigation = (Component) => {
    return (props) => <Component {...props} navigate={useNavigate()} />
}

var loginRolesApi = JSON.parse(localStorage.getItem('loginRoles'))
var roleValue = loginRolesApi ? loginRolesApi : []
// var roleValue = JSON.parse(localStorage.getItem('loginRoles'))
var rolesView = rolesFilterForSidNav
    .filter((val, index, self) => roleValue.includes(val.label))
    .map((item) => item.value)
// console.log('rolesView', rolesView)
export const hiddenElement = (val) => {
    if (rolesView.includes(val)) {
        return {
            hideEditDelete: false,
        }
    } else {
        return {
            hideEditDelete: true,
        }
    }
}

//roles for topNav
var rolesTopView = rolesFilterForTopNav
    .filter((val, index, self) => roleValue.includes(val.label))
    .map((item) => item.value)

rolesTopView.map((item) => {
    rolesFilterForTopNav.map((val) => {
        if (item == val.value) {
            return {
                hideEditDelete: true,
            }
        } else {
            return {
                hideEditDelete: false,
            }
        }
    })
})

export const hiddenTopElement = (val) => {
    if (rolesTopView.includes(val)) {
        return {
            hideEditDelete: false,
        }
    } else {
        return {
            hideEditDelete: true,
        }
    }
}

export var filterForHideTopnav = [
    { value: '1', label: 'visibility.view' },
    { value: '1', label: 'visibility.maintain' },
    { value: '2', label: 'adminuser.execute' }, //Report Execute
    { value: '2', label: 'adminuser.view' },
    { value: '3', label: 'template.master' },
    { value: '3', label: 'template.view' },
    { value: '4', label: 'report.view' },
    { value: '4', label: 'report.maintain' },
    { value: '5', label: 'processcriteria.view' },
    { value: '5', label: 'processcriteria.maintain' },
]
export var rolesTop = filterForHideTopnav
    .filter((val, index, self) => roleValue.includes(val.label))
    .map((item) => item.value)

// console.log('rolesTop', rolesTop)

export var TopNavUnique = rolesTop.filter(
    (value, index, array) => array.indexOf(value) === index
)

// console.log('unique', TopNavUnique)
