import { processCriteriaType } from '../../constant/ActionTypes'
import _ from 'lodash'

import { TryRounded } from '@mui/icons-material'
import { generalFunction } from 'app/constant/Common'

const INITIAL_STATE = {
    loader: false,
    openConfirm: false,
    secondDialogBox: false,
    editHeader: false,
    detailsPopup: false,
    processId: '',
    processType: '',
    ruleNameError: '',
    processCriteria: '',
    status: 'ACTIVE',
    buttonEnable: true,
    criteriaTableData: [],
    getCriteriaPagination: [],
    generatedId: '',
    ruleTagName: '',
    singleProcessDelete: [],
    detailsProcessCriteriaTable: [],
    detailGeneratedId: '',
    detailsPosition: [],
    menuPopupOpen: false,
    detailsCriteria: '',
    detailsCriteriaError: '',
    detailsTagError: '',
    detailsPositionError: '',
    detailsTag: '',
    detailTableOpen: true,
    disableProcessCriDetails: false,
    descriptionRule: '',
    descriptionRuleError: '',
    tagNameRule: '',
    tagNameRuleError: '',
    operator: 'IS_EQUAL_TO',
    operatorError: '',
    tagValueRule: '',
    allDetails: [],
    tagValueRuleError: '',
    ruleStatus: '',
    ruleStatusError: '',
    detailsSinglecriteriaTable: [],
    processCriteriaError: '',
    processIdError: '',
    processTypeError: '',
    statusError: '',
    processCriteriaName: '',
    processCriteriaNameError: '',
    ruleName: '',
    infoDetail: false,
    detaildisable: true,
    openRulePanel: 'false',
    transactionRuleData: [],
    tagPositionList: generalFunction.tagPosition(),
    modifiedBy: JSON.parse(localStorage.getItem('auth_user')),
    postNewdetailsProcess: [],
    confirmPopupDeleteRule: false,
    closeOnClickOutside: false,
    hideProcessCriteria: false,
    closeOnEscape: false,
    ruleGeneratedId: '',
    ruleAndDetails: [],
    criteriaDetailsData: [],
    ruleDetailsData: [],
    processCriteriaId: '',
    miscField4: '',
    miscField4Error: '',
    miscField1: '',
    miscField1Error: '',
    miscField2: '',
    miscField2Error: '',
    miscField3: '',
    miscField3Error: '',
    miscFlag1: '',
    miscFlag2: '',
    miscFlag3: '',
    miscFlag4: '',
    miscFlag5: '',

    ruleMiscField4: '',
    ruleMiscField4Error: '',
    ruleMiscField1: '',
    ruleMiscField1Error: '',
    ruleMiscField2: '',
    ruleMiscField2Error: '',
    ruleMiscField3: '',
    ruleMiscField3Error: '',
    ruleMiscFlag1: '',
    ruleMiscFlag2: '',
    ruleMiscFlag3: '',
    ruleMiscFlag4: '',
    ruleMiscFlag5: '',

    detailsMiscField4: '',
    detailsMiscField4Error: '',
    detailsMiscField1: '',
    detailsMiscField1Error: '',
    detailsMiscField2: '',
    detailsMiscField2Error: '',
    detailsMiscField3: '',
    detailsMiscField3Error: '',
    detailsMiscFlag1: '',
    detailsMiscFlag2: '',
    detailsMiscFlag3: '',
    detailsMiscFlag4: '',
    detailsMiscFlag5: '',
    idval: [],
    setLoading:false
}
const ProcessCriteriaReducers = function (
    state = INITIAL_STATE,
    { type, payload }
) {
    switch (type) {
        case processCriteriaType.OPEN_CRITERIA_POPUP: {
            //.log('reducers')
            return {
                ...state,
                secondDialogBox: true,
                loader: false,
                openConfirm: false,
                editHeader: false,
                processId: '',
                processType: '',
                processCriteria: '',
                processCriteriaName: '',
                status: 'ACTIVE',
                processIdError: '',
                processCriteriaError: '',
                processTypeError: '',
                processCriteriaNameError: '',
                statusError: '',
                menuPopupOpen: false,
            }
        }
        case processCriteriaType.INPUT_CHANGE_PROCESS:
            return {
                ...state,
                [payload.props]: payload.value,
                [payload.error]: '',
                setLoading:false
            }
        case processCriteriaType.CRITERIA_CLOSE_POPUP:
            return {
                ...state,
                secondDialogBox: false,
            }
        case processCriteriaType.OPEN_ADD_DETAIL_POPUP:
            return {
                ...state,
                detailsPopup: true,
                buttonEnable: false,
                setLoading:false,
                // disableProcessCriDetails: true,
                detaildisable: false,
                // processId: '',
                // processType: '',
                // // processCriteria: '',
                // status: [],
            }
        case processCriteriaType.GET_DETAILS_INITIAL_EMPTY:
            return {
                ...state,
                loading: false,
                detailGeneratedId: '',
                processCriteriaNameError: '',
                detailsPosition: '0',
                detailsCriteria: '',
                detailsCriteriaError: '',
                detailsTagError: '',
                detailsPositionError: '',
                detailsTag: '',
                detailsMiscField4: '',
                detailsMiscField4Error: '',
                detailsMiscField1: '',
                detailsMiscField1Error: '',
                detailsMiscField2: '',
                detailsMiscField2Error: '',
                detailsMiscField3: '',
                detailsMiscField3Error: '',
                detailsMiscFlag1: '',
                detailsMiscFlag2: '',
                detailsMiscFlag3: '',
                detailsMiscFlag4: '',
                detailsMiscFlag5: '',
                setLoading:false
            }
        case processCriteriaType.RESPONSE_RULE_DATA:
            //    console.log('payload', payload)
            return {
                ...state,
                descriptionRule: payload.description,
                // processCriteria
            }
        case processCriteriaType.CLOSE_DETAILS_POPUP:
            return {
                ...state,
                detailsPopup: false,
                buttonEnable: true,
                detaildisable: true,
            }
        case processCriteriaType.EDIT_REQUEST_RULE_DATA:
            return {
                ...state,
            }
        case processCriteriaType.DETAIL_TABLE_OPEN:
            return {
                ...state,
                detailTableOpen: payload,
            }
        case processCriteriaType.PROCESS_CRITERIA_LOADING_STOP:
            return{
                ...state,
                loader:false
            }
        case processCriteriaType.OPEN_EDIT_DETAIL_POPUP:
            return {
                ...state,
                buttonEnable: true,
                disableProcessCriDetails: true,
            }
        case processCriteriaType.GET_PROCESS_CRITERIA_TABLE:
            return {
                ...state,
                loader: true,
                menuPopupOpen: false,
            }
        case processCriteriaType.DETAIL_FIELD_DISABLE:
            return {
                detaildisable: payload,
            }
        case processCriteriaType.PROCESS_CRITERIA_AUTH_ERROR:
            localStorage.clear()
            window.location.reload()
            return {
                ...state,
                loader: false,
            }
        case processCriteriaType.DISPLAY_PROCESS_TABLE_DATA:
            console.log('vsllll')
            return {
                ...state,
                loader: false,
                criteriaTableData: payload,

                getCriteriaPagination: _(payload)
                    .slice(0)
                    .take(payload.length)
                    .value(),
            }

        case processCriteriaType.INFO_DETAIL_PANEL_OPEN:
            return {
                ...state,
                infoDetail: payload,
            }
        case processCriteriaType.GET_INITIAL_CRITERIA:
            return {
                ...state,
                loading: false,
                getCriteriaPagination: _(payload)
                    .slice(0)
                    .take(payload.length)
                    .value(),
            }
        case processCriteriaType.RESPONSE_SAVE_EDIT_TRANSACTION:
            return {
                ...state,
                loading: false,
                detailTableOpen: true,
                detailsPopup: false,
            }
        case processCriteriaType.MENU_TAB_POPUP: {
            return {
                ...state,
                menuPopupOpen: payload,
                setLoading:false
            }
        }
        case processCriteriaType.VALIDATE_RULE_DATA: {
            return {
                ...state,

                tagNameRuleError: payload.tagNameRuleError,
                // operatorError: payload.operatorError,
                ruleNameError: payload.ruleNameError,
                tagValueRuleError: payload.tagValueRuleError,
                detailsMiscField4Error: payload.detailsMiscField4Error,
                ruleMiscField4Error: payload.ruleMiscField4Error,
            }
        }
        case processCriteriaType.DISPLAY_PROCESS_DATA:
            //.log('displayuser', payload)
            return {
                ...state,
                loader: false,
                getCriteriaPagination: _(payload).slice(0).take(10).value(),
            }
        case processCriteriaType.ADMIN_USER_ERROR_UNAUTH:
            localStorage.clear()
            window.location.reload()
            return {
                ...state,
                loader: false,
            }
        case processCriteriaType.MAKE_INITIAL_PROCESS_EMPTY:
            return {
                ...state,
                loading: false,
                processId: '',
                processType: '',
                processCriteriaName: '',
                status: 'ACTIVE',
                miscField4: '',
                miscField4Error: '',
                miscField1: '',
                miscField1Error: '',
                miscField2: '',
                miscField2Error: '',
                miscField3: '',
                miscField3Error: '',
                miscFlag1: '',
                miscFlag2: '',
                miscFlag3: '',
                miscFlag4: '',
                miscFlag5: '',
                setLoading:false
            }
        case processCriteriaType.REQUEST_SAVE_CRITERIA:
            return {
                ...state,
                loading: true,
                status: 'ACTIVE',
                secondDialogBox: false,
                setLoading:true
            }
        case processCriteriaType.RESPONSE_SAVE_CRITERIA:
            return {
                ...state,
                loading: false,
            }
        case processCriteriaType.REQUEST_EDIT_SAVE_CRITERIA:
            //.log('editsaveresponse', payload)
            return {
                ...state,
                loading: true,
                setLoading:true
                // processCriteria:payload.processCriteria,
                // processId:payload.processId,
                // processType:payload.processType,
                // status:payload.status,
                // generatedId:payload.generatedID
            }
        case processCriteriaType.RESPONSE_EDIT_SAVE_CRITERIA:
            //.log('editsaveresponse', payload)
            return {
                ...state,
                loading: false,
                menuPopupOpen: false,
            }
        case processCriteriaType.UPDATE_SINGLE_PROCESS:
            // console.log('updateSingle get', payload)
            return {
                ...state,
                loader: true,
                hideProcessCriteria: true,
                singleProcessDelete: payload,
                processCriteriaName: payload.processCriteriaName,
                processId: payload.processId,
                processType: payload.processType,
                status: payload.status,
                generatedId: payload.id,
            }
        case processCriteriaType.DISPLAY_SINGLE_DETAILS_TABLE:
            // console.log('displaySingle data', payload)
            return {
                ...state,
                loader: false,
                detailsSinglecriteriaTable: payload,
                processCriteria: payload.processCriteria,
                processCriteriaName: payload.processCriteriaName,
                processId: payload.processId,
                processType: payload.processType,
                status: payload.status,
                generatedId: payload.id,
                miscFlag1: payload.miscFlag1,
                miscFlag2: payload.miscFlag2,
                miscFlag3: payload.miscFlag3,
                miscFlag4: payload.miscFlag4,
                miscFlag5: payload.miscFlag5,
                miscField1: payload.miscField1,
                miscField2: payload.miscField2,
                miscField3: payload.miscField3 != 0 ? payload.miscField3 : '',
                miscField4:
                    payload.miscField4 != 0 || payload.miscField4 != 0.0
                        ? payload.miscField4
                        : '',
            }
        case processCriteriaType.REQUEST_SAVE_EDIT_TRANSACTION:
            return {
                ...state,
                loading: true,
                setLoading:true
            }

        case processCriteriaType.DELETE_REQUEST_PROCESS_CRITERIA:
            return {
                ...state,
                loading: true,
                singleProcessDelete: payload,
                menuPopupOpen: false,
            }
        case processCriteriaType.DELETE_RESPONSE_PROCESS_CRITERIA:
            return {
                ...state,
                loading: false,
            }
        case processCriteriaType.GET_PROCESS_CRITERIA_DETAILS:
            //  console.log('detailsprocessCriteria', payload)
            return {
                ...state,
                loading: true,
                processCriteriaId: payload,
                detailTableOpen: true,
                // processCriteria: payload.processCriteria,
                // generatedId:payload.id,
                // processCriteriaName:payload.processCriteriaName,
                // processCriteriaNameError:'',
                processCriteriaNameError: '',
                processIdError: '',
                processTypeError: '',
                statusError: '',
                // detailTableOpen: true,
            }
        case processCriteriaType.DISPLAY_PROCESS_TABLE_DETAILS:
            // console.log("detailsProcessCriteriaTable",payload)
            return {
                ...state,
                loading: false,
                allDetails: payload,
                // detailsProcessCriteriaTable: payload && payload.map((itm)=> itm.processCriteriaDetails).concat(payload.map((itm)=> {
                //     if(itm.transactionRule == null){
                //         return ''
                //     }else{
                //         return itm.transactionRule
                //     }
                // }))
                transactionRuleData:
                    payload &&
                    payload.map((itm) => {
                        if (itm.transactionRule == null) {
                            return ''
                        } else {
                            return itm.transactionRule
                        }
                    }),
            }
        case processCriteriaType.VALIDATION_PROCESS_DETAILS:
            return {
                ...state,
                detailsPopup: true,
                processCriteriaError: payload.processCriteriaError,
                processCriteriaNameError: payload.processCriteriaNameError,
                detailsTagError: payload.detailsTagError,
                detailsPositionError: payload.detailsPositionError,
                detailsMiscField4Error: payload.detailsMiscField4Error,
            }
        case processCriteriaType.GET_EDIT_PROCESS_DETAILS_RULE:
            return {
                ...state,
                loading: true,
                // detailGeneratedId: payload.generatedId,

                // detailsPosition: payload.position,
                // detailsCriteria: payload.criteria,
                // detailsTag: payload.tag,
            }
        case processCriteriaType.GET_TRANSACTION_RECORD:
            if (payload != null) {
                return {
                    ...state,
                    // buttonEnable: false,
                    openRulePanel: true,
                    // ruleName: payload.ruleName,
                    // ruleGeneratedId: payload.generatedID,
                    // descriptionRule: payload.description,
                    // ruleTagName: payload.tagName,
                    // operator: payload.operator,
                    // tagValue: payload.tagValue,
                    // ruleStatus: payload.status,
                }
            } else {
                return {
                    ...state,
                    // buttonEnable: true,
                    // allRuleEmpty: '',
                    // ruleGeneratedId: '',
                    // descriptionRule: '',
                    // ruleTagName: '',
                    // operator: '',
                    // tagValue: '',
                    // ruleStatus: '',
                    // // emptyRule: payload
                    // openRulePanel: false,
                }
            }
        case processCriteriaType.REQUEST_NEW_DETAILS_CRITERIA:
            return {
                ...state,
                loading: true,
                postNewdetailsProcess: payload,
                detailsPopup: false,
                // detailsPopup: false,
                buttonEnable: true,
                setLoading:true
            }
        case processCriteriaType.RULE_BUTTON_ACTION:
            return {
                ...state,
                openRulePanel: payload,
                ruleStatus: 'ACTIVE',
                ruleGeneratedId: '0',
            }
        case processCriteriaType.RESPONSE_SAVE_DETAILS_CRITERIA:
            return {
                ...state,
                loading: false,
                detailsPopup: false,
                detaildisable: true,
            }
        case processCriteriaType.DELETE_RULEPROCESS_REQUEST:
            return {
                ...state,
                loading: true,
                confirmPopupDeleteRule: true,
            }
        case processCriteriaType.DELETE_RULEPROCESS_RESPONSE:
            return {
                ...state,
                loading: false,
                detailTableOpen: true,
            }
        case processCriteriaType.VALIDATION_PROCESS_CRITERIA:
            return {
                ...state,
                processCriteriaError: payload.processCriteriaError,
                processCriteriaNameError: payload.processCriteriaNameError,
                processIdError: payload.processIdError,
                processTypeError: payload.processTypeError,
                statusError: payload.statusError,
                miscField4Error: payload.miscField4Error,
            }
        case processCriteriaType.REQUEST_DELETE_RULE_TRANSACTION:
            return {
                ...state,
                loading: true,
            }
        case processCriteriaType.RESPONSE_DELETE_RULE_TRANSACTION:
            return {
                ...state,
                loading: false,
                ruleName: '',
                descriptionRule: '',
                ruleTagName: '',
                operator: 'IS_EQUAL_TO',
                tagValue: '',
                ruleStatus: 'Active',
                openRulePanel: false,
            }
        case processCriteriaType.INFO_DETAIL_DATA:
            // console.log('INFO_DETAIL_DATA', payload)
            return {
                ...state,
                criteriaDetailsData: payload.allCriteriaDetail,
                ruleDetailsData: payload.allCriteriaDetail.transactionRule,
            }
        case processCriteriaType.GET_RULE_AND_DETAILS_EDIT:
            //  console.log('ROWDATA_REDUCER', payload)

            return {
                ...state,
                loading: true,
                ruleGeneratedId: payload.ruleGeneratedId,
                detailGeneratedId: payload.detailGeneratedId,
                detailsTag: payload.tag,
                detailsTagError: '',
                detailsPosition: payload.position,
                ruleNameError: '',
                tagValueRuleError: '',
                setLoading:false
            }
        case processCriteriaType.DISPLAY_DETAILS_AND_RULE:
            //  console.log('data from API', payload)
            if (payload.transactionRule != null) {
                return {
                    ...state,
                    loading: false,
                    openRulePanel: true,
                    ruleAndDetails: payload,
                    detailsTagError: '',
                    processCriteria: payload.processCriteriaName,
                    detailsTag: payload.tag,
                    detailsPosition: payload.position,
                    detailsMiscField1:
                        payload.miscField1 != null ? payload.miscField1 : '',
                    detailsMiscField2:
                        payload.miscField2 != null ? payload.miscField2 : '',
                    detailsMiscField3:
                        payload.miscField3 != null ? payload.miscField3 : '',
                    detailsMiscField4:
                        payload.miscField4 != null ? payload.miscField4 : '',
                    detailsMiscFlag1: payload.miscFlag1,
                    detailsMiscFlag2: payload.miscFlag2,
                    detailsMiscFlag3: payload.miscFlag3,
                    detailsMiscFlag4: payload.miscFlag4,
                    detailsMiscFlag5: payload.miscFlag5,
                    ruleGeneratedId: payload.transactionRule.id,
                    descriptionRule: payload.transactionRule.description,
                    ruleTagName: payload.transactionRule.tagName,
                    operator: payload.transactionRule.operator,
                    tagValue: payload.transactionRule.tagValue,
                    ruleStatus: payload.transactionRule.status,
                    ruleName: payload.transactionRule.ruleName,
                    ruleMiscField1:
                        payload.transactionRule.miscField1 != null
                            ? payload.transactionRule.miscField1
                            : '',
                    ruleMiscField2:
                        payload.transactionRule.miscField2 != null
                            ? payload.transactionRule.miscField2
                            : '',
                    ruleMiscField3:
                        payload.transactionRule.miscField3 != null
                            ? payload.miscField3
                            : '',
                    ruleMiscField4:
                        payload.transactionRule.miscField4 != null
                            ? payload.miscField4
                            : '',
                    ruleMiscFlag1: payload.transactionRule.miscFlag1,
                    ruleMiscFlag2: payload.transactionRule.miscFlag2,
                    ruleMiscFlag3: payload.transactionRule.miscFlag3,
                    ruleMiscFlag4: payload.transactionRule.miscFlag4,
                    ruleMiscFlag5: payload.transactionRule.miscFlag5,
                }
            } else {
                return {
                    ...state,
                    loading: false,
                    ruleAndDetails: payload,
                    processCriteria: payload.processCriteriaName,
                    detailsTag: payload.tag,
                    detailsPosition: payload.position,
                    detailsMiscField1:
                        payload.miscField1 != null ? payload.miscField1 : '',
                    detailsMiscField2:
                        payload.miscField2 != null ? payload.miscField2 : '',
                    detailsMiscField3:
                        payload.miscField3 != null ? payload.miscField3 : '',
                    detailsMiscField4:
                        payload.miscField4 != null ? payload.miscField4 : '',
                    detailsMiscFlag1: payload.miscFlag1,
                    detailsMiscFlag2: payload.miscFlag2,
                    detailsMiscFlag3: payload.miscFlag3,
                    detailsMiscFlag4: payload.miscFlag4,
                    detailsMiscFlag5: payload.miscFlag5,
                    ruleGeneratedId: '',
                    descriptionRule: '',
                    ruleTagName: payload.tag,
                    operator: 'IS_EQUAL_TO',
                    tagValue: '',
                    ruleStatus: 'ACTIVE',
                    ruleName: '',
                    ruleMiscField4: '',
                    ruleMiscField1: '',
                    ruleMiscField2: '',
                    ruleMiscField3: '',
                    ruleMiscFlag1: '',
                    ruleMiscFlag2: '',
                    ruleMiscFlag3: '',
                    ruleMiscFlag4: '',
                    ruleMiscFlag5: '',

                    // emptyRule: payload
                    openRulePanel: false,
                }
            }
        default: {
            return {
                ...state,
            }
        }
    }
}

export default ProcessCriteriaReducers
