import { reportUserDetails } from '../../constant/ActionTypes'
import { reportInnerTableDetails } from '../../constant/ActionTypes'
import {
    GET_ALL_TABLE_REPORT,
    REPORT_INPUT_CHANGE,
    GET_REPORT_REQUEST,
} from '../../constant/ErrorConstant'
import 'react-notifications/lib/notifications.css'
import { NotificationManager, NotificationContainer } from 'react-notifications'
import { generalFunction } from 'app/constant/Common'
export const reportUserConstant = {
    getAllReportList,
    reportInputChange,
    executionRecordDelete,
    closePopUp,
    emptyDialogContent,
    reportNameInputChange,
    reportChangeTimeDuration,
    processDurationHourChange,
    newDialogOpenReport,
    checkedValue,
    postNewReportData,
    getEditDialogContent,
    postEditedReportData,
    getReportAllData,
    openNewReportMasterPopup,
    closeReportPopup,
    getPaginationInitialReport,
    saveNewReport,
    menuReportTabPopUp,
    getReportMasterById,
    saveEditedReportData,
    deleteReportById,
    getServerById,
    makeInitialReportEmpty,
    changeStyleComponent,
    getReportTableDesign,
    makeEditReportEmpty,
    updateDefaultDesignSettings,
    getDefaultSettings,
    passAllColumnValue,
    passOnChangeDragValue,
    passSequenceStatus,
    postNewDesignSettings,
    passDesignValues,
    columSequencyInputChange,
    openTablePopup,
    closeTablePopup,
    // tableColumnValues,
    //innerTable SKU
    passSkuValues,
    tableValues,
    secondaryTableData,
    defaultTableValues,
    openSecondPopup,
    dragChildValues,
    passChildSequenceStatus,
    columChildStatusUpdate,
    columDonutInputChange,
    deleteDefaultDesignAndColumns,
    inputChange,
    getModuleID,
    selectOneCheck,
    selectOneCheckOutput,
    openJsonPopup,
    duplicateReportById,
    reportEndTimeInputChange,
    reportTimeInputChange,
    getUniqueGroups,
    timesOpenPopup,
    closeDailyPopup,
    getGroupListAndUser,
    getUserListByGrp,
    getUserList,
    saveUserAndGrpListRqst,
    deleteGrpUserRequest,
    openJsonPopupNew,
}
function openJsonPopupNew(props) {
    return {
        type: reportUserDetails.OPEN_JSON_EDITOR_NEW,
        payload: props,
    }
}
function deleteGrpUserRequest(props) {
    return {
        type: reportUserDetails.DELETE_GRP_USER_REQUEST,
        payload: props,
    }
}
function saveUserAndGrpListRqst(props) {
    return {
        type: reportUserDetails.SAVE_USER_AND_GRP_REQUEST,
        payload: props,
    }
}
function getUserList(props) {
    return {
        type: reportUserDetails.GET_USER_LIST,
        payload: props,
    }
}
function getUserListByGrp(props) {
    return {
        type: reportUserDetails.GET_USER_LIST_BY_GRP,
        payload: props,
    }
}
function getGroupListAndUser(props) {
    return {
        type: reportUserDetails.GET_GROUP_LIST_USER,
        payload: props,
    }
}
function getUniqueGroups(props) {
    return {
        type: reportUserDetails.GET_UNIQUE_GRP,
        payload: props,
    }
}
function reportEndTimeInputChange(props) {
    return {
        type: reportUserDetails.REPORT_TIMEEND_INPUT_CHANGE,
        payload: props,
    }
}
function reportTimeInputChange(props) {
    return {
        type: reportUserDetails.REPORT_TIME_INPUT_CHANGE,
        payload: props,
    }
}
function timesOpenPopup(props) {
    return {
        type: reportUserDetails.TIMES_OPEN_POPUP,
        payload: props,
    }
}
function closeDailyPopup(props) {
    return {
        type: reportUserDetails.CLOSE_DAILY_POPUP,
        payload: props,
    }
}
function duplicateReportById(props) {
    return {
        type: reportUserDetails.DUPLICATE_REPORT_BY_ID,
        payload: props,
    }
}
function openJsonPopup(props) {
    return {
        type: reportUserDetails.OPEN_JSON_POPUP,
        payload: props,
    }
}
function selectOneCheckOutput(props) {
    return {
        type: reportUserDetails.SELECT_ONE_CHECK_OUTPUT,
        payload: props,
    }
}
function selectOneCheck(props) {
    return {
        type: reportUserDetails.SELECT_ONE_CHECK,
        payload: props,
    }
}
function deleteDefaultDesignAndColumns(props) {
    return {
        type: reportUserDetails.DELETE_REQUEST_DEFAULT_SETTINGS,
        payload: props,
    }
}
function columDonutInputChange(props) {
    return {
        type: reportUserDetails.COLUMN_DONUT_INPUT_CHANGE,
        payload: props,
    }
}
function columSequencyInputChange(props) {
    return {
        type: reportUserDetails.COLUMN_SEQUENCES_INPUT_CHANGE,
        payload: props,
    }
}

function columChildStatusUpdate(props) {
    return {
        type: reportUserDetails.COLUMN_SEQUENCES_CHILD_STATUS_CHANGE,
        payload: props,
    }
}

function executionRecordDelete(props) {
    return { type: reportUserDetails.EXECUTION_DELETE_REQUEST, payload: props }
}
function getAllReportList(props) {
    return {
        type: reportUserDetails.GET_REPORT_REQUEST,
        payload: props,
    }
}

function reportInputChange(props) {
    return {
        type: reportUserDetails.REPORT_INPUT_CHANGE,
        payload: props,
    }
}
function closePopUp() {
    return {
        type: reportUserDetails.DIALOG_POPUP_CLOSE,
        payload: {},
    }
}
function emptyDialogContent() {
    return {
        type: reportUserDetails.EMPTY_INITIAL_DIALOG_CONTENT,
        payload: {},
    }
}
function reportNameInputChange(props) {
    return {
        type: reportUserDetails.REPORT_INPUT_CHANGE,
        payload: props,
    }
}
function reportChangeTimeDuration(props) {
    return {
        type: reportUserDetails.CHANGE_TIME_DURATION,
        payload: props,
    }
}
function processDurationHourChange(props) {
    return {
        type: reportUserDetails.INPUT_CHANGE_TIME,
        payload: props,
    }
}
function newDialogOpenReport(props) {
    return {
        type: reportUserDetails.ADD_OPEN_NEWPOPUP,
        payload: props,
    }
}
function checkedValue(props) {
    return {
        type: reportUserDetails.CHECKED_VALUE_BOOLEAN,
        payload: props,
    }
}
function postNewReportData(props) {
    //console.log('validation', props.execution, props.server)
    // console.log('compare',props.processDurationHours < props.processDurationHoursEnd)
    let reportNameError = '',
        serverError = '',
        executionError = '',
        intervalTimeError = '',
        processDurationError = '',
        processDurationEndError = '',
        descriptionError = ''
    // processDurationValidationError=''
    if (!props.reportName) {
        reportNameError = 'Enter the Report name'
    }
    if (!props.server) {
        serverError = 'Enter the value'
    }
    if (props.execution == '') {
        executionError = 'Select the value'
    }
    if (!props.intervalTime) {
        intervalTimeError = 'Enter the value'
    }
    if (!props.description) {
        descriptionError = 'Enter the description'
    }
    if (
        !props.processDurationHours ||
        !props.processDurationMinute ||
        !props.processDurationSecond ||
        !props.processDurationHoursEnd ||
        !props.processDurationMinuteEnd ||
        !props.processDurationSecondEnd
    ) {
        processDurationError = 'Select the valid filed'
    }
    let processDur =
        parseInt(props.processDurationHours) * 60 * 60 +
        parseInt(props.processDurationMinute) * 60 +
        parseInt(props.processDurationSecond)
    if (processDur == 0) {
        return {
            type: reportUserDetails.VALIDATION_ERROR,
            payload: {
                reportNameError,
                executionError,
                serverError,
                intervalTimeError,
                processDurationError: 'Select Valid duration',
                processDurationEndError,
            },
        }
    }
    let processDurEnd =
        parseInt(props.processDurationHoursEnd) * 60 * 60 +
        parseInt(props.processDurationMinuteEnd) * 60 +
        parseInt(props.processDurationSecondEnd)
    //console.log('duration', processDurEnd > processDur)
    if (processDurEnd < processDur) {
        //console.log('duration', processDurEnd > processDur)
        return {
            type: reportUserDetails.VALIDATION_ERROR,
            payload: {
                reportNameError,
                executionError,
                serverError,
                intervalTimeError,
                processDurationError,
                processDurationEndError: 'Select Valid duration',
            },
        }
    }
    if (
        reportNameError ||
        executionError ||
        serverError ||
        intervalTimeError ||
        processDurationError ||
        processDurationEndError
    ) {
        return {
            type: reportUserDetails.VALIDATION_ERROR,
            payload: {
                reportNameError,
                executionError,
                serverError,
                intervalTimeError,
                processDurationError,
                processDurationEndError,
                processDurationError,
            },
        }
    } else {
        return {
            type: reportUserDetails.REPORT_NEW_SAVE_SUBMIT,
            payload: props,
        }
    }
}
function getEditDialogContent(props) {
    return {
        type: reportUserDetails.GET_DIALOG_EDIT_DATA,
        payload: props,
    }
}
function postEditedReportData(props) {
    let reportNameError = '',
        serverError = '',
        executionError = '',
        intervalTimeError = '',
        processDurationError = '',
        processDurationEndError = ''
    // processDurationValidationError=''
    if (!props.reportName) {
        reportNameError = 'Enter the Report name'
    }
    if (!props.server) {
        serverError = 'Enter the value'
    }
    if (props.execution == '') {
        executionError = 'Select the value'
    }
    if (!props.intervalTime) {
        intervalTimeError = 'Enter the value'
    }
    if (
        !props.processDurationHours ||
        !props.processDurationMinute ||
        !props.processDurationSecond ||
        !props.processDurationHoursEnd ||
        !props.processDurationMinuteEnd ||
        !props.processDurationSecondEnd
    ) {
        processDurationError = 'Select the valid filed'
    }
    let processDur =
        parseInt(props.processDurationHours) * 60 * 60 +
        parseInt(props.processDurationMinute) * 60 +
        parseInt(props.processDurationSecond)
    if (processDur == 0) {
        return {
            type: reportUserDetails.VALIDATION_ERROR,
            payload: {
                reportNameError,
                executionError,
                serverError,
                intervalTimeError,
                processDurationError: 'Select Valid duration',
                processDurationEndError,
            },
        }
    }
    let processDurEnd =
        parseInt(props.processDurationHoursEnd) * 60 * 60 +
        parseInt(props.processDurationMinuteEnd) * 60 +
        parseInt(props.processDurationSecondEnd)
    //console.log('duration', processDurEnd > processDur)
    if (processDurEnd < processDur) {
        //console.log('duration', processDurEnd > processDur)
        return {
            type: reportUserDetails.VALIDATION_ERROR,
            payload: {
                reportNameError,
                executionError,
                serverError,
                intervalTimeError,
                processDurationError,
                processDurationEndError: 'Select Valid duration',
            },
        }
    }
    if (
        reportNameError ||
        executionError ||
        serverError ||
        intervalTimeError ||
        processDurationError ||
        processDurationEndError
    ) {
        return {
            type: reportUserDetails.VALIDATION_ERROR,
            payload: {
                reportNameError,
                executionError,
                serverError,
                intervalTimeError,
                processDurationError,
                processDurationEndError,
                processDurationError,
            },
        }
    } else {
        return {
            type: reportUserDetails.POST_EDIT_REPORT,
            payload: props,
        }
    }
}
function getReportAllData(props) {
    return {
        type: reportUserDetails.GET_EDITED_REPORTDATA,
        payload: props,
    }
}
function openNewReportMasterPopup(props) {
    return {
        type: reportUserDetails.OPEN_NEW_REPORT_POPUP,
        payload: props,
    }
}
function closeReportPopup(props) {
    return {
        type: reportUserDetails.CLOSE_REPORT_POPUP,
        payload: props,
    }
}
function getPaginationInitialReport(props) {
    return {
        type: reportUserDetails.DISPLAY_EDITED_REPORT,
        payload: props,
    }
}
function saveNewReport(props) {
    let reportNameError = '',
        startTimeError = '',
        executionError = '',
        endTimeError = '',
        parametersError = '',
        serverError = '',
        transactionNameError = '',
        descriptionError = '',
        moduleIdError = '',
        toEmailError = '',
        ccEmailError = '',
        suffixEmailError = '',
        subjectEmailError = '',
        pathFolderError = '',
        suffixFolderError = '',
        ipFtpError = '',
        ftpPortError = '',
        folderPathFtpError = '',
        userNameFtpError = '',
        passwordFtpError = '',
        suffixFtpError = '',
        validityError = '',
        outputTableNameError = '',
        componentError = '',
        intervalTimeError = ''

    if (!props.reportName) {
        reportNameError = 'Enter Report Name'
    }

    if (!props.startTime) {
        startTimeError = 'Enter Start Time'
    }
    let date = props.startDate == '' ? new Date() : props.startDate
    let millisecondsStart = date.getTime()

    let Enddate = props.endDate == '' ? new Date() : props.endDate
    let millisecondsEnd = Enddate.getTime()

    if (millisecondsStart > millisecondsEnd) {
        endTimeError = 'End time is always greater then start time'
    }

    if (!props.execution) {
        executionError = 'Enter Execution Time'
    }
    if (!props.description) {
        descriptionError = 'Enter the description'
    }
    if (props.destination == 'email') {
        if (!props.toEmail) {
            toEmailError = 'Enter the email'
        } else if (!generalFunction.validateEmailFormat(props.toEmail)) {
            toEmailError = 'Enter the valid email'
        }

        if (props.ccEmail) {
            if (!generalFunction.validateEmailFormat(props.ccEmail)) {
                ccEmailError = 'Enter the valid email'
            }
        }
    }
    if (props.destination == 'email') {
        if (!props.subjectEmail) {
            subjectEmailError = 'Enter the subject'
        }
    }
    if (props.destination == 'email' && props.output == 'image') {
        if (!props.suffixEmail) {
            suffixEmailError = 'Choose the suffix'
        }
    }
    if (props.destination == 'folder') {
        if (!props.pathFolder) {
            pathFolderError = 'Enter the path'
        }
    }
    if (props.destination == 'folder' && props.output == 'image') {
        if (!props.suffixFolder) {
            suffixFolderError = 'Choose the suffix'
        }
    }
    if (props.destination == 'ftp') {
        if (!props.ipFtp) {
            ipFtpError = 'Enter the IP'
        }
    }
    if (props.destination == 'ftp') {
        if (!props.ftpPort) {
            ftpPortError = 'Enter the port'
        }
    }
    if (props.destination == 'ftp') {
        if (!props.folderPathFtp) {
            folderPathFtpError = 'Enter the path'
        }
    }
    if (props.destination == 'ftp') {
        if (!props.userNameFtp) {
            userNameFtpError = 'Enter the username'
        }
    }
    if (props.destination == 'ftp') {
        if (!props.passwordFtp) {
            passwordFtpError = 'Enter the password'
        }
    }
    if (props.destination == 'ftp' && props.output == 'image') {
        if (!props.suffixFtp) {
            suffixFtpError = 'Select the suffix'
        }
    }

    if (
        (!props.parameters && props.execution == 'COMMAND') ||
        (!props.parameters && props.execution == 'SQL')
    ) {
        parametersError = "parameters field can't empty "
    }
    if (!props.server) {
        serverError = 'Enter Server Link'
    }
    if (!props.transactionName) {
        transactionNameError = 'Enter Transaction Name'
    }
    if (!props.moduleId) {
        moduleIdError = 'Select Module Id'
    }
    if (props.interactive == false) {
        if (!props.outputTableName) {
            outputTableNameError = 'Enter output table name'
        }
    }
    if (props.interactive == false) {
        if (!props.validity) {
            validityError = 'Enter the validity'
        }
    }
    if (props.component) {
        if (!props.component) {
            componentError = 'Select Display Type'
        }
    }
    if (!props.intervalTime) {
        intervalTimeError = 'Enter Interval Time eg:(00:00:00)'
    } else if (!generalFunction.validateTimeFormat(props.intervalTime)) {
        intervalTimeError = 'Invalid Interval Time format eg:(00:00:00)'
    }

    if (
        reportNameError ||
        startTimeError ||
        executionError ||
        parametersError ||
        serverError ||
        transactionNameError ||
        descriptionError ||
        moduleIdError ||
        toEmailError ||
        endTimeError ||
        ccEmailError ||
        suffixEmailError ||
        subjectEmailError ||
        pathFolderError ||
        suffixFolderError ||
        ipFtpError ||
        ftpPortError ||
        folderPathFtpError ||
        userNameFtpError ||
        passwordFtpError ||
        suffixFtpError ||
        validityError ||
        outputTableNameError ||
        componentError ||
        intervalTimeError
    ) {
        return {
            type: reportUserDetails.VALIDATION_REPORT_NEW_CRITERIA,
            payload: {
                reportNameError,
                startTimeError,
                executionError,
                endTimeError,
                serverError,
                parametersError,
                transactionNameError,
                descriptionError,
                moduleIdError,
                toEmailError,
                ccEmailError,
                suffixEmailError,
                subjectEmailError,
                pathFolderError,
                suffixFolderError,
                ipFtpError,
                ftpPortError,
                folderPathFtpError,
                userNameFtpError,
                passwordFtpError,
                suffixFtpError,
                validityError,
                outputTableNameError,
                componentError,
                intervalTimeError,
            },
        }
    } else {
        return {
            type: reportUserDetails.SAVE_NEW_REPORT_REQUEST,
            payload: props,
        }
    }
}
function menuReportTabPopUp(props) {
    return {
        type: reportUserDetails.MENU_EDIT_REPORT_POPUP,
        payload: props,
    }
}
function getReportMasterById(props) {
    return {
        type: reportUserDetails.GET_REPORT_MASTER_BYID,
        payload: props,
    }
}
function saveEditedReportData(props) {
    let reportNameError = '',
        intervalTimeError = '',
        startTimeError = '',
        endTimeError = '',
        executionError = '',
        parametersError = '',
        serverError = '',
        transactionNameError = '',
        descriptionError = '',
        moduleIdError = '',
        toEmailError = '',
        destination = '',
        // ccEmailError = '',
        suffixEmailError = '',
        subjectEmailError = '',
        pathFolderError = '',
        suffixFolderError = '',
        ipFtpError = '',
        ftpPortError = '',
        folderPathFtpError = '',
        userNameFtpError = '',
        passwordFtpError = '',
        suffixFtpError = '',
        outputTableNameError = '',
        validityError = '',
        ccEmailError = '',
    componentError = ''

    if (!props.reportName) {
        reportNameError = 'Enter Report Name'
    }
    if (props.component) {
        if (!props.component) {
            componentError = 'Select Display Type'
        }
    }
    if (!props.intervalTime) {
        intervalTimeError = 'Enter Interval Time eg:(00:00:00)'
    } else if (!generalFunction.validateTimeFormat(props.intervalTime)) {
        intervalTimeError = 'Invalid Interval Time format eg:(00:00:00)'
    }
    // var processSpliter = props.intervalTime.split(':')
    // let processDurationHours = processSpliter[0]
    // let processDurationMinute = processSpliter[1]
    // let processDurationSecond = processSpliter[2]
    // if(props.intervalTime){
    //     console.log(
    //         'intervalTime',
    //         processDurationHours,props.intervalTime
    //     )
    //     if(processDurationHours > 24){
    //         intervalTimeError='Hour is not greater the 24'
    //     }
    //     if(processDurationMinute > 60){
    //         intervalTimeError='Minutes is not greater the 60'
    //     }
    //     if(processDurationSecond > 60){
    //         intervalTimeError='Seconds is not greater the 60'
    //     }
    // }

    if (!props.startTime) {
        startTimeError = 'Enter Start Time'
    }
    let dateEdit =
        props.startDate == '' ? new Date(props.startTime) : props.startDate
    let millisecondsEditStart = dateEdit.getTime()

    let EndDateEdit =
        props.endDate == '' ? new Date(props.endTime) : props.endDate
    let millisecondsEditEnd = EndDateEdit.getTime()
    if (millisecondsEditStart > millisecondsEditEnd) {
        endTimeError = 'End time is always greater then start time'
    }
    if (!props.description) {
        descriptionError = 'Enter the description'
    }
    if (props.destination == 'email') {
        if (!props.toEmail) {
            toEmailError = 'Enter the email'
        } else if (!generalFunction.validateEmailFormat(props.toEmail)) {
            toEmailError = 'Enter the valid email'
        }
        if (props.ccEmail) {
            if (!generalFunction.validateEmailFormat(props.ccEmail)) {
                ccEmailError = 'Enter the valid email'
            }
        }
    }
    if (props.destination == 'email') {
        if (!props.subjectEmail) {
            subjectEmailError = 'Enter the subject'
        }
    }
    if (props.destination == 'email' && props.output == 'image') {
        if (!props.suffixEmail) {
            suffixEmailError = 'Enter the suffix'
        }
    }
    if (props.destination == 'folder') {
        if (!props.pathFolder) {
            pathFolderError = 'Enter the path'
        }
    }
    if (props.destination == 'folder' && props.output == 'image') {
        if (!props.suffixFolder) {
            suffixFolderError = 'Choose the suffix'
        }
    }
    if (props.destination == 'ftp') {
        if (!props.ipFtp) {
            ipFtpError = 'Enter the IP'
        }
    }
    if (props.destination == 'ftp') {
        if (!props.ftpPort) {
            ftpPortError = 'Enter the port'
        }
    }
    if (props.destination == 'ftp') {
        if (!props.folderPathFtp) {
            folderPathFtpError = 'Enter the path'
        }
    }
    if (props.destination == 'ftp') {
        if (!props.userNameFtp) {
            userNameFtpError = 'Enter the username'
        }
    }
    if (props.destination == 'ftp') {
        if (!props.passwordFtp) {
            passwordFtpError = 'Enter the password'
        }
    }
    if (props.destination == 'ftp' && props.output == 'image') {
        if (!props.suffixFtp) {
            suffixFtpError = 'Select the suffix'
        }
    }

    if (
        (!props.parameters && props.execution == 'COMMAND') ||
        (!props.parameters && props.execution == 'SQL')
    ) {
        parametersError = 'parameters field cannot be empty'
    }
    if (!props.execution) {
        executionError = 'Enter Execution Time'
    }
    if (!props.server) {
        serverError = 'Enter Server Link'
    }
    if (!props.transactionName) {
        transactionNameError = 'Enter Transaction Name'
    }
    if (!props.moduleId) {
        moduleIdError = 'Select Module Id'
    }
    if (props.interactive == false) {
        if (!props.outputTableName) {
            outputTableNameError = 'Enter output table name'
        }
    }
    if (props.interactive == false) {
        if (!props.validity) {
            validityError = 'Enter the validity'
        }
    }

    if (
        reportNameError ||
        intervalTimeError ||
        startTimeError ||
        endTimeError ||
        descriptionError ||
        parametersError ||
        executionError ||
        serverError ||
        transactionNameError ||
        moduleIdError ||
        toEmailError ||
        ccEmailError ||
        suffixEmailError ||
        subjectEmailError ||
        pathFolderError ||
        suffixFolderError ||
        ipFtpError ||
        ftpPortError ||
        folderPathFtpError ||
        userNameFtpError ||
        passwordFtpError ||
        suffixFtpError ||
        outputTableNameError ||
        validityError ||
        componentError
    ) {
        return {
            type: reportUserDetails.VALIDATION_REPORT_CRITERIA,
            payload: {
                reportNameError,
                intervalTimeError,
                startTimeError,
                parametersError,
                descriptionError,
                endTimeError,
                executionError,
                transactionNameError,
                serverError,
                moduleIdError,
                toEmailError,
                ccEmailError,
                suffixEmailError,
                subjectEmailError,
                pathFolderError,
                suffixFolderError,
                ipFtpError,
                ftpPortError,
                folderPathFtpError,
                userNameFtpError,
                passwordFtpError,
                suffixFtpError,
                outputTableNameError,
                validityError,
            },
        }
    } else {
        return {
            type: reportUserDetails.REQUEST_SAVE_EDITED_REPORT,
            payload: props,
        }
    }
}
function deleteReportById(props) {
    return {
        type: reportUserDetails.REQUEST_DELETE_REPORT_BYID,
        payload: props,
    }
}
function getServerById(props) {
    return {
        type: reportUserDetails.GET_SERVER_BYID,
        payload: props,
    }
}
function makeInitialReportEmpty(props) {
    return {
        type: reportUserDetails.MAKE_INITIAL_REPORT_EMPTY,
        payload: props,
    }
}
function changeStyleComponent(props) {
    return {
        type: reportUserDetails.CHANGE_STYLE_COMPONENT,
        payload: props,
    }
}
function getReportTableDesign(props) {
    return {
        type: reportUserDetails.GET_REPORT_TABLE_DESIGN,
        payload: props,
    }
}
function makeEditReportEmpty(props) {
    return {
        type: reportUserDetails.EDIT_DETAILS_REPORT_EMPTY,
        payload: props,
    }
}
function updateDefaultDesignSettings(props) {
    return {
        type: reportUserDetails.UPDATE_DEFAULT_SETTINGS,
        payload: props,
    }
}
function getDefaultSettings(props) {
    return {
        type: reportUserDetails.GET_DEFAULT_SETTINGS,
        payload: props,
    }
}
function passAllColumnValue(props) {
    return {
        type: reportUserDetails.PASS_ALLCOLUMN_VALUE,
        payload: props,
    }
}
function passOnChangeDragValue(props) {
    return {
        type: reportUserDetails.PASS_ONCHANGE_DRAG_VALUE,
        payload: props,
    }
}
function passChildSequenceStatus(props) {
    return {
        type: reportUserDetails.PASS_CHILD_SEQUENCE_STATUS,
        payload: props,
    }
}
function passSequenceStatus(props) {
    return {
        type: reportUserDetails.PASS_SEQUENCE_STATUS,
        payload: props,
    }
}
function postNewDesignSettings(props) {
    return {
        type: reportUserDetails.REQUEST_NEW_DESIGN_COLUMNS,
        payload: props,
    }
}
function passDesignValues(props) {
    return {
        type: reportUserDetails.PASS_DESIGN_VALUES,
        payload: props,
    }
}
function openTablePopup(props) {
    return {
        type: reportUserDetails.OPEN_TABLE_POPUP,
        payload: props,
    }
}
function closeTablePopup(props) {
    return {
        type: reportUserDetails.CLOSE_TABLE_POPUP,
        payload: props,
    }
}
// function tableColumnValues(props) {
//     return {
//         type: reportUserDetails.TABLE_COLUMN_VALUES,
//         payload: props,
//     }
// }
//inner table SKU data
function passSkuValues(props) {
    return {
        type: reportUserDetails.GET_INNERTABLE_DATA,
        payload: props,
    }
}
function tableValues(props) {
    return {
        type: reportUserDetails.TABLE_COLUMN_VALUES,
        payload: props,
    }
}
function secondaryTableData(props) {
    return {
        type: reportUserDetails.SECONDARY_TABLE_DATA,
        payload: props,
    }
}
function defaultTableValues(props) {
    return {
        type: reportUserDetails.DESIGN_TABLE_VALUES,
        payload: props,
    }
}
function openSecondPopup(props) {
    return {
        type: reportUserDetails.OPEN_SECOND_POPUP,
        payload: props,
    }
}
function dragChildValues(props) {
    return {
        type: reportUserDetails.PASS_CHILD_DRAG_VALUES,
        payload: props,
    }
}
function inputChange(props) {
    return {
        type: reportUserDetails.INPUT_CHANGE,
        payload: props,
    }
}
function getModuleID(props) {
    return {
        type: reportUserDetails.GET_MODULE_ID,
        payload: props,
    }
}
