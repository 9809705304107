import { all, call, put, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { NotificationManager } from 'react-notifications'
import Moment from 'moment'
import { ConfirmConstant } from '../../constant/ActionTypes'
import {
    headers,
    UPDATE_BASE_URL,
    CONFORM_CODE_API
} from '../../constant/Common'

function changeDateFormat(dates) {
    const newDate = new Date(dates)
    return Moment(newDate).format('YYYY-MM-DD')
}

//   ======================================================Save Confirm Code ====================================
function* saveUserConfirmCode(payload) {
    var props =payload.payload
    try {
        var req = JSON.stringify({username: props.confirmUsername
            ,confirmationCode:props.confirmCode})

            let headers = {
                'Content-Type': 'application/json;charset=UTF-8',
                Accept: 'application/json',
            }
        var res = yield axios
            .request({
                method: 'post',
                url: UPDATE_BASE_URL + CONFORM_CODE_API,
                headers: headers,
                data: req,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        if (res.status === 200) {
        if(res.data.code == -1){
            NotificationManager.error(res.data.message)
            yield put({
                type: ConfirmConstant.CONFIRM_ERROR,
                payload: {},
            })
        }else if(res.data.code == 0){
            NotificationManager.success(res.data.message)
            yield put({
                type: ConfirmConstant.CONFIRM_SUCCESS,
                payload: {},
            })
            
        }
        else{

        }
        } else {
            NotificationManager.error('Check Your Connection')
        }
    } catch (error) {
        console.log(error)
    }
}

function* saveConfirm() {
    yield takeEvery(
        ConfirmConstant.SAVE_CONFIRM_CODE,
        saveUserConfirmCode
    )
}

export default function* rootSaga() {
    yield all([
        saveConfirm()
    ])
}
