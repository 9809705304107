import React, { useEffect, useState } from 'react'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Chip from '@mui/material/Chip'
import { useSelector, useDispatch } from 'react-redux'
import { CommonAction } from 'app/redux/actions'
import { reportUserConstant } from 'app/redux/actions/ReportAction'
import { adminUserAction } from '../../redux/actions/AdminUserAction'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    }
}

export default function MultipleSelect() {
    const theme = useTheme()
    const dispatch = useDispatch()
    const [reportName, setReportName] = React.useState([])
    const AdminReducers = useSelector((state) => state.AdminUserReducer)
    const ReportReducers = useSelector((state) => state.ReportReducers)

    useEffect(() => {
        const User1 = JSON.parse(localStorage.getItem('loginDetails'))
        dispatch(CommonAction.getJsonEditedData({ userId: User1.generatedID }))
    }, [])

    // const ReportReducers = useSelector((state) => state.ReportReducers)
    // const AdminReducers = useSelector((state) => state.AdminUserReducer)
    let arrayEdit = []
    let tempEditArray = []
    const handleChange = (event) => {
        const {
            target: { value },
        } = event

        let array = []
        let tempArray = []

        let selectedReport =
            typeof value === 'string' ? value.split(',') : value

        selectedReport &&
            selectedReport.map((val) => {
                ReportReducers.getReportAllMaster &&
                    ReportReducers.getReportAllMaster.map((it) => {
                        if (it.reportName == val) {
                            tempArray.push(it.reportName)
                            array.push({
                                reportId: it.reportId,
                                transactionName: it.transactionName,
                            })
                        }
                    })
            })
      
        dispatch(
            adminUserAction.adminReportMultiChange({
                props: 'reportMasterAllData',
                value: array,
                allSelectedReport: tempArray,
            })
        )
    }
    return (
        <div>
            <FormControl sx={{ width: 240 }}>
                <InputLabel id="demo-multiple-chip-label">
                    Report Master
                </InputLabel>
                <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    value={AdminReducers.allSelectedReport}
                    SelectDisplayProps={(e) => (e)}
                    onChange={(e, key) => handleChange(e, key)}
                    input={
                        <OutlinedInput
                            id="select-multiple-chip"
                            label="Report Name"
                        />
                    }
                    renderValue={(selected) => (
                        <Box
                            sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: 0.5,
                            }}
                        >
                            {selected.map((value) => {
                                console.log(value)
                                return <Chip key={value} label={value} />
                            })}
                        </Box>
                    )}
                    MenuProps={MenuProps}
                >
                    {ReportReducers.getReportAllMaster.map((it) => (
                        <MenuItem
                            key={it.reportId}
                            value={it.reportName}
                            transaction={it.transactionName}
                            id={it.reportId}
                            style={getStyles(it.reportId, it.reportName, theme)}
                        >
                            {it.reportName}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    )
}
