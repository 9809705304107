import { TrainRounded } from '@mui/icons-material'
import { reportUserDetails } from '../../constant/ActionTypes'
import { reportInnerTableDetails } from '../../constant/ActionTypes'
import { generalFunction, changeTimeFormat } from '../../constant/Common'
import _ from 'lodash'
import dayjs from 'dayjs'
import Moment from 'moment'
import { value } from 'app/views/CommonComponents/JsonFile'
var currentdate = new Date()
function changeDateFormat(currentdate) {
    var now = new Date(currentdate)
    return (
        now.getFullYear() +
        '-' +
        (now.getMonth() + 1) +
        '-' +
        now.getDate() +
        ' ' +
        now.getHours() +
        ':' +
        (now.getMinutes() < 10 ? '0' + now.getMinutes() : now.getMinutes()) +
        ':' +
        (now.getSeconds() < 10 ? '0' + now.getSeconds() : now.getSeconds())
    )
}

function changeEndDateFormat(currentdate) {
    var now = new Date(currentdate)
    return (
        now.getFullYear() +
        '-' +
        (now.getMonth() + 1) +
        '-' +
        (now.getDate() + 1) +
        ' ' +
        now.getHours() +
        ':' +
        (now.getMinutes() < 10 ? '0' + now.getMinutes() : now.getMinutes()) +
        ':' +
        (now.getSeconds() < 10 ? '0' + now.getSeconds() : now.getSeconds())
    )
}

const INITIAL_STATE = {
    loader: false,
    timer: new Date(),
    reportId: 0,
    reportName: '',
    execution: [],
    endTime: changeEndDateFormat(currentdate),
    reportNameError: '',
    executionError: '',
    serverError: '',
    description: '',
    descriptionError: '',
    report: '',
    server: '',
    grp: [],
    serverError: '',
    executionReportList: [],
    rowDataEdit: [],
    editPage: false,
    cardDesigns: '',
    uploadPopup: false,
    displayUpdatedData: [],
    processDurationEndError: '',
    menuPopupOpen: false,
    reportName: '',
    jsonOutput: false,
    execution: 'CUSTOM',
    interactive: true,
    parameters: '',
    destination: '',
    parametersError: '',
    server: '',
    reportNameError: '',
    executionError: '',
    interactiveError: '',
    remNumber: Array.apply(null, { length: 20 }).map((_, i) => {
        return { value: i, label: i }
    }),
    allModuleIdArray: [],
    serverError: '',
    startTime: changeDateFormat(currentdate),
    startTimeError: '',
    getReportMaterById: '',
    modifiedBy: JSON.parse(localStorage.getItem('auth_user')),
    intervalTime: '',
    endTimeError: '',
    intervalTimeError: '',
    component: 'table',
    componentError: '',
    designSettingsTable: [],
    designSettingsTableSequence: '',
    tableColumn: [],
    AllColumnValue: [],
    dragValues: '',
    sequence: 0,
    sequenceError: '',
    rowData: '',
    dataChecked: false,
    displayKey: '',
    displayKeyError: '',

    allDesignValues: '',
    displayType: '',
    displayMode: '',
    transactionName: '',
    fontSize: 20,
    fontSizeError: '',
    headerBackgroundColor: 'teal',
    headerFontColor: '#D33B3B',
    headerFontSize: '12',
    highlightColor: '#D33B3B',
    margin: '5',
    numberOfRows: '10',
    rowSelectedBackgroundColor: '#fff',
    tableBackgroundColor: '#D33B3B',
    tableColor: '#D33B3B',
    tableFontFamily: 'verdana',
    tableFontSize: '10',
    tablePaddingBottom: '0rem',
    tablePaddingLeft: '0rem',
    tablePaddingTop: '0rem',
    tablePaddingRight: '0rem',
    tableTitleFontSize: '15',
    tableTitleFontStyle: 'serif',
    textAlign: 'top',
    verticalAlign: 'center',
    barRefreshDuration: '',

    //GraphStyles
    barTitleFontSize: '20',
    donutTitleFontSize: '',
    donutFontFamily: 'arial',
    barFontFamily: 'arial',
    donutRefreshDuration: '',
    donutHeightStyle: '',
    donutWidthStyle: '',
    headerDonutColor: '',
    headerBarColor: '',
    barRefreshDuration: '',
    barWidthStyle: '86',
    barHeightStyle: '343',
    designSettings: '',
    donutPaddingLeft: '',
    donutPaddingRight: '',
    donutPaddingTop: '',
    donutPaddingBottom: '',
    barPaddingLeft: '',
    barPaddingRight: '',
    barPaddingTop: '',
    barPaddingBottom: '',
    gridDonut: '',
    refreshDuration: '10',
    secondaryValues: [],
    tableValues: [],
    openDragSecondTablePopup: false,
    openPopUp: false,
    activeCount: '',

    //card designs
    cardColor: '#EA0303',
    cardColorError: '',
    cardBackgroundColor: '#fcfaf5',
    cardBackgroundColorError: '',
    cardTitleColor: '#EA0303',
    cardTitleColorError: '',
    cardPaddingLeft: '0rem',
    cardPaddingLeftError: '',
    cardPaddingRight: '0rem',
    cardPaddingRightError: '',
    cardPaddingTop: '0rem',
    cardPaddingTopError: '',
    cardPaddingBottom: '0rem',
    cardPaddingBottomError: '',
    cardFontSize: 10,
    cardFontSizeError: '',
    titleFontSize: '15',
    titleFontSizeError: '',
    cardFontFamily: 'arial',
    cardFontFamilyError: '',
    cardTitleFontFamily: 'arial',
    cardTitleFontFamilyError: '',
    cardHeaderFontSize: '15',
    cardHeaderColor: '#EA0303',
    cardHeaderFontFamily: 'arial',
    cardHeaderFontSizeError: '',
    cardHeaderColorError: '',
    cardHeaderFontFamilyError: '',
    gridCount: 12,
    gridCountError: '',
    cardRefreshDuration: '',
    cardRefreshDurationError: '',
    moduleId: '',
    moduleIdError: '',

    processDurationSecond: '00',
    processDurationMinute: '00',
    processDurationHours: '00',

    sev1LowDurationSecond: '00',
    sev1LowDurationMinute: '00',
    sev1StartDurationHour: '00',

    sev1MidDurationSecond: '00',
    sev1MidDurationMinute: '00',
    sev1EndDurationHour: '01',

    processDurationSecondEnd: '00',
    processDurationMinuteEnd: '00',
    processDurationHoursEnd: '00',
    reportMasterAllData: [],
    getReportAllMaster: [],
    openMenuPopup: false,
    lastFocustext: '',
    openDragTablePopup: false,
    displayValue: '',

    pdfOutput: true,
    pdfOutputError: '',
    excelOutput: false,
    excelOutputError: '',
    imagesOutput: false,
    imagesOutputError: '',
    emailDestination: true,
    emailDestinationError: '',
    folderDestination: false,
    folderDestinationError: '',
    ftpDestination: false,
    ftpDestinationError: '',
    toEmail: '',
    toEmailError: '',
    ccEmail: '',
    ccEmailError: '',
    subjectEmail: '',
    subjectEmailError: '',
    pathFolder: '',
    pathFolderError: '',
    prefixFolder: '',
    prefixFolderError: '',
    suffixFolder: '',
    suffixFolderError: '',
    ipFtp: '',
    ipFtpError: '',
    userNameFtp: '',
    userNameFtpError: '',
    passwordFtp: '',
    passwordFtpError: '',
    folderPathFtp: '',
    folderPathFtpError: '',
    prefixFtp: '',
    prefixFtpError: '',
    suffixFtp: '',
    suffixFtpError: '',
    output: '',
    outputError: '',
    outputTableName: '',
    outputTableNameError: '',
    validity: '',
    validityError: '',
    prefixEmail: '',
    prefixEmailError: '',
    suffixEmail: '',
    suffixEmailError: '',
    serverParameters: '',
    destinationParameters: '',
    isInteractive: '',
    interactiveParameters: '',
    allModuleId: [],
    moduleId: '',
    openJson: false,
    suffixImages: '',
    suffixImageError: '',
    ftpPort: '',
    ftpPortError: '',
    duplicate: false,
    startDate: '',
    endDate: '',
    uniqueGroup: [],
    dailyPopup: false,
    schedular: '',
    dayDailyweek: '',
    dayRepeatEvery: '',
    onDate: '',
    onTheDay: '',
    onDateOfMonth: '',
    on: '',
    WeekOfYear: '',
    WeekOfYearError: '',
    monthOfYear: '',
    monthOfYearError: '',
    dayFirstMonth: '',
    dayFirstMonthError: '',
    dayMonthOfFirst: '',
    dayMonthOfFirstError: '',
    dayYear: '',
    dayRepeatYear: '',
    sundayDaily: false,
    mondayDaily: false,
    tuesdayDaily: false,
    wednesdayDaily: false,
    thursdayDaily: false,
    fridayDaily: false,
    saturdayDaily: false,
    dailyTime: new Date().toString(),
    onTheDayYear: 'onDayYear',
    dateDaily: '',
    dayRepeatEvery: '',
    onTheDayMonth: '',
    onDateOfMonth: '',
    dayMonthOfFirst: '',
    dayMonthofWeeks: '',
    dayMonthsOfYear: '',
    onDateOfYear: '',
    dayFirstOfYear: '',
    dayWeekOfYear: '',
    dayMonthOfYear: '',
    bothUserAndGrp: '',
    userListAllss: [],
    defaultSelectGroup: [],
    defaultSelectUser: [],
    userListFromGrp: [],
    parameterJsonView: {},
    parameterJsonViewError: '',
    generateLoading: '0',

    defaultCardWidgetWidth: 2,
    defaultTableWidgetWidth: 8,
    defaultDoughnutWidgetWidth: 3,
    defaultBarWidgetWidth: 4,
    defaultWidgetHeight: 300,
    widgetWidth: 2,
    widgetHeight: 300,
}
export default (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case reportUserDetails.EXECUTION_DELETE_REQUEST:
            return { ...state, loader: true }
        case reportUserDetails.EXECUTION_DELETE_RESPONSE:
            return { ...state, loader: false }
        case reportUserDetails.GET_REPORT_REQUEST:
            return { ...state, executionReportList: [], loader: true }
        case reportUserDetails.GET_REPORT_RESPONSE:
            return { ...state, executionReportList: payload, loader: false }
        case reportUserDetails.REPORT_INPUT_CHANGE:
            return {
                ...state,
                [payload.props]: payload.value,
                [payload.error]: '',
            }

        case reportUserDetails.GET_ALL_TABLE_REPORT:
            return {
                ...state,
                loader: true,
                executionReportList: payload,
            }
        case reportUserDetails.REPORT_USER_ERROR_UNAUTH:
            return {
                ...state,
                loader: false,
                generateLoading: '0',
            }
        case reportUserDetails.REPORT_STOP_LOADING:
            return {
                ...state,
                loader: false,
                generateLoading: '0',
            }
        case reportUserDetails.DISPLAY_REPORT_TABLE_DATA:
            return {
                ...state,
                loader: false,
                reportTabledata: payload,
            }
        case reportUserDetails.CHANGE_TIME_DURATION:
            return {
                ...state,
                [payload.props]: payload.value,
                [payload.error]: '',
            }
        case reportUserDetails.INPUT_CHANGE_TIME:
            // console.log(
            //     'gbfv',
            //     state.sev1StartDurationHour - 1 == state.processDurationHours,
            //     payload.value
            // )
            return {
                ...state,
                [payload.props]: payload.value,
                [payload.error]: '',
                sev1StartDurationHour:
                    state.sev1StartDurationHour - 1 ==
                    state.processDurationHours
                        ? Number(payload.value) + Number(1) < 10
                            ? '0' + (Number(payload.value) + Number(1))
                            : Number(payload.value) + Number(1)
                        : state.sev1StartDurationHour,
                sev1EndDurationHour:
                    state.sev1EndDurationHour - 2 ==
                    state.processDurationHoursEnd
                        ? Number(payload.value) + Number(2) < 10
                            ? '0' + (Number(payload.value) + Number(2))
                            : Number(payload.value) + Number(2)
                        : state.sev1EndDurationHour,
                // sev1HighDurationHours:
                //     state.sev1HighDurationHours - 3 ==
                //     state.processDurationHours
                //         ? Number(payload.value) + Number(3) < 10
                //             ? '0' + (Number(payload.value) + Number(3))
                //             : Number(payload.value) + Number(3)
                //         : state.sev1HighDurationHours,
            }
        case reportUserDetails.EMPTY_INITIAL_DIALOG_CONTENT:
            return {
                ...state,
                loader: false,
                editPage: false,
                uploadPopup: true,
                reportName: '',
                server: '',
                interactive: true,
                execution: [],
                parameters: '',
                description: '',
                intervalTime: '',
                processDurationSecond: '00',
                processDurationMinute: '00',
                processDurationHours: '00',
                processDurationSecondEnd: '00',
                processDurationMinuteEnd: '00',
                processDurationHoursEnd: '01',
                reportNameError: '',
                serverError: '',
                executionError: '',
                intervalTimeError: '',
                processDurationError: '',
                processDurationEndError: '',
            }
        case reportUserDetails.CHECKED_VALUE_BOOLEAN:
            return {
                ...state,
                interactive: payload,
            }
        case reportUserDetails.REPORT_NEW_SAVE_SUBMIT:
            // console.log(
            //     'post data',
            //     payload.processDurationHours +
            //         ':' +
            //         payload.processDurationMinute +
            //         ':' +
            //         payload.processDurationSecond,
            //     payload.processDurationHoursEnd +
            //         ':' +
            //         payload.processDurationMinuteEnd +
            //         ':' +
            //         payload.processDurationSecondEnd
            // )
            return {
                ...state,
                editPage: false,
                uploadPopup: false,
                execution: payload.execution,
                processDurationHours: payload.processDurationHours,
                processDurationHoursEnd: payload.processDurationHoursEnd,
                processDurationMinute: payload.processDurationMinute,
                processDurationMinuteEnd: payload.processDurationMinuteEnd,
                processDurationSecond: payload.processDurationSecond,
                processDurationSecondEnd: payload.processDurationSecondEnd,
                reportName: payload.reportname,
                server: payload.server,
                interactive: payload.interactive,
                intervalTime: payload.intervalTime,
            }
        case reportUserDetails.CLOSE_POPUP:
            return {
                ...state,
                loader: false,
                editPage: false,
                uploadPopup: false,
            }
        case reportUserDetails.GET_DIALOG_EDIT_DATA:
            //console.log('payloadedit', payload)
            var processSpliter = payload.startTime.split(':')
            state.processDurationSecond = processSpliter[2]
            state.processDurationMinute = processSpliter[1]
            state.processDurationHours = processSpliter[0]
            var sevLow = payload.endTime.split(':')
            state.processDurationSecondEnd = sevLow[2]
            state.processDurationMinuteEnd = sevLow[1]
            state.processDurationHoursEnd = sevLow[0]
            return {
                ...state,
                loading: true,
                editPage: true,
                uploadPopup: true,
                reportNameError: '',
                serverError: '',
                executionError: '',
                intervalTimeError: '',
                processDurationError: '',
                processDurationEndError: '',
                reportName: payload.reportName,
                server: payload.server,
                interactive: payload.interactive,
                execution: payload.execution,
                intervalTime: payload.intervalTime,
                processDurationSecond: state.processDurationSecond,
                processDurationMinute: state.processDurationMinute,
                processDurationHours: state.processDurationHours,
                processDurationHoursEnd: state.processDurationHoursEnd,
                processDurationMinuteEnd: state.processDurationMinuteEnd,
                processDurationSecondEnd: state.processDurationSecondEnd,
            }
        case reportUserDetails.DIALOG_POPUP_CLOSE:
            return {
                ...state,
                editPage: false,
                uploadPopup: false,
            }
        case reportUserDetails.POST_EDIT_REPORT:
            return {
                ...state,
                loading: true,
                uploadPopup: false,
                reportName: payload.reportName,
                server: payload.server,
                interactive: payload.interactive,
                execution: payload.execution,
                intervalTime: payload.intervalTime,
                processDurationSecond: payload.processDurationSecond,
                processDurationMinute: payload.processDurationMinute,
                processDurationHours: payload.processDurationHours,
                processDurationHoursEnd: payload.processDurationHoursEnd,
                processDurationMinuteEnd: payload.processDurationMinuteEnd,
                processDurationSecondEnd: payload.processDurationSecondEnd,
            }
        case reportUserDetails.VALIDATION_ERROR:
            return {
                ...state,
                reportNameError: payload.reportNameError
                    ? payload.reportNameError
                    : '',
                serverError: payload.serverError ? payload.serverError : '',
                executionError: payload.executionError
                    ? payload.executionError
                    : '',
                intervalTimeError: payload.intervalTimeError
                    ? payload.intervalTimeError
                    : '',
                processDurationError: payload.processDurationError
                    ? payload.processDurationError
                    : '',
                processDurationEndError: payload.processDurationEndError
                    ? payload.processDurationEndError
                    : '',
            }
        case reportUserDetails.DISPLAY_ADDED_NEW_DATA:
            return {
                ...state,
                displayUpdatedData: payload,
            }
        case reportUserDetails.GET_EDITED_REPORTDATA:
            return {
                ...state,
                loading: true,
                menuPopupOpen: false,
                generateLoading: '0',
            }
        case reportUserDetails.DUPLICATE_REPORT_BY_ID:
            return {
                ...state,
                duplicate: payload,
                menuPopupOpen: false,
                // menuPopupOpen:true
            }
        case reportUserDetails.MAKE_INITIAL_REPORT_EMPTY:
            return {
                ...state,
                reportId: '',
                reportName: '',
                description:
                    state.duplicate == true
                        ? state.getReportMaterById.description
                        : '',

                parameterJsonView:
                    state.duplicate == true ? state.parameterJsonView : {},
                parameterJsonViewError: '',
                parameters:
                    state.duplicate == true
                        ? state.parameters
                        : JSON.stringify({}),
                descriptionError: '',
                parametersError: '',
                execution:
                    state.duplicate == true
                        ? state.getReportMaterById.execution
                        : 'CUSTOM',
                interactive: state.duplicate == true ? state.interactive : true,
                server:
                    state.duplicate == true
                        ? state.getReportMaterById.server
                        : '',
                startTime:
                    state.duplicate == true
                        ? state.startTime
                        : changeDateFormat(currentdate),
                endTime:
                    state.duplicate == true
                        ? state.endTime
                        : changeEndDateFormat(currentdate),
                transactionName: '',
                intervalTimeError: '',
                reportIdError: '',
                moduleIdError: '',
                reportNameError: '',
                executionError: '',
                transactionNameError: '',
                interactiveError: '',
                startTimeError: '',
                serverError: '',
                endTimeError: '',
                component: state.duplicate == true ? state.component : 'table',
                toEmail: state.duplicate == true ? state.toEmail : '',
                toEmailError: '',
                ccEmailError: '',
                ccEmail: state.duplicate == true ? state.ccEmail : '',
                subjectEmail: state.duplicate == true ? state.subjectEmail : '',
                subjectEmailError: '',
                pathFolder: state.duplicate == true ? state.pathFolder : '',
                pathFolderError: '',
                prefixFolder: state.duplicate == true ? state.prefixFolder : '',
                prefixFolderError: '',
                suffixFolder: state.duplicate == true ? state.suffixFolder : '',
                suffixFolderError: '',
                ipFtp: state.duplicate == true ? state.ipFtp : '',
                ipFtpError: '',
                userNameFtp: state.duplicate == true ? state.userNameFtp : '',
                userNameFtpError: '',
                passwordFtp: state.duplicate == true ? state.passwordFtp : '',
                passwordFtpError: '',
                folderPathFtp:
                    state.duplicate == true ? state.folderPathFtp : '',
                folderPathFtpError: '',
                prefixFtp: state.duplicate == true ? state.prefixFtp : '',
                prefixFtpError: '',
                suffixFtp: state.duplicate == true ? state.suffixFtp : '',
                suffixFtpError: '',
                output: state.duplicate == true ? state.output : 'pdf',
                destination:
                    state.duplicate == true ? state.destination : 'email',
                intervalTime: state.duplicate == true ? state.intervalTime : '',
                ftpPort: state.duplicate == true ? state.ftpPort : '',
                ftpPortError: '',
                outputTableName:
                    state.duplicate == true ? state.outputTableName : '',
                outputTableNameError: '',
                validity: state.duplicate == true ? state.validity : '',
                validityError: '',
                prefixEmail: state.duplicate == true ? state.prefixEmail : '',
                prefixEmailError: '',
                suffixEmail: state.duplicate == true ? state.suffixEmail : '',
                suffixEmailError: '',
                serverParameters: '',
                destinationParameters: '',
                isInteractive: '',
                interactiveParameters: '',
                suffixImages: '',
                suffixImagesError: '',
                ccEmailError: '',
                allModuleId: [],
                moduleId: state.duplicate == true ? state.moduleId : '',
                validityError: '',
                outputTableNameError: '',
                startDate: '',
                endDate: '',
            }
        case reportUserDetails.EDIT_DETAILS_REPORT_EMPTY:
            return {
                ...state,
                // timer:new Date(),
                designSettingsTable: '',
                duplicate: false,
                component: '',
                designSettingsTableSequence: '',
                refreshDuration: '',
                transactionName: '',
                tableColumn: [],
                moduleIdError: '',
                reportNameError: '',
                transactionNameError: '',
                intervalTimeError: '',
                descriptionError: '',
                startTimeError: '',
                endTimeError: '',
                executionError: '',
                parametersError: '',
                parameters: '',
                serverError: '',
                refreshDuration: '',
                toEmail: '',
                ccEmail: '',
                prefixEmail: '',
                prefixFolder: '',
                prefixFtp: '',
                suffixEmail: '',
                subjectEmail: '',
                pathFolder: '',
                suffixFolder: '',
                ipFtp: '',
                ftpPort: '',
                folderPathFtp: '',
                userNameFtp: '',
                passwordFtp: '',
                suffixFtp: '',
                validity: '',
                outputTableName: '',
                toEmailError: '',
                ccEmailError: '',
                suffixEmailError: '',
                subjectEmailError: '',
                pathFolderError: '',
                suffixFolderError: '',
                ipFtpError: '',
                ftpPortError: '',
                folderPathFtpError: '',
                userNameFtpError: '',
                passwordFtpError: '',
                suffixFtpError: '',
                validityError: '',
                outputTableNameError: '',
                validity: '',
                outputTableName: '',
                startDate: '',
                endDate: '',
                dayDailyweek: '',
                dayRepeatEvery: '',
                onDate: '',
                onTheDay: '',
                onDateOfMonth: '',
                on: '',
                WeekOfYear: '',
                WeekOfYearError: '',
                monthOfYear: '',
                monthOfYearError: '',
                dayFirstMonth: '',
                dayFirstMonthError: '',
                dayMonthOfFirst: '',
                dayMonthOfFirstError: '',
                dayYear: '',
                dayRepeatYear: '',
                sundayDaily: false,
                mondayDaily: false,
                tuesdayDaily: false,
                wednesdayDaily: false,
                thursdayDaily: false,
                fridayDaily: false,
                saturdayDaily: false,
                dailyTime: new Date().toString(),
                onTheDayYear: 'onDayYear',
                dayFirstOfYear: '',
                dayWeekOfYear: '',
                dayMonthOfYear: '',
                dateDaily: '',
                dayRepeatEvery: '',
                onTheDayMonth: '',
                onDateOfMonth: '',
                dayMonthOfFirst: '',
                dayMonthofWeeks: '',
                dayMonthsOfYear: '',
                defaultSelectGroup: [],
                defaultSelectUser: [],
                userListFromGrp: [],
            }
        case reportUserDetails.OPEN_NEW_REPORT_POPUP:
            return {
                ...state,
                loading: true,
                menuPopupOpen: false,
                openMenuPopup: true,
            }
        case reportUserDetails.CLOSE_REPORT_POPUP:
            return {
                ...state,
                loading: false,
                openMenuPopup: false,
                duplicate: false,
                component: '',
                designSettingsTableSequence: '',
                refreshDuration: '',
                transactionName: '',
                tableColumn: [],
                moduleIdError: '',
                reportNameError: '',
                transactionNameError: '',
                intervalTimeError: '',
                descriptionError: '',
                startTimeError: '',
                endTimeError: '',
                executionError: '',
                parametersError: '',
                parameters: '',
                serverError: '',
                refreshDuration: '',
                toEmail: '',
                ccEmail: '',
                prefixEmail: '',
                prefixFolder: '',
                prefixFtp: '',
                suffixEmail: '',
                subjectEmail: '',
                pathFolder: '',
                suffixFolder: '',
                ipFtp: '',
                ftpPort: '',
                folderPathFtp: '',
                userNameFtp: '',
                passwordFtp: '',
                suffixFtp: '',
                validity: '',
                outputTableName: '',
                toEmailError: '',
                ccEmailError: '',
                suffixEmailError: '',
                subjectEmailError: '',
                pathFolderError: '',
                suffixFolderError: '',
                ipFtpError: '',
                ftpPortError: '',
                folderPathFtpError: '',
                userNameFtpError: '',
                passwordFtpError: '',
                suffixFtpError: '',
                validityError: '',
                outputTableNameError: '',
                validity: '',
                outputTableName: '',
            }
        case reportUserDetails.DISPLAY_EDITED_REPORTDATA:
            return {
                ...state,
                loading: false,
                reportMasterAllData: payload,
            }
        case reportUserDetails.DISPLAY_EDITED_REPORT:
            return {
                ...state,
                loading: false,
                getReportAllMaster: _(payload)
                    .slice(0)
                    .take(payload.length)
                    .value(),
            }
        case reportUserDetails.SAVE_NEW_REPORT_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case reportUserDetails.SAVE_NEW_REPORT_RESPONSE:
            return {
                ...state,
                loading: false,
                openMenuPopup: false,
            }
        case reportUserDetails.MENU_EDIT_REPORT_POPUP:
            return {
                ...state,
                loading: true,
                menuPopupOpen: payload,
            }
        case reportUserDetails.GET_REPORT_MASTER_BYID:
            return {
                ...state,
                loader: true,
            }
        case reportUserDetails.DISPLAY_REPORT_MASTER_BYID:
            let destinationData = JSON.parse(payload.destinationParameters)
            let interactivePara = JSON.parse(payload.interactiveParameters)
            // let outputPara = JSON.parse(payload.outputParameters)
            return {
                ...state,
                loader: false,
                getReportMaterById: payload,
                // timer: Moment(payload.intervalTime).format('HH:MM:SS'),
                reportId: payload.reportId == null ? 0 : payload.reportId,
                reportName:
                    payload.reportName == null ? '' : payload.reportName,
                execution:
                    payload.execution == null ? 'CUSTOM' : payload.execution,
                interactive: payload.interactive == true ? true : false,
                transactionName:
                    payload.transactionName == null
                        ? ''
                        : payload.transactionName,
                startTime: payload.startTime,
                description: payload.description,
                server: payload.server == null ? '' : payload.server,
                endTime: payload.endTime,
                intervalTime:
                    payload.intervalTime == null ? '' : payload.intervalTime,
                moduleId: payload.moduleId == null ? '' : payload.moduleId,
                parameterJsonView: payload.serverParameters
                    ? JSON.parse(payload.serverParameters)
                    : {},
                parameters:
                    payload.serverParameters == ''
                        ? JSON.stringify({})
                        : payload.serverParameters == null
                        ? JSON.stringify({})
                        : JSON.stringify(JSON.parse(payload.serverParameters)),
                destination:
                    payload.destination == null ? '' : payload.destination,
                output: payload.outputType,
                pdfOutput: payload.outputType == 'pdf' ? true : false,
                excelOutput: payload.outputType == 'csv' ? true : false,
                imagesOutput: payload.outputType == 'image' ? true : false,
                jsonOutput: payload.outputType == 'json' ? true : false,
                emailDestination: payload.destination == 'email' ? true : false,
                folderDestination:
                    payload.destination == 'folder' ? true : false,
                ftpDestination: payload.destination == 'ftp' ? true : false,
                outputTableName:
                    payload.interactiveParameters != null
                        ? interactivePara.outputTableName
                        : '',
                validity:
                    payload.interactiveParameters != null
                        ? interactivePara.validity
                        : '',
                destinationParameters:
                    payload.destinationParameters == null
                        ? ''
                        : JSON.parse(payload.destinationParameters),
                // suffixImages: outputPara.suffixImages,
                toEmail:
                    payload.destinationParameters != null
                        ? destinationData.email_to
                        : '',
                ccEmail:
                    payload.destinationParameters == null
                        ? ''
                        : destinationData.email_cc,
                subjectEmail:
                    payload.destinationParameters == null
                        ? ''
                        : destinationData.email_subject,
                prefixEmail:
                    payload.destinationParameters == null
                        ? ''
                        : destinationData.email_filePrefix,
                suffixEmail:
                    payload.destinationParameters == null
                        ? ''
                        : destinationData.email_fileSuffix,
                pathFolder:
                    payload.destinationParameters == null
                        ? ''
                        : destinationData.folder_path,
                prefixFolder:
                    payload.destinationParameters == null
                        ? ''
                        : destinationData.folder_filePrefix,
                suffixFolder:
                    payload.destinationParameters == null
                        ? ''
                        : destinationData.folder_fileSuffix,
                ipFtp:
                    payload.destinationParameters == null
                        ? ''
                        : destinationData.ftp_ip,
                userNameFtp:
                    payload.destinationParameters == null
                        ? ''
                        : destinationData.ftp_userName,
                passwordFtp:
                    payload.destinationParameters == null
                        ? ''
                        : destinationData.ftp_password,
                folderPathFtp:
                    payload.destinationParameters == null
                        ? ''
                        : destinationData.ftp_folderPath,
                prefixFtp:
                    payload.destinationParameters == null
                        ? ''
                        : destinationData.ftp_fileprefix,
                suffixFtp:
                    payload.destinationParameters == null
                        ? ''
                        : destinationData.ftp_filesuffix,
                ftpPort:
                    payload.destinationParameters == null
                        ? ''
                        : destinationData.ftp_port,
                isInteractive:
                    payload.isInteractive == null ? '' : payload.isInteractive,
                interactiveParameters:
                    payload.interactiveParameters == null
                        ? ''
                        : payload.interactiveParameters,
                component:
                    payload.displayType == null ? 'Table' : payload.displayType,
            }
        case reportUserDetails.REQUEST_SAVE_EDITED_REPORT:
            return {
                ...state,
                loading: true,
            }
        case reportUserDetails.RESPONSE_SAVE_EDITED_REPORT:
            return {
                ...state,
                loading: false,
                menuPopupOpen: false,
            }
        case reportUserDetails.REQUEST_DELETE_REPORT_BYID:
            return {
                ...state,
                loading: true,
            }
        case reportUserDetails.RESPONSE_DELETE_REPORT_BYID:
            return {
                ...state,
                loading: false,
                menuPopupOpen: false,
            }
        case reportUserDetails.GET_SERVER_BYID:
            return {
                ...state,
                loading: true,
                generateLoading: payload.value.reportId,
            }
        case reportUserDetails.DISPLAY_SERVER_BYID:
            return {
                ...state,
                loading: false,
            }
        case reportUserDetails.CHANGE_STYLE_COMPONENT:
            return {
                ...state,
                loading: true,
                [payload.props]: payload.value,
                [payload.error]: '',
            }
        case reportUserDetails.GET_REPORT_TABLE_DESIGN:
            return {
                ...state,
                loading: true,
            }

        case reportUserDetails.DISPLAY_DEFAULT_SETTINGS:
            let graphDesigns = payload && JSON.parse(payload.designSettings)
            return {
                ...state,
                loader: false,
                designSettings: payload && JSON.parse(payload.designSettings),
                donutTitleFontSize:
                    graphDesigns && graphDesigns.donutTitleFontSize,
                donutFontFamily: graphDesigns && graphDesigns.donutFontFamily,
                headerDonutColor: graphDesigns && graphDesigns.headerDonutColor,
                donutHeightStyle: graphDesigns && graphDesigns.donutHeightStyle,
                donutWidthStyle: graphDesigns && graphDesigns.donutWidthStyle,
                gridDonut: graphDesigns && graphDesigns.gridDonut,
                //component: payload.displayType,
                refreshDuration: graphDesigns && payload.refreshDuration,
                displayType: payload && payload.displayType,
                displayMode: payload && payload.displayMode,
            }
        case reportUserDetails.GET_DEFAULT_SETTINGS:
            return {
                ...state,
                loader: true,
                generateLoading: '0',
            }
        case reportUserDetails.UPDATE_DEFAULT_SETTINGS:
            return {
                ...state,
                loader: true,
            }
        case reportUserDetails.PASS_ALLCOLUMN_VALUE:
            return {
                ...state,
                loader: false,
                allColumnData: payload,
                tableColumn: newValueData,
            }
        case reportUserDetails.OPEN_JSON_EDITOR_NEW:
            return {
                ...state,
                jsonOpen: payload,
            }
        case reportUserDetails.DISPLAY_REPORT_TABLE_DESIGN:
            //Make Sort Based on Sequenc
            let modify = JSON.parse(localStorage.getItem('auth_user'))
            let alll =
                payload &&
                payload.uiReportColumnProfiles.map((item) => {
                    return {
                        // ...item,
                        displaySequence: item.displaySequence,
                        displayTagName: item.displayTagName,
                        tagName: item.tagName,
                        transactionName: item.transactionName,
                        sequence: item.displaySequence,
                        level: item.level,
                        parentSequence: item.parentSequence,
                        isActive: item.active == true ? 1 : 0,
                        formatInfo: item.formatInfo,
                        modifiedBy: modify.name,
                        // reportDescription: item.description,
                        childCount: payload.uiReportColumnProfiles.filter(
                            (res) => res.parentSequence == item.displaySequence
                        ).length,
                    }
                })

            let activeCountData =
                alll &&
                alll.filter((e) => e.parentSequence == '0' && e.isActive != 0)
            let inactiveCountt =
                alll &&
                alll.filter((e) => e.isActive == 0 && e.parentSequence == '0')
            let activeInactiveValues = [...activeCountData, ...inactiveCountt]
            let finalActiveSort = activeCountData.sort((a, b) => {
                return a.displaySequence - b.displaySequence
            })
            let finalInactiveSort = inactiveCountt.sort((a, b) => {
                return a.displaySequence - b.displaySequence
            })
            let active = [...finalActiveSort]
            let inActive = [...finalInactiveSort]
            let newValueData = [...finalActiveSort, ...finalInactiveSort]
            let finalSorted = newValueData.sort((a, b) => {
                return a.displaySequence - b.displaySequence
            })

            let graphdesign = JSON.parse(payload.designSettings)

            return {
                ...state,
                loader: false,
                designSettingsTableSequence: payload ? payload : '',
                AllColumnValue: alll,
                activeCount: active.length,
                tableValues: finalSorted,
                designSettings: JSON.parse(payload.designSettings),
                donutRefreshDuration:
                    payload.displayType == 'DoughnutChart'
                        ? payload.refreshDuration
                        : 60,
                cardRefreshDuration:
                    payload.displayType == 'Card'
                        ? payload.refreshDuration
                        : 60,
                barRefreshDuration:
                    payload.displayType == 'Barchart'
                        ? payload.refreshDuration
                        : 60,
                headerBackgroundColor: graphdesign.headerBackgroundColor,
                headerFontColor: graphdesign.headerFontColor,
                headerFontSize: graphdesign.headerFontSize,
                highlightColor: graphdesign.highlightColor,
                margin: graphdesign.margin,
                numberOfRows: graphdesign.numberOfRows,
                rowSelectedBackgroundColor:
                    graphdesign.rowSelectedBackgroundColor,
                tableBackgroundColor: graphdesign.tableBackgroundColor
                    ? graphdesign.tableBackgroundColor
                    : '#fcfaf5',
                tableColor: graphdesign.tableColor
                    ? graphdesign.tableColor
                    : 'black',
                tableFontFamily: graphdesign.tableFontFamily
                    ? graphdesign.tableFontFamily
                    : 'arial',
                tableFontSize: graphdesign.tableFontSize
                    ? graphdesign.tableFontSize
                    : 12,
                tablePaddingBottom: graphdesign.tablePaddingBottom
                    ? graphdesign.tablePaddingBottom
                    : '0rem',
                tablePaddingLeft: graphdesign.tablePaddingLeft
                    ? graphdesign.tablePaddingLeft
                    : '0rem',
                tablePaddingTop: graphdesign.tablePaddingTop
                    ? graphdesign.tablePaddingTop
                    : '0rem',
                tablePaddingRight: graphdesign.tablePaddingRight
                    ? graphdesign.tablePaddingRight
                    : '0rem',
                tableTitleFontSize: graphdesign.tableTitleFontSize
                    ? graphdesign.tableTitleFontSize
                    : 15,
                tableTitleFontStyle: graphdesign.tableTitleFontStyle
                    ? graphdesign.tableTitleFontStyle
                    : 'arial',
                textAlign: graphdesign.textAlign,
                verticalAlign: graphdesign.verticalAlign,

                donutTitleFontSize: graphdesign.donutTitleFontSize,
                donutFontFamily: graphdesign.donutFontFamily
                    ? graphdesign.donutFontFamily
                    : 'arial',
                donutHeightStyle: graphdesign.donutHeightStyle,
                donutWidthStyle: graphdesign.donutWidthStyle,
                gridDonut: graphdesign.gridDonut ? graphdesign.gridDonut : 11,
                headerDonutColor: graphdesign.headerDonutColor,
                barTitleFontSize: graphdesign.barTitleFontSize
                    ? graphdesign.barTitleFontSize
                    : '20',
                barFontFamily: graphdesign.barFontFamily
                    ? graphdesign.barFontFamily
                    : 'arial',
                headerBarColor: graphdesign.headerBarColor
                    ? graphdesign.headerBarColor
                    : 'red',
                barWidthStyle: graphdesign.barWidthStyle
                    ? graphdesign.barWidthStyle
                    : 86,
                barHeightStyle: graphdesign.barHeightStyle
                    ? graphdesign.barHeightStyle
                    : 343,
                gridDonut: graphdesign.gridDonut ? graphdesign.gridDonut : 1,
                cardColor: graphdesign.cardColor
                    ? graphdesign.cardColor
                    : '#fcfaf5',
                cardBackgroundColor: graphdesign.cardBackgroundColor
                    ? graphdesign.cardBackgroundColor
                    : '#fcfaf5',
                cardTitleColor: graphdesign.cardTitleColor
                    ? graphdesign.cardTitleColor
                    : 'black',
                cardPaddingLeft: graphdesign.cardPaddingLeft
                    ? graphdesign.cardPaddingLeft
                    : '0rem',
                cardPaddingRight: graphdesign.cardPaddingRight
                    ? graphdesign.cardPaddingRight
                    : '0rem',
                cardPaddingTop: graphdesign.cardPaddingTop
                    ? graphdesign.cardPaddingTop
                    : '0rem',
                cardPaddingBottom: graphdesign.cardPaddingBottom
                    ? graphdesign.cardPaddingBottom
                    : '0rem',
                cardFontSize: graphdesign.cardFontSize
                    ? graphdesign.cardFontSize
                    : 12,
                titleFontSize: graphdesign.titleFontSize
                    ? graphdesign.titleFontSize
                    : 15,
                cardFontFamily: graphdesign.cardFontFamily
                    ? graphdesign.cardFontFamily
                    : 'arial',
                cardTitleFontFamily: graphdesign.cardTitleFontFamily
                    ? graphdesign.cardTitleFontFamily
                    : 'arial',
                cardHeaderFontSize: graphdesign.cardHeaderFontSize
                    ? graphdesign.cardHeaderFontSize
                    : 15,
                cardHeaderColor: graphdesign.cardHeaderColor
                    ? graphdesign.cardHeaderColor
                    : 'black',
                cardHeaderFontFamily: graphdesign.cardHeaderFontFamily
                    ? graphdesign.cardHeaderFontFamily
                    : 'arial',
                component: payload.displayType
                    ? payload.displayType
                    : state.component,
                refreshDuration: payload.refreshDuration,
                displayType: payload.displayType
                    ? payload.displayType
                    : state.displayType,
                displayMode: payload.displayMode,
                widgetHeight: graphdesign.widgetHeight
                    ? graphdesign.widgetHeight
                    : state.defaultWidgetHeight,
                widgetWidth: graphdesign.widgetWidth
                    ? graphdesign.widgetWidth
                    : state.defaultCardWidgetWidth,
            }
        case reportUserDetails.PASS_CHILD_DRAG_VALUES:
            let updateSeqChild = payload.e
            var finaldata = []
            if (!updateSeqChild.destination) return

            var tempData1 = Array.from(state.tableValues)
            let oldIndexIdChild = updateSeqChild.source.index + 1
            let newIndexIdChild = updateSeqChild.destination.index + 1

            state.AllColumnValue.map((val) => {
                val.sequence = val.displaySequence
                val.tempParentSeq = val.parentSequence
            })
            state.tableValues.map((val) => {
                val.sequence = val.displaySequence
                val.tempParentSeq = val.parentSequence
            })
            let array1 = state.AllColumnValue

            var maxOfLevel = Math.max(...array1.map((o) => o.level))
            for (var ilevel = 2; ilevel <= maxOfLevel; ilevel++) {
                if (oldIndexIdChild < newIndexIdChild) {
                    //Downward

                    state.tableValues.map((val, i) => {
                        let idx = i + 1
                        if (
                            oldIndexIdChild < idx &&
                            i != newIndexIdChild &&
                            newIndexIdChild > i
                        ) {
                            let temChildIdx = idx - 1

                            val.displaySequence =
                                val.parentSequence + '.' + temChildIdx
                        } else if (
                            val.sequence ==
                            val.parentSequence + '.' + oldIndexIdChild
                        ) {
                            //Update Changed Row
                            val.displaySequence =
                                val.parentSequence + '.' + newIndexIdChild
                        } else if (
                            val.parentSequence + '.' + i ==
                            val.parentSequence + '.' + newIndexIdChild
                        ) {
                            //Update Changed Row
                            val.displaySequence = val.parentSequence + '.' + idx
                        } else {
                            val.displaySequence = val.parentSequence + '.' + idx
                        }
                    })
                    state.tableValues.map((val, inx) => {
                        let parentSeq = val.sequence
                        let newParentSeq = val.displaySequence
                        var newArray = state.AllColumnValue.filter((el) => {
                            return (
                                el.level == ilevel &&
                                el.tempParentSeq == parentSeq
                            )
                        })
                        let tempAr = generalFunction.updateSequencyIndex(
                            newArray,
                            val.displaySequence
                        )
                    })
                } else if (oldIndexIdChild > newIndexIdChild) {
                    //Upward
                    state.tableValues.map((val, index) => {
                        let idx = index + 1
                        if (
                            oldIndexIdChild > idx &&
                            idx != newIndexIdChild &&
                            newIndexIdChild < idx
                        ) {
                            let tempIndexChild = idx + 1
                            val.displaySequence =
                                val.parentSequence + '.' + tempIndexChild
                        } else if (
                            val.sequence ==
                            val.parentSequence + '.' + oldIndexIdChild
                        ) {
                            //Update Changed Row
                            val.displaySequence =
                                val.parentSequence + '.' + newIndexIdChild
                        } else if (
                            val.sequence ==
                            val.parentSequence + '.' + newIndexIdChild
                        ) {
                            //Update Changed Row
                            let i = idx + 1
                            val.displaySequence = val.parentSequence + '.' + i
                        } else if (
                            index ==
                            val.parentSequence + '.' + newIndexIdChild
                        ) {
                            let i = idx + 1
                            val.displaySequence = val.parentSequence + '.' + i
                        } else {
                            val.displaySequence = val.parentSequence + '.' + idx
                        }
                    })

                    state.tableValues.map((val, inx) => {
                        let parentSeq = val.sequence
                        var newArray = state.AllColumnValue.filter((el) => {
                            return (
                                el.level == ilevel &&
                                el.tempParentSeq == parentSeq
                            )
                        })
                        let tempAr = generalFunction.updateSequencyIndex(
                            newArray,
                            val.displaySequence
                        )
                    })
                }
            }
            finaldata = state.tableValues.sort(
                (a, b) => {
                    return a.displaySequence - b.displaySequence
                }
                //? 1 : -1
            )
            return {
                ...state,
            }

        case reportUserDetails.PASS_ONCHANGE_DRAG_VALUE:
            var nupdateSeq = payload.e
            var finaldata1 = []
            var finalTempData1 = []
            if (!nupdateSeq.destination) return
            var noldIndexId = nupdateSeq.source.index + 1
            var nnewIndexId = nupdateSeq.destination.index + 1
            state.AllColumnValue.map((val) => {
                val.sequence = val.displaySequence
                val.tempParentSeq = val.parentSequence
                val.tempsequence = val.displaySequence
            })
            state.tableValues.map((val) => {
                val.sequence = val.displaySequence
                val.tempParentSeq = val.parentSequence
            })
            let narray = state.AllColumnValue
            var nmaxOfLevel = Math.max(...narray.map((o) => o.level))
            var ninitLevel =
                Math.min(...state.tableValues.map((o) => o.level)) + 1

            if (noldIndexId < nnewIndexId) {
                //Downward
                state.tableValues.map((val, i) => {
                    let idx = i + 1
                    if (
                        noldIndexId < idx &&
                        i != nnewIndexId &&
                        nnewIndexId > i
                    ) {
                        let temIdx = idx - 1
                        val.displaySequence = temIdx.toString()
                    } else if (val.sequence == noldIndexId) {
                        //Update Changed Row
                        val.displaySequence = nnewIndexId.toString()
                    } else if (i == nnewIndexId) {
                        //Update Changed Row
                        val.displaySequence = idx.toString()
                    } else {
                        val.displaySequence = idx.toString()
                    }
                })
                state.tableValues.sort((a, b) => {
                    return a.displaySequence - b.displaySequence
                })
                let time = generalFunction.sleep(3000)
                state.tableValues.map((val, inx) => {
                    var values = []

                    let nparentSeq = val.sequence
                    let newParentSeq = val.displaySequence
                    var sequenceTemp = val.tempsequence

                    if (val.childCount > 0) {
                        //Update Upcomming all child
                        let nextLevel = ninitLevel
                        var upAllArray = state.AllColumnValue.filter(
                            (el) =>
                                el.parentSequence.startsWith(nparentSeq) &&
                                el.level >= nextLevel
                        )

                        let test = generalFunction.changeSequencyNumber(
                            upAllArray,
                            val.displaySequence.toString()
                        )

                        //Remove the filtered variable
                        const toRemove = new Set(upAllArray)
                        const difference = state.AllColumnValue.filter(
                            (x) => !toRemove.has(x)
                        )
                        state.AllColumnValue = difference.concat(test)
                    }
                    state.tableValues.sort((a, b) => {
                        return a.displaySequence - b.displaySequence
                    })
                })
            } else if (noldIndexId > nnewIndexId) {
                //Upward
                console.log('Upward')
                state.tableValues.map((val, index) => {
                    let idx = index + 1
                    if (
                        noldIndexId > idx &&
                        index != nnewIndexId &&
                        nnewIndexId < idx
                    ) {
                        let tempIndex = idx + 1
                        val.displaySequence = tempIndex.toString()
                    } else if (val.sequence == noldIndexId) {
                        //Update Changed Row
                        val.displaySequence = nnewIndexId.toString()
                    } else if (val.sequence == nnewIndexId) {
                        //Update Changed Row
                        val.displaySequence = (idx + 1).toString()
                    } else if (index == nnewIndexId) {
                        val.displaySequence = (idx + 1).toString()
                    } else {
                        val.displaySequence = idx.toString()
                    }
                })
                state.tableValues.sort((a, b) => {
                    return a.displaySequence - b.displaySequence
                })
                let time = generalFunction.sleep(1000)
                state.tableValues.map((val, inx) => {
                    var values = []
                    console.log('upwards', val.sequence, val, inx)
                    let nparentSeq = val.sequence
                    let newParentSeq = val.displaySequence
                    var sequenceTemp = val.tempsequence

                    if (val.childCount > 0) {
                        console.log(
                            'startsWirh',
                            state.AllColumnValue.filter(
                                (el) =>
                                    el.tempParentSeq.startsWith(nparentSeq) &&
                                    el.level >= nextLevel
                            )
                        )
                        //Update Upcomming all child
                        let nextLevel = ninitLevel
                        var upAllArray = state.AllColumnValue.filter(
                            (el) =>
                                el.tempParentSeq.startsWith(nparentSeq) &&
                                el.level >= nextLevel
                        )

                        let test = generalFunction.changeSequencyNumber(
                            upAllArray,
                            val.displaySequence.toString()
                        )
                        console.log('testUpWards', test)

                        //Remove the filtered variable
                        const toRemove = new Set(upAllArray)
                        const difference = state.AllColumnValue.filter(
                            (x) => !toRemove.has(x)
                        )

                        state.AllColumnValue = difference.concat(test)
                    }
                    state.tableValues.sort((a, b) => {
                        return a.displaySequence - b.displaySequence
                    })
                })
            } else {
                //Equal Index
                console.log('equal')
            }
            var activeVal = state.tableValues.filter((it) => it.isActive == 1)
            var inActiveVal = state.tableValues.filter((it) => it.isActive == 0)
            // state.tableValues = [...activeVal, ...inActiveVal]
            // state.tableValues.sort((a, b) =>
            //     a.displaySequence > b.displaySequence ? 1 : -1
            // )
            // console.log('order', state.tableValues)
            // }
            return { ...state }

        case reportUserDetails.TABLE_COLUMN_VALUES:
            let activeCountDatas =
                state.AllColumnValue &&
                state.AllColumnValue.filter(
                    (e) => e.parentSequence == payload && e.isActive != 0
                )

            let inActiveCountDatas =
                state.AllColumnValue &&
                state.AllColumnValue.filter(
                    (e) => e.parentSequence == payload && e.isActive == 0
                )

            let finalActiveSort1 = activeCountDatas.sort((a, b) => {
                return a.displaySequence - b.displaySequence
            })

            let finalInactiveSort1 = inActiveCountDatas.sort((a, b) => {
                return a.displaySequence - b.displaySequence
            })

            let active1 = [...finalActiveSort1]
            let inActive1 = [...finalInactiveSort1]
            let newValueData1 = [...finalActiveSort1, ...finalInactiveSort1]
            let finalSorted1 = newValueData1.sort((a, b) => {
                return a.displaySequence - b.displaySequence
            })
            console.log('finalSorted1', finalSorted1)
            let temperaryDatas =
                activeCountDatas &&
                activeCountDatas
                    .filter((e) => e.isActive != 0)
                    .sort((a, b) =>
                        a.displaySequence > b.displaySequence ? 1 : -1
                    )
            let inactiveCountts =
                state.AllColumnValue &&
                state.AllColumnValue.filter(
                    (e) => e.isActive == 0 && e.parentSequence == payload
                )
            let newValueDatas = [...temperaryDatas, ...inactiveCountts]
            let Active = [...temperaryDatas]
            // console.log('newValueData', newValueData)

            return {
                ...state,
                tableValues: finalSorted1,
                activeCount: Active.length,
                openPopUp: payload.openPopUp,
            }
        case reportUserDetails.SECONDARY_TABLE_DATA:
            let totalActiveCounts =
                state.AllColumnValue &&
                state.AllColumnValue.filter(
                    (e) => e.parentSequence == payload && e.isActive != 0
                )
            let sortedData = totalActiveCounts
                .filter((e) => e.isActive != 0)
                .sort((a, b) =>
                    a.displaySequence > b.displaySequence ? 1 : -1
                )
            let inactiveCounts = state.AllColumnValue.filter(
                (e) => e.isActive == 0 && e.parentSequence == payload
            )
            let combindedValues = [...sortedData, ...inactiveCounts]
            return {
                ...state,
                secondaryValues: combindedValues,
            }
        case reportUserDetails.COLUMN_SEQUENCES_CHILD_STATUS_CHANGE:
            console.log('rowDatar', payload)
            let vals = payload.rowData
            if (vals.childCount > 0) {
                var nparentSeq = vals.sequence
                var nextLevel = vals.level + 1
                var upAllArray = state.AllColumnValue.filter(
                    (el) =>
                        el.parentSequence.startsWith(nparentSeq) &&
                        el.level >= nextLevel
                )
                let time = generalFunction.sleep(3000)
                let test = generalFunction.changeChildStatus(
                    upAllArray,
                    payload.value,
                    vals.displaySequence
                )

                //Remove the filtered variable
                const toRemove = new Set(upAllArray)
                const difference = state.AllColumnValue.filter(
                    (x) => !toRemove.has(x)
                )
                state.AllColumnValue = difference.concat(test)
            }
            return { ...state }
        case reportUserDetails.COLUMN_DONUT_INPUT_CHANGE:
            console.log('changeData', payload)
            let final12 = state.AllColumnValue.map((val) => {
                if (val.displaySequence == payload.displaySequence) {
                    if (payload.fieldName == 'displayTagName') {
                        val.displayTagName = payload.value
                    }
                }
                return val
            })
            return {
                ...state,
                loading: true,
                AllColumnValue: final12,
            }

        case reportUserDetails.INPUT_CHANGE:
            return {
                ...state,
                displayValue: payload,
            }

        case reportUserDetails.COLUMN_SEQUENCES_INPUT_CHANGE:
            let final_data = state.AllColumnValue.map((val) => {
                if (val.displayTagName == payload.tagName) {
                    if (payload.fieldName == 'displayTagName') {
                        val.displayTagName = payload.value
                    } else if (payload.fieldName == 'formatInfo') {
                        val.formatInfo = payload.value
                    } else if (payload.fieldName == 'isActive') {
                        val.isActive = payload.value
                    }
                }
                return val
            })
            return {
                ...state,
                AllColumnValue: final_data,
            }

        // case reportUserDetails.DESIGN_TABLE_VALUES:
        //     console.log('AllCXolum', payload)
        //     return {
        //         ...state,
        //         AllColumnValue: payload,
        //     }
        case reportUserDetails.PASS_CHILD_SEQUENCE_STATUS:
            let affectedChildTag = payload.rowData.tagName
            let finalChildData = state.tableValues.map((e, index) => {
                if (
                    e.tagName == affectedChildTag
                    //  payload.rowData.displaySequence == e.displaySequence
                ) {
                    return {
                        ...e,
                        isActive: payload.e ? '1' : '0',
                    }
                }
                return e
            })

            let finalChildActive = finalChildData.filter(
                (e, index) => e.isActive != 0
            )
            let tempChildActive = []
            finalChildActive.map((val, index) => {
                if (val.isActive != 0) {
                    tempChildActive = [
                        ...tempChildActive,
                        {
                            ...val,
                            isActive: 1,
                        },
                    ]
                }
                return val
            })
            let tempChildDatas = tempChildActive
                .filter((e) => e.isActive != 0)
                .sort((a, b) =>
                    a.displaySequence > b.displaySequence ? 1 : -1
                )
            //Make Sort Based on Sequenc
            var tempChildInactive = finalChildData.filter(
                (e) => e.isActive == 0
            )
            finalChildData = [...tempChildDatas, ...tempChildInactive]
            //Make Sort Based on Sequenc

            finalChildData.map((item, index) => {
                let i = index + 1
                item.displaySequence = payload.rowData.parentSequence + '.' + i
            })
            let array2 = state.AllColumnValue
            var maxOfLevel = Math.max(...array2.map((o) => o.level))
            for (var ilevel = 3; ilevel <= maxOfLevel; ilevel++) {
                finalChildData.map((val, inx) => {
                    let displaySeq = val.sequence
                    var newArray = state.AllColumnValue.filter((el) => {
                        return (
                            el.level == ilevel && el.tempParentSeq == displaySeq
                        )
                    })
                    let tempAr = generalFunction.updateSequencyIndex(
                        newArray,
                        val.displaySequence
                    )
                })
            }
            let activeData = [...tempChildDatas]
            return {
                ...state,
                tableValues: finalChildData,
                activeCount: activeData.length,
            }
        case reportUserDetails.PASS_SEQUENCE_STATUS:
            let affectedTag = payload.rowData.tagName
            let finalData = state.tableValues.map((e, index) => {
                if (
                    e.tagName == affectedTag
                    //  payload.rowData.displaySequence == e.displaySequence
                ) {
                    return {
                        ...e,
                        isActive: payload.e ? '1' : '0',
                    }
                }
                return e
            })

            let finalActive = finalData.filter((e, index) => e.isActive != 0)
            let tempActive = []
            finalActive.map((val, index) => {
                if (val.isActive != 0) {
                    tempActive = [
                        ...tempActive,
                        {
                            ...val,
                            isActive: 1,
                        },
                    ]
                }
                return val
            })
            let tempDatas = tempActive
                .filter((e) => e.isActive != 0)
                .sort((a, b) =>
                    a.displaySequence > b.displaySequence ? 1 : -1
                )
            //Make Sort Based on Sequenc
            let tempInactive = finalData.filter((e) => e.isActive == '0')
            finalData = [...tempDatas, ...tempInactive]
            //Make Sort Based on Sequenc

            finalData.map((item, index) => {
                item.sequence = item.displaySequence
                item.displaySequence = index + 1
            })
            state.AllColumnValue.map((val) => {
                val.sequence = val.displaySequence
                val.tempParentSeq = val.parentSequence
                val.tempsequence = val.displaySequence
            })

            let array3 = state.AllColumnValue
            var maxOfLevel = Math.max(...array3.map((o) => o.level))
            state.tableValues.map((val, inx) => {
                var values = []
                let nparentSeq = val.sequence
                let newParentSeq = val.displaySequence
                var sequenceTemp = val.tempsequence

                if (val.childCount > 0) {
                    //Update Upcomming all child
                    let nextLevel = ninitLevel
                    var upAllArray = state.AllColumnValue.filter(
                        (el) =>
                            el.parentSequence.startsWith(nparentSeq) &&
                            el.level >= nextLevel
                    )
                    let test = generalFunction.changeSequencyNumber(
                        upAllArray,
                        val.displaySequence.toString()
                    )
                    //Remove the filtered variable
                    const toRemove = new Set(upAllArray)
                    const difference = state.AllColumnValue.filter(
                        (x) => !toRemove.has(x)
                    )
                    state.AllColumnValue = difference.concat(test)
                }
                state.tableValues.sort((a, b) =>
                    a.displaySequence > b.displaySequence ? 1 : -1
                )
            })
            // for (var ilevel = 2; ilevel <= maxOfLevel; ilevel++) {

            //     finalData.map((val, inx) => {
            //         console.log('val.displaySequence', val.displaySequence)
            //         let sequen = val.sequence
            //         var newArray = state.AllColumnValue.filter((el) => {
            //             return el.level == ilevel && el.tempParentSeq == sequen
            //         })
            //         let tempAr = generalFunction.updateSequencyIndex(
            //             newArray,
            //             val.displaySequence
            //         )
            //     })
            //     console.log('finalDataLast', finalData)
            // }
            return {
                ...state,
                tableValues: finalData,
            }
        case reportUserDetails.REQUEST_NEW_DESIGN_COLUMNS:
            return {
                ...state,
                loading: true,
            }
        case reportUserDetails.RESPONSE_NEW_DESIGN_COLUMNS:
            return {
                ...state,
                loader: false,
            }
        case reportUserDetails.RESPONSE_DEFAULT_SETTINGS:
            return {
                ...state,
                loader: false,
            }
        case reportUserDetails.VALIDATION_REPORT_CRITERIA:
            return {
                ...state,
                loading: true,
                reportNameError: payload.reportNameError,
                intervalTimeError: payload.intervalTimeError,
                startTimeError: payload.startTimeError,
                parametersError: payload.parametersError,
                descriptionError: payload.descriptionError,
                // endTimeError: payload.endTimeError,
                transactionNameError: payload.transactionNameError,
                executionError: payload.executionError,
                serverError: payload.serverError,
                moduleIdError: payload.moduleIdError,
                toEmailError: payload.toEmailError,
                suffixEmailError: payload.suffixEmailError,
                subjectEmailError: payload.subjectEmailError,
                pathFolderError: payload.pathFolderError,
                suffixFolderError: payload.suffixFolderError,
                ipFtpError: payload.ipFtpError,
                ftpPortError: payload.ftpPortError,
                folderPathFtpError: payload.folderPathFtpError,
                userNameFtpError: payload.userNameFtpError,
                passwordFtpError: payload.passwordFtpError,
                suffixFtpError: payload.suffixFtpError,
                validityError: payload.validityError,
                endTimeError: payload.endTimeError,
                outputTableNameError: payload.outputTableNameError,
                ccEmailError: payload.ccEmailError,
                componentError: payload.componentError,
            }
        case reportUserDetails.VALIDATION_REPORT_NEW_CRITERIA:
            return {
                ...state,
                loading: true,
                reportNameError: payload.reportNameError,
                startTimeError: payload.startTimeError,
                endTimeError: payload.endTimeError,
                executionError: payload.executionError,
                serverError: payload.serverError,
                transactionNameError: payload.transactionNameError,
                parametersError: payload.parametersError,
                descriptionError: payload.descriptionError,
                moduleIdError: payload.moduleIdError,
                toEmailError: payload.toEmailError,
                suffixEmailError: payload.suffixEmailError,
                subjectEmailError: payload.subjectEmailError,
                pathFolderError: payload.pathFolderError,
                suffixFolderError: payload.suffixFolderError,
                ipFtpError: payload.ipFtpError,
                ftpPortError: payload.ftpPortError,
                folderPathFtpError: payload.folderPathFtpError,
                userNameFtpError: payload.userNameFtpError,
                passwordFtpError: payload.passwordFtpError,
                suffixFtpError: payload.suffixFtpError,
                outputTableNameError: payload.outputTableNameError,
                validityError: payload.validityError,
                componentError: payload.componentError,
                intervalTimeError: payload.intervalTimeError,
                ccEmailError: payload.ccEmailError,
            }
        case reportUserDetails.PASS_DESIGN_VALUES:
            // var str = payload.tableFontSize
            // var matches = str.match(/(\d+)/)
            // let fontSize
            // if (matches) {
            //     fontSize = matches[0]
            // }
            // var str = payload.tableTitleFontSize
            // var matches = str.match(/(\d+)/)
            // let titleFontSize
            // if (matches) {
            //     titleFontSize = matches[0]
            // }
            return {
                ...state,
                loading: true,
                headerBackgroundColor: payload.headerBackgroundColor,
                tablePaddingLeft: payload.tablePaddingLeft,
                tablePaddingRight: payload.tablePaddingRight,
                tablePaddingTop: payload.tablePaddingTop,
                tablePaddingBottom: payload.tablePaddingBottom,
                tableFontSize: payload.tableFontSize,
                tableTitleFontSize: payload.tableTitleFontSize,
                tableFontFamily: payload.tableFontFamily,
                tableColor: payload.tableColor,
                headerFontColor: payload.headerFontColor,
                headerFontSize: payload.headerFontSize,
                highlightColor: payload.highlightColor,
                fontSize: payload.fontSize == null ? '20' : payload.fontSize,
                margin: payload.margin,
                numberOfRows: payload.numberOfRows,
                rowSelectedBackgroundColor: payload.rowSelectedBackgroundColor,
                tableBackgroundColor: payload.tableBackgroundColor,
                tableTitleFontStyle: payload.tableTitleFontStyle,
                textAlign: payload.textAlign,
                verticalAlign: payload.verticalAlign,
            }
        case reportUserDetails.OPEN_TABLE_POPUP:
            return {
                ...state,
                loading: true,
                openDragTablePopup: payload,
            }
        case reportUserDetails.CLOSE_TABLE_POPUP:
            return {
                ...state,
                loading: false,
                openDragTablePopup: payload,
            }

        //Inner table SKU data
        case reportInnerTableDetails.GET_INNERTABLE_DATA:
            return {
                ...state,
                loader: true,
            }
        case reportUserDetails.DELETE_REQUEST_DEFAULT_SETTINGS:
            return {
                ...state,
                loader: true,
            }
        case reportUserDetails.DELETE_RESPONSE_DEFAULT_SETTINGS:
            return {
                ...state,
                loader: false,
            }
        case reportUserDetails.CARD_REFRESH_RESPONSE:
            return {
                ...state,
                loader: false,
                cardDesigns: payload,
            }
        case reportUserDetails.GET_MODULE_ID:
            return {
                ...state,
                loader: true,
            }
        case reportUserDetails.DISPLAY_MODULE_ID:
            return {
                ...state,
                loader: false,
                allModuleId: payload,
            }
        case reportUserDetails.SELECT_ONE_CHECK:
            return {
                ...state,
                loader: false,
                emailDestination: payload == 'email' ? true : false,
                folderDestination: payload == 'folder' ? true : false,
                ftpDestination: payload == 'ftp' ? true : false,
            }
        case reportUserDetails.SELECT_ONE_CHECK_OUTPUT:
            return {
                ...state,
                loader: false,
                pdfOutput: payload == 'pdf' ? true : false,
                excelOutput: payload == 'csv' ? true : false,
                imagesOutput: payload == 'image' ? true : false,
                jsonOutput: payload == 'json' ? true : false,
            }
        case reportUserDetails.OPEN_JSON_POPUP:
            return {
                ...state,
                openJson: payload,
            }
        case reportUserDetails.REPORT_TIME_INPUT_CHANGE:
            return {
                ...state,
                loading: false,
                [payload.props]: payload.value,
                startDate: payload.startDate,
                [payload.error]: '',
            }
        case reportUserDetails.REPORT_TIMEEND_INPUT_CHANGE:
            return {
                ...state,
                loading: false,
                [payload.props]: payload.value,
                endDate: payload.endDate,
                [payload.error]: '',
            }
        case reportUserDetails.GET_UNIQUE_GRP:
            return {
                ...state,
                loading: true,
            }
        case reportUserDetails.DISPLAY_UNIQUE_GRP:
            let unique = payload.map((item, i) => {
                return {
                    id: i + 1,
                    name: item,
                }
            })
            return {
                ...state,
                loading: false,
                uniqueGroup: unique,
            }
        case reportUserDetails.TIMES_OPEN_POPUP:
            return {
                ...state,
                loading: false,
                dailyPopup: payload == 'daily' ? true : false,
                weeklyPopup: payload == 'weekly' ? true : false,
                monthlyPopup: payload == 'monthly' ? true : false,
                WeekOfYear: '',
                WeekOfYearError: '',
                monthOfYear: '',
                monthOfYearError: '',
                dayFirstMonth: '',
                dayFirstMonthError: '',
                dayMonthOfFirst: '',
                dayMonthOfFirstError: '',
                dayYear: '',
                dayRepeatYear: '',
                sundayDaily: false,
                mondayDaily: false,
                tuesdayDaily: false,
                wednesdayDaily: false,
                thursdayDaily: false,
                fridayDaily: false,
                saturdayDaily: false,
                dailyTime: new Date().toString(),
                onTheDayYear: 'onDayYear',
                dayFirstOfYear: '',
                dayWeekOfYear: '',
                dayMonthOfYear: '',
                dateDaily: '',
                dayRepeatEvery: '',
                onTheDayMonth: '',
                onDateOfMonth: '',
                dayMonthOfFirst: '',
                dayMonthofWeeks: '',
                dayMonthsOfYear: '',
            }
        case reportUserDetails.CLOSE_DAILY_POPUP:
            return {
                ...state,
                loading: false,
                dailyPopup: false,
                schedular: false,
            }
        case reportUserDetails.GET_GROUP_LIST_USER:
            return {
                ...state,
                loading: true,
            }
        case reportUserDetails.GET_USER_LIST:
            return {
                ...state,
                loading: true,
            }
        case reportUserDetails.DISPLAY_USER_LIST:
            let userAll = payload.map((item) => {
                return {
                    id: item.generatedID,
                    name: item.userName,
                }
            })
            return {
                ...state,
                loading: false,
                userListAllss: userAll,
            }

        case reportUserDetails.DISPLAY_GROUP_LIST_USER:
            let bothUserGrp = payload.groupList
            let grpList = bothUserGrp && bothUserGrp.split(',')
            let tempArrayGrp = []
            var tempLstGrp = state.uniqueGroup
            tempLstGrp &&
                tempLstGrp.map((item) => {
                    if (grpList != null && grpList.length > 0) {
                        grpList.map((it) => {
                            if (it == item.name) {
                                tempArrayGrp.push(item)
                            }
                        })
                    }
                })

            let usrList = payload.userList && payload.userList.split(',')
            let tempArray = []
            var tempLst = state.userListAllss
            tempLst &&
                tempLst.map((item) => {
                    if (usrList != null && usrList.length > 0) {
                        usrList.map((it) => {
                            if (it == item.id) {
                                tempArray.push(item)
                            }
                        })
                    }
                })
            return {
                ...state,
                loading: false,
                bothUserAndGrp: payload,
                //uniqueGroup: grpValue,
                defaultSelectGroup: tempArrayGrp,
                defaultSelectUser: tempArray,
            }
        case reportUserDetails.GET_USER_LIST_BY_GRP:
            return {
                ...state,
                loading: true,
                grp: state.defaultSelectGroup,
            }
        case reportUserDetails.DISPLAY_USER_LIST_BY_GRP:
            let userListGrp =
                payload &&
                payload.map((item) => {
                    return {
                        id: item.generatedID,
                        name: item.userName,
                    }
                })
            let temp = []
            let defaultUser = state.defaultSelectUser
            payload.map((it) => {
                defaultUser.map((itm) => {
                    if (it.generatedID == itm.id) {
                        temp.push(itm)
                    }
                })
            })
            return {
                ...state,
                loading: false,
                userListFromGrp: userListGrp,
                defaultSelectUser: temp,
            }
        case reportUserDetails.SAVE_USER_AND_GRP_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case reportUserDetails.SAVE_USER_AND_GRP_RESPONSE:
            return {
                ...state,
                loading: false,
                menuPopupOpen: false,
            }
        case reportUserDetails.DELETE_GRP_USER_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case reportUserDetails.DELETE_GRP_USER_RESPONSE:
            return {
                ...state,
                loading: false,
            }
        default: {
            return {
                ...state,
            }
        }
    }
}
