export const topBarHeight = 64
export const sideNavWidth = 260
export const navbarHeight = 60
export const sidenavCompactWidth = 80
export const containedLayoutWidth = 1200
export const getAttributes = [
    { name: 'Select', id: '' },
    { name: 'Bold', id: 'bold' },
    { name: 'Highlight', id: "highlight" },
    { name: 'Sort', id: "sort" },
    { name: 'FontSize', id: "fontsize" },
  ]
  export const getDonutColumn = [
    { name: 'value', id: 'value' },
    { name: 'name', id: 'name' },
  ]
