import { all, call, put, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { NotificationManager } from 'react-notifications'
import Moment from 'moment'
import { forceChangePasswordConstant } from '../../constant/ActionTypes'
import {
    headers,
    FORCE_CHANGE_PASSWORD,
    FORCE_CHANGE_PSWD,
    UPDATE_BASE_URL,
    TOKEN_VERIFY,
} from '../../constant/Common'

function changeDateFormat(dates) {
    const newDate = new Date(dates)
    return Moment(newDate).format('YYYY-MM-DD')
}

// //   ======================================================Save Force Change Password ====================================
function* saveForceChangePassword(payload) {
    var props = payload.payload
    try {
        var req = JSON.stringify({
            userName: props.changePasswordDetails.changePasswordUsername,
            oldPassword: props.changePasswordDetails.tempPassword,
            newPassword: props.changePasswordDetails.ConfirmNewPassword,
        })
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
        }
        var res = yield axios
            .request({
                method: 'post',
                url: UPDATE_BASE_URL + FORCE_CHANGE_PSWD,
                headers: headers,
                data: req,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        //console.log("dfvdfvvd",res);
        if (res.status === 200) {
            if (res.data.status.code == -1) {
                NotificationManager.error(res.data.status.details)
                yield put({
                    type: forceChangePasswordConstant.CHANGE_PASSWORD_ERROR,
                    payload: {},
                })
            } else if (res.data.status.code == 0) {
                NotificationManager.success(res.data.status.details)
                yield put({
                    type: forceChangePasswordConstant.CHANGE_PASSWORD_SUCCESS,
                    payload: {},
                })
            } else {
                NotificationManager.error(res.data.status.details)
            }
        } else {
            NotificationManager.error('Check Your Connection')
        }
    } catch (error) {
        console.log(error)
    }
}
function* getValidateToken(payload) {
    var props = payload.payload

    var token = props.token

    try {
        let headers = {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        }
        var req = JSON.stringify({
            token: token,
        })
        var res = yield axios
            .request({
                method: 'GET',
                url: UPDATE_BASE_URL + TOKEN_VERIFY + token,
                headers: headers,
                //data: req,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        //console.log("dfvdfvvd",res);
        if (res.status === 200) {
            if (res.data.code && res.data.code == 200) {
                console.log(res.data.result)
                yield put({
                    type: forceChangePasswordConstant.VALIDATE_TOKEN_SUCCESS,
                    payload: {
                        userDetails: res.data.result,
                    },
                })
            } else if (res.data.status == 500) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: forceChangePasswordConstant.VALIDATE_TOKEN_FAILED,
                    payload: {},
                })
                props.navigate('/')
            }
            // if(res.data.status.code == -1){
            //     NotificationManager.error(res.data.status.details)
            //     yield put({
            //         type: forceChangePasswordConstant.CHANGE_PASSWORD_ERROR,
            //         payload: {},
            //     })
            // }else if(res.data.status.code == 0){
            //     NotificationManager.success(res.data.status.details)
            //     yield put({
            //         type: forceChangePasswordConstant.CHANGE_PASSWORD_SUCCESS,
            //         payload: {},
            //     })
            // }
            else {
                NotificationManager.error(res.data.status)
                props.navigate('/')
            }
        } else {
            NotificationManager.error('Check Your Connection')
            props.navigate('/')
        }
    } catch (error) {
        console.log(error)
        props.navigate('/')
    }
}
function* saveChangePassword() {
    yield takeEvery(
        forceChangePasswordConstant.SAVE_CHANGE_PASSWORD,
        saveForceChangePassword
    )
}
function* validateToken() {
    yield takeEvery(
        forceChangePasswordConstant.VALIDATE_TOKEN,
        getValidateToken
    )
}
export default function* rootSaga() {
    yield all([saveChangePassword(), validateToken()])
}
