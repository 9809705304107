import { all, call, put, takeEvery, getContext } from 'redux-saga/effects'
import { transType, visibilityHeader } from '../../constant/ActionTypes'
import axios from 'axios'
import {
    API_URL,
    headers,
    headers1,
    LOOKUP_SAVE,
    LOOKUP_GET,
    LOOKUP_HEADER_EDIT,
    LOOKUP_DETAILS_LIST,
    LOOKUP_DETAIL_BY_ID,
    ADD_LOOKUP_DETAILS_DATA,
    DELETE_LOOKUP_DETAILS,
    EDIT_SAVE_LOOKUP_DETAILS,
    TEMPLATE_LIST,
    TEMPLATE_EDIT_BY_ID,
    DELETE_TEMPLATE_DATA,
    ADD_TEMPLATE,
    generalFunction,
    EDIT_SAVE_TEMPLATE_DATA,
    TRANS_LIST,
    TRANS_FILE_UPLOAD,
} from '../../constant/Common'
import { API } from '../../constant/ActionTypes'
import { NotificationManager } from 'react-notifications'

import { LOCATION_CHANGE, push } from 'react-router-redux'
function* getTransactionListDetails(prop) {
    let props = prop.payload
    try {
        var res = yield axios
            .request({
                method: 'get',
                url: API_URL.api + TRANS_LIST,
                headers: headers,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        //console.log('response', res)
        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: transType.TRANSACTION_ERROR,
                payload: { error: 'Internal application error. Please contact system administrator.' },
            })
            return
        }
        if (res.status == 200) {
            if (res.data.status == 500) {
                NotificationManager.error(res.data.error)
            } else {
                yield put({
                    type: transType.TRANSACTION_LIST_SUCCESS,
                    payload: res.data,
                })
            }
        } else {
            NotificationManager.error('Internal application error. Please contact system administrator.')
        }
    } catch (e) {
        console.log(e)
    }
}
function* transFileUpload(payload) {
    //console.log(payload)
    var generatedId = generalFunction.randomGeneratedString(16)
    var extName = payload.payload.props.name
    var extensionValue = extName.split('.')
    var extLength = extensionValue.length
    var extFileName = extensionValue[extLength - 1]
    let form = new FormData()
    //form.append('templateName', payload.payload.props.templateName)
    //form.append('fileName', generatedId + '.' + extFileName)
    form.append('fileName', extName)
    form.append('file', payload.payload.props)
    try {
        var res = yield axios
            .request({
                method: 'post',
                url: API_URL.api + TRANS_FILE_UPLOAD,
                data: form,
                headers: headers,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: transType.TRANSACTION_ERROR,
                payload: { error: 'Internal application error. Please contact system administrator.' },
            })
            return
        }

        if (res.status == 200) {
            if (res.data.status == 409) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: transType.TRANSACTION_ERROR,
                    payload: {},
                })
            } else if (res.data.status == 500) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: transType.TRANSACTION_ERROR,
                    payload: {},
                })
            } else {
                NotificationManager.success('Successfully uploaded')
                yield put({
                    type: transType.TRANSACTION_UPLOAD_RESPONSE,
                    payload: res.data,
                })
            }
        } else {
            if (res.data.status != '') {
                NotificationManager.error(res.data.error)
                yield put({
                    type: transType.TRANSACTION_ERROR,
                    payload: {},
                })
            } else {
                NotificationManager.error('Internal application error. Please contact system administrator.')
                yield put({
                    type: transType.TRANSACTION_ERROR,
                    payload: {},
                })
            }
        }
    } catch (e) {
        console.log(e)
    }
}
function* getTransactionDetails() {
    yield takeEvery(transType.GET_TRANSACTION_LIST, getTransactionListDetails)
}
function* transFileUploadWatcher() {
    yield takeEvery(transType.TRANSACTION_UPLOAD_REQUEST, transFileUpload)
}
export default function* rootSaga() {
    yield all([getTransactionDetails(), transFileUploadWatcher()])
}
