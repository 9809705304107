export const API = ''
export const ENC_KEY = ''
export const API_START = ''
export const API_END = ''
export const ACCESS_DENIED = ''
export const API_ERROR = ''
export const UPDATE_AUTH = ''

export const commonComponentType = {
    DISPLAY_SINGLE_DASHBOARD_DATA: 'DISPLAY_SINGLE_DASHBOARD_DATA',
    GET_SINGLE_DASHBOARD_DATA: 'GET_SINGLE_DASHBOARD_DATA',
    COMMON_STOP_LOADING: 'COMMON_STOP_LOADING',
    GET_MAIN_DASHBOARD_DATA: 'GET_MAIN_DASHBOARD_DATA',
    DISPLAY_MAIN_DASHBOARD_DATA: 'DISPLAY_MAIN_DASHBOARD_DATA',
    PASS_ALL_DONUT_COLUMN_VALUES: 'PASS_ALL_DONUT_COLUMN_VALUES',
    PASS_ALL_COLUMN_VALUES: 'PASS_ALL_COLUMN_VALUES',
    RESET_SHOW_COMPONENT: 'RESET_SHOW_COMPONENT',
    SHOW_COMPONENT_SPECIFIC: 'SHOW_COMPONENT_SPECIFIC',
    REFRESH_DASHBOARD_CARD: 'REFRESH_DASHBOARD_CARD',
    RESPONSE_REFRESH_DASHBOARD_CARD: 'RESPONSE_REFRESH_DASHBOARD_CARD',
    REFRESH_DASHBOARD_DONUT: 'REFRESH_DASHBOARD_DONUT',
    REFRESH_DASHBOARD_BAR: 'REFRESH_DASHBOARD_BAR',
    RESPONSE_REFRESH_DASHBOARD_DONUT: 'RESPONSE_REFRESH_DASHBOARD_DONUT',
    RESPONSE_REFRESH_DASHBOARD_BAR: 'RESPONSE_REFRESH_DASHBOARD_BAR',
    RESPONSE_REFRESH_DURATION_RESULT: 'RESPONSE_REFRESH_DURATION_RESULT',
    REFRESH_DASHBOARD_DATA: 'REFRESH_DASHBOARD_DATA',
    COMMON_INPUT_CHANGE: 'COMMON_INPUT_CHANGE',
    GET_ALL_DATA: 'GET_ALL_DATA',
    DISPLAY_ALL_DATA: 'DISPLAY_ALL_DATA',
    COMMON_ERROR_UNAUTH: 'COMMON_ERROR_UNAUTH',
    PASS_TIMER_VALUE: 'PASS_TIMER_VALUE',
    REQUEST_SAVE_NEW_JSONDATA: 'REQUEST_SAVE_NEW_JSONDATA',
    RESPONSE_SAVE_NEW_JSONDATA: 'RESPONSE_SAVE_NEW_JSONDATA',
    GET_JSON_TEXT_DATA: 'GET_JSON_TEXT_DATA',
    DISPLAY_JSON_ALL_DATA: 'DISPLAY_JSON_ALL_DATA',
    REQUEST_SAVE_UPDATE_JSONDATA: 'REQUEST_SAVE_UPDATE_JSONDATA',
    RESPONSE_SAVE_UPDATE_JSONDATA: 'RESPONSE_SAVE_UPDATE_JSONDATA',
    RESPONSE_FONT_FAMILY: 'RESPONSE_FONT_FAMILY',
    UPDATE_JSON_DATA: 'UPDATE_JSON_DATA',
    SHOW_EDIT_JSON: 'SHOW_EDIT_JSON',
    EDITED_JSON_DATA: 'EDITED_JSON_DATA',
    SAVE_UPDATED_JSON_TABLE: 'SAVE_UPDATED_JSON_TABLE',
    RESPONSE_UPDATED_JSON_TABLE: 'RESPONSE_UPDATED_JSON_TABLE',
    GET_DASHBOARD_DATA: 'GET_DASHBOARD_DATA',
    DISPLAY_DASHBOARD_DATA: 'DISPLAY_DASHBOARD_DATA',
    TEMP_DISPLAY_DASHBOARD_DATA: 'TEMP_DISPLAY_DASHBOARD_DATA',
    CHILD_DATA_ID: ' CHILD_DATA_ID',
    DASHBOARD_STOP_LOADING: 'DASHBOARD_STOP_LOADING',
}
export const visibilityChange = {
    DIALOG_POPUP_OPEN: 'DIALOG_POPUP_OPEN',
    DIALOG_POPUP_CLOSE: 'DIALOG_POPUP_CLOSE',
    VISIBILITY_INPUT_CHANGE: 'VISIBILITY_INPUT_CHANGE',
    SAVE_THE_VISIBILITY_DATA: 'SAVE_THE_VISIBILITY_DATA',
    GET_EDIT_RECORDS: 'GET_EDIT_RECORDS',
    EMPTY_INITIAL_DIALOG_CONTENT: 'EMPTY_INITIAL_DIALOG_CONTENT',
    DELETE_THE_VISIBILITY: 'DELETE_THE_VISIBILITY',
    EDIT_SAVE_DATA: 'EDIT_SAVE_DATA',
    VALIDATION_ERROR: 'VALIDATION_ERROR',
    GET_VISIBILITY_DETAILS: 'GET_VISIBILITY_DETAILS',
    VISIBILITY_DETAILS_ERROR_STATUS: 'VISIBILITY_DETAILS_ERROR_STATUS',
    DISPLAY_VISIBILITY_DETAILS: 'DISPLAY_VISIBILITY_DETAILS',
    DISPLAY_EDIT_DETAILS_RECORD: 'DISPLAY_EDIT_DETAILS_RECORD',
    APPEND_ADDED_DETAILS: 'APPEND_ADDED_DETAILS',
    DELETE_DETAILS_RECORD: 'DELETE_DETAILS_RECORD',
    APPEND_EDITED_DATA: 'APPEND_EDITED_DATA',
    ADD_OPEN_VISIBILITYDETAILS: 'ADD_OPEN_VISIBILITYDETAILS',
    CHANGES_PROCESS_DURATION: 'CHANGES_PROCESS_DURATION',
    VISIBILITY_DETAIL_OFF_LOADER: 'VISIBILITY_DETAIL_OFF_LOADER',
    CLOSE_DIALOG_START: 'CLOSE_DIALOG_START',
    ADD_NEW_START_STATUS: 'ADD_NEW_START_STATUS',
    INITIAL_START_END_EMPTY: 'INITIAL_START_END_EMPTY',
    VISIBILITY_ERROR_DATA_DISABLED: 'VISIBILITY_ERROR_DATA_DISABLED',
}
export const visibilityHeader = {
    REQUEST_UNIQUE_PROCESS_ID: 'REQUEST_UNIQUE_PROCESS_ID',
    RESPONSE_UNIQUE_PROCESS_ID: 'RESPONSE_UNIQUE_PROCESS_ID',
    GET_VISIBILITY_ID_DROPDOWN: 'GET_VISIBILITY_ID_DROPDOWN',
    DISPLAY_VISIBILITY_ID_DROPDOWN: 'DISPLAY_VISIBILITY_ID_DROPDOWN',
    VISIBILITY_STOP_LOADING: 'VISIBILITY_STOP_LOADING',
    SECOND_PUP_UP: 'SECOND_PUP_UP',
    SECOND_DIALOG_CLOSE_POPUP: 'SECOND_DIALOG_CLOSE_POPUP',
    VISIBILITY_HEADER_INPUT_CHANGE: 'VISIBILITY_HEADER_INPUT_CHANGE',
    ADD_HEADER_DATA_FOR_SAVE: 'ADD_HEADER_DATA_FOR_SAVE',
    EDIT_VISIBILITY_DATA: 'EDIT_VISIBILITY_DATA',
    SAVE_EDITED_HEADER_DATA: 'SAVE_EDITED_HEADER_DATA',
    DELETE_VISIBILITY_DATA: 'DELETE_VISIBILITY_DATA',
    GET_HEADER_TABLE_DETAILS: 'GET_HEADER_TABLE_DETAILS',
    VISIBILITY_HEADER_ERROR_STATUS: 'VISIBILITY_HEADER_ERROR_STATUS',
    DISPLAY_HEADER_DETAILS: 'DISPLAY_HEADER_DETAILS',
    DISPLAY_EDIT_DATA: 'DISPLAY_EDIT_DATA',
    DISPLAY_ADDED_HEADER_DATA: 'DISPLAY_ADDED_HEADER_DATA',
    DELETED_CHANGES_HEADER: 'DELETED_CHANGES_HEADER',
    DISPLAY_EDITED_HEADER_DATA: 'DISPLAY_EDITED_HEADER_DATA',
    GET_VISIBILITY_ID: 'GET_VISIBILITY_ID',
    VISIBILITY_ERROR: 'VISIBILITY_ERROR',
    CLOSE_HEADER_TEMPLATE: 'CLOSE_HEADER_TEMPLATE',
    HEADER_ERROR_OFF_LOADER: 'HEADER_ERROR_OFF_LOADER',
    ////////////////////////////////////////////////////////////////////////////////////////////////////////
    OPEN_VISIBILITY_HEADER_POPUP: 'OPEN_VISIBILITY_HEADER_POPUP',
    CLOSE_VISIBILITY_HEADER_POPUP: 'CLOSE_VISIBILITY_HEADER_POPUP',
    GET_INITIAL_VISIBILITY_PAGING: 'GET_INITIAL_VISIBILITY_PAGING',
    CLOSE_VISIBILITY_POPUP: 'CLOSE_VISIBILITY_POPUP',
    OPEN_VISIBILITY_DETAILS_ROW: 'OPEN_VISIBILITY_DETAILS_ROW',
    OPEN_VISIBILITY_DETAIL_PANEL: 'OPEN_VISIBILITY_DETAIL_PANEL',
    OPEN_MENU_POPUP: 'OPEN_MENU_POPUP',
    CLOSE_MENU_POPUP: 'CLOSE_MENU_POPUP',
    ADD_VISIBILITY_DETAILS: 'ADD_VISIBILITY_DETAILS',
    CLOSE_VISIBILITY_DETAILS_POPUP: 'CLOSE_VISIBILITY_DETAILS_POPUP',
    CLOSE_HEADER_PANEL: 'CLOSE_HEADER_PANEL',
    CLOSE_HEADER_PANEL_WITHOUT_LOADING: 'CLOSE_HEADER_PANEL_WITHOUT_LOADING',
    GET_VISIBILITY_TABLEDATA: 'GET_VISIBILITY_TABLEDATA',
    DISPLAY_VISI_HEADER_TABLE_DATA: 'DISPLAY_VISI_HEADER_TABLE_DATA',
    REQUEST_SAVE_VISI_HEADER: 'REQUEST_SAVE_VISI_HEADER',
    RESPONSE_SAVE_VISI_HEADER: 'RESPONSE_SAVE_VISI_HEADER',
    INITIAL_NEW_HEADER_EMPTY: 'INITIAL_NEW_HEADER_EMPTY',
    GET_VISIBILITY_SINGLE_BYID: 'GET_VISIBILITY_SINGLE_BYID',
    DISPLAY_VISIBILITY_SINGLE_BYID: 'DISPLAY_VISIBILITY_SINGLE_BYID',
    VISIBILITY_HEADER_AUTH_ERROR: 'VISIBILITY_HEADER_AUTH_ERROR',
    REQUEST_EDIT_VISIBILITY_HEADER: 'REQUEST_EDIT_VISIBILITY_HEADER',
    RESPONSE_EDIT_VISIBILITY_HEADER: 'RESPONSE_EDIT_VISIBILITY_HEADER',
    REQUEST_DELETE_VISIBILITY_HEADER: 'REQUEST_DELETE_VISIBILITY_HEADER',
    RESPONSE_DELETE_VISIBILITY_HEADER: 'RESPONSE_DELETE_VISIBILITY_HEADER',
    GET_VISIBILITY_TABLE_DETAIL: 'GET_VISIBILITY_TABLE_DETAIL',
    DISPLAY_VISI_DETAILS_TABLE: 'DISPLAY_VISI_DETAILS_TABLE',
    REQUEST_SAVE_VISIBILITY_NEW_DETAILS: 'REQUEST_SAVE_VISIBILITY_NEW_DETAILS',
    RESPONSE_SAVE_VISIBILITY_NEW_DETAILS:
        'RESPONSE_SAVE_VISIBILITY_NEW_DETAILS',
    GET_DETAILS_BY_ID: 'GET_DETAILS_BY_ID',
    DISPLAY_DETAILS_BY_ID: 'DISPLAY_DETAILS_BY_ID',
    INITIAL_NEW_DETAIL_EMPTY: 'INITIAL_NEW_DETAIL_EMPTY',
    REQUEST_VISIBILITY_EDIT_DETAILS: 'REQUEST_VISIBILITY_EDIT_DETAILS',
    RESPONSE_VISIBILITY_EDIT_DETAILS: 'RESPONSE_VISIBILITY_EDIT_DETAILS',
    DELETE_VISIBILITY_DETAILS: 'DELETE_VISIBILITY_DETAILS',
    DELETE_RESPONSE_VISIBILITY_DETAILS: 'DELETE_RESPONSE_VISIBILITY_DETAILS',
    VALIDATION_VISIBILITY_HEADER: 'VALIDATION_VISIBILITY_HEADER',
    GET_TEMPLATE_TABLEDATA: 'GET_TEMPLATE_TABLEDATA',
    DISPLAY_TEMPLATE_TABLEDATA: 'DISPLAY_TEMPLATE_TABLEDATA',
    INITIAL_EDITERROR_RESET_HEADER: 'INITIAL_EDITERROR_RESET_HEADER',
    VALIDATION_VISIBILITY_DETAILS: 'VALIDATION_VISIBILITY_DETAILS',
    RESET_VISIBILITY_DETAILS_ERROR: 'RESET_VISIBILITY_DETAILS_ERROR',
    VALIDATION_VISIBILITY_EDIT: 'VALIDATION_VISIBILITY_EDIT',
    PASS_VALUE: 'PASS_VALUE',
    OPEN_INFO_VISIBILITY: 'OPEN_INFO_VISIBILITY',
    CLOSE_INFO_VISIBILITY: 'CLOSE_INFO_VISIBILITY',
    INFO_OPEN_DETAIL_PANEL: 'INFO_OPEN_DETAIL_PANEL',
    GET_INITIAL_VISI_TABLE: 'GET_INITIAL_VISI_TABLE',
    PASS_PROCESS_ID: 'PASS_PROCESS_ID',
    DISPLAY_VISI_HEADER_TABLE_LIST: 'DISPLAY_VISI_HEADER_TABLE_LIST',
    VISIBILITY_MODE_INPUT_CHANGE: 'VISIBILITY_MODE_INPUT_CHANGE',
    CHANGES_PROCESS_DURATION: 'CHANGES_PROCESS_DURATION',
    VISIBILITY_STATUS_GET_REQUEST: 'VISIBILITY_STATUS_GET_REQUEST',
    VISIBILITY_STATUS_GET_RESPONSE: 'VISIBILITY_STATUS_GET_RESPONSE',
    VISIBILITY_STATUS_SAVE_REQUEST: 'VISIBILITY_STATUS_SAVE_REQUEST',
    VISIBILITY_STATUS_SAVE_RESPONSE: 'VISIBILITY_STATUS_SAVE_RESPONSE',
    VISIBILITY_STATUS_ADD_NEW: 'VISIBILITY_STATUS_ADD_NEW',
    VISIBILITY_STATUS_ERROR: 'VISIBILITY_STATUS_ERROR',
}
//===============================================template management========================================
export const templateActionTypes = {
    TEMPLATE_LOADING_STOP: 'TEMPLATE_LOADING_STOP',
    TEMPLATE_INPUT_CHANGE: 'TEMPLATE_INPUT_CHANGE',
    CLOSE_HEADER_PANEL: 'CLOSE_HEADER_PANEL',
    OPEN_MENU_POPUP: 'OPEN_MENU_POPUP',
    CLOSE_TEMPLATE_POPUP: 'CLOSE_TEMPLATE_POPUP',
    OPEN_HEADER_OPENPOPUP: 'OPEN_HEADER_OPENPOPUP',
    CLOSE_MENUPOPUP: 'CLOSE_MENUPOPUP',
    GET_TEMPLATE_TABLE_DATA: 'GET_TEMPLATE_TABLE_DATA',
    DISPLAY_TEMPLATE_TABLE_DATA: 'DISPLAY_TEMPLATE_TABLE_DATA',
    TEMPLATE_AUTH_ERROR: 'TEMPLATE_AUTH_ERROR',
    GET_TEMPLATE_LIST: 'GET_TEMPLATE_LIST',
    REQUEST_SAVE_NEW_TEMPLATE: 'REQUEST_SAVE_NEW_TEMPLATE',
    RESPONSE_SAVE_NEW_TEMPLATE: 'RESPONSE_SAVE_NEW_TEMPLATE',
    DISPLAY_TEMPLATE_TABLE_LIST: 'DISPLAY_TEMPLATE_TABLE_LIST',
    DELETE_TEMPLATE_REQUEST: 'DELETE_TEMPLATE_REQUEST',
    DELETE_TEMPLATE_RESPONSE: 'DELETE_TEMPLATE_RESPONSE',
    EDIT_TEMPLATE_REQUEST: 'EDIT_TEMPLATE_REQUEST',
    EDIT_TEMPLATE_RESPONSE: 'EDIT_TEMPLATE_RESPONSE',
    OPEN_EDIT_POPUP: 'OPEN_EDIT_POPUP',
    CLOSE_EDIT_POPUP: 'CLOSE_EDIT_POPUP',
    REQUEST_SAVE_EDIT_TEMPLATE: 'REQUEST_SAVE_EDIT_TEMPLATE',
    RESPONSE_SAVE_EDIT_TEMPLATE: 'RESPONSE_SAVE_EDIT_TEMPLATE',
    VALIDATION_TEMPLATE_NEW: 'VALIDATION_TEMPLATE_NEW',
    VALIDATION_TEMPLATE_EDIT: 'VALIDATION_TEMPLATE_EDIT',
}
//================================================Process Criteria=========================================
export const processCriteriaType = {
    PROCESS_CRITERIA_LOADING_STOP: 'PROCESS_CRITERIA_LOADING_STOP',
    GET_RULE_DETAILS: 'GET_RULE_DETAILS',
    DETAIL_TABLE_OPEN: 'DETAIL_TABLE_OPEN',
    MENU_TAB_POPUP: 'MENU_TAB_POPUP',
    INPUT_CHANGE_PROCESS: 'INPUT_CHANGE_PROCESS',
    OPEN_CRITERIA_POPUP: 'OPEN_CRITERIA_POPUP',
    CRITERIA_CLOSE_POPUP: 'CRITERIA_CLOSE_POPUP',
    OPEN_ADD_DETAIL_POPUP: 'OPEN_ADD_DETAIL_POPUP',
    CLOSE_DETAILS_POPUP: 'CLOSE_DETAILS_POPUP',
    OPEN_EDIT_DETAIL_POPUP: 'OPEN_EDIT_DETAIL_POPUP',
    GET_PROCESS_CRITERIA_TABLE: 'GET_PROCESS_CRITERIA_TABLE',
    DISPLAY_PROCESS_TABLE_DATA: 'DISPLAY_PROCESS_TABLE_DATA',
    GET_INITIAL_CRITERIA: 'GET_INITIAL_CRITERIA',
    DISPLAY_PROCESS_DATA: 'DISPLAY_PROCESS_DATA',
    MAKE_INITIAL_PROCESS_EMPTY: 'MAKE_INITIAL_PROCESS_EMPTY',
    REQUEST_SAVE_CRITERIA: 'REQUEST_SAVE_CRITERIA',
    RESPONSE_SAVE_CRITERIA: 'RESPONSE_SAVE_CRITERIA',
    REQUEST_EDIT_SAVE_CRITERIA: 'REQUEST_EDIT_SAVE_CRITERIA',
    RESPONSE_EDIT_SAVE_CRITERIA: 'RESPONSE_EDIT_SAVE_CRITERIA',
    UPDATE_SINGLE_PROCESS: 'UPDATE_SINGLE_PROCESS',
    DELETE_REQUEST_PROCESS_CRITERIA: 'DELETE_REQUEST_PROCESS_CRITERIA',
    DELETE_RESPONSE_PROCESS_CRITERIA: 'DELETE_RESPONSE_PROCESS_CRITERIA',
    GET_PROCESS_CRITERIA_DETAILS: 'GET_PROCESS_CRITERIA_DETAILS',
    DISPLAY_PROCESS_TABLE_DETAILS: 'DISPLAY_PROCESS_TABLE_DETAILS',
    GET_EDIT_PROCESS_DETAILS_RULE: 'GET_EDIT_PROCESS_DETAILS_RULE',
    GET_DETAILS_INITIAL_EMPTY: 'GET_DETAILS_INITIAL_EMPTY',
    REQUEST_NEW_DETAILS_CRITERIA: 'REQUEST_NEW_DETAILS_CRITERIA',
    RESPONSE_SAVE_DETAILS_CRITERIA: 'RESPONSE_SAVE_DETAILS_CRITERIA',
    DELETE_RULEPROCESS_REQUEST: 'DELETE_RULEPROCESS_REQUEST',
    DISPLAY_SINGLE_DETAILS_TABLE: 'DISPLAY_SINGLE_DETAILS_TABLE',
    DELETE_RULEPROCESS_RESPONSE: 'DELETE_RULEPROCESS_RESPONSE',
    PROCESS_CRITERIA_ERROR: 'PROCESS_CRITERIA_ERROR',
    PROCESS_ID_ERROR: 'PROCESS_ID_ERROR',
    PROCESS_TYPE_ERROR: 'PROCESS_TYPE_ERROR',
    STATUS_ERROR: 'STATUS_ERROR',
    VALIDATION_PROCESS_CRITERIA: 'VALIDATION_PROCESS_CRITERIA',
    VALIDATE_RULE_DATA: 'VALIDATE_RULE_DATA',
    REQUEST_RULE_DATA: 'REQUEST_RULE_DATA',
    RESPONSE_RULE_DATA: 'RESPONSE_RULE_DATA',
    EDIT_REQUEST_TRANSACTION_RULE_DATA: 'EDIT_REQUEST_TRANSACTION_RULE_DATA',
    // GET_EDIT_TRANSACTION_RULE:'GET_EDIT_TRANSACTION_RULE'
    GET_TRANSACTION_RECORD: 'GET_TRANSACTION_RECORD',
    REQUEST_SAVE_EDIT_TRANSACTION: 'REQUEST_SAVE_EDIT_TRANSACTION',
    RESPONSE_SAVE_EDIT_TRANSACTION: 'RESPONSE_SAVE_EDIT_TRANSACTION',
    RESPONSE_DELETE_RULE_TRANSACTION: 'RESPONSE_DELETE_RULE_TRANSACTION',
    REQUEST_DELETE_RULE_TRANSACTION: 'REQUEST_DELETE_RULE_TRANSACTION',
    RULE_BUTTON_ACTION: 'RULE_BUTTON_ACTION',
    PROCESS_CRITERIA_AUTH_ERROR: 'PROCESS_CRITERIA_AUTH_ERROR',
    VALIDATION_PROCESS_DETAILS: 'VALIDATION_PROCESS_DETAILS',
    GET_RULE_AND_DETAILS_EDIT: 'GET_RULE_AND_DETAILS_EDIT',
    DISPLAY_DETAILS_AND_RULE: 'DISPLAY_DETAILS_AND_RULE',
    DETAIL_FIELD_DISABLE: 'DETAIL_FIELD_DISABLE',
    INFO_DETAIL_PANEL_OPEN: 'INFO_DETAIL_PANEL_OPEN',
    INFO_DETAIL_DATA: 'INFO_DETAIL_DATA',
    MISCFIELD_4_ERROR: 'MISCFIELD_4_ERROR',
}
export const lookupType = {
    LOOKUP_INPUT_CHANGE: 'LOOKUP_INPUT_CHANGE',
    LOOKUP_RESET: 'LOOKUP_RESET',
    LOOKUP_ERROR: 'LOOKUP_ERROR',
    LOOK_UP_HEADER_OPEN: 'LOOK_UP_HEADER_OPEN',
    LOOK_UP_HEADER_CLOSE: 'LOOK_UP_HEADER_CLOSE',
    LOOK_UP_DETAILS_OPEN: 'LOOK_UP_DETAILS_OPEN',
    LOOK_UP_DETAILS_CLOSE: 'LOOK_UP_DETAILS_CLOSE',
    LOOK_UP_SAVE: 'LOOK_UP_SAVE',
    LOOKUP_SAVE_RESPONSE: 'LOOKUP_SAVE_RESPONSE',
    LOOKUP_TEMPLATE_SAVE_REQUEST: 'LOOKUP_TEMPLATE_SAVE_REQUEST',
    LOOKUP_TEMPLATE_SAVE_RESPONSE: 'LOOKUP_TEMPLATE_SAVE_RESPONSE',
    LOOKUP_TEMPLATE_DELETE_RESPONSE: 'LOOKUP_TEMPLATE_DELETE_RESPONSE',
    LOOKUP_TEMPLATE_ERROR: 'LOOKUP_TEMPLATE_ERROR',
    LOOKUP_LIST_REQUEST: 'LOOKUP_LIST_REQUEST',
    LOOKUP_LIST_RESPONSE: 'LOOKUP_LIST_RESPONSE',
    LOOKUP_LIST_ERROR: 'LOOKUP_LIST_ERROR',
    LOOKUP_EDIT_REQUEST: 'LOOKUP_EDIT_REQUEST',
    LOOKUP_EDIT_RESPONSE: 'LOOKUP_EDIT_RESPONSE',
    LOOKUP_EDIT_ERROR: 'LOOKUP_EDIT_ERROR',
    LOOKUP_HEADER_DELETE_REQUEST: 'LOOKUP_HEADER_DELETE_REQUEST',
    LOOKUP_HEADER_DELETE_RESPONSE: 'LOOKUP_HEADER_DELETE_RESPONSE',
    LOOKUP_HEADER_DELETE_ERROR: 'LOOKUP_HEADER_DELETE_ERROR',
    OPEN_DETAIL_LOOKUP_PANEL: 'OPEN_DETAIL_LOOKUP_PANEL',

    //============================================ LookUp details ActionTypes =================================

    GET_LOOKUP_DETAILS_LIST: 'GET_LOOKUP_DETAILS_LIST',
    DISPLAY_ALL_LOOKUP_DETAILS: 'DISPLAY_ALL_LOOKUP_DETAILS',
    GET_LOOKUP_EDIT_DETAILS: 'GET_LOOKUP_EDIT_DETAILS',
    DISPLAY_EDITED_DATA: 'DISPLAY_EDITED_DATA',
    ADD_LOOKUP_DETAILS: 'ADD_LOOKUP_DETAILS',
    DELETE_LOOKUP_DETAILS: 'DELETE_LOOKUP_DETAILS',
    DELETED_SHOW_LIST: 'DELETED_SHOW_LIST',
    SAVE_EDIT_LOOKUP_DETAILS: 'SAVE_EDIT_LOOKUP_DETAILS',
    DISPLAY_EDIT_DATA_LOOKUP: 'DISPLAY_EDIT_DATA_LOOKUP',
    SET_TRUE_CARD: 'SET_TRUE_CARD',
    SET_CLOSE_CARD: 'SET_CLOSE_CARD',

    //===============================================Template ActionTypes ======================================
    GET_ALL_TEMPLATE_DETAILS: 'GET_ALL_TEMPLATE_DETAILS',
    DISPLAY_ALL_TEMPLATE_LIST: 'DISPLAY_ALL_TEMPLATE_LIST',
    GET_EDITABLE_TEMPLATE_BY_ID: 'GET_EDITABLE_TEMPLATE_BY_ID',
    DISPLAY_TEMPLATE_BY_ID: 'DISPLAY_TEMPLATE_BY_ID',
    DELETE_TEMPLATE_DATA: 'DELETE_TEMPLATE_DATA',
    SAVE_TEMPLATE_DATA: 'SAVE_TEMPLATE_DATA',
    TEMPLATE_SAVE_DISPLAY: 'TEMPLATE_SAVE_DISPLAY',
    EDITED_SAVE_TEMPLATE_DATA: 'EDITED_SAVE_TEMPLATE_DATA',
    DISPLAY_EDIT_TEMPLATE_DATA: 'DISPLAY_EDIT_TEMPLATE_DATA',

    INITIAL_EMPTY_TEMPLATE_DATA: 'INITIAL_EMPTY_TEMPLATE_DATA',
    LOOK_UP_SAVE_FAILED: 'LOOK_UP_SAVE_FAILED',
}

export const transType = {
    TRANSACTION_INPUT_CHANGE: 'TRANSACTION_INPUT_CHANGE',
    TRANSACTION_RESET: 'TRANSACTION_RESET',
    TRANSACTION_ERROR: 'TRANSACTION_ERROR',
    GET_TRANSACTION_LIST: 'GET_TRANSACTION_LIST',
    TRANSACTION_LIST_SUCCESS: 'TRANSACTION_LIST_SUCCESS',
    TRANSACTION_LIST_ERROR: 'TRANSACTION_LIST_ERROR',
    TRANSACTION_UPLOAD_REQUEST: 'TRANSACTION_UPLOAD_REQUEST',
    TRANSACTION_UPLOAD_RESPONSE: 'TRANSACTION_UPLOAD_RESPONSE',
    TRANSACTION_UPLOAD_INVALIDFILE: 'TRANSACTION_UPLOAD_INVALIDFILE',
    EMPTY_CONTENT: 'EMPTY_CONTENT',
    CLOSE_POPUP: 'CLOSE_POPUP',
}

export const adminUserConstant = {
    CANCEL_LOADING_ADMIN: 'CANCEL_LOADING_ADMIN',
    PASS_REPORT_MASTER: 'PASS_REPORT_MASTER',
    ADMIN_REPORT_MULTI_INPUT_CHANGE: 'ADMIN_REPORT_MULTI_INPUT_CHANGE',
    GET_ADMIN_NEW_USER_PAGE: 'GET_ADMIN_NEW_USER_PAGE',
    GET_NEWGRP_PAGE: 'GET_NEWGRP_PAGE',
    CLOSE_POPUP: 'CLOSE_POPUP',
    ADMIN_INPUT_CHANGE: 'ADMIN_INPUT_CHANGE',
    GET_ALL_USER_ROLES_LIST: 'GET_ALL_USER_ROLES_LIST',
    GET_NEW_ROLE_PAGE: 'GET_NEW_ROLE_PAGE',
    ADMIN_USER_ERROR_UNAUTH: 'ADMIN_USER_ERROR_UNAUTH',
    DISPLAY_ADMIN_ROLES_DATA: 'DISPLAY_ADMIN_ROLES_DATA',
    GET_ENABLE_USER_LIST: 'GET_ENABLE_USER_LIST',
    GET_ROLES: 'GET_ROLES',
    MAKE_DISABLE_USER: 'MAKE_DISABLE_USER',
    DISPLAY_DISABLE_USER: 'DISPLAY_DISABLE_USER',
    DISPLAY_ENABLE_USER: 'DISPLAY_ENABLE_USER',
    DISPLAY_ROLES_ALLOCATION: 'DISPLAY_ROLES_ALLOCATION',
    SELECT_ROLE_ALLOCATED: 'SELECT_ROLE_ALLOCATED',
    SAVE_REGISTER_USERS: 'SAVE_REGISTER_USERS',
    VALIDATION_REGISTER: 'VALIDATION_REGISTER',
    GET_EDIT_USER_LIST: 'GET_EDIT_USER_LIST',
    DISPLAY_SINGLE_USER: 'DISPLAY_SINGLE_USER',
    EDITED_SAVE_USER_SUBMIT: 'EDITED_SAVE_USER_SUBMIT',
    EDIT_SAVE_SUCCESS: 'EDIT_SAVE_SUCCESS',
    DELETE_THE_USER_REQUEST: 'DELETE_THE_USER_REQUEST',
    DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
    DISPLAY_ADDED_USER_SUCCESS: 'DISPLAY_ADDED_USER_SUCCESS',
    MAKE_INITIAL_ADMINUSER_EMPTY: 'MAKE_INITIAL_ADMINUSER_EMPTY',
    GET_ALL_GROUP_TABLE: 'GET_ALL_GROUP_TABLE',
    GET_UNIQUE_GROUP_TABLE_REQUEST: 'GET_UNIQUE_GROUP_TABLE_REQUEST',
    GET_UNIQUE_GROUP_TABLE_SUCCESS: 'GET_UNIQUE_GROUP_TABLE_SUCCESS',
    CREATE_GROUP_SAVE_SUBMIT: 'CREATE_GROUP_SAVE_SUBMIT',
    DISPLAY_ALL_GROUP_LIST: 'DISPLAY_ALL_GROUP_LIST',
    SAVE_EDIT_USER_GROUP: 'SAVE_EDIT_USER_GROUP',
    DISPLAY_GROUP_EDIT_LIST: 'DISPLAY_GROUP_EDIT_LIST',
    DISPLAY_GROUP_EDIT: 'DISPLAY_GROUP_EDIT',
    MAKE_INITIAL_GRP_EMPTY: 'MAKE_INITIAL_GRP_EMPTY',
    DELETE_GROUP_ROW_REQUEST: 'DELETE_GROUP_ROW_REQUEST',
    PASS_ROW_DATA_GRP: 'PASS_ROW_DATA_GRP',
    OWN_USER_CANT_DELETE: 'OWN_USER_CANT_DELETE',
    INITIAL_EMPTY_NEW_ROLE: 'INITIAL_EMPTY_NEW_ROLE',
    GET_ALL_ROLES: 'GET_ALL_ROLES',
    DISPLAY_ALL_ROLES: 'DISPLAY_ALL_ROLES',
    DISPLAY_ROLE_INITIAL_PAGE: 'DISPLAY_ROLE_INITIAL_PAGE',
    ROLE_INITIAL_PAGE: 'ROLE_INITIAL_PAGE',
    INITIAL_USER_DATA: 'INITIAL_USER_DATA',
    DISPLAY_ALL_USER_DATA: 'DISPLAY_ALL_USER_DATA',
    USER_INITIAL_DATA: 'USER_INITIAL_DATA',
    GET_GROUP_INITIAL_DATA: 'GET_GROUP_INITIAL_DATA',
    DISPLAY_GRP_DATA: 'DISPLAY_GRP_DATA',
    VALIDATION_GRP: 'VALIDATION_GRP',
    DISPLAY_CREATED_NEW_GROUP: 'DISPLAY_CREATED_NEW_GROUP',
    GET_EDIT_GRP_DATA: 'GET_EDIT_GRP_DATA',
    DISPLAY_EDIT_GRP_DATA: 'DISPLAY_EDIT_GRP_DATA',
    CLOSE_POPUP_GRP: 'CLOSE_POPUP_GRP',
    SELECT_ROLE_ALLOCATED_EDIT: 'SELECT_ROLE_ALLOCATED_EDIT',
    SAVE_NEW_ROLE_REQUEST: 'SAVE_NEW_ROLE_REQUEST',
    VALIDATION_ROLE_ERROR: 'VALIDATION_ROLE_ERROR',
    DISPLAY_EDIT_ROLES: 'DISPLAY_EDIT_ROLES',
    GET_EDIT_ROLES: 'GET_EDIT_ROLES',
    EDIT_SAVE_ROLES_REQ: 'EDIT_SAVE_ROLES_REQ',
    EDIT_SAVE_ROLE_SUCCESS: 'EDIT_SAVE_ROLE_SUCCESS',
    DELETE_ROLE_REQ: 'DELETE_ROLE_REQ',
    DELETE_ROLE_SUCCESS: 'DELETE_ROLE_SUCCESS',
    CLOSE_USER_POPUP: 'CLOSE_USER_POPUP',
    POPUP_USER_OPEN: 'POPUP_USER_OPEN',
    POPUP_OPEN_GRP: 'POPUP_OPEN_GRP',
    POPUP_CLOSE_GRP: 'POPUP_CLOSE_GRP',
    DELETE_GRP_SUCCESS: 'DELETE_GRP_SUCCESS',
    POPUP_CLOSE_ROLE: 'POPUP_CLOSE_ROLE',
    POPUP_OPEN_ROLE: 'POPUP_OPEN_ROLE',
    DISPLAY_SAVED_ROLES: 'DISPLAY_SAVED_ROLES',
    VALIDATION_REGISTER_EDIT: 'VALIDATION_REGISTER_EDIT',
    VALIDATION_EDIT_GRP_ERROR: 'VALIDATION_EDIT_GRP_ERROR',
    VALIDATION_ROLE_EDIT_ERROR: 'VALIDATION_ROLE_EDIT_ERROR',
    SHOW_USER_DETAILS: 'SHOW_USER_DETAILS',
    REQUEST_SAVE_LOGIN_PROFILE: 'REQUEST_SAVE_LOGIN_PROFILE',
    RESPONSE_SAVE_LOGIN_PROFILE: 'RESPONSE_SAVE_LOGIN_PROFILE',
    ADMIN_INPUT_FILE_CHANGE: 'ADMIN_INPUT_FILE_CHANGE',
    INPUT_CHANGE_PROFILE: 'INPUT_CHANGE_PROFILE',
    DISPLAY_OLD_USERDETAILS: 'DISPLAY_OLD_USERDETAILS',
    GET_OLD_USERDETAILS: 'GET_OLD_USERDETAILS',
    GET_PROFILE_PICTURE: 'GET_PROFILE_PICTURE',
    DISPLAY_PROFILE_IMAGE: 'DISPLAY_PROFILE_IMAGE',
}

export const ConfirmConstant = {
    OPEN_CHANGE_PASSWORD_DIALOG: 'OPEN_CHANGE_PASSWORD_DIALOG',
    INPUT_CHANGE_CONFIRM: 'INPUT_CHANGE_CONFIRM',
    SAVE_CONFIRM_CODE: 'SAVE_CONFIRM_CODE',
    CONFIRM_SUCCESS: 'CONFIRM_SUCCESS',
    CONFIRM_ERROR: 'CONFIRM_ERROR',
}
export const ruleConstant = {
    GET_RULE_DIALOG_OPEN: 'GET_RULE_DIALOG_OPEN',
    ADD_RULE_OPEN_DIALOG: 'ADD_RULE_OPEN_DIALOG',
    GET_ALL_RULE_LIST: 'GET_ALL_RULE_LIST',
    RULE_ERROR: 'RULE_ERROR',
    GET_LOCAL_DATA_RULE: 'GET_LOCAL_DATA_RULE',
    DISPLAY_ALL_RULES_LIST: 'DISPLAY_ALL_RULES_LIST',
    RULE_INPUT_INITIAL_CHANGE: 'RULE_INPUT_INITIAL_CHANGE',
    POST_NEW_RULE_DATA: 'POST_NEW_RULE_DATA',
    RULE_DATA_FAILED: 'RULE_DATA_FAILED',
    ADDING_NEW_RULE_SUCCESS: 'ADDING_NEW_RULE_SUCCESS',
    GET_RULE_RECORD_BY_RULEID: 'GET_RULE_RECORD_BY_RULEID',
    DISPLAY_RECORD_BY_RULEID: 'DISPLAY_RECORD_BY_RULEID',
    RULE_DELETE_BY_RULEID: 'RULE_DELETE_BY_RULEID',
    DELETE_RULID_SUCCESS: 'DELETE_RULID_SUCCESS',
    UPDATE_RULE_RECORD: 'UPDATE_RULE_RECORD',
    UPDATED_RULE_SUCCESS: 'UPDATED_RULE_SUCCESS',
    POST_DATA_VALIDATION_RULE: 'POST_DATA_VALIDATION_RULE',
    NEW_RULE_ADD_DETAILS: 'NEW_RULE_ADD_DETAILS',
    CLOSE_NEW_RULE: 'CLOSE_NEW_RULE',
    CLOSE_RULE_DIALOG: 'CLOSE_RULE_DIALOG',
}

export const forceChangePasswordConstant = {
    CHANGE_INPUT_PASSWORD: 'CHANGE_INPUT_PASSWORD',
    SAVE_CHANGE_PASSWORD: 'SAVE_CHANGE_PASSWORD',
    VALIDATION_FORCE_CHANGE: 'VALIDATION_FORCE_CHANGE',
    CHANGE_PASSWORD_ERROR: 'CHANGE_PASSWORD_ERROR',
    CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
    USER_LOGIN_DETAILS: 'USER_LOGIN_DETAILS',
    VISIBLE_PASSWORD: 'VISIBLE_PASSWORD',
    VALIDATE_TOKEN: 'VALIDATE_TOKEN',
    VALIDATE_TOKEN_SUCCESS: 'VALIDATE_TOKEN_SUCCESS',
    VALIDATE_TOKEN_FAILED: 'VALIDATE_TOKEN_FAILED',
}
//==========================================Report actiontypes ==================================

export const reportUserDetails = {
    OPEN_JSON_EDITOR_NEW: 'OPEN_JSON_EDITOR_NEW',
    DELETE_GRP_USER_REQUEST: 'DELETE_GRP_USER_REQUEST',
    DELETE_GRP_USER_RESPONSE: 'DELETE_GRP_USER_RESPONSE',
    SAVE_USER_AND_GRP_REQUEST: 'SAVE_USER_AND_GRP_REQUEST',
    SAVE_USER_AND_GRP_RESPONSE: 'SAVE_USER_AND_GRP_RESPONSE',
    GET_USER_LIST: 'GET_USER_LIST',
    DISPLAY_USER_LIST: 'DISPLAY_USER_LIST',
    GET_USER_LIST_BY_GRP: 'GET_USER_LIST_BY_GRP',
    GET_GROUP_LIST_USER: 'GET_GROUP_LIST_USER',
    DISPLAY_GROUP_LIST_USER: 'DISPLAY_GROUP_LIST_USER',
    DISPLAY_USER_LIST_BY_GRP: 'DISPLAY_USER_LIST_BY_GRP',
    DISPLAY_GROUP_LIST: 'DISPLAY_GROUP_LIST',
    GET_GROUP_LIST: 'GET_GROUP_LIST',
    CLOSE_DAILY_POPUP: 'CLOSE_DAILY_POPUP',
    TIMES_OPEN_POPUP: 'TIMES_OPEN_POPUP',
    REPORT_TIMEEND_INPUT_CHANGE: 'REPORT_TIMEEND_INPUT_CHANGE',
    REPORT_TIME_INPUT_CHANGE: 'REPORT_TIME_INPUT_CHANGE',
    DISPLAY_UNIQUE_GRP: 'DISPLAY_UNIQUE_GRP',
    GET_UNIQUE_GRP: 'GET_UNIQUE_GRP',
    REPORT_TIMEEND_INPUT_CHANGE: 'REPORT_TIMEEND_INPUT_CHANGE',
    REPORT_TIME_INPUT_CHANGE: 'REPORT_TIME_INPUT_CHANGE',
    DUPLICATE_REPORT_BY_ID: 'DUPLICATE_REPORT_BY_ID',
    OPEN_JSON_POPUP: 'OPEN_JSON_POPUP',
    SELECT_ONE_CHECK_OUTPUT: 'SELECT_ONE_CHECK_OUTPUT',
    SELECT_ONE_CHECK: 'SELECT_ONE_CHECK',
    GET_MODULE_ID: 'GET_MODULE_ID',
    DISPLAY_MODULE_ID: 'DISPLAY_MODULE_ID',
    CARD_REFRESH_RESPONSE: 'CARD_REFRESH_RESPONSE',
    DELETE_RESPONSE_DEFAULT_SETTINGS: 'DELETE_RESPONSE_DEFAULT_SETTINGS',
    DELETE_REQUEST_DEFAULT_SETTINGS: 'DELETE_REQUEST_DEFAULT_SETTINGS',
    REPORT_STOP_LOADING: 'REPORT_STOP_LOADING',
    COLUMN_DONUT_INPUT_CHANGE: 'COLUMN_DONUT_INPUT_CHANGE',
    PASS_CHILD_SEQUENCE_STATUS: 'PASS_CHILD_SEQUENCE_STATUS',
    PASS_CHILD_DRAG_VALUES: 'PASS_CHILD_DRAG_VALUES',
    CLOSE_TABLE_POPUP: 'CLOSE_TABLE_POPUP',
    OPEN_TABLE_POPUP: 'OPEN_TABLE_POPUP',
    GET_ALL_TABLE_REPORT: 'GET_ALL_TABLE_REPORT',
    REPORT_INPUT_CHANGE: 'REPORT_INPUT_CHANGE',
    REPORT_USER_ERROR_UNAUTH: 'REPORT_USER_ERROR_UNAUTH',
    DISPLAY_REPORT_TABLE_DATA: 'DISPLAY_REPORT_TABLE_DATA',
    GET_REPORT_REQUEST: 'GET_REPORT_REQUEST',
    GET_REPORT_RESPONSE: 'GET_REPORT_RESPONSE',
    EXECUTION_EDIT_DATA_REQUEST: 'EXECUTION_EDIT_DATA_REQUEST',
    EXECUTION_EDIT_DATA_RESPONSE: 'EXECUTION_EDIT_DATA_RESPONSE',
    EXECUTION_SAVE_DATA_REQUEST: 'EXECUTION_SAVE_DATA_REQUEST',
    EXECUTION_SAVE_DATA_RESPONSE: 'EXECUTION_SAVE_DATA_RESPONSE',
    EXECUTION_DELETE_REQUEST: 'EXECUTION_DELETE_REQUEST',
    EXECUTION_DELETE_RESPONSE: 'EXECUTION_DELETE_RESPONSE',
    DIALOG_POPUP_CLOSE: 'DIALOG_POPUP_CLOSE',
    EMPTY_INITIAL_DIALOG_CONTENT: 'EMPTY_INITIAL_DIALOG_CONTENT',
    REPORT_NAME_INPUT_CHANGE: 'REPORT_NAME_INPUT_CHANGE',
    CHANGE_TIME_DURATION: 'CHANGE_TIME_DURATION',
    INPUT_CHANGE_TIME: 'INPUT_CHANGE_TIME',
    ADD_OPEN_NEWPOPUP: 'ADD_OPEN_NEWPOPUP',
    CHECKED_VALUE_BOOLEAN: 'CHECKED_VALUE_BOOLEAN',
    REPORT_NEW_SAVE_SUBMIT: 'REPORT_NEW_SAVE_SUBMIT',
    GET_DIALOG_EDIT_DATA: 'GET_DIALOG_EDIT_DATA',
    POST_EDIT_REPORT: 'POST_EDIT_REPORT',
    VALIDATION_ERROR: 'VALIDATION_ERROR',
    DISPLAY_ADDED_NEW_DATA: 'DISPLAY_ADDED_NEW_DATA',
    GET_EDITED_REPORTDATA: 'GET_EDITED_REPORTDATA',
    DISPLAY_EDITED_REPORTDATA: 'DISPLAY_EDITED_REPORTDATA',
    DISPLAY_EDITED_REPORT: 'DISPLAY_EDITED_REPORT',
    OPEN_NEW_REPORT_POPUP: 'OPEN_NEW_REPORT_POPUP',
    CLOSE_REPORT_POPUP: 'CLOSE_REPORT_POPUP',
    SAVE_NEW_REPORT_REQUEST: 'SAVE_NEW_REPORT_REQUEST',
    SAVE_NEW_REPORT_RESPONSE: 'SAVE_NEW_REPORT_RESPONSE',
    MENU_EDIT_REPORT_POPUP: 'MENU_EDIT_REPORT_POPUP',
    GET_REPORT_MASTER_BYID: 'GET_REPORT_MASTER_BYID',
    DISPLAY_REPORT_MASTER_BYID: 'DISPLAY_REPORT_MASTER_BYID',
    REQUEST_SAVE_EDITED_REPORT: 'REQUEST_SAVE_EDITED_REPORT',
    RESPONSE_SAVE_EDITED_REPORT: 'RESPONSE_SAVE_EDITED_REPORT',
    REQUEST_DELETE_REPORT_BYID: 'REQUEST_DELETE_REPORT_BYID',
    RESPONSE_DELETE_REPORT_BYID: 'RESPONSE_DELETE_REPORT_BYID',
    GET_SERVER_BYID: 'GET_SERVER_BYID',
    DISPLAY_SERVER_BYID: 'DISPLAY_SERVER_BYID',
    MAKE_INITIAL_REPORT_EMPTY: 'MAKE_INITIAL_REPORT_EMPTY',
    CHANGE_STYLE_COMPONENT: 'CHANGE_STYLE_COMPONENT',
    GET_REPORT_TABLE_DESIGN: 'GET_REPORT_TABLE_DESIGN',
    DISPLAY_REPORT_TABLE_DESIGN: 'DISPLAY_REPORT_TABLE_DESIGN',
    GET_DEFAULT_SETTINGS: 'GET_DEFAULT_SETTINGS',
    DISPLAY_DEFAULT_SETTINGS: 'DISPLAY_DEFAULT_SETTINGS',
    EDIT_DETAILS_REPORT_EMPTY: 'EDIT_DETAILS_REPORT_EMPTY',
    UPDATE_DEFAULT_SETTINGS: 'UPDATE_DEFAULT_SETTINGS',
    RESPONSE_DEFAULT_SETTINGS: 'RESPONSE_DEFAULT_SETTINGS',
    PASS_ALLCOLUMN_VALUE: 'PASS_ALLCOLUMN_VALUE',
    PASS_ONCHANGE_DRAG_VALUE: 'PASS_ONCHANGE_DRAG_VALUE',
    PASS_SEQUENCE_STATUS: 'PASS_SEQUENCE_STATUS',
    REQUEST_NEW_DESIGN_COLUMNS: 'REQUEST_NEW_DESIGN_COLUMNS',
    RESPONSE_NEW_DESIGN_COLUMNS: 'RESPONSE_NEW_DESIGN_COLUMNS',
    VALIDATION_REPORT_CRITERIA: 'VALIDATION_REPORT_CRITERIA',
    VALIDATION_REPORT_NEW_CRITERIA: 'VALIDATION_REPORT_NEW_CRITERIA',
    PASS_DESIGN_VALUES: 'PASS_DESIGN_VALUES',
    COLUMN_SEQUENCES_INPUT_CHANGE: 'COLUMN_SEQUENCES_INPUT_CHANGE',
    COLUMN_SEQUENCES_CHILD_STATUS_CHANGE:
        'COLUMN_SEQUENCES_CHILD_STATUS_CHANGE',
    TABLE_COLUMN_VALUES: 'TABLE_COLUMN_VALUES',
    SECONDARY_TABLE_DATA: 'SECONDARY_TABLE_DATA',
    DESIGN_TABLE_VALUES: 'DESIGN_TABLE_VALUES',
    OPEN_SECOND_POPUP: 'OPEN_SECOND_POPUP',
    INPUT_CHANGE: 'INPUT_CHANGE',
}
export const reportInnerTableDetails = {
    GET_INNERTABLE_DATA: 'GET_INNERTABLE_DATA',
    REPORT_INPUT_CHANGE: 'REPORT_INPUT_CHANGE',
    ON_DRAG_INNER_TABLE: 'ON_DRAG_INNER_TABLE',
    PASS_SEQUENCE_INNER_TABLE: 'PASS_SEQUENCE_INNER_TABLE',
    COLUMN_DISPLAY_TABLE_INPUTCHANGE: 'COLUMN_DISPLAY_TABLE_INPUTCHANGE',
    OPEN_SECOND_POPUP: 'OPEN_SECOND_POPUP',
}
