import { visibilityHeader } from '../../constant/ActionTypes'
import _ from 'lodash'

const INITIAL_STATE = {
    loader: false,
    openConfirm: false,
    secondDialogBox: false,
    visibilityId: '',
    alertMode: 'None',
    headerAlertInfoLabel: '',
    alertInfo: '',
    templateName: '',
    description: '',
    userId: JSON.parse(localStorage.getItem('auth_user')),
    uid: '',
    editHeader: false,
    addSaveHeaderData: [],
    createdBy: '',
    lastModifiedBy: '',
    createdDate: '',
    lastModifiedDate: '',
    visibilityIdError: '',
    descriptionError: '',
    alertModeError: '',
    alertInfoError: '',
    templateNameError: '',
    detailsTemplateName: '',
    detailsTemplateNameError: '',
    detailAlertInfo: '',
    detailAlertInfoError: '',
    detailsAlertMode: 'None',
    detailsAlertModeError: '',
    detailsVisibilityId: '',
    detailsVisibilityIdError: '',
    openHeaderDialogBox: false,
    criteriaId: '',
    criteriaIdError: '',
    processId: '',
    processIdError: '',
    visibilityMiscField4: '',
    visibilityMiscField4Error: '',
    visibilityMiscField1: '',
    visibilityMiscField1Error: '',
    visibilityMiscField2: '',
    visibilityMiscField2Error: '',
    visibilityMiscField3: '',
    visibilityMiscField3Error: '',
    visibilityMiscFlag1: false,
    visibilityMiscFlag2: false,
    visibilityMiscFlag3: false,
    visibilityMiscFlag4: false,
    visibilityMiscFlag5: false,
    detailVisibilityMiscField1: '',
    detailVisibilityMiscField1Error: '',
    detailVisibilityMiscField2: '',
    detailVisibilityMiscField2Error: '',
    detailVisibilityMiscField3: '',
    detailVisibilityMiscField3Error: '',
    detailVisibilityMiscField4: '',
    detailVisibilityMiscField4Error: '',
    detailsVisibilityMiscFlag1: false,
    detailsVisibilityMiscFlag2: false,
    detailsVisibilityMiscFlag3: false,
    detailsVisibilityMiscFlag4: false,
    detailsVisibilityMiscFlag5: false,
    detailsVisibilityMiscFlag1Error: '',
    detailsVisibilityMiscFlag2Error: '',
    detailsVisibilityMiscFlag3Error: '',
    detailsVisibilityMiscFlag4Error: '',
    detailsVisibilityMiscFlag5Error: '',
    openVisibilityDetailsRow: true,
    openVisibilityDetailPanel: false,
    //    PopupOpenDetails :false,
    openMenuPopup: false,
    openDetailsPopup: false,
    visibilityHeaderTableData: [],
    getVisiPagination: [],
    displaySingleVisiHeader: '',
    rowHeaderIdVisi: '',
    visibilityDetailsTable: [],
    processDurationSecond: '00',
    processDurationMinute: '00',
    processDurationHours: '00',
    sev1LowDurationSecond: '00',
    sev1LowDurationMinute: '00',
    sev1LowDurationHours: '01',
    sev1MidDurationSecond: '00',
    sev1MidDurationMinute: '00',
    sev1MidDurationHours: '02',
    sev1HighDurationSecond: '00',
    sev1HighDurationMinute: '00',
    sev1HighDurationHours: '03',
    sev1highDurationError: '',
    sev1midDurationError: '',
    sev1LowDurationError: '',
    displaySingleDetails: '',
    displayTemplateTableData: [],
    detailRowData: '',
    value: '',
    openInfoPopup: false,
    infoRowData: '',
    processIdVisi: '',
    getVisibilityPagination: [],
    setLoading: false,
    startEndStatusDropdown: [],
    visibilityStatusNewModel: false,
    visibilityStatusString: '',
    visibilityStatusStringError: '',
    visibilityStatusEnableProp: '',
    sequenceNumber: 1,
    sequenceNumberError: '',
}
export default (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case visibilityHeader.REQUEST_UNIQUE_PROCESS_ID: {
            return { ...state, loading: true, visibilityProcessId: [] }
        }
        case visibilityHeader.RESPONSE_UNIQUE_PROCESS_ID: {
            return {
                ...state,
                loading: false,
                visibilityProcessId: payload
                    ? payload.map((res) => {
                          return {
                              id: res,
                              processId: res,
                          }
                      })
                    : [],
            }
        }
        case visibilityHeader.VISIBILITY_STATUS_ERROR: {
            return {
                ...state,
                loader: false,
                visibilityStatusStringError:
                    payload.visibilityStatusStringError,
            }
        }
        case visibilityHeader.VISIBILITY_STATUS_ADD_NEW: {
            return {
                ...state,
                loader: false,
                visibilityStatusNewModel: true,
                visibilityStatusStringError: '',
                visibilityStatusString: '',
                visibilityStatusEnableProp: payload.props,
            }
        }
        case visibilityHeader.VISIBILITY_STATUS_SAVE_REQUEST: {
            return {
                ...state,
                loader: true,
                // visibilityStatusNewModel: true,
                visibilityStatusStringError: '',
            }
        }
        case visibilityHeader.VISIBILITY_STATUS_GET_REQUEST: {
            return {
                ...state,
                loader: true,
                startEndStatusDropdown: [],
            }
        }
        case visibilityHeader.VISIBILITY_STATUS_GET_RESPONSE: {
            return {
                ...state,
                loader: false,
                startEndStatusDropdown:
                    payload.length > 0
                        ? payload.map((res) => {
                              return {
                                  value: res,
                                  label: res,
                              }
                          })
                        : [],
                [state.visibilityStatusEnableProp]: '',
                visibilityStatusEnableProp: '',
                visibilityStatusNewModel: false,
            }
        }
        case visibilityHeader.VISIBILITY_ERROR: {
            return {
                ...state,
                visibilityIdError: payload.visibilityIdError
                    ? payload.visibilityIdError
                    : '',
                descriptionError: payload.descriptionError
                    ? payload.descriptionError
                    : '',
                alertModeError: payload.alertModeError
                    ? payload.alertModeError
                    : '',
                alertInfoError: payload.alertInfoError
                    ? payload.alertInfoError
                    : '',
                templateNameError: payload.templateNameError
                    ? payload.templateNameError
                    : '',
            }
        }
        case visibilityHeader.SECOND_PUP_UP:
            return {
                ...state,
                visibilityId: '',
                secondDialogBox: true,
                loader: false,
                alertMode: 'None',
                alertInfo: '',
                headerAlertInfoLabel: '',
                templateName: '',
                description: '',
                visibilityIdError: '',
                descriptionError: '',
                alertModeError: '',
                alertInfoError: '',
                templateNameError: '',
                uid: '',
                openConfirm: false,
                secondDialogBox: true,
                editHeader: false,
            }

        case visibilityHeader.SECOND_DIALOG_CLOSE_POPUP:
            return {
                ...state,
                secondDialogBox: false,
            }

        case visibilityHeader.VISIBILITY_HEADER_INPUT_CHANGE:
            console.log('alert', payload.value)
            return {
                ...state,
                [payload.props]: payload.value,
                [payload.error]: '',
                setLoading: false,
            }
        case visibilityHeader.VISIBILITY_MODE_INPUT_CHANGE:
            console.log('alert', payload.value)
            return {
                ...state,
                [payload.props]: payload.value,
                [payload.error]: '',
                headerAlertInfoLabel:
                    payload.value == 'email'
                        ? 'Alert Email'
                        : payload.value == 'both'
                        ? 'Alert Both'
                        : payload.value == 'text'
                        ? 'Alert Text'
                        : 'Alert Information',
                setLoading: false,
            }

        case visibilityHeader.DISPLAY_HEADER_DETAILS:
            return {
                ...state,
                addSaveHeaderData: payload.sort(),
                loader: false,
            }
        case visibilityHeader.VISIBILITY_HEADER_AUTH_ERROR:
            localStorage.clear()
            window.location.reload()
            return {
                ...state,
                loader: false,
            }
        case visibilityHeader.EDIT_VISIBILITY_DATA:
            return {
                ...state,
                loader: true,
            }

        case visibilityHeader.DISPLAY_EDIT_DATA:
            //console.log('DISPLAY_EDIT_DATA', payload.alertMode)
            return {
                ...state,
                loader: false,
                visibilityId: payload.visibilityId,
                alertMode: payload.alertMode,
                headerAlertInfoLabel:
                    payload.alertMode == 'email'
                        ? 'Alert Email'
                        : payload.alertMode == 'both'
                        ? 'Alert Both'
                        : payload.alertMode == 'text'
                        ? 'Alert Text'
                        : 'Alert Information',
                alertInfo: payload.alertInfo,
                templateName: payload.templateName,
                description: payload.description,
                createdBy: payload.createdBy,
                lastModifiedBy: payload.lastModifiedBy,
                createdDate: payload.createdDate,
                lastModifiedDate: payload.lastModifiedDate,
                secondDialogBox: true,
                editHeader: true,
            }
        case visibilityHeader.SAVE_EDITED_HEADER_DATA:
            return {
                ...state,
                loader: true,
                openConfirm: true,
                editHeader: true,
            }
        case visibilityHeader.DELETE_VISIBILITY_DATA:
            return {
                ...state,
                loader: true,
            }

        case visibilityHeader.GET_HEADER_TABLE_DETAILS:
            return {
                ...state,
                loader: true,
            }
        case visibilityHeader.VISIBILITY_HEADER_ERROR_STATUS:
            localStorage.clear()
            window.location.reload()
            return {
                ...state,
                loader: false,
            }
        case visibilityHeader.DISPLAY_ADDED_HEADER_DATA:
            return {
                ...state,
                loader: false,
                openConfirm: true,
                secondDialogBox: false,
                addSaveHeaderData: [...state.addSaveHeaderData, payload],
            }

        case visibilityHeader.DELETED_CHANGES_HEADER:
            return {
                ...state,
                loader: false,
                addSaveHeaderData: state.addSaveHeaderData
                    .filter((item) => item.visibilityId != payload)
                    .map((item) => item),
            }

        case visibilityHeader.DISPLAY_EDITED_HEADER_DATA:
            //console.log('header data', payload.alertMode)
            const index = state.addSaveHeaderData.findIndex(
                (item) => item.visibilityId == payload.visibilityId
            )
            state.addSaveHeaderData[index].templateName = payload.templateName
            state.addSaveHeaderData[index].visibilityId = payload.visibilityId
            state.addSaveHeaderData[index].description = payload.description
            state.addSaveHeaderData[index].alertMode = payload.alertMode
            state.addSaveHeaderData[index].alertInfo = payload.alertInfo

            state.addSaveHeaderData[index].createdBy = payload.createdBy
            state.addSaveHeaderData[index].createdDate = payload.createdDate
            state.addSaveHeaderData[index].lastModifiedBy =
                payload.lastModifiedBy
            state.addSaveHeaderData[index].lastModifiedDate =
                payload.lastModifiedDate
            /*console.log(
                (state.addSaveHeaderData[index].templateName =
                    payload.templateName),
                (state.addSaveHeaderData[index].visibilityId =
                    payload.visibilityId),
                (state.addSaveHeaderData[index].description =
                    payload.description),
                (state.addSaveHeaderData[index].alertMode = payload.alertMode),
                (state.addSaveHeaderData[index].alertInfo = payload.alertInfo),
                (state.addSaveHeaderData[index].createdBy = payload.createdBy),
                (state.addSaveHeaderData[index].createdDate =
                    payload.createdDate),
                (state.addSaveHeaderData[index].lastModifiedBy =
                    payload.lastModifiedBy),
                (state.addSaveHeaderData[index].lastModifiedDate =
                    payload.lastModifiedDate)
            )*/
            return {
                ...state,
                loader: false,
                openConfirm: false,
                editHeader: false,
                secondDialogBox: false,
            }

        case visibilityHeader.CLOSE_HEADER_TEMPLATE:
            return {
                ...state,
                templateName: payload,
            }
        case visibilityHeader.ADD_HEADER_DATA_FOR_SAVE:
            return {
                ...state,
                loader: true,
            }
        case visibilityHeader.HEADER_ERROR_OFF_LOADER:
            return {
                ...state,
                loader: false,
            }
        case visibilityHeader.OPEN_VISIBILITY_HEADER_POPUP:
            return {
                ...state,
                loader: true,
                openHeaderDialogBox: true,
                openMenuPopup: false,
                headerAlertInfoLabel: '',
                setLoading: false,
            }
        case visibilityHeader.CLOSE_VISIBILITY_HEADER_POPUP:
            return {
                ...state,
                loader: false,
                openHeaderDialogBox: false,
            }
        case visibilityHeader.GET_INITIAL_VISIBILITY_PAGING:
            return {
                ...state,
                loader: true,
            }
        case visibilityHeader.CLOSE_VISIBILITY_POPUP:
            return {
                ...state,
                loader: false,
                openHeaderDialogBox: false,
            }
        case visibilityHeader.OPEN_VISIBILITY_DETAILS_ROW:
            return {
                ...state,
                loader: false,
                openVisibilityDetailsRow: payload,
            }
        case visibilityHeader.OPEN_VISIBILITY_DETAIL_PANEL:
            return {
                ...state,
                loader: true,
                openVisibilityDetailPanel: true,

                // openVisibilityDetailsRow:false
            }
        case visibilityHeader.OPEN_MENU_POPUP:
            return {
                ...state,
                loader: true,
                openMenuPopup: payload,
            }
        case visibilityHeader.CLOSE_MENU_POPUP:
            return {
                ...state,
                loader: false,
                openMenuPopup: false,
            }
        case visibilityHeader.ADD_VISIBILITY_DETAILS:
            return {
                ...state,
                loader: true,
                detailsTemplateName: payload.templateName,
                detailsAlertMode: payload.alertMode,
                headerAlertInfoLabel:
                    payload.alertMode == 'email'
                        ? 'Alert Email'
                        : payload.alertMode == 'both'
                        ? 'Alert Both'
                        : payload.alertMode == 'text'
                        ? 'Alert Text'
                        : 'Alert Information',
                detailAlertInfo: payload.alertInfo,
                openDetailsPopup: true,
                setLoading: false,
            }
        case visibilityHeader.INITIAL_NEW_DETAIL_EMPTY:
            return {
                ...state,
                startStatus: '',
                endStatus: '',
                processDuration: '',
                severity1Low: '',
                severity2Medium: '',
                severity3High: '',
                processDurationSecond: '00',
                processDurationMinute: '00',
                processDurationHours: '00',
                sev1LowDurationSecond: '00',
                sev1LowDurationMinute: '00',
                sev1LowDurationHours: '01',
                sev1MidDurationSecond: '00',
                sev1MidDurationMinute: '00',
                sev1MidDurationHours: '02',
                sev1HighDurationSecond: '00',
                sev1HighDurationMinute: '00',
                sev1HighDurationHours: '03',
                detailsVisibilityMiscFlag1: '',
                detailsVisibilityMiscFlag2: '',
                detailsVisibilityMiscFlag3: '',
                detailsVisibilityMiscFlag4: '',
                detailsVisibilityMiscFlag5: '',
                detailVisibilityMiscField1: '',
                detailVisibilityMiscField2: '',
                detailVisibilityMiscField3: '',
                detailVisibilityMiscField4: '',
                detailVisibilityMiscField4Error: '',
                detailVisibilityMiscField1Error: '',
                detailVisibilityMiscField2Error: '',
                detailVisibilityMiscField3Error: '',
                detailAlertInfoError: '',
                detailsTemplateNameError: '',
                startStatusError: '',
                endStatusError: '',
                processDurationError: '',
                sev1midDurationError: '',
                sev1LowDurationError: '',
                sev1highDurationError: '',
                setLoading: false,
                sequenceNumber: 1,
                sequenceNumberError: '',
                // detailsAlertMode: '',
                // detailAlertInfo: '',
                // detailsTemplateName: '',
            }
        case visibilityHeader.CLOSE_VISIBILITY_DETAILS_POPUP:
            return {
                ...state,
                loader: false,
                openDetailsPopup: false,
            }
        case visibilityHeader.CLOSE_HEADER_PANEL:
            return {
                ...state,
                loader: false,
                openMenuPopup: false,
            }
        case visibilityHeader.CLOSE_HEADER_PANEL_WITHOUT_LOADING:
            return {
                ...state,
                openMenuPopup: false,
            }
        case visibilityHeader.GET_VISIBILITY_TABLEDATA:
            return {
                ...state,
                loader: true,
            }
        case visibilityHeader.DISPLAY_VISI_HEADER_TABLE_DATA:
            return {
                ...state,
                loader: false,
                visibilityHeaderTableData: payload,
            }
        case visibilityHeader.GET_INITIAL_VISI_TABLE:
            return {
                ...state,
                loader: false,
                getVisibilityPagination: _(payload)
                    .slice(0)
                    .take(payload.length)
                    .value(),
            }
        case visibilityHeader.DISPLAY_VISI_HEADER_TABLE_LIST:
            return {
                ...state,
                loader: false,
                visibilityHeaderTableData: payload,
                getVisibilityPagination: _(payload).slice(0).take(10).value(),
            }
        case visibilityHeader.REQUEST_SAVE_VISI_HEADER:
            return {
                ...state,
                loader: true,
                setLoading: true,
            }
        case visibilityHeader.VISIBILITY_STOP_LOADING:
            return {
                ...state,
                loader: false,
                setLoading: false,
            }
        case visibilityHeader.RESPONSE_SAVE_VISI_HEADER:
            return {
                ...state,
                loader: false,
                openHeaderDialogBox: false,
            }
        case visibilityHeader.INITIAL_NEW_HEADER_EMPTY:
            return {
                ...state,
                loader: true,
                criteriaId: [],
                processId: '',
                description: '',
                alertMode: 'Email',
                alertInfo: '',
                templateName: '',
                visibilityMiscFlag1: '',
                visibilityMiscFlag2: '',
                visibilityMiscFlag3: '',
                visibilityMiscFlag4: '',
                visibilityMiscFlag5: '',
                visibilityMiscField1: '',
                visibilityMiscField2: '',
                visibilityMiscField3: '',
                visibilityMiscField4: '',
                processIdError: '',
                templateNameError: '',
                alertModeError: '',
                alertInfoError: '',
                descriptionError: '',
                visibilityMiscField4Error: '',
            }
        case visibilityHeader.GET_VISIBILITY_SINGLE_BYID:
            console.log('valuee', payload)
            return {
                ...state,
                openVisibilityDetailsRow: true,
                loader: true,
            }
        case visibilityHeader.DISPLAY_VISIBILITY_SINGLE_BYID:
            console.log('single data', payload.description)
            return {
                ...state,
                loader: false,
                rowHeaderIdVisi: payload.id,
                criteriaId: payload.processId,
                displaySingleVisiHeader: payload,
                processId: payload.processId == null ? '' : payload.processId,
                description:
                    payload.description != null ? payload.description : '',
                alertMode: payload.alertMode != null ? payload.alertMode : '',
                headerAlertInfoLabel:
                    payload.alertMode == 'email'
                        ? 'Alert Email'
                        : payload.alertMode == 'both'
                        ? 'Alert Both'
                        : payload.alertMode == 'text'
                        ? 'Alert Text'
                        : 'Alert Information',
                alertInfo: payload.alertInfo != null ? payload.alertInfo : '',
                templateName:
                    payload.templateName != null ? payload.templateName : '',
                visibilityMiscFlag1:
                    payload.miscFlag1 != null ? payload.miscFlag1 : '',
                visibilityMiscFlag2:
                    payload.miscFlag2 != null ? payload.miscFlag2 : '',
                visibilityMiscFlag3:
                    payload.miscFlag3 != null ? payload.miscFlag3 : '',
                visibilityMiscFlag4:
                    payload.miscFlag4 != null ? payload.miscFlag4 : '',
                visibilityMiscFlag5:
                    payload.miscFlag5 != null ? payload.miscFlag5 : '',
                visibilityMiscField1:
                    payload.miscField1 != null ? payload.miscField1 : '',
                visibilityMiscField2:
                    payload.miscField2 != null ? payload.miscField2 : '',
                visibilityMiscField3:
                    payload.miscField3 != null ? payload.miscField3 : '',
                visibilityMiscField4:
                    payload.miscField4 != null ? payload.miscField4 : '',
                visibilityDetailsTable: payload.visibilityDetailsList,
            }
        case visibilityHeader.REQUEST_EDIT_VISIBILITY_HEADER:
            return {
                ...state,
                loader: true,
                processIdError: '',
                templateNameError: '',
                alertModeError: '',
                alertInfoError: '',
                descriptionError: '',
                visibilityMiscField4Error: '',
            }
        case visibilityHeader.INITIAL_EDITERROR_RESET_HEADER:
            return {
                ...state,
                loader: true,
                processIdError: '',
                templateNameError: '',
                alertModeError: '',
                alertInfoError: '',
                descriptionError: '',
                visibilityMiscField4Error: '',
            }
        case visibilityHeader.RESPONSE_EDIT_VISIBILITY_HEADER:
            return {
                ...state,
                loader: false,
                openMenuPopup: false,
            }
        case visibilityHeader.REQUEST_DELETE_VISIBILITY_HEADER:
            return {
                ...state,
                loader: true,
            }
        case visibilityHeader.RESPONSE_DELETE_VISIBILITY_HEADER:
            return {
                ...state,
                loader: false,
                openMenuPopup: false,
            }
        case visibilityHeader.GET_VISIBILITY_TABLE_DETAIL:
            return {
                ...state,
                loader: true,
            }
        case visibilityHeader.DISPLAY_VISI_DETAILS_TABLE:
            return {
                ...state,
                loader: false,
                // visibilityDetailsTable: payload,
            }
        case visibilityHeader.REQUEST_SAVE_VISIBILITY_NEW_DETAILS:
            return {
                ...state,
                loader: true,
                setLoading: true,
            }
        case visibilityHeader.RESPONSE_SAVE_VISIBILITY_NEW_DETAILS:
            return {
                ...state,
                loader: false,
                openDetailsPopup: false,
            }
        case visibilityHeader.GET_DETAILS_BY_ID:
            return {
                ...state,
                loader: true,
                detailRowData: payload,
            }
        case visibilityHeader.DISPLAY_DETAILS_BY_ID:
            var processSpliter = payload.processDuration.split(':')
            state.processDurationSecond = processSpliter[2]
            state.processDurationMinute = processSpliter[1]
            state.processDurationHours = processSpliter[0]
            var sevLow = payload.severity1Low.split(':')
            state.sev1LowDurationSecond = sevLow[2]
            state.sev1LowDurationMinute = sevLow[1]
            state.sev1LowDurationHours = sevLow[0]
            var sevMedium = payload.severity2Medium.split(':')
            state.sev1MidDurationSecond = sevMedium[2]
            state.sev1MidDurationMinute = sevMedium[1]
            state.sev1MidDurationHours = sevMedium[0]
            var sevHigh = payload.severity3High.split(':')
            state.sev1HighDurationSecond = sevHigh[2]
            state.sev1HighDurationMinute = sevHigh[1]
            state.sev1HighDurationHours = sevHigh[0]

            console.log('payload.alertInfo', payload)

            return {
                ...state,
                loader: false,
                detailsRowId: payload.id,
                displaySingleDetails: payload,
                processId: payload.processId,
                detailsAlertMode: payload.alertMode,
                detailAlertInfo: payload.alertInfo,
                detailsTemplateName: payload.templateName,
                startStatus: payload.startStatus,
                endStatus: payload.endStatus,
                processDuration: payload.processDuration,
                headerAlertInfoLabel:
                    payload.alertMode == 'email'
                        ? 'Alert Email'
                        : payload.alertMode == 'both'
                        ? 'Alert Both'
                        : payload.alertMode == 'text'
                        ? 'Alert Text'
                        : 'Alert Information',
                // severity1Low: payload.severity1Low,
                // severity2Medium: payload.severity2Medium,
                // severity3High: payload.severity3High,
                detailsVisibilityMiscFlag1: payload.miscFlag1,
                detailsVisibilityMiscFlag2: payload.miscFlag2,
                detailsVisibilityMiscFlag3: payload.miscFlag3,
                detailsVisibilityMiscFlag4: payload.miscFlag4,
                detailsVisibilityMiscFlag5: payload.miscFlag5,
                detailVisibilityMiscField1: payload.miscField1,
                detailVisibilityMiscField2: payload.miscField2,
                detailVisibilityMiscField3: payload.miscField3,
                detailVisibilityMiscField4: payload.miscField4,
                detailAlertInfo: payload.alertEmail,
                sequenceNumber: payload.sequenceNumber,
            }
        case visibilityHeader.REQUEST_VISIBILITY_EDIT_DETAILS:
            return {
                ...state,
                loader: true,
            }
        case visibilityHeader.RESPONSE_VISIBILITY_EDIT_DETAILS:
            return {
                ...state,
                loader: false,
                openVisibilityDetailsRow: true,
            }
        case visibilityHeader.DELETE_VISIBILITY_DETAILS:
            return {
                ...state,
                loader: true,
            }
        case visibilityHeader.DELETE_RESPONSE_VISIBILITY_DETAILS:
            return {
                ...state,
                loader: false,
                openVisibilityDetailsRow: true,
            }
        case visibilityHeader.VALIDATION_VISIBILITY_HEADER:
            return {
                ...state,
                processIdError: payload.processIdError,
                visibilityMiscField4Error: payload.visibilityMiscField4Error,
                visibilityMiscField3Error: payload.visibilityMiscField3Error,
                alertInfoError: payload.alertInfoError,
            }
        case visibilityHeader.VALIDATION_VISIBILITY_EDIT:
            return {
                ...state,
                processIdError: payload.processIdError,
                visibilityMiscField4Error: payload.visibilityMiscField4Error,
                alertInfoError: payload.alertInfoError,
            }

        case visibilityHeader.GET_TEMPLATE_TABLEDATA:
            return {
                ...state,
                loader: true,
            }
        case visibilityHeader.DISPLAY_TEMPLATE_TABLEDATA:
            return {
                ...state,
                loader: false,
                displayTemplateTableData: payload,
            }
        case visibilityHeader.VALIDATION_VISIBILITY_DETAILS:
            return {
                ...state,
                //processIdError: payload.processIdError,
                // detailsAlertModeError: payload.detailsAlertModeError,
                detailAlertInfoError: payload.detailAlertInfoError,
                detailsTemplateNameError: payload.detailsTemplateNameError,
                detailVisibilityMiscField3Error:
                    payload.detailVisibilityMiscField3Error,
                startStatusError: payload.startStatusError,
                endStatusError: payload.endStatusError,
                detailVisibilityMiscField4Error:
                    payload.detailVisibilityMiscField4Error,
                sev1highDurationError: payload.sev1highDurationError,
                sev1midDurationError: payload.sev1midDurationError,
                sev1LowDurationError: payload.sev1LowDurationError,
                processDurationError: payload.processDurationError,
                detailVisibilityMiscField2Error:
                    payload.detailVisibilityMiscField2Error,
                detailVisibilityMiscField1Error:
                    payload.detailVisibilityMiscField1Error,
                sequenceNumberError: payload.sequenceNumberError,
            }
        case visibilityHeader.RESET_VISIBILITY_DETAILS_ERROR:
            return {
                ...state,
                detailVisibilityMiscField4Error: '',
                detailVisibilityMiscField1Error: '',
                detailVisibilityMiscField2Error: '',
                detailVisibilityMiscField3Error: '',
                detailAlertInfoError: '',
                detailsTemplateNameError: '',
                startStatusError: '',
                endStatusError: '',
                processDurationError: '',
                sev1midDurationError: '',
                sev1LowDurationError: '',
                sev1highDurationError: '',
                detailVisibilityMiscField2Error: '',
                detailVisibilityMiscField1Error: '',
                sequenceNumberError: '',
            }
        case visibilityHeader.PASS_VALUE:
            return {
                ...state,
                loader: false,
                value: payload,
            }
        case visibilityHeader.OPEN_INFO_VISIBILITY:
            return {
                ...state,
                loader: false,
                openInfoPopup: true,
            }
        case visibilityHeader.CLOSE_INFO_VISIBILITY:
            return {
                ...state,
                loader: false,
                openInfoPopup: false,
            }
        case visibilityHeader.GET_VISIBILITY_ID_DROPDOWN:
            return {
                ...state,
                loading: true,
            }
        case visibilityHeader.DISPLAY_VISIBILITY_ID_DROPDOWN:
            console.log('visibilityProcessId', payload)
            return {
                ...state,
                loading: false,
                visibilityProcessId: payload,
            }
        case visibilityHeader.INFO_OPEN_DETAIL_PANEL:
            return {
                ...state,
                loader: true,
                infoRowData: payload,
            }
        case visibilityHeader.PASS_PROCESS_ID:
            console.log('processIdVisi', payload)
            return {
                ...state,
                loader: true,
                processIdVisi: payload,
            }
        case visibilityHeader.CHANGES_PROCESS_DURATION:
            // console.log(
            //     'gbfv',
            //     state.sev1LowDurationHours - 1 == state.processDurationHours
            // )
            return {
                ...state,
                processDurationHours: payload.value,

                sev1LowDurationHours:
                    state.sev1LowDurationHours - 1 == state.processDurationHours
                        ? Number(payload.value) + Number(1) < 10
                            ? '0' + (Number(payload.value) + Number(1))
                            : Number(payload.value) + Number(1)
                        : state.sev1LowDurationHours,

                sev1MidDurationHours:
                    state.sev1MidDurationHours - 2 == state.processDurationHours
                        ? Number(payload.value) + Number(2) < 10
                            ? '0' + (Number(payload.value) + Number(2))
                            : Number(payload.value) + Number(2)
                        : state.sev1MidDurationHours,
                sev1HighDurationHours:
                    state.sev1HighDurationHours - 3 ==
                    state.processDurationHours
                        ? Number(payload.value) + Number(3) < 10
                            ? '0' + (Number(payload.value) + Number(3))
                            : Number(payload.value) + Number(3)
                        : state.sev1HighDurationHours,
            }
        default: {
            return {
                ...state,
            }
        }
    }
}
