import { value } from 'app/views/CommonComponents/JsonFile'
import it from 'react-intl/locale-data/it'
import { commonComponentType } from '../../constant/ActionTypes'
const INITIAL_STATE = {
    loading: false,
    allCommonDatas: [],
    valueTimer: '',
    allJson: '',
    userId: JSON.parse(localStorage.getItem('auth_user')),
    jsonChangeData: [],
    updateJsonId: '',
    cardColor: '',
    cardColorError: '',
    titleColor: '',
    titleColorError: '',
    cardBackgroundColor: '',
    cardBackgroundColorError: '',
    cardTitleColor: '',
    cardTitleColorError: '',
    tableColor: '',
    tableColorError: '',
    tableBackgroundColor: '',
    tableBackgroundColorError: '',
    tableTitleColor: '',
    tableTitleColorError: '',
    graphColor: '',
    graphColorError: '',
    graphBackgroundColor: '',
    graphBackgroundColorError: '',
    graphTitleColor: '',
    graphTitleColorError: '',
    cardPadding: '',
    cardPaddingError: '',
    cardPaddingLeft: '',
    cardPaddingLeftError: '',
    cardPaddingRight: '',
    cardPaddingRightError: '',
    cardPaddingTop: '',
    cardPaddingTopError: '',
    cardPaddingBottom: '',
    cardPaddingBottomError: '',
    remNumber: Array.apply(null, { length: 20 }).map((_, i) => {
        return { value: i, label: i }
    }),
    remsNumber: Array.apply(null, { length: 30 }).map((_, i) => {
        return { value: i, label: i }
    }),
    remNumberDonut: Array.apply(null, { length: 13 }).map((_, i) => {
        return { value: i, label: i }
    }),
    tablePadding: '',
    tablePaddingError: '',
    tablePaddingLeft: '',
    tablePaddingLeftError: '',
    tablePaddingBottom: '',
    tablePaddingBottomError: '',
    tablePaddingTop: '',
    tablePaddingTopError: '',
    tablePaddingRight: '',
    tablePaddingRightError: '',
    cardFontSize: '',
    cardFontSizeError: '',
    tableFontSize: '',
    tableFontSizeError: '',
    titleFontSize: '',
    titleFontSizeError: '',
    tableTitleFontSize: '',
    tableTitleFontSizeError: '',
    fontFamily: '',
    cardFontFamily: '',
    cardFontFamilyError: '',
    tableFontFamily: '',
    tableFontFamilyError: '',
    numberOfRows: '',
    numberOfRowsError: '',
    barDatasetColor1: '',
    barDatasetColor1Error: '',
    columnsAll: [],
    barDatasetColor2: '',
    barDatasetColor2Error: '',
    barTitleFontSize: '',
    barTitleFontSizeError: '',
    donutTitleFontSize: '',
    donutTitleFontSizeError: '',
    barFontFamily: '',
    barFontFamilyError: '',
    donutFontFamily: '',
    donutFontFamilyError: '',
    donutTitleFontSize: '',
    donutTitleFontSizeError: '',
    showEdit: false,
    fontFamilys: [],
    tableSettings: '',
    cardSettings: '',
    graphSettings: '',
    //card style
    fontWeight: '',
    titleFontSize: '',
    titleFontStyle: '',
    titleMarginLeft: '',
    titleMarginRight: '',
    titleMarginTop: '',
    titleMarginBottom: '',
    cardMargin: '',
    marginLeft: '',
    marginRight: '',
    marginTop: '',
    marginBottom: '',
    //table style
    textAlign: '',
    verticalAlign: '',
    tableMargin: '',
    headerFontSize: '',
    headerFontColor: '',
    headerBackgroundColor: '',
    tableTitleFontStyle: '',
    highlightColor: '',
    rowSelectedBackgroundColor: '',
    // garph style
    fontSize: '',
    color: '',
    barDatasetColor1: '',
    barDatasetColor2: '',
    backgroundColor: '',
    fontWeight: '',
    fontFamily: '',
    headerFontSize: '',
    headerFontColor: '',
    numberOfRows: '',
    titleColor: '',
    graphTitleFontSize: '',
    graphTitleFontStyle: '',
    titleMarginLeft: '',
    titleMarginRight: '',
    titleMarginTop: '',
    titleMarginBottom: '',
    graphMargin: '',
    paddingLeft: '',
    height: '',
    width: '',
    paddingRight: '',
    paddingTop: '',
    paddingBottom: '',
    marginLeft: '',
    marginRight: '',
    marginTop: '',
    marginBottom: '',
    barTitleFontSize: '',
    barFontFamily: '',
    donutFontFamily: '',
    donutTitleFontSize: '',
    dashboardData: [],
    tempDashboardData: [],
    childDataId: '',
    donutRefreshDuration: '',
    barRefreshDuration: '',
    cardRefreshDuration: '',
    refreshDuration: '',
    tableUpdatedValues: '',
    barUpdatedValues: '',
    donutUpdatedValues: '',
    cardUpdatedValues: '',
    cardVal: '',
    specificComponent: '',
    barShownType: false,
    cardShownType: false,
    doughnutShownType: false,
    tableShownType: false,
    doughnutColumns: [],
    tableColumns: [],
    donutColumn: [],
    dashboardDataAll: '',
    singleDashboardData: '',
    dashboardLoading: false,
}
export default (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case commonComponentType.COMMON_INPUT_CHANGE: {
            return {
                ...state,
                [payload.props]: payload.value,
                [payload.error]: '',
                loading: false,
                //   color: [payload.props]: payload.value,
            }
        }
        case commonComponentType.GET_ALL_DATA:
            return {
                ...state,
                loading: true,
            }
        case commonComponentType.COMMON_STOP_LOADING:
            return {
                ...state,
                loading: false,
            }
        case commonComponentType.DISPLAY_ALL_DATA:
            return {
                ...state,
                loading: false,
                allCommonDatas: payload,
            }
        case commonComponentType.GET_JSON_TEXT_DATA:
            // console.log("userIdreducers", payload)
            return {
                ...state,
                loading: true,
            }
        case commonComponentType.DISPLAY_JSON_ALL_DATA:
            var type = payload[0] ? JSON.parse(payload[0].fileData.trim()) : []

            let tableSetting, cardSetting, graphSetting
            var allArray =
                type &&
                type.forEach((element) => {
                    if (element.displaytype == 'table') {
                        tableSetting = element.designsettings
                    }
                })
            var allArray =
                type &&
                type.forEach((element) => {
                    if (element.displaytype == 'card') {
                        cardSetting = element.styleText
                    }
                })
            var allArray =
                type &&
                type.forEach((element) => {
                    if (element.displaytype == 'graph') {
                        graphSetting = element.styleGraph
                    }
                })
            return {
                ...state,
                loading: false,
                jsonChangeData: type,
                updateJsonId: payload[0] ? payload[0].id : 0,
                tableSettings: tableSetting,
                cardSettings: cardSetting,
                graphSettings: graphSetting,
            }

        case commonComponentType.COMMON_ERROR_UNAUTH:
            localStorage.clear()
            window.location.reload()
            return {
                ...state,
                loading: false,
            }
        case commonComponentType.PASS_TIMER_VALUE:
            return {
                ...state,
                valueTimer: payload,
            }
        case commonComponentType.REQUEST_SAVE_NEW_JSONDATA:
            return {
                ...state,
                loading: true,
            }
        case commonComponentType.RESPONSE_SAVE_NEW_JSONDATA:
            return {
                ...state,
                loading: false,
            }
        case commonComponentType.DISPLAY_JSON_ALL_DATA:
            // console.log("map",payload.map((it)=> it.fileData.replace('\n', ' ')));
            console.log('trim', JSON.stringify(it.fileData.trim()))

            return {
                ...state,
                allJson: payload.map((it) =>
                    JSON.stringify(it.fileData.trim())
                ),
            }
        case commonComponentType.REQUEST_SAVE_UPDATE_JSONDATA:
            return {
                ...state,
                loading: true,
            }
        case commonComponentType.RESPONSE_SAVE_UPDATE_JSONDATA:
            return {
                ...state,
                loading: false,
                showEdit: false,
            }
        case commonComponentType.RESPONSE_FONT_FAMILY:
            return {
                ...state,
                loading: false,
                fontFamilys: payload,
            }
        case commonComponentType.UPDATE_JSON_DATA:
            return {
                ...state,
                loading: false,
                jsonChangeData: payload,
            }
        case commonComponentType.SHOW_EDIT_JSON:
            return {
                ...state,
                loading: true,
                showEdit: payload,
            }
        case commonComponentType.EDITED_JSON_DATA:
            return {
                ...state,
                loading: true,
                jsonChangeData: payload,
            }
        // case commonComponentType.SAVE_UPDATED_JSON_TABLE:
        //     return {
        //         ...state,
        //         loading: true,
        //     }
        // case commonComponentType.RESPONSE_UPDATED_JSON_TABLE:
        //     return {
        //         ...state,
        //         loading: false,
        //     }
        case commonComponentType.GET_DASHBOARD_DATA:
            return {
                ...state,
                loading: true,
                dashboardLoading: true,
            }
        case commonComponentType.DASHBOARD_STOP_LOADING:
            return {
                ...state,
                loading: false,
                dashboardLoading: false,
            }
        case commonComponentType.TEMP_DISPLAY_DASHBOARD_DATA:
            console.log('displayDashboard', payload)
            const dashboardData =
            Array.isArray(payload) &&
                payload.map((values) => {
                    return {
                        columns: values.columns,
                        parentColumns:
                            values.columns &&
                            values.columns.filter(
                                (val) => val.parentSequence == 0
                            ),
                        designsettings: JSON.parse(values.designSettings),
                        displayMode: values.displayMode
                            ? values.displayMode
                            : 'dashboard',
                        displayType: values.displayType,
                        labels: values.labels && values.labels,
                        refreshDuration: values.refreshDuration,
                        donutRefreshDuration:
                            values.displayType == 'DoughnutChart' &&
                            values.refreshDuration,
                        cardRefreshDuration:
                            values.displayType == 'Card' &&
                            values.refreshDuration,
                        cardRefreshDuration:
                            values.displayType == 'Barchart' &&
                            values.refreshDuration,
                        reportId: values.reportId,
                        reportDescription: values.reportDescription,
                        transactionName: values.transactionName,
                        list: values.list,
                        widgetHeight: values.widgetHeight
                            ? values.widgetHeight
                            : 300,
                    }
                })

            return {
                ...state,
                loading: false,
                barShownType: false,
                cardShownType: false,
                doughnutShownType: false,
                tableShownType: false,
                dashboardLoading: false,
                tempDashboardData: JSON.parse(
                    JSON.stringify(dashboardData).replace(
                        /( +)(?=[(\w* *]*":)/g,
                        ''
                    )
                ),
            }
        case commonComponentType.CHILD_DATA_ID:
            return {
                ...state,
                childDataId: payload,
            }
        case commonComponentType.DISPLAY_DASHBOARD_DATA:
            var listData = payload && payload.map((values) => values.list)

            // console.log('listData', listData && listData.map((item) => item))
            return {
                ...state,
                loading: false,
                dashboardData:
                    payload &&
                    payload.map((values) => {
                        return {
                            column: values.columns,
                            designsettings: JSON.parse(values.designSettings),
                            displayMode: values.displayMode
                                ? values.displayMode
                                : 'dashboard',
                            displayType: values.displayType,
                            list: values.list,
                            refreshDuration: values.refreshDuration,
                            reportId: values.reportId,
                            transactionName: values.transactionName,
                        }
                    }),
            }
        case commonComponentType.REFRESH_DASHBOARD_DATA:
            return {
                ...state,
                loading: true,
            }
        case commonComponentType.REFRESH_DASHBOARD_DONUT:
            return {
                ...state,
                loading: true,
            }
        case commonComponentType.REFRESH_DASHBOARD_BAR:
            return {
                ...state,
                loading: true,
            }
        case commonComponentType.REFRESH_DASHBOARD_CARD:
            return {
                ...state,
                loading: true,
            }
        case commonComponentType.PASS_ALL_COLUMN_VALUES:
            return {
                ...state,
                columnsAll: payload.columns,
                parentColumns: payload.parentColumns,
            }
        case commonComponentType.RESPONSE_REFRESH_DURATION_RESULT:
            const valu = state.tempDashboardData.map((values) => {
                if (values.reportId == payload.reportId) {
                    return {
                        ...values,
                        columns: payload.columns,
                        parentColumns:
                            values.columns &&
                            values.columns.filter(
                                (val) => val.parentSequence == 0
                            ),
                        designsettings: JSON.parse(payload.designSettings),
                        displayMode: values.displayMode
                            ? values.displayMode
                            : 'dashboard',
                        displayType: values.displayType,
                        labels: values.labels && values.labels,
                        refreshDuration: payload.refreshDuration,
                        reportId: values.reportId,
                        reportDescription: values.reportDescription,
                        transactionName: values.transactionName,
                        list: payload.list,
                    }
                } else {
                    return {
                        ...values,
                    }
                }
            })
            return {
                ...state,
                loading: false,
                tableShownType: true,
                tempDashboardData: JSON.parse(
                    JSON.stringify(valu).replace(/( +)(?=[(\w* *]*":)/g, '')
                ),
                tableUpdatedValues: {
                    columns: payload.columns,
                    parentColumns:
                        state.columnsAll &&
                        state.columnsAll.filter(
                            (val) => val.parentSequence == 0
                        ),
                    designsettings: JSON.parse(payload.designSettings),
                    displayMode: payload.displayMode
                        ? payload.displayMode
                        : 'dashboard',
                    displayType: payload.displayType,
                    labels: payload.labels && payload.labels,
                    refreshDuration: payload.refreshDuration,
                    reportId: payload.reportId,
                    reportDescription: payload.reportDescription,
                    transactionName: payload.transactionName,
                    list: payload.list,
                },
            }
        case commonComponentType.RESPONSE_REFRESH_DASHBOARD_CARD:
            const cardValues = state.tempDashboardData.map((values) => {
                if (values.reportId == payload.reportId) {
                    return {
                        ...values,
                        columns: values.columns,
                        parentColumns:
                            values.columns &&
                            values.columns.filter(
                                (val) => val.parentSequence == 0
                            ),
                        designsettings: JSON.parse(payload.designSettings),
                        displayMode: values.displayMode
                            ? values.displayMode
                            : 'dashboard',
                        displayType: values.displayType,
                        labels: values.labels && values.labels,
                        refreshDuration: payload.refreshDuration,
                        reportId: values.reportId,
                        reportDescription: values.reportDescription,
                        transactionName: values.transactionName,
                        list: payload.list,
                    }
                } else {
                    return {
                        ...values,
                    }
                }
            })
            console.log('statecolumnsAllcardValues', cardValues)

            return {
                ...state,
                loading: false,
                cardShownType: true,
                tempDashboardData: JSON.parse(
                    JSON.stringify(cardValues).replace(
                        /( +)(?=[(\w* *]*":)/g,
                        ''
                    )
                ),
                cardUpdatedValues: {
                    columns: payload.columns,
                    parentColumns:
                        payload.columns &&
                        payload.columns.filter(
                            (val) => val.parentSequence == 0
                        ),
                    designsettings: JSON.parse(payload.designSettings),
                    displayMode: payload.displayMode
                        ? payload.displayMode
                        : 'dashboard',
                    displayType: payload.displayType,
                    labels: payload.labels && payload.labels,
                    refreshDuration: payload.refreshDuration,
                    reportId: payload.reportId,
                    reportDescription: payload.reportDescription,
                    transactionName: payload.transactionName,
                    list: payload.list,
                },
            }
        case commonComponentType.RESPONSE_REFRESH_DASHBOARD_BAR:
            const barValues = state.tempDashboardData.map((values) => {
                if (values.reportId == payload.reportId) {
                    return {
                        ...values,
                        columns: values.columns,
                        parentColumns:
                            values.columns &&
                            values.columns.filter(
                                (val) => val.parentSequence == 0
                            ),
                        designsettings: JSON.parse(payload.designSettings),
                        displayMode: values.displayMode
                            ? values.displayMode
                            : 'dashboard',
                        displayType: values.displayType,
                        labels: values.labels && values.labels,
                        refreshDuration: payload.refreshDuration,
                        reportId: values.reportId,
                        reportDescription: values.reportDescription,
                        transactionName: values.transactionName,
                        list: payload.list,
                    }
                } else {
                    return {
                        ...values,
                    }
                }
            })
            return {
                ...state,
                loading: false,
                barShownType: true,
                tempDashboardData: JSON.parse(
                    JSON.stringify(barValues).replace(
                        /( +)(?=[(\w* *]*":)/g,
                        ''
                    )
                ),
                barUpdatedValues: {
                    columns: payload.columns,
                    parentColumns:
                        payload.columns &&
                        payload.columns.filter(
                            (val) => val.parentSequence == 0
                        ),
                    designsettings: JSON.parse(payload.designSettings),
                    displayMode: payload.displayMode
                        ? payload.displayMode
                        : 'dashboard',
                    displayType: payload.displayType,
                    labels: payload.labels && payload.labels,
                    refreshDuration: payload.refreshDuration,
                    reportId: payload.reportId,
                    reportDescription: payload.reportDescription,
                    transactionName: payload.transactionName,
                    list: payload.list,
                },
            }
        case commonComponentType.PASS_ALL_DONUT_COLUMN_VALUES:
            return {
                ...state,
                donutColumn: payload.columns,
            }
        case commonComponentType.RESPONSE_REFRESH_DASHBOARD_DONUT:
            const donutValues = state.tempDashboardData.map((values) => {
                if (values.reportId == payload.reportId) {
                    return {
                        ...values,
                        columns: values.columns,
                        parentColumns:
                            values.columns &&
                            values.columns.filter(
                                (val) => val.parentSequence == 0
                            ),
                        designsettings: JSON.parse(payload.designSettings),
                        displayMode: values.displayMode
                            ? values.displayMode
                            : 'dashboard',
                        displayType: values.displayType,
                        labels: values.labels && values.labels,
                        refreshDuration: values.refreshDuration,
                        reportId: values.reportId,
                        reportDescription: payload.reportDescription,
                        transactionName: values.transactionName,
                        list: payload.list,
                    }
                } else {
                    return {
                        ...values,
                    }
                }
            })
            return {
                ...state,
                loading: false,
                tempDashboardData: JSON.parse(
                    JSON.stringify(donutValues).replace(
                        /( +)(?=[(\w* *]*":)/g,
                        ''
                    )
                ),
                doughnutShownType: true,
                donutUpdatedValues: {
                    columns: state.donutColumn,
                    parentColumns:
                        payload.columns &&
                        payload.columns.filter(
                            (val) => val.parentSequence == 0
                        ),
                    designsettings: JSON.parse(payload.designSettings),
                    displayMode: payload.displayMode
                        ? payload.displayMode
                        : 'dashboard',
                    displayType: payload.displayType,
                    labels: payload.labels && payload.labels,
                    refreshDuration: payload.refreshDuration,
                    reportId: payload.reportId,
                    reportDescription: payload.reportDescription,
                    transactionName: payload.transactionName,
                    list: payload.list,
                },
            }
        case commonComponentType.RESET_SHOW_COMPONENT:
            return {
                ...state,
                specificComponent: '',
            }
        case commonComponentType.SHOW_COMPONENT_SPECIFIC:
            return {
                ...state,
                loading: false,
                specificComponent: payload,
            }
        case commonComponentType.GET_MAIN_DASHBOARD_DATA:
            return {
                ...state,
                loading: true,
            }
        case commonComponentType.DISPLAY_MAIN_DASHBOARD_DATA:
            return {
                ...state,
                loading: false,
                columnsAll: [],
                parentColumns: [],
                singleDashboardData: '',
                dashboardDataAll: payload,
            }
        case commonComponentType.GET_SINGLE_DASHBOARD_DATA:
            return {
                ...state,
                loading: true,
            }
        case commonComponentType.DISPLAY_SINGLE_DASHBOARD_DATA:
            let graphdesign = JSON.parse(payload.designSettings)
            return {
                ...state,
                loading: false,
                singleDashboardData: JSON.parse(
                    JSON.stringify(payload).replace(/( +)(?=[(\w* *]*":)/g, '')
                ),
                designSettings: JSON.parse(payload.designSettings),
                donutRefreshDuration:
                    payload.displayType == 'DoughnutChart'
                        ? payload.refreshDuration
                        : 60,
                cardRefreshDuration:
                    payload.displayType == 'Card'
                        ? payload.refreshDuration
                        : 60,
                barRefreshDuration:
                    payload.displayType == 'Barchart'
                        ? payload.refreshDuration
                        : 60,
                headerBackgroundColor: graphdesign.headerBackgroundColor,
                headerFontColor: graphdesign.headerFontColor,
                headerFontSize: graphdesign.headerFontSize,
                highlightColor: graphdesign.highlightColor,
                margin: graphdesign.margin,
                numberOfRows: graphdesign.numberOfRows,
                rowSelectedBackgroundColor:
                    graphdesign.rowSelectedBackgroundColor,
                tableBackgroundColor: graphdesign.tableBackgroundColor
                    ? graphdesign.tableBackgroundColor
                    : '#fcfaf5',
                tableColor: graphdesign.tableColor
                    ? graphdesign.tableColor
                    : 'black',
                tableFontFamily: graphdesign.tableFontFamily
                    ? graphdesign.tableFontFamily
                    : 'arial',
                tableFontSize: graphdesign.tableFontSize
                    ? graphdesign.tableFontSize
                    : 12,
                tablePaddingBottom: graphdesign.tablePaddingBottom
                    ? graphdesign.tablePaddingBottom
                    : '0rem',
                tablePaddingLeft: graphdesign.tablePaddingLeft
                    ? graphdesign.tablePaddingLeft
                    : '0rem',
                tablePaddingTop: graphdesign.tablePaddingTop
                    ? graphdesign.tablePaddingTop
                    : '0rem',
                tablePaddingRight: graphdesign.tablePaddingRight
                    ? graphdesign.tablePaddingRight
                    : '0rem',
                tableTitleFontSize: graphdesign.tableTitleFontSize
                    ? graphdesign.tableTitleFontSize
                    : 15,
                tableTitleFontStyle: graphdesign.tableTitleFontStyle
                    ? graphdesign.tableTitleFontStyle
                    : 'arial',
                textAlign: graphdesign.textAlign,
                verticalAlign: graphdesign.verticalAlign,

                donutTitleFontSize: graphdesign.donutTitleFontSize,
                donutFontFamily: graphdesign.donutFontFamily
                    ? graphdesign.donutFontFamily
                    : 'arial',
                donutHeightStyle: graphdesign.donutHeightStyle,
                donutWidthStyle: graphdesign.donutWidthStyle,
                gridDonut: graphdesign.gridDonut ? graphdesign.gridDonut : 11,
                headerDonutColor: graphdesign.headerDonutColor,
                barTitleFontSize: graphdesign.barTitleFontSize
                    ? graphdesign.barTitleFontSize
                    : '20',
                barFontFamily: graphdesign.barFontFamily
                    ? graphdesign.barFontFamily
                    : 'arial',
                headerBarColor: graphdesign.headerBarColor
                    ? graphdesign.headerBarColor
                    : 'red',
                barWidthStyle: graphdesign.barWidthStyle
                    ? graphdesign.barWidthStyle
                    : 86,
                barHeightStyle: graphdesign.barHeightStyle
                    ? graphdesign.barHeightStyle
                    : 343,
                gridDonut: graphdesign.gridDonut ? graphdesign.gridDonut : 1,
                cardColor: graphdesign.cardColor
                    ? graphdesign.cardColor
                    : '#fcfaf5',
                cardBackgroundColor: graphdesign.cardBackgroundColor
                    ? graphdesign.cardBackgroundColor
                    : '#fcfaf5',
                cardTitleColor: graphdesign.cardTitleColor
                    ? graphdesign.cardTitleColor
                    : 'black',
                cardPaddingLeft: graphdesign.cardPaddingLeft
                    ? graphdesign.cardPaddingLeft
                    : '0rem',
                cardPaddingRight: graphdesign.cardPaddingRight
                    ? graphdesign.cardPaddingRight
                    : '0rem',
                cardPaddingTop: graphdesign.cardPaddingTop
                    ? graphdesign.cardPaddingTop
                    : '0rem',
                cardPaddingBottom: graphdesign.cardPaddingBottom
                    ? graphdesign.cardPaddingBottom
                    : '0rem',
                cardFontSize: graphdesign.cardFontSize
                    ? graphdesign.cardFontSize
                    : 12,
                titleFontSize: graphdesign.titleFontSize
                    ? graphdesign.titleFontSize
                    : 15,
                cardFontFamily: graphdesign.cardFontFamily
                    ? graphdesign.cardFontFamily
                    : 'arial',
                cardTitleFontFamily: graphdesign.cardTitleFontFamily
                    ? graphdesign.cardTitleFontFamily
                    : 'arial',
                cardHeaderFontSize: graphdesign.cardHeaderFontSize
                    ? graphdesign.cardHeaderFontSize
                    : 15,
                cardHeaderColor: graphdesign.cardHeaderColor
                    ? graphdesign.cardHeaderColor
                    : 'black',
                cardHeaderFontFamily: graphdesign.cardHeaderFontFamily
                    ? graphdesign.cardHeaderFontFamily
                    : 'arial',
                component: payload.displayType,
                refreshDuration: payload.refreshDuration,
                displayType: payload.displayType,
                displayMode: payload.displayMode,
            }
        default:
            return { ...state }
    }
}
