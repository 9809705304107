import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'

const MultipleTables = Loadable(lazy(() => import('./MultipleTables')));



const MultipleTableRoutes = [
    {
        path: '/MultipleTables',
        element: <MultipleTables />,
    },
]

export default MultipleTableRoutes
