import { generalFunction } from 'app/constant/Common'
import { lookupType } from '../../constant/ActionTypes'

const INITIAL_STATE = {
    loader: false,
    groupId: '',
    disableGroupId: false,
    modeOpen: false,
    visibilityId: '',
    tagName: '',
    generatedID: '',
    tagPosition: '',
    lookupDetailsId: 0,
    lookupHeaderUid: 0,
    groupIdError: '',
    visibilityIdError: '',
    tagNameError: '',
    tagPositionError: '',
    headerPopup: false,
    detailsPopup: false,
    lookupHeaderList: [],
    lookupDetailsList: [],
    nextPopUp: false,
    groupIdList: Array.apply(null, { length: 61 }).map((_, i) => {
        return { value: i, label: i }
    }),
    tagPositionList: generalFunction.tagPosition(),
    templateList: [],
    templateName: '',
    templateFile: '',
    fileName: '',
    templateFileError: '',
    templateNameError: '',
    openCard: false,
}

export default (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case lookupType.LOOKUP_HEADER_DELETE_REQUEST: {
            return { ...state, loader: true, nextPopUp: false }
        }
        case lookupType.LOOKUP_TEMPLATE_DELETE_RESPONSE: {
            return {
                ...state,
                loader: false,
                templateList: state.templateList.filter((res) => {
                    if (res.templateName != payload.templateName) {
                        return res
                    }
                }),
            }
        }
        case lookupType.LOOKUP_HEADER_DELETE_RESPONSE: {
            return {
                ...state,
                loader: false,
                lookupHeaderList: state.lookupHeaderList.filter((res) => {
                    if (
                        res.groupId != payload.groupId &&
                        res.visibilityId != payload.visibilityId
                    ) {
                        return res
                    }
                }),
            }
        }
        case lookupType.LOOKUP_HEADER_DELETE_ERROR: {
            localStorage.clear()
            window.location.reload()
            return {
                ...state,
                loader: false,
            }
        }
        case lookupType.LOOKUP_EDIT_REQUEST: {
            return {
                ...state,
                loader: true,
                groupId: '',
                visibilityId: '',
                groupIdError: '',
                visibilityIdError: '',
                headerPopup: false,
            }
        }
        case lookupType.LOOKUP_EDIT_RESPONSE: {
            return {
                ...state,
                loader: true,
                groupId: payload.groupId,
                visibilityId: payload.visibilityId,
                groupIdError: '',
                visibilityIdError: '',
                headerPopup: true,
            }
        }
        case lookupType.LOOKUP_EDIT_ERROR: {
            return {
                ...state,
                loader: false,
                headerPopup: false,
            }
        }
        case lookupType.LOOKUP_LIST_REQUEST: {
            return { ...state, loader: true, nextPopUp: false }
        }
        case lookupType.LOOKUP_LIST_RESPONSE: {
            return { ...state, loader: false, lookupHeaderList: payload.sort() }
        }
        case lookupType.LOOKUP_LIST_ERROR: {
            return { ...state, loader: false }
        }
        case lookupType.LOOKUP_TEMPLATE_SAVE_REQUEST:
            return { ...state, loader: true }
        case lookupType.LOOKUP_TEMPLATE_SAVE_RESPONSE:
            return {
                ...state,
                loader: false,
                templateList: [
                    ...state.templateList,
                    {
                        templateId: payload.templateId,
                        templateName: payload.templateName,
                        templateFile: payload.templateFile,
                    },
                ],
            }
        case lookupType.LOOKUP_TEMPLATE_ERROR:
            return {
                ...state,
                loader: false,
                templateFileError: payload.templateFileError
                    ? payload.templateFileError
                    : '',
                templateNameError: payload.templateNameError
                    ? payload.templateNameError
                    : '',
            }
        case lookupType.LOOKUP_SAVE_RESPONSE:
            //console.log('cdcsdcsd', payload)
            return {
                ...state,
                loader: false,

                lookupHeaderList:
                    payload.mode == 1
                        ? [
                              ...state.lookupHeaderList,
                              {
                                  groupId: payload.groupId,
                                  visibilityId: payload.visibilityId,
                              },
                          ]
                        : state.lookupHeaderList,
                lookupDetailsList:
                    payload.mode == 2
                        ? [
                              ...state.lookupDetailsList,
                              {
                                  groupId: payload.groupId,
                                  tag: payload.tagName,
                                  position: payload.tagPosition,
                                  generatedID: payload.generatedID,
                              },
                          ]
                        : state.lookupDetailsList,
                headerPopup: false,
                detailsPopup: false,
                groupId: payload.groupId,
                visibilityId: payload.mode == 1 ? payload.visibilityId : '',
                tagName: '',
                tagPosition: '',
                lookupDetailsId: 0,
                lookupHeaderUid: 0,
                nextPopUp: payload.mode == 1 ? true : false,
            }
        case lookupType.LOOK_UP_SAVE:
            return { ...state, loader: true }
        case lookupType.LOOK_UP_HEADER_OPEN:
            return {
                ...state,
                headerPopup: true,
                fileName: '',
                groupId: '',
                visibilityId: '',
                templateName: '',
                templateFile: '',
                groupIdError: '',
                visibilityIdError: '',
                tagNameError: '',
                tagPositionError: '',
                templateFileError: '',
                templateNameError: '',
            }
        case lookupType.LOOK_UP_HEADER_CLOSE:
            return {
                ...state,
                headerPopup: false,
                templateName: '',
                templateFile: '',
                fileName: '',
                templateFileError: '',
                templateNameError: '',
            }
        case lookupType.LOOK_UP_DETAILS_OPEN:
            return {
                ...state,
                detailsPopup: true,
                groupId: state.modeOpen ? state.groupId : '',
                tagName: '',
                generatedID: '',
                tagPosition: '',
                groupIdError: '',
                visibilityIdError: '',
                tagNameError: '',
                tagPositionError: '',
                templateFileError: '',
                templateNameError: '',
            }
        case lookupType.LOOK_UP_DETAILS_CLOSE:
            return {
                ...state,
                detailsPopup: false,
            }
        case lookupType.LOOKUP_INPUT_CHANGE:
            return {
                ...state,
                [payload.props]: payload.value,
                [payload.error]: '',
                loader: false,
            }
        case lookupType.LOOKUP_ERROR:
            return {
                ...state,
                groupIdError: payload.groupIdError ? payload.groupIdError : '',
                visibilityIdError: payload.visibilityIdError
                    ? payload.visibilityIdError
                    : '',
                tagNameError: payload.tagNameError ? payload.tagNameError : '',
                tagPositionError: payload.tagPositionError
                    ? payload.tagPositionError
                    : '',
            }
        //=================================================== Look Up details Reducers=============================

        case lookupType.GET_LOOKUP_DETAILS_LIST:
            return {
                ...state,
                loader: true,
                nextPopUp: false,
                modeOpen: false,
            }
        case lookupType.DISPLAY_ALL_LOOKUP_DETAILS:
            return {
                ...state,
                loader: false,
                lookupDetailsList: payload.sort(),
            }
        case lookupType.GET_LOOKUP_EDIT_DETAILS:
            return {
                ...state,
                loader: true,
            }
        case lookupType.DISPLAY_EDITED_DATA:
            return {
                ...state,
                loader: false,
                detailsPopup: true,
                groupId: payload.groupId,
                tagName: payload.tag,
                tagPosition: payload.position,
                generatedID: payload.generatedID,
                tagNameError: '',
                groupIdError: '',
                tagPositionError: '',
            }
        case lookupType.ADD_LOOKUP_DETAILS:
            return {
                ...state,
                loader: true,
            }
        case lookupType.DELETE_LOOKUP_DETAILS:
            return {
                ...state,
                loader: true,
            }
        case lookupType.DELETED_SHOW_LIST:
            return {
                ...state,
                loader: false,
                lookupDetailsList: state.lookupDetailsList
                    .filter((item) => item.generatedID != payload)
                    .map((item) => item),
            }
        case lookupType.SAVE_EDIT_LOOKUP_DETAILS:
            return {
                ...state,
                loader: true,
            }

        case lookupType.DISPLAY_EDIT_DATA_LOOKUP:
            const index = state.lookupDetailsList.findIndex(
                (item) => item.generatedID == payload.generatedID
            )
            //console.log(
            //     (state.lookupDetailsList[index].generatedID =
            //         payload.generatedID),
            //     (state.lookupDetailsList[index].groupId = payload.groupId),
            //     (state.lookupDetailsList[index].position = payload.position),
            //     (state.lookupDetailsList[index].tag = payload.tag)
            // )
            return {
                ...state,
                loader: false,
                detailsPopup: false,
            }

        //==================================================Template Reducers ================================

        case lookupType.GET_ALL_TEMPLATE_DETAILS:
            return {
                ...state,
                loader: true,
            }

        case lookupType.DISPLAY_ALL_TEMPLATE_LIST:
            return {
                ...state,
                loader: false,
                templateList: payload,
            }

        case lookupType.GET_EDITABLE_TEMPLATE_BY_ID:
            return {
                ...state,
                loader: true,
                visibilityIdError: '',
                tagNameError: '',
                tagPositionError: '',
                templateFileError: '',
                templateNameError: '',
            }
        case lookupType.DISPLAY_TEMPLATE_BY_ID:
            return {
                ...state,
                loader: false,
                headerPopup: true,
                templateName: payload.templateName,
                fileName: payload.fileName,
            }
        case lookupType.DELETE_TEMPLATE_DATA:
            return {
                ...state,
                loader: true,
            }

        case lookupType.SAVE_TEMPLATE_DATA:
            return {
                ...state,
                loader: true,
            }

        case lookupType.TEMPLATE_SAVE_DISPLAY:
            return {
                ...state,
                loader: false,
                headerPopup: false,
                templateList: [...state.templateList, payload],
            }
        case lookupType.EDITED_SAVE_TEMPLATE_DATA:
            return {
                ...state,
                loader: true,
            }

        case lookupType.DISPLAY_EDIT_TEMPLATE_DATA:
            const editIndex = state.templateList.findIndex(
                (item) => item.templateName == payload.templateName
            )
            // console.log(
            //     (state.templateList[editIndex].templateName =
            //         payload.templateName),
            //     (state.templateList[editIndex].fileName = payload.fileName)
            // )
            return {
                ...state,
                loader: false,
                headerPopup: false,
                fileName: '',
            }

        case lookupType.OPEN_DETAIL_LOOKUP_PANEL:
            return {
                ...state,
                detailsPopup: true,
                nextPopUp: false,
                groupId: payload.props,
                modeOpen: true,
                tagName: '',
                generatedID: '',
                tagPosition: '',
                visibilityIdError: '',
                tagNameError: '',
                tagPositionError: '',
            }
        case lookupType.INITIAL_EMPTY_TEMPLATE_DATA:
            return {
                ...state,
                templateName: '',
                templateFile: '',
                fileName: '',
                templateFileError: '',
                templateNameError: '',
            }
        case lookupType.LOOK_UP_SAVE_FAILED:
            return {
                ...state,
                loader: false,
            }
        case lookupType.SET_TRUE_CARD:
            console.log('moc', payload)

            return {
                ...state,
                loader: false,
                openCard: true,
            }

        case lookupType.SET_CLOSE_CARD:
            return {
                ...state,
                loader: false,
                openCard: false,
            }

        default:
            return { ...state }
    }
}
