import React, { useState } from 'react'
import { TrainRounded } from '@mui/icons-material'
import { adminUserConstant } from '../../constant/ActionTypes'
import _ from 'lodash'

const INITIAL_STATE = {
    loader: false,
    addNewUserPage: false,
    addNewGrpPage: false,
    roleSelection: '2',
    userName: '',
    editUser: false,
    editGrpPage: false,
    ownUser: false,
    userEmailId: '',
    hideEditDelete: false,
    password: '',
    editGrpData: [],
    confirmPassword: '',
    forceChangePassword: false,
    emailVerification: false,
    adminRolesList: [],
    getRolesAllocate: [],
    groupNameSelection: '',
    groupListTable: [],
    saveRole: [],
    phoneNumber: '',
    phoneNumberError: '',
    userNameError: '',
    userEmailIdError: '',
    address: '',
    addressError: '',
    passwordError: '',
    confirmPasswordError: '',
    roleSelectionError: '',
    groupUserFirstName: '',
    groupEditList: [],
    groupUserFirstNameError: '',
    generatedID: '',
    editGroupName: '',
    groupName: '',
    editRoles: [],
    userLastName: '',
    PopupOpen: false,
    userId: JSON.parse(localStorage.getItem('auth_user')),
    groupDescription: '',
    groupDescriptionError: '',
    roleListRowData: [],
    groupNameSelectionError: '',
    ownUserCantDelete: '',
    singleGrpData: '',
    data: '',
    newRoleName: '',
    allRoles: '',
    newRoleId: '',
    newRoleIdError: '',
    saveEditRole: [],
    newRoleNameError: '',
    newDescription: '',
    newDescriptionError: '',
    editRolePage: false,
    addNewRolePage: false,
    headerPopup: false,
    getUserPagination: [],
    getGrpPagination: [],
    groupUserFirstNameError: '',
    saveRoleNew: [],
    groupDescriptionError: '',
    userGender: 'Female',
    userGenderError: '',
    // pagination: _(allRoles).slice(0).take(allRoles.length).value(),
    pageSize: '',
    userPopupOpen: false,
    grpPopupOpen: false,
    rolePopupOpen: false,
    userEmailIdError: '',
    groupNameSelectionError: '',
    userFirstNameError: '',
    userLastNameError: '',
    genderError: '',
    emailId: '',
    gender: '',
    firstNameError: '',
    lastNameError: '',
    emailIdError: '',
    groupNameError: '',
    userLoginDetails: '',
    profileFile: '',
    profileFileError: '',
    loginGeneratedID: '',
    profileFileUsers: '',
    profileApi: '',
    profileToApi: '',
    uniqueGroupList: [],
    setLoading: false,
    reportMasterAllData: [],
    reportMasterAllDataError: '',
    allSelectedReport: [],
    allSelectedReportError: '',
    array: [],
    reportMasterAll: [],
    widgetHeight: 300,
}
export default (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case adminUserConstant.GET_UNIQUE_GROUP_TABLE_SUCCESS:
            return {
                ...state,
                uniqueGroupList: payload,
            }
        case adminUserConstant.PASS_ROW_DATA_GRP:
            return {
                ...state,
                loader: false,
                roleListRowData: payload,
            }

        case adminUserConstant.GET_ADMIN_NEW_USER_PAGE:
            return {
                ...state,
                loader: false,
                userName: '',
                editUser: false,
                userPopupOpen: false,
                // addNewUserPage:true,
                userEmailId: '',
                gender: '',
                password: '',
                confirmPassword: '',
                groupDescription: '',
                userFirstName: '',
                userLastName: '',
                roleSelection: '2',
                forceChangePassword: false,
                emailVerification: false,
                userNameError: '',
                saveRole: [],
                userEmailIdError: '',
                passwordError: '',
                confirmPasswordError: '',
                address: '',
                addressError: '',
                phoneNumber: '',
                roleSelectionError: '',
                addNewUserPage: !state.addNewUserPage,
                groupNameSelection: '',
                groupNameSelectionError: '',
                profileFileUsers: '',
                profileApi: '',
                allSelectedReport: [],
            }
        case adminUserConstant.GET_NEWGRP_PAGE:
            return {
                ...state,
                loader: false,
                editGrpPage: false,
                addNewGrpPage: true,
                setLoading: false,
                grpPopupOpen: false,
                groupUserFirstName: '',
                saveRole: '',
                groupDescription: '',
                groupUserFirstNameError: '',
                groupDescriptionError: '',
                newRoleName: '',
                newRoleNameError: '',
            }
        case adminUserConstant.GET_NEW_ROLE_PAGE:
            return {
                ...state,
                loader: true,
                addNewRolePage: true,
                rolePopupOpen: false,
                newRoleName: '',
                newRoleNameError: '',
                newDescription: '',
                newDescriptionError: '',
                editRolePage: false,
                newRoleId: '',
                newRoleIdError: '',
            }
        case adminUserConstant.CLOSE_POPUP:
            return {
                ...state,
                addNewGrpPage: false,
                addNewUserPage: false,
                setLoading: false,
                addNewRolePage: false,
                editGrpPage: true,
            }

        case adminUserConstant.MAKE_INITIAL_ADMINUSER_EMPTY:
            return {
                ...state,
                //loader: false,
                addNewUserPage: false,
                roleSelection: '2',
                userName: '',
                editUser: false,
                userEmailId: '',
                password: '',
                confirmPassword: '',
                userFirstName: '',
                gender: '',
                userLastName: '',
                forceChangePassword: false,
                emailVerification: false,
                adminRolesList: [],
                getRolesAllocate: [],
                saveRole: [],
                userNameError: '',
                userEmailIdError: '',
                passwordError: '',
                confirmPasswordError: '',
                roleSelectionError: '',
                profileFileUsers: '',
                profileApi: '',
                allSelectedReport: [],
            }

        case adminUserConstant.ADMIN_INPUT_CHANGE:
            return {
                ...state,
                [payload.props]: payload.value,
                [payload.error]: '',
                setLoading: false,
            }

        case adminUserConstant.ADMIN_REPORT_MULTI_INPUT_CHANGE:
            return {
                ...state,
                reportMasterAllData: payload.value,
                allSelectedReport: payload.allSelectedReport,
            }

        case adminUserConstant.ADMIN_INPUT_FILE_CHANGE:
            let filevalue = payload.value.name ? payload.raw : payload.value
            return {
                ...state,
                profileFileUsers: payload.value.name
                    ? payload.raw
                    : payload.value,
                profileApi: payload.raw,
            }
        case adminUserConstant.INPUT_CHANGE_PROFILE:
            return {
                ...state,
                profileFile: payload.value.name ? payload.raw : payload.value,
                profileToApi: payload.raw,
            }
        case adminUserConstant.GET_ALL_USER_ROLES_LIST:
            return {
                ...state,
                loader: true,
            }
        case adminUserConstant.ADMIN_USER_ERROR_UNAUTH:
            localStorage.clear()
            window.location.reload()
            return {
                ...state,
                loader: false,
            }
        case adminUserConstant.CANCEL_LOADING_ADMIN:
            return {
                ...state,
                loader: false,
                setLoading: false,
            }
        case adminUserConstant.DISPLAY_ADMIN_ROLES_DATA:
            return {
                ...state,
                loader: false,
                adminRolesList: payload.result,
                getUserPagination: _(payload.result).slice(0).take(10).value(),
            }
        case adminUserConstant.GET_ENABLE_USER_LIST:
            return {
                ...state,
                loader: true,
            }
        case adminUserConstant.MAKE_DISABLE_USER:
            return {
                ...state,
                loader: true,
            }
        case adminUserConstant.GET_ROLES:
            return {
                ...state,
                loader: true,
            }
        case adminUserConstant.DISPLAY_DISABLE_USER:
            return {
                ...state,
                loader: false,
            }
        case adminUserConstant.DISPLAY_ENABLE_USER:
            return {
                ...state,
                loader: false,
            }
        case adminUserConstant.DISPLAY_ROLES_ALLOCATION:
            // const [pageSize, setPageSize] = useState(5)
            return {
                ...state,
                loader: false,
                getRolesAllocate: payload.result,
                //  getPagination:  _(payload.result,).slice(0).take(pageSize).value()
            }
        case adminUserConstant.GET_ALL_ROLES:
            return {
                ...state,
                loading: true,
            }
        case adminUserConstant.DISPLAY_ALL_ROLES:
            return {
                ...state,
                loading: false,
                allRoles: payload.result,
                getPagination: _(payload.result).slice(0).take(10).value(),
            }
        case adminUserConstant.DISPLAY_ROLE_INITIAL_PAGE:
            return {
                ...state,
                loading: false,
                getPagination: _(payload).slice(0).take(10).value(),
            }
        case adminUserConstant.ROLE_INITIAL_PAGE:
            return {
                ...state,
                loading: false,
                getPagination: payload,
            }
        case adminUserConstant.USER_INITIAL_DATA:
            return {
                ...state,
                loading: false,
                getUserPagination: payload,
            }
        case adminUserConstant.DISPLAY_ALL_USER_DATA:
            return {
                ...state,
                loader: false,
                getUserPagination: _(payload).slice(0).take(10).value(),
            }
        case adminUserConstant.GET_GROUP_INITIAL_DATA:
            return {
                ...state,
                loading: false,
                addNewGrpPage: false,
                getGrpPagination: payload,
            }
        case adminUserConstant.DISPLAY_GRP_DATA:
            return {
                ...state,
                loader: false,
                getGrpPagination: _(payload.result).slice(0).take(10).value(),
            }
        case adminUserConstant.SELECT_ROLE_ALLOCATED:
            let aa = state.saveRole
            let newArray =
                payload.value == true
                    ? [...aa, payload]
                    : aa.filter((val) => val.roleName != payload.roleName)
            state.saveRole = newArray.filter((val) => val.value == true)

            return {
                ...state,
            }
        case adminUserConstant.SELECT_ROLE_ALLOCATED_EDIT:
            let aaa = state.saveEditRole
            // console.log("role",aaa, payload,[...aaa, payload.roleID])
            let newEdit =
                payload.value == true
                    ? [...aaa, payload.roleID]
                    : aaa.filter((val) => val != payload.roleID)
            state.saveEditRole = newEdit
            return {
                ...state,
            }
        case adminUserConstant.VALIDATION_REGISTER:
            return {
                ...state,
                userNameError: payload.userNameError,
                userEmailIdError: payload.userEmailIdError,
                passwordError: payload.passwordError,
                confirmPasswordError: payload.confirmPasswordError,
                roleSelectionError: payload.roleSelectionError,
                groupNameSelectionError: payload.groupNameError,
                userFirstNameError: payload.userFirstNameError,
                userLastNameError: payload.userLastNameError,
                genderError: payload.genderError,
                phoneNumberError: payload.phoneNumberError,
            }
        case adminUserConstant.VALIDATION_REGISTER_EDIT:
            return {
                ...state,
                userNameError: payload.userNameError,
                userEmailIdError: payload.userEmailIdError,
                groupNameSelectionError: payload.groupNameError,
                userFirstNameError: payload.userFirstNameError,
                userLastNameError: payload.userLastNameError,
                genderError: payload.genderError,
                phoneNumberError: payload.phoneNumberError,
            }
        case adminUserConstant.VALIDATION_EDIT_GRP_ERROR:
            return {
                ...state,
                groupUserFirstNameError: payload.groupUserFirstNameError,
                groupDescriptionError: payload.groupDescriptionError,
            }
        case adminUserConstant.VALIDATION_GRP:
            return {
                ...state,
                groupUserFirstNameError: payload.groupUserFirstNameError,
                groupDescriptionError: payload.groupDescriptionError,
            }
        case adminUserConstant.SAVE_REGISTER_USERS:
            return {
                ...state,
                loader: true,
                setLoading: true,
            }
        case adminUserConstant.GET_EDIT_USER_LIST:
            return {
                ...state,
                loader: true,
                userPopupOpen: true,
                addNewUserPage: false,
                userEmailIdError: '',
                groupNameSelectionError: '',
                userFirstNameError: '',
                userLastNameError: '',
                genderError: '',
                phoneNumberError: '',
                userNameError: '',
                confirmPasswordError: '',
                passwordError: '',
            }
        case adminUserConstant.PASS_REPORT_MASTER:
            return {
                ...state,
                loading: false,
                reportMasterAll: payload,
            }
        case adminUserConstant.DISPLAY_SINGLE_USER:
            let tempEditArray = []
            let arrayEdit = []
            payload.data.report &&
                payload.data.report.map((val) => {
                    state.reportMasterAll &&
                        state.reportMasterAll.map((it) => {
                            if (it.reportId == val.reportId) {
                                console.log('id', it, val)
                                tempEditArray.push(it.reportName)
                                arrayEdit.push({
                                    reportId: it.reportId,
                                    transactionName: it.transactionName,
                                })
                            }
                        })
                })
            return {
                ...state,
                loader: false,
                userPopupOpen: true,
                // editUser: true,
                // addNewUserPage: false,
                userName: payload.data.userName,
                userFirstName: payload.data.firstName,
                userLastName: payload.data.lastName,
                roleSelection: '2',
                userEmailId: payload.data.email,
                groupName: payload.data.groupName,
                gender: payload.data.gender,
                generatedID: payload.data.generatedID,
                profileFileUsers: payload.imageurl,
                // saveRole: payload.group.roles,
                // addNewUserPage: !state.addNewUserPage,
                address: payload.data.address,
                phoneNumber: payload.data.phone,
                groupNameSelection: payload.data ? payload.data.groupName : '',
                reportMasterAllData: arrayEdit,
                allSelectedReport: tempEditArray,
                widgetHeight: payload.data.widgetHeight
                    ? payload.data.widgetHeight
                    : 300,
            }
        case adminUserConstant.MAKE_INITIAL_GRP_EMPTY: {
            return {
                ...state,
                loader: false,
                editGrpPage: false,
                addNewGrpPage: true,
                groupUserFirstName: '',
                saveRole: '',
                groupDescription: '',
            }
        }
        case adminUserConstant.GET_EDIT_GRP_DATA:
            return {
                ...state,
                loading: true,
                addNewGrpPage: false,
                groupUserFirstNameError: '',
                groupDescriptionError: '',
            }
        case adminUserConstant.DISPLAY_GROUP_EDIT:
            return {
                ...state,
                loader: false,
                singleGrpData: payload,
                // addNewGrpPage: false,
                // editGrpPage: true,
                groupUserFirstName: payload.groupName,
                saveRole: payload.roleIds,
                saveEditRole: payload.roleIds,
                groupDescription: payload.description,
                generatedID: payload.generatedID,
                newRoleName: payload.roleName,
            }
        // case adminUserConstant.ONLY_DISPLAY_GRP_ROLES:
        //     return{

        //     }

        case adminUserConstant.EDITED_SAVE_USER_SUBMIT:
            return {
                ...state,
                loader: true,
                setLoading: true,
            }
        case adminUserConstant.CREATE_GROUP_SAVE_SUBMIT:
            return {
                ...state,
                loader: true,
                setLoading: true,
            }
        case adminUserConstant.DISPLAY_CREATED_NEW_GROUP:
            return {
                ...state,
                loading: false,
                grpPopupOpen: false,
                addNewGrpPage: false,
            }
        case adminUserConstant.CLOSE_POPUP_GRP:
            return {
                ...state,
                addNewUserPage: false,
                grpPopupOpen: false,
            }
        case adminUserConstant.EDIT_SAVE_SUCCESS:
            return {
                ...state,
                loader: false,
                addNewUserPage: false,
                PopupOpen: false,
                userPopupOpen: false,
                setLoading: false,
            }
        case adminUserConstant.DELETE_THE_USER_REQUEST:
            return {
                ...state,
                loader: true,
                ownUser: false,
            }
        case adminUserConstant.DELETE_GROUP_ROW_REQUEST:
            return {
                ...state,
                loader: true,
            }
        case adminUserConstant.DELETE_GRP_SUCCESS:
            return {
                ...state,
                loading: false,
                grpPopupOpen: false,
            }
        case adminUserConstant.DELETE_USER_SUCCESS:
            return {
                ...state,
                loader: false,
                ownUser: false,
                PopupOpen: false,
                userPopupOpen: false,
            }
        case adminUserConstant.DISPLAY_ADDED_USER_SUCCESS:
            return {
                ...state,
                loader: false,
                addNewUserPage: false,
            }
        case adminUserConstant.GET_ALL_GROUP_TABLE:
            //('GET_ALL_GROUP_TABLE', payload)
            return {
                ...state,
                loader: true,
            }
        case adminUserConstant.DISPLAY_ALL_GROUP_LIST:
            return {
                ...state,
                loader: false,
                groupListTable: payload.result,
                getGrpPagination: _(payload.result).slice(0).take(10).value(),
            }
        case adminUserConstant.SAVE_EDIT_USER_GROUP:
            //('getEditGroup', payload)
            return {
                ...state,
                loader: true,
                editGrpData: payload,
            }
        case adminUserConstant.DISPLAY_GROUP_EDIT_LIST:
            //('displayedit list grp', payload)
            return {
                ...state,
                loader: false,
                grpPopupOpen: false,
                groupEditList: payload,
                PopupOpen: false,
            }
        // case adminUserConstant.OWN_USER_CANT_DELETE:
        //     let user = JSON.parse(localStorage.getItem('auth_user'))
        //     if(payload.userName == user.name){
        //         return{
        //             ownUser:true
        //         }
        //     }
        //     return{
        //         ...state,
        //         loader:false,
        //         ownUser:true,
        //     }
        case adminUserConstant.INITIAL_EMPTY_NEW_ROLE:
            return {
                ...state,
                loader: false,
                editRolePage: false,
                addNewRolePage: true,
                newRoleName: '',
                newDescription: '',
                newRoleId: '',
                newRoleIdError: '',
            }
        case adminUserConstant.SAVE_NEW_ROLE_REQUEST:
            //console.log("create roles redu",payload)
            return {
                ...state,
                loading: true,
                editRolePage: false,
                addNewRolePage: true,
            }
        case adminUserConstant.VALIDATION_ROLE_ERROR:
            return {
                ...state,
                newRoleNameError: payload.newRoleNameError,
                newDescriptionError: payload.newDescriptionError,
            }
        case adminUserConstant.VALIDATION_ROLE_EDIT_ERROR:
            return {
                ...state,
                newRoleNameError: payload.newRoleNameError,
                newDescriptionError: payload.newDescriptionError,
            }
        case adminUserConstant.GET_EDIT_ROLES:
            return {
                ...state,
                loading: true,
                newRoleNameError: '',
                newDescriptionError: '',
            }
        case adminUserConstant.DISPLAY_EDIT_ROLES:
            return {
                ...state,
                loading: false,
                editRolePage: true,
                addNewRolePage: false,
                newRoleName: payload.name,
                newDescription: payload.description,
                newRoleId: payload.roleID,
            }
        case adminUserConstant.EDIT_SAVE_ROLES_REQ:
            return {
                ...state,
                loading: true,
            }

        case adminUserConstant.EDIT_SAVE_ROLE_SUCCESS:
            return {
                ...state,
                loading: false,
                rolePopupOpen: false,
            }
        case adminUserConstant.DELETE_ROLE_REQ:
            return {
                ...state,
                loading: true,
            }
        case adminUserConstant.DELETE_ROLE_SUCCESS:
            return {
                ...state,
                loading: false,
                addNewRolePage: false,
                rolePopupOpen: false,
            }
        case adminUserConstant.CLOSE_USER_POPUP:
            return {
                ...state,
                loading: true,
                userPopupOpen: false,
            }
        case adminUserConstant.POPUP_USER_OPEN:
            return {
                ...state,
                loading: true,
                userPopupOpen: true,
            }
        case adminUserConstant.POPUP_OPEN_GRP:
            return {
                ...state,
                loading: true,
                grpPopupOpen: true,
            }
        case adminUserConstant.POPUP_CLOSE_GRP:
            return {
                ...state,
                loading: true,
                grpPopupOpen: false,
            }
        case adminUserConstant.POPUP_OPEN_ROLE:
            return {
                ...state,
                loading: true,
                rolePopupOpen: true,
            }
        case adminUserConstant.POPUP_CLOSE_ROLE:
            return {
                ...state,
                loading: true,
                rolePopupOpen: false,
            }
        case adminUserConstant.DISPLAY_SAVED_ROLES:
            return {
                ...state,
                loading: false,
                addNewRolePage: false,
            }
        case adminUserConstant.GET_OLD_USERDETAILS:
            return {
                ...state,
                loading: true,
                firstName: payload.firstName,
                lastName: payload.lastName,
                userName: payload.userName,
                emailId: payload.email,
                phoneNumber: payload.phone,
                userGender: payload.gender,
                address: payload.address,
                groupName: payload.groupName,
                profileFile: payload.profileFile,
                loginGeneratedID: payload.generatedID,
            }
        case adminUserConstant.DISPLAY_OLD_USERDETAILS:
            localStorage.setItem(
                'imageSingle',
                JSON.stringify(payload.imageurl)
            )
            const userImage = JSON.parse(localStorage.getItem('imageSingle'))

            const loginDetails = JSON.parse(localStorage.getItem('loginimage'))

            const images = userImage == loginDetails ? userImage : loginDetails

            localStorage.setItem('image', JSON.stringify(images))
            return {
                ...state,
                loading: true,
                firstName: payload.data.firstName,
                lastName: payload.data.lastName,
                userName: payload.data.userName,
                emailId: payload.data.email,
                phoneNumber: payload.data.phone,
                userGender: payload.data.gender,
                address: payload.data.address,
                groupName: payload.data.groupName,
                profileFile: payload.imageurl, // payload.imageurl + payload.data.profileFile,
                loginGeneratedID: payload.data.generatedID,
                //   userId: payload,
            }
        case adminUserConstant.DISPLAY_PROFILE_IMAGE:
            // console.log("image payload",payload);
            return {
                ...state,
                profileFile: payload,
                profileFileUsers: payload,
            }
        case adminUserConstant.SHOW_USER_DETAILS:
            //  let val = JSON.parse(localStorage.getItem('auth_user'))
            return {
                ...state,
                loading: true,
                // firstName:val.firstName,
                // lastName:val.lastName,
                // userName:val.userName,
                // emailId:val.email,
                // phoneNumber:val.phoneNumber,
                // gender:val.gender,
                // userId: payload,
            }
        case adminUserConstant.REQUEST_SAVE_LOGIN_PROFILE:
            return {
                ...state,
                loading: true,
            }
        case adminUserConstant.RESPONSE_SAVE_LOGIN_PROFILE:
            return {
                ...state,
                loading: false,
            }
        default: {
            return {
                ...state,
            }
        }
    }
}
