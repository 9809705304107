import { all } from 'redux-saga/effects'
import LookupSaga from './LookupSaga'
import VisibilitySaga from './VisibilitySaga'
import VisibilityHeaderSaga from './VisibilityHeaderSaga'
import TransactionSaga from './TransactionSaga'
import AdminUserSaga from './AdminUserSaga'
import ConfirmSaga from './ConfirmSaga'
import RuleSaga from './RuleSaga';
import ForceChangeSaga from "./ForceChangeSaga";
import ReportSaga from './ReportSaga'
import ProcessCriteriaSaga from './ProcessCriteriaSaga'
import TemplateSaga from './TemplateSaga'
import CommonSaga from './CommonSaga'

export default function* rootSaga(getState) {
    yield all([
        LookupSaga(),
        VisibilitySaga(),
        VisibilityHeaderSaga(),
        TransactionSaga(),
        AdminUserSaga(),
        ConfirmSaga(),
        RuleSaga(),
        ForceChangeSaga(),
        ReportSaga(),
        ProcessCriteriaSaga(),
        TemplateSaga(),
        CommonSaga()
    ])
}
