import { all, call, put, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { NotificationManager } from 'react-notifications'
import Moment from 'moment'
import { visibilityHeader } from '../../constant/ActionTypes'
import {
    API_URL,
    headers,
    HEADER_ALL,
    ADD_HEADER_DATA,
    GET_HEADER_DATA_BY_ID,
    DELETE_HEADER_DATA,
    UPDATE_HEADER_DATA,
    VISIBILITY_HEADER_API,
    UPDATE_BASE_URL,
    VISI_HEADER_TABLE,
    SAVE_NEW_HEADER,
    GET_BY_ID_VISI_HEADER,
    UPDATE_SINGLE_HEADER,
    DELETE_SINGLE_VISI_HEADER,
    GET_ID,
    VISI_DETAILS_TABLE,
    VISIBILITY_ID,
    SAVE_NEW_DETAILS,
    ADD_DETAILS,
    GET_BY_ID_VISI_DETAILS,
    DELETE_SINGLE_VISI_DETAIL,
    DETAILS_PROCESS,
    VISI_TEMPLATE_TABLE,
    VISIBILITY_START_END_STATUS_LIST,
    VISIBILITY_START_END_STATUS_SAVE,
    GET_UNIQUE_PROCESSED,
    SAVE_NEW_HEADER1,
} from '../../constant/Common'

function changeDateFormat(dates) {
    const newDate = new Date(dates)
    return Moment(newDate).format('YYYY-MM-DD')
}
function getJwtToken() {
    //  const sessionLoginToken = useSelector((state)=>state.login);
    var abc = localStorage.getItem('accessToken')
    //  console.log("Getting the Token",sessionLoginToken);
    //sessionStorage.getItem("tokenId")
    return abc
}
//   ======================================================get All Visibility Header Data ====================================
function* getAllHeaderDetails(payload) {
    try {
        var res = yield axios
            .request({
                method: 'get',
                url: API_URL.api + HEADER_ALL,
                headers: headers,
                // data: req,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: visibilityHeader.VISIBILITY_HEADER_ERROR_STATUS,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status === 403) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: visibilityHeader.VISIBILITY_HEADER_ERROR_STATUS,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status >= 500) {
            //console.log('401')
            NotificationManager.error('Server error')
            return
        }

        if (res.status === 200) {
            if (res.data.status == 404) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: visibilityHeader.VISIBILITY_STOP_LOADING,
                    payload: {},
                })
            } else if (res.data.status >= 500) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: visibilityHeader.VISIBILITY_STOP_LOADING,
                    payload: {},
                })
            } else {
                yield put({
                    type: visibilityHeader.DISPLAY_VISI_HEADER_TABLE_DATA,
                    payload: res.data,
                })
            }
            yield put({
                type: visibilityHeader.DISPLAY_HEADER_DETAILS,
                payload: res.data,
            })
        } else {
            NotificationManager.error('Data is InCorrect')
        }
    } catch (error) {
        console.log(error)
    }
}

function* getVisibilityHeaderDetails() {
    yield takeEvery(
        visibilityHeader.GET_HEADER_TABLE_DETAILS,
        getAllHeaderDetails
    )
}

// =================================================== get Edit Visibility Header Data By ID ================================
function* getEditData(payload) {
    try {
        var res = yield axios
            .request({
                method: 'get',
                url:
                    API_URL.api +
                    GET_HEADER_DATA_BY_ID +
                    payload.payload.visibilityId,
                headers: headers,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        //console.log('Response', res)

        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: visibilityHeader.VISIBILITY_HEADER_ERROR_STATUS,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status === 403) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: visibilityHeader.VISIBILITY_HEADER_ERROR_STATUS,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status >= 500) {
            //console.log('401')
            NotificationManager.error('Server error')
            return
        }
        if (res.status === 200) {
            if (res.data.status == 500) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: visibilityHeader.HEADER_ERROR_OFF_LOADER,
                    payload: {},
                })
            } else {
                yield put({
                    type: visibilityHeader.DISPLAY_EDIT_DATA,
                    payload: res.data,
                })
            }
        } else {
            NotificationManager.error(res.message)
            yield put({
                type: visibilityHeader.HEADER_ERROR_OFF_LOADER,
                payload: {},
            })
        }
    } catch (error) {
        console.log(error)
    }
}

function* getEditDataById() {
    yield takeEvery(visibilityHeader.EDIT_VISIBILITY_DATA, getEditData)
}

// =================================================== Add Visibility Header Data ================================
function* addNewVisibilityData(payload) {
    var prop = payload.payload
    //console.log(prop)
    // let users = JSON.parse(prop.userId)

    try {
        var req = JSON.stringify({
            visibilityId: prop.visibilityId,
            // description: prop.description,
            // alertMode: prop.alertMode,
            // alertInfo: prop.alertInfo,
            // templateName: prop.templateName,
            createdBy: prop.userId.name,
            createdDate: changeDateFormat(new Date()),
            lastModifiedBy: prop.userId.name,
            lastModifiedDate: changeDateFormat(new Date()),
        })
        var res = yield axios
            .request({
                method: 'post',
                url: API_URL.api + ADD_HEADER_DATA,
                headers: headers,
                data: req,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        //  console.log('Response', res)

        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: visibilityHeader.VISIBILITY_HEADER_ERROR_STATUS,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status === 403) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: visibilityHeader.VISIBILITY_HEADER_ERROR_STATUS,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status === 200) {
            if (res.data.status == 409) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: visibilityHeader.HEADER_ERROR_OFF_LOADER,
                    payload: {},
                })
            } else if (res.data.status == 400) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: visibilityHeader.HEADER_ERROR_OFF_LOADER,
                    payload: {},
                })
            } else if (res.data.status == 500) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: visibilityHeader.HEADER_ERROR_OFF_LOADER,
                    payload: {},
                })
            } else {
                NotificationManager.success('Added successfully')
                yield put({
                    type: visibilityHeader.DISPLAY_ADDED_HEADER_DATA,
                    payload: res.data,
                })
            }
        } else {
            NotificationManager.error(res.message)
            yield put({
                type: visibilityHeader.HEADER_ERROR_OFF_LOADER,
                payload: {},
            })
        }
    } catch (error) {
        console.log(error)
    }
}

function* addVisivibilityHeaderData() {
    yield takeEvery(
        visibilityHeader.ADD_HEADER_DATA_FOR_SAVE,
        addNewVisibilityData
    )
}

// =================================================== Delete Visibility Header Data By ID ================================
function* deleteVisibilityData(payload) {
    try {
        var res = yield axios
            .request({
                method: 'delete',
                url:
                    API_URL.api +
                    DELETE_HEADER_DATA +
                    payload.payload.visibilityId,
                headers: headers,
                // data: req,
            })
            .catch((err) => {
                return err.response
            })
        // console.log('Response', res)

        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: visibilityHeader.VISIBILITY_HEADER_ERROR_STATUS,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status === 403) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: visibilityHeader.VISIBILITY_HEADER_ERROR_STATUS,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status >= 500) {
            //console.log('401')
            NotificationManager.error('Server error')
            return
        }

        if (res.status === 200) {
            if (res.data.status == 500) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: visibilityHeader.HEADER_ERROR_OFF_LOADER,
                    payload: {},
                })
            }
            if (res.data.status == 400) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: visibilityHeader.HEADER_ERROR_OFF_LOADER,
                    payload: {},
                })
            } else {
                NotificationManager.success(res.data.message)
                yield put({
                    type: visibilityHeader.DELETED_CHANGES_HEADER,
                    payload: payload.payload.visibilityId,
                })
            }
        } else {
            NotificationManager.error(res.message)
            yield put({
                type: visibilityHeader.HEADER_ERROR_OFF_LOADER,
                payload: {},
            })
        }
    } catch (error) {
        console.log(error)
    }
}

function* deleteVisibilityHeader() {
    yield takeEvery(
        visibilityHeader.DELETE_VISIBILITY_DATA,
        deleteVisibilityData
    )
}
// =================================================== Update the edited Visibility Header Data ================================
function* updateVisibilityData(payload) {
    //console.log("updateVisibilityData",payload)
    var prop = payload.payload
    let users = prop.userId
    try {
        var req = JSON.stringify({
            visibilityId: prop.visibilityId,
            description: prop.description,
            alertMode: prop.alertMode,
            alertInfo: prop.alertInfo,
            alertEmail: prop.alertInfo,
            templateName: prop.templateName,
            createdBy: prop.createdBy,
            createdDate: prop.createdDate,
            lastModifiedBy: users.name,
            lastModifiedDate: changeDateFormat(new Date()),
        })
        var res = yield axios
            .request({
                method: 'put',
                url:
                    API_URL.api +
                    UPDATE_HEADER_DATA +
                    payload.payload.visibilityId,
                headers: headers,
                data: req,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        //  console.log('Response', res)

        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: visibilityHeader.VISIBILITY_HEADER_ERROR_STATUS,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status === 403) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: visibilityHeader.VISIBILITY_HEADER_ERROR_STATUS,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status === 200) {
            if (res.data.status == 500) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: visibilityHeader.HEADER_ERROR_OFF_LOADER,
                    payload: {},
                })
            } else {
                NotificationManager.success('successfully Updated')
                yield put({
                    type: visibilityHeader.DISPLAY_EDITED_HEADER_DATA,
                    payload: res.data,
                })
            }
        } else {
            NotificationManager.error(res.message)
            yield put({
                type: visibilityHeader.HEADER_ERROR_OFF_LOADER,
                payload: {},
            })
        }
    } catch (error) {
        console.log(error)
    }
}

function* updateVisibilityHeaderData() {
    yield takeEvery(
        visibilityHeader.SAVE_EDITED_HEADER_DATA,
        updateVisibilityData
    )
}

//===============================================get visibility header table data======================
function* getVisibilityHeaderTable(payload) {
    try {
        var res = yield axios
            .request({
                method: 'GET',
                url: UPDATE_BASE_URL + VISI_HEADER_TABLE,
                headers: headers,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        //console.log('Response', res)

        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: visibilityHeader.VISIBILITY_HEADER_ERROR_STATUS,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status === 403) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: visibilityHeader.VISIBILITY_HEADER_ERROR_STATUS,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status === 504) {
            //console.log('401')
            NotificationManager.error('Server error')
            yield put({
                type: visibilityHeader.VISIBILITY_STOP_LOADING,
                payload: {},
            })
            return
        }
        if (res.status >= 500) {
            //console.log('401')
            NotificationManager.error('Server error')
            yield put({
                type: visibilityHeader.VISIBILITY_STOP_LOADING,
                payload: {},
            })
            return
        }
        if (res.status === 200) {
            //  console.log('200', res)
            if (res.data.status === 400) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: visibilityHeader.VISIBILITY_STOP_LOADING,
                    payload: {},
                })
            } else if (res.data.code == 404) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: visibilityHeader.VISIBILITY_STOP_LOADING,
                    payload: {},
                })
            } else if (res.data.status == 500) {
                NotificationManager.error('Server Error')
                yield put({
                    type: visibilityHeader.VISIBILITY_STOP_LOADING,
                    payload: {},
                })
            } else {
                // NotificationManager.success('data fetched successfully')
                yield put({
                    type: visibilityHeader.DISPLAY_VISI_HEADER_TABLE_DATA,
                    payload: res.data,
                })
                yield put({
                    type: visibilityHeader.DISPLAY_VISI_HEADER_TABLE_LIST,
                    payload: res.data,
                })
            }
        } else {
            if (res.data.error) {
                NotificationManager.error(res.data.error)
            } else {
                NotificationManager.error(
                    'Internal application error. Please contact system administrator.'
                )
            }
        }
    } catch (error) {
        console.log(error)
    }
}

function* getVisibilityHeader() {
    yield takeEvery(
        visibilityHeader.GET_VISIBILITY_TABLEDATA,
        getVisibilityHeaderTable
    )
}

//==========================================post new process criteria data==================================

function* saveNewVisibilityHeader(payload) {
    var props = payload.payload
    console.log('visibilirty save new', props)
    console.log('visibilirty save new', props.value)

    try {
        var req = JSON.stringify({
            processId: props.processId.value,
            description: props.description,
            alertMode: props.alertMode,
            alertInfo: props.alertInfo,
            alertEmail: props.alertInfo,
            templateName: props.templateName,
            miscFlag1: props.visibilityMiscFlag1,
            miscFlag2: props.visibilityMiscFlag2,
            miscFlag3: props.visibilityMiscFlag3,
            miscFlag4: props.visibilityMiscFlag4,
            miscFlag5: props.visibilityMiscFlag5,
            miscField1: props.visibilityMiscField1,
            miscField2: props.visibilityMiscField2,
            miscField3: props.visibilityMiscField3,
            miscField4: props.visibilityMiscField4,
            modifiedBy: 0,
        })
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        }

        var res = yield axios
            .request({
                method: 'post',
                url: UPDATE_BASE_URL + SAVE_NEW_HEADER1, // + props.processId.id,
                headers: headers,
                data: req,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        if (res.status === 504) {
            //console.log('401')
            NotificationManager.error('Server error')
            return
        }
        if (res.status === 401) {
            //console.log('401')
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: visibilityHeader.VISIBILITY_HEADER_AUTH_ERROR,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status >= 500) {
            //console.log('401')
            NotificationManager.error('Server error')
            return
        }
        if (res.status === 504) {
            //console.log('401')
            NotificationManager.error('Server error')
            return
        }
        if (res.status === 403) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: visibilityHeader.VISIBILITY_HEADER_ERROR_STATUS,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status === 200) {
            //.log('ressss', res)
            if (res.data.status === 400) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: visibilityHeader.VISIBILITY_STOP_LOADING,
                    payload: {},
                })
            } else if (res.data.status == 404) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: visibilityHeader.VISIBILITY_STOP_LOADING,
                    payload: {},
                })
            } else if (res.data.status == 500) {
                NotificationManager.error('Server Error')
                yield put({
                    type: visibilityHeader.VISIBILITY_STOP_LOADING,
                    payload: {},
                })
            } else {
                NotificationManager.success(
                    'Record has been successfully created'
                )
                yield put({
                    type: visibilityHeader.GET_VISIBILITY_TABLEDATA,
                    payload: {},
                })

                yield put({
                    type: visibilityHeader.RESPONSE_SAVE_VISI_HEADER,
                    payload: {},
                })
            }
        } else {
            if (res.data.error) {
                NotificationManager.error(res.data.error)
            } else {
                NotificationManager.error(
                    'Internal application error. Please contact system administrator.'
                )
            }
        }
    } catch (error) {
        console.log(error)
    }
}

function* saveNewVisibility() {
    yield takeEvery(
        visibilityHeader.REQUEST_SAVE_VISI_HEADER,
        saveNewVisibilityHeader
    )
}

//=======================================get visisbility header by id =============================
function* getVisibilityHeaderById(payload) {
    var props = payload.payload
    console.log('sagaprocessCriteria', payload)
    try {
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        }
        var res = yield axios
            .request({
                method: 'get',
                url: UPDATE_BASE_URL + GET_BY_ID_VISI_HEADER + props.id,
                headers: headers,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })

        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: visibilityHeader.VISIBILITY_HEADER_AUTH_ERROR,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status === 403) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: visibilityHeader.VISIBILITY_HEADER_ERROR_STATUS,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status >= 500) {
            //console.log('401')
            NotificationManager.error('Server error')
            return
        }
        if (res.status === 504) {
            //console.log('401')
            NotificationManager.error('Server error')
            return
        }

        if (res.status === 200) {
            if (res.data.status === 404) {
                NotificationManager.error(res.data.error)
            } else {
                yield put({
                    type: visibilityHeader.DISPLAY_VISIBILITY_SINGLE_BYID,
                    payload: res.data,
                })
            }
        } else if (res.data.status == 500) {
            NotificationManager.error('Server Error')
        } else {
            NotificationManager.error(
                'Internal application error. Please contact system administrator.'
            )
        }
    } catch (error) {
        console.log(error)
    }
}

function* getvisibilityHeaderId() {
    yield takeEvery(
        visibilityHeader.GET_VISIBILITY_SINGLE_BYID,
        getVisibilityHeaderById
    )
}

function* saveEditVisibilityHeader(payload) {
    // console.log('saveEdit header', payload)
    let props = payload.payload

    try {
        var req = JSON.stringify({
            processId: props.processId,
            description: props.description,
            alertMode: props.alertMode,
            alertInfo: props.alertInfo,
            alertEmail: props.alertInfo,
            templateName: props.templateName,
            miscFlag1: props.visibilityMiscFlag1,
            miscFlag2: props.visibilityMiscFlag2,
            miscFlag3: props.visibilityMiscFlag3,
            miscFlag4: props.visibilityMiscFlag4,
            miscFlag5: props.visibilityMiscFlag5,
            miscField1: props.visibilityMiscField1,
            miscField2: props.visibilityMiscField2,
            miscField3: props.visibilityMiscField3,
            miscField4: props.visibilityMiscField4,
            modifiedBy: props.userId.name,
        })

        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        }

        var res = yield axios
            .request({
                method: 'PUT',
                url:
                    UPDATE_BASE_URL +
                    GET_BY_ID_VISI_HEADER +
                    props.displaySingleVisiHeader.id,
                headers: headers,
                data: req,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        //  console.log('RESPOSNSE', res)
        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')

            return
        }
        if (res.status === 403) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: visibilityHeader.VISIBILITY_HEADER_ERROR_STATUS,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status === 504) {
            //console.log('401')
            NotificationManager.error('Server error')
            return
        }
        if (res.status >= 500) {
            //console.log('401')
            NotificationManager.error('Server error')
            return
        }
        if (res.status === 400) {
            {
                if (res.data.status === 400) {
                    NotificationManager.error(res.status.error)
                }
            }
        }

        if (res.status === 200) {
            if (res.data.status === 400) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: visibilityHeader.VISIBILITY_STOP_LOADING,
                    payload: {},
                })
            } else if (res.data.status == 500) {
                NotificationManager.error('Server Error')
                yield put({
                    type: visibilityHeader.VISIBILITY_STOP_LOADING,
                    payload: {},
                })
            } else {
                NotificationManager.success('Successfully updated')
                yield put({
                    type: visibilityHeader.RESPONSE_EDIT_VISIBILITY_HEADER,
                    payload: res,
                })
            }
            yield put({
                type: visibilityHeader.GET_VISIBILITY_TABLEDATA,
                payload: {},
            })
        } else {
            if (res.data.error) {
                NotificationManager.error(res.data.error)
            } else {
                NotificationManager.error(
                    'Internal application error. Please contact system administrator.'
                )
            }
        }
    } catch (error) {
        console.log(error)
    }
}
function* saveEditVisiHeader() {
    //  console.log('saveEditTransaction')
    yield takeEvery(
        visibilityHeader.REQUEST_EDIT_VISIBILITY_HEADER,
        saveEditVisibilityHeader
    )
}
//======================================================Delete the process criteria table ====================================
function* deleteVisibilityHeaderData(payload) {
    var props = payload.payload
    console.log('Payload delete Props', props)
    try {
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        }
        var res = yield axios
            .request({
                method: 'delete',
                url:
                    UPDATE_BASE_URL +
                    DELETE_SINGLE_VISI_HEADER +
                    props.visibilityReducer,
                //+ props.visibilityHeaderSingleId.id +GET_ID +props.visibilityReducer,
                headers: headers,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        if (res.status === 401) {
            //console.log('401')
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: visibilityHeader.VISIBILITY_HEADER_AUTH_ERROR,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status === 504) {
            //console.log('401')
            NotificationManager.error('Server error')
            return
        }
        if (res.status === 403) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: visibilityHeader.VISIBILITY_HEADER_ERROR_STATUS,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status >= 500) {
            //console.log('401')
            NotificationManager.error('Server error')
            return
        }
        //console.log('fvvdfvfdvd', res)
        if (res.status === 200) {
            if (res.data.status === 400) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: visibilityHeader.VISIBILITY_STOP_LOADING,
                    payload: {},
                })
            } else if (res.data.status === 404) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: visibilityHeader.VISIBILITY_STOP_LOADING,
                    payload: {},
                })
            } else if (res.data.status == 500) {
                NotificationManager.error('Server Error')
                yield put({
                    type: visibilityHeader.VISIBILITY_STOP_LOADING,
                    payload: {},
                })
            } else {
                yield put({
                    type: visibilityHeader.RESPONSE_DELETE_VISIBILITY_HEADER,
                    payload: {},
                })
                yield put({
                    type: visibilityHeader.GET_VISIBILITY_TABLEDATA,
                    payload: {},
                })
            }
            //props.navigate('/GroupListView')
        } else {
            if (res.data.error) {
                NotificationManager.error(res.data.error)
            } else {
                NotificationManager.error(
                    'Internal application error. Please contact system administrator.'
                )
            }
        }
    } catch (error) {
        console.log(error)
    }
}

function* deleteVisibilityHeaderSingle() {
    yield takeEvery(
        visibilityHeader.REQUEST_DELETE_VISIBILITY_HEADER,
        deleteVisibilityHeaderData
    )
}
//=======================================get visibility details =========================================
function* getVisibilityDetailsTable(payload) {
    console.log('value', payload)
    try {
        var res = yield axios
            .request({
                method: 'GET',
                url:
                    UPDATE_BASE_URL +
                    GET_BY_ID_VISI_DETAILS +
                    payload.payload.id,
                headers: headers,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        //console.log('Response', res)

        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: visibilityHeader.VISIBILITY_HEADER_ERROR_STATUS,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status === 403) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: visibilityHeader.VISIBILITY_HEADER_ERROR_STATUS,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status === 504) {
            //console.log('401')
            NotificationManager.error('Server error')
            return
        }
        if (res.status >= 500) {
            //console.log('401')
            NotificationManager.error('Server error')
            return
        }
        if (res.status === 200) {
            // console.log('200', res)
            if (res.data.status === 400) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: visibilityHeader.VISIBILITY_STOP_LOADING,
                    payload: {},
                })
            } else if (res.data.status == 404) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: visibilityHeader.VISIBILITY_STOP_LOADING,
                    payload: {},
                })
            } else if (res.data.status == 500) {
                NotificationManager.error('Server Error')
            } else {
                // NotificationManager.success('data fetched successfully')
                yield put({
                    type: visibilityHeader.DISPLAY_VISI_DETAILS_TABLE,
                    payload: res.data,
                })
            }
        } else {
            if (res.data.error) {
                NotificationManager.error(res.data.error)
            } else {
                NotificationManager.error(
                    'Internal application error. Please contact system administrator.'
                )
            }
        }
    } catch (error) {
        console.log(error)
    }
}

function* getVisibilityDetails() {
    yield takeEvery(
        visibilityHeader.GET_VISIBILITY_TABLE_DETAIL,
        getVisibilityDetailsTable
    )
}

//==========================================post new process criteria data==================================

function* saveNewVisibilityDetails(payload) {
    var props = payload.payload
    console.log('visibilirty save new', props)
    let int = props.detailVisibilityMiscField3
    let misc3 = parseInt(int)
    console.log('misc3', misc3)
    var processDuration =
        props.processDurationHours +
        ':' +
        props.processDurationMinute +
        ':' +
        props.processDurationSecond
    var severity1Low =
        props.sev1LowDurationHours +
        ':' +
        props.sev1LowDurationMinute +
        ':' +
        props.sev1LowDurationSecond
    var severity2Medium =
        props.sev1MidDurationHours +
        ':' +
        props.sev1MidDurationMinute +
        ':' +
        props.sev1MidDurationSecond
    var severity3High =
        props.sev1HighDurationHours +
        ':' +
        props.sev1HighDurationMinute +
        ':' +
        props.sev1HighDurationSecond
    try {
        var req = JSON.stringify({
            processId: props.processId,
            startStatus: props.startStatus,
            endStatus: props.endStatus,
            processDuration: processDuration,
            severity1Low: severity1Low,
            severity2Medium: severity2Medium,
            severity3High: severity3High,
            alertMode: props.detailsAlertMode,
            alertInfo: props.detailAlertInfo,
            alertEmail: props.detailAlertInfo,
            templateName: props.detailsTemplateName,
            miscFlag1: props.detailsVisibilityMiscFlag1,
            miscFlag2: props.detailsVisibilityMiscFlag2,
            miscFlag3: props.detailsVisibilityMiscFlag3,
            miscFlag4: props.detailsVisibilityMiscFlag4,
            miscFlag5: props.detailsVisibilityMiscFlag5,
            miscField1: props.detailVisibilityMiscField1,
            miscField2: props.detailVisibilityMiscField2,
            miscField3: props.detailVisibilityMiscField3,
            miscField4: props.detailVisibilityMiscField4,
            modifiedBy: 0,
            sequenceNumber: props.sequenceNumber,
        })
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        }
        var res = yield axios
            .request({
                method: 'post',
                url:
                    UPDATE_BASE_URL +
                    SAVE_NEW_DETAILS +
                    props.rowHeaderIdVisi +
                    ADD_DETAILS,
                headers: headers,
                data: req,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })

        if (res.status === 401) {
            //console.log('401')
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: visibilityHeader.VISIBILITY_HEADER_AUTH_ERROR,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status >= 500) {
            //console.log('401')
            NotificationManager.error('Server error')
            return
        }
        if (res.status === 403) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: visibilityHeader.VISIBILITY_HEADER_ERROR_STATUS,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status === 504) {
            //console.log('401')
            NotificationManager.error('Server error')
            return
        }
        if (res.status === 200) {
            //.log('ressss', res)
            if (res.data.status === 400) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: visibilityHeader.VISIBILITY_STOP_LOADING,
                    payload: {},
                })
            } else if (res.data.status == 404) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: visibilityHeader.VISIBILITY_STOP_LOADING,
                    payload: {},
                })
            } else if (res.data.status == 500) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: visibilityHeader.VISIBILITY_STOP_LOADING,
                    payload: {},
                })
            } else {
                NotificationManager.success(
                    'Record has been successfully created'
                )
                yield put({
                    type: visibilityHeader.GET_VISIBILITY_SINGLE_BYID,
                    payload: res.data.visibilityHeader,
                })

                yield put({
                    type: visibilityHeader.RESPONSE_SAVE_VISIBILITY_NEW_DETAILS,
                    payload: {},
                })
            }
        } else {
            if (res.data.error) {
                NotificationManager.error(res.data.error)
            } else {
                NotificationManager.error(
                    'Internal application error. Please contact system administrator.'
                )
            }
        }
    } catch (error) {
        console.log(error)
    }
}

function* saveVisibilityDetailsNew() {
    yield takeEvery(
        visibilityHeader.REQUEST_SAVE_VISIBILITY_NEW_DETAILS,
        saveNewVisibilityDetails
    )
}

//=======================================get visisbility details by id =============================
function* getVisibilityDetailsById(payload) {
    var props = payload.payload
    console.log('process details by id', props, props.id)
    try {
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        }
        var res = yield axios
            .request({
                method: 'get',
                url: UPDATE_BASE_URL + GET_BY_ID_VISI_DETAILS + props.id,
                headers: headers,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })

        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: visibilityHeader.VISIBILITY_HEADER_AUTH_ERROR,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status === 403) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: visibilityHeader.VISIBILITY_HEADER_ERROR_STATUS,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status >= 500) {
            //console.log('401')
            NotificationManager.error('Server error')
            return
        }
        if (res.status === 504) {
            //console.log('401')
            NotificationManager.error('Server error')
            return
        }
        if (res.status === 200) {
            // console.log('responseblackqueryparam', res)
            yield put({
                type: visibilityHeader.DISPLAY_DETAILS_BY_ID,
                payload: res.data,
            })
            if (res.data.status === 404) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: visibilityHeader.VISIBILITY_STOP_LOADING,
                    payload: {},
                })
            }
        } else if (res.data.status == 500) {
            NotificationManager.error('Server Error')
            yield put({
                type: visibilityHeader.VISIBILITY_STOP_LOADING,
                payload: {},
            })
        } else {
            NotificationManager.error(
                'Internal application error. Please contact system administrator.'
            )
        }
    } catch (error) {
        console.log(error)
    }
}

function* getVisiDetailsById() {
    yield takeEvery(
        visibilityHeader.GET_DETAILS_BY_ID,
        getVisibilityDetailsById
    )
}

//======================================save visibility edit details ============================
function* saveEditVisibilityDetails(payload) {
    console.log('saveEdit details', payload.payload)
    let props = payload.payload

    var processDuration =
        props.processDurationHours +
        ':' +
        props.processDurationMinute +
        ':' +
        props.processDurationSecond
    var severity1Low =
        props.sev1LowDurationHours +
        ':' +
        props.sev1LowDurationMinute +
        ':' +
        props.sev1LowDurationSecond
    var severity2Medium =
        props.sev1MidDurationHours +
        ':' +
        props.sev1MidDurationMinute +
        ':' +
        props.sev1MidDurationSecond
    var severity3High =
        props.sev1HighDurationHours +
        ':' +
        props.sev1HighDurationMinute +
        ':' +
        props.sev1HighDurationSecond

    try {
        var req = JSON.stringify({
            processId: props.processId,
            startStatus: props.startStatus,
            endStatus: props.endStatus,
            processDuration: processDuration,
            alertMode: props.detailsAlertMode,
            alertInfo: props.detailAlertInfo,
            alertEmail: props.detailAlertInfo,
            templateName: props.detailsTemplateName,
            severity1Low: severity1Low,
            severity2Medium: severity2Medium,
            severity3High: severity3High,
            miscFlag1: props.detailsVisibilityMiscFlag1,
            miscFlag2: props.detailsVisibilityMiscFlag2,
            miscFlag3: props.detailsVisibilityMiscFlag3,
            miscFlag4: props.detailsVisibilityMiscFlag4,
            miscFlag5: props.detailsVisibilityMiscFlag5,
            miscField1: props.detailVisibilityMiscField1,
            miscField2: props.detailVisibilityMiscField2,
            miscField3: props.detailVisibilityMiscField3,
            miscField4: props.detailVisibilityMiscField4,
            modifiedBy: payload.payload.userId.name,
            sequenceNumber: props.sequenceNumber,
        })

        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        }

        var res = yield axios
            .request({
                method: 'PUT',
                url:
                    UPDATE_BASE_URL +
                    GET_BY_ID_VISI_DETAILS +
                    props.detailsRowId,
                // props.displaySingleVisiHeader.id,
                headers: headers,
                data: req,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        // console.log('RESPOSNSE', res)
        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')

            return
        }
        if (res.status === 403) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: visibilityHeader.VISIBILITY_HEADER_ERROR_STATUS,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status >= 500) {
            //console.log('401')
            NotificationManager.error('Server error')
            yield put({
                type: visibilityHeader.VISIBILITY_STOP_LOADING,
                payload: {},
            })
            return
        }
        if (res.status === 504) {
            //console.log('401')
            NotificationManager.error('Server error')
            yield put({
                type: visibilityHeader.VISIBILITY_STOP_LOADING,
                payload: {},
            })
            return
        }
        if (res.status === 400) {
            {
                if (res.data.status === 400) {
                    NotificationManager.error(res.status.error)
                    yield put({
                        type: visibilityHeader.VISIBILITY_STOP_LOADING,
                        payload: {},
                    })
                }
            }
        }

        if (res.status === 200) {
            if (res.data.status === 400) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: visibilityHeader.VISIBILITY_STOP_LOADING,
                    payload: {},
                })
            } else if (res.data.status == 500) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: visibilityHeader.VISIBILITY_STOP_LOADING,
                    payload: {},
                })
            } else {
                NotificationManager.success('Successfully updated')
                console.log('res', res)
                yield put({
                    type: visibilityHeader.GET_VISIBILITY_SINGLE_BYID,
                    payload: res.data.visibilityHeader,
                })
                yield put({
                    type: visibilityHeader.RESPONSE_VISIBILITY_EDIT_DETAILS,
                    payload: res,
                })
            }
        } else {
            if (res.data.error) {
                NotificationManager.error(res.data.error)
            } else {
                NotificationManager.error(
                    'Internal application error. Please contact system administrator.'
                )
            }
        }
    } catch (error) {
        console.log(error)
    }
}
function* saveEditDetails() {
    //  console.log('saveEditTransaction')
    yield takeEvery(
        visibilityHeader.REQUEST_VISIBILITY_EDIT_DETAILS,
        saveEditVisibilityDetails
    )
}
//======================================================Delete visibility details ====================================
function* deleteVisibilityDetailSingle(payload) {
    var props = payload.payload
    console.log('Payload delete details', props)
    try {
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        }
        var res = yield axios
            .request({
                method: 'delete',
                url:
                    UPDATE_BASE_URL +
                    DELETE_SINGLE_VISI_DETAIL +
                    props.id +
                    DETAILS_PROCESS +
                    props.visibilityRowDetailId,
                headers: headers,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        if (res.status === 401) {
            //console.log('401')
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: visibilityHeader.VISIBILITY_HEADER_AUTH_ERROR,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status >= 500) {
            //console.log('401')
            NotificationManager.error('Server error')
            return
        }
        if (res.status === 403) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: visibilityHeader.VISIBILITY_HEADER_ERROR_STATUS,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status === 504) {
            //console.log('401')
            NotificationManager.error('Server error')
            yield put({
                type: visibilityHeader.VISIBILITY_STOP_LOADING,
                payload: {},
            })
            return
        }
        //console.log('fvvdfvfdvd', res)
        if (res.status === 200) {
            if (res.data.status === 400) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: visibilityHeader.VISIBILITY_STOP_LOADING,
                    payload: {},
                })
            } else if (res.data.status === 404) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: visibilityHeader.VISIBILITY_STOP_LOADING,
                    payload: {},
                })
            } else if (res.data.status == 500) {
                NotificationManager.error('Server Error')
            } else {
                yield put({
                    type: visibilityHeader.GET_VISIBILITY_SINGLE_BYID,
                    payload: props,
                })
                yield put({
                    type: visibilityHeader.DELETE_RESPONSE_VISIBILITY_DETAILS,
                    payload: {},
                })
            }
            //props.navigate('/GroupListView')
        } else {
            if (res.data.error) {
                NotificationManager.error(res.data.error)
            } else {
                NotificationManager.error(
                    'Internal application error. Please contact system administrator.'
                )
            }
        }
    } catch (error) {
        console.log(error)
    }
}

function* deleteVisibilityDetails() {
    yield takeEvery(
        visibilityHeader.DELETE_VISIBILITY_DETAILS,
        deleteVisibilityDetailSingle
    )
}
//===============================================get Template table data======================
function* getTemplateTable(payload) {
    try {
        var res = yield axios
            .request({
                method: 'GET',
                url: UPDATE_BASE_URL + VISI_TEMPLATE_TABLE,
                headers: headers,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        //console.log('Response', res)

        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: visibilityHeader.VISIBILITY_HEADER_ERROR_STATUS,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status === 403) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: visibilityHeader.VISIBILITY_HEADER_ERROR_STATUS,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status >= 500) {
            //console.log('401')
            NotificationManager.error('Server error')
            yield put({
                type: visibilityHeader.VISIBILITY_STOP_LOADING,
                payload: {},
            })
            return
        }
        if (res.status === 504) {
            //console.log('401')
            NotificationManager.error('Server error')
            yield put({
                type: visibilityHeader.VISIBILITY_STOP_LOADING,
                payload: {},
            })
            return
        }
        if (res.status === 200) {
            //  console.log('200', res)
            if (res.data.status === 400) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: visibilityHeader.VISIBILITY_STOP_LOADING,
                    payload: {},
                })
            } else if (res.data.code == 404) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: visibilityHeader.VISIBILITY_STOP_LOADING,
                    payload: {},
                })
            } else if (res.data.status == 500) {
                NotificationManager.error('Server Error')

                yield put({
                    type: visibilityHeader.VISIBILITY_STOP_LOADING,
                    payload: {},
                })
            } else {
                // NotificationManager.success('data fetched successfully')
                yield put({
                    type: visibilityHeader.DISPLAY_TEMPLATE_TABLEDATA,
                    payload: res.data,
                })
            }
        } else {
            if (res.data.error) {
                NotificationManager.error(res.data.error)
            } else {
                NotificationManager.error(
                    'Internal application error. Please contact system administrator.'
                )
            }
        }
    } catch (error) {
        console.log(error)
    }
}

function* getTemplateTableData() {
    yield takeEvery(visibilityHeader.GET_TEMPLATE_TABLEDATA, getTemplateTable)
}

//===============================================get Template table data======================
function* visibilityDropDownId(payload) {
    try {
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        }
        var res = yield axios
            .request({
                method: 'GET',
                url: VISIBILITY_ID + 'visibility',
                headers: headers,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        //console.log('Response', res)

        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: visibilityHeader.VISIBILITY_HEADER_ERROR_STATUS,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status === 403) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: visibilityHeader.VISIBILITY_HEADER_ERROR_STATUS,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status >= 500) {
            //console.log('401')
            NotificationManager.error('Server error')
            yield put({
                type: visibilityHeader.VISIBILITY_STOP_LOADING,
                payload: {},
            })
            return
        }
        if (res.status === 504) {
            //console.log('401')
            NotificationManager.error('Server error')
            yield put({
                type: visibilityHeader.VISIBILITY_STOP_LOADING,
                payload: {},
            })
            return
        }
        if (res.status === 200) {
            //  console.log('200', res)
            if (res.data.status === 400) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: visibilityHeader.VISIBILITY_STOP_LOADING,
                    payload: {},
                })
            } else if (res.data.code == 404) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: visibilityHeader.VISIBILITY_STOP_LOADING,
                    payload: {},
                })
            } else if (res.data.status == 500) {
                NotificationManager.error('Server Error')

                yield put({
                    type: visibilityHeader.VISIBILITY_STOP_LOADING,
                    payload: {},
                })
            } else {
                yield put({
                    type: visibilityHeader.DISPLAY_VISIBILITY_ID_DROPDOWN,
                    payload: res.data,
                })
            }
        } else {
            if (res.data.error) {
                NotificationManager.error(res.data.error)
            } else {
                NotificationManager.error(
                    'Internal application error. Please contact system administrator.'
                )
            }
        }
    } catch (error) {
        console.log(error)
    }
}

function* getVisibilityDropDownId() {
    yield takeEvery(
        visibilityHeader.GET_VISIBILITY_ID_DROPDOWN,
        visibilityDropDownId
    )
}

function* VisibilityStartEndStatus() {
    try {
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        }
        var res = yield axios
            .request({
                method: 'GET',
                url: VISIBILITY_START_END_STATUS_LIST,
                headers: headers,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        //console.log('Response', res)

        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: visibilityHeader.VISIBILITY_HEADER_ERROR_STATUS,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status === 403) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: visibilityHeader.VISIBILITY_HEADER_ERROR_STATUS,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status >= 500) {
            //console.log('401')
            NotificationManager.error('Server error')
            yield put({
                type: visibilityHeader.VISIBILITY_STOP_LOADING,
                payload: {},
            })
            return
        }
        if (res.status === 504) {
            //console.log('401')
            NotificationManager.error('Server error')
            yield put({
                type: visibilityHeader.VISIBILITY_STOP_LOADING,
                payload: {},
            })
            return
        }
        if (res.status === 200) {
            //  console.log('200', res)
            if (res.data.status === 400) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: visibilityHeader.VISIBILITY_STOP_LOADING,
                    payload: {},
                })
            } else if (res.data.code == 404) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: visibilityHeader.VISIBILITY_STOP_LOADING,
                    payload: {},
                })
            } else if (res.data.status == 500) {
                NotificationManager.error('Server Error')

                yield put({
                    type: visibilityHeader.VISIBILITY_STOP_LOADING,
                    payload: {},
                })
            } else {
                yield put({
                    type: visibilityHeader.VISIBILITY_STATUS_GET_RESPONSE,
                    payload: res.data ? res.data[0].visibilityStatus : [],
                })
            }
        } else {
            if (res.data.error) {
                NotificationManager.error(res.data.error)
            } else {
                NotificationManager.error(
                    'Internal application error. Please contact system administrator.'
                )
            }
        }
    } catch (error) {
        console.log(error)
    }
}

function* getVisibilityStartEndStatusList() {
    yield takeEvery(
        visibilityHeader.VISIBILITY_STATUS_GET_REQUEST,
        VisibilityStartEndStatus
    )
    // yield takeEvery(
    //     visibilityHeader.GET_VISIBILITY_ID_DROPDOWN,
    //     visibilityDropDownId
    // )
}
function* saveVisibilityStatus({ payload }) {
    console.log(payload.visibilityStatusString)

    try {
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        }
        var req = JSON.stringify({
            visibilityStatus: [payload.visibilityStatusString],
        })
        var res = yield axios
            .request({
                method: 'PUT',
                url: VISIBILITY_START_END_STATUS_SAVE,
                headers: headers,
                data: req,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        //console.log('Response', res)

        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: visibilityHeader.VISIBILITY_HEADER_ERROR_STATUS,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        // if (res.status === 403) {
        //     NotificationManager.error('Unauthorized Access')
        //     yield put({
        //         type: visibilityHeader.VISIBILITY_HEADER_ERROR_STATUS,
        //         payload: { error: 'Internal application error. Please contact system administrator.' },
        //     })
        //     return
        // }
        if (res.status >= 500) {
            //console.log('401')
            NotificationManager.error('Server error')
            yield put({
                type: visibilityHeader.VISIBILITY_STOP_LOADING,
                payload: {},
            })
            return
        }
        if (res.status === 504) {
            //console.log('401')
            NotificationManager.error('Server error')
            yield put({
                type: visibilityHeader.VISIBILITY_STOP_LOADING,
                payload: {},
            })
            return
        }
        if (res.status === 200) {
            //  console.log('200', res)
            if (res.data.status === 400) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: visibilityHeader.VISIBILITY_STOP_LOADING,
                    payload: {},
                })
            } else if (res.data.code == 404) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: visibilityHeader.VISIBILITY_STOP_LOADING,
                    payload: {},
                })
            } else if (res.data.status == 500) {
                NotificationManager.error('Server Error')

                yield put({
                    type: visibilityHeader.VISIBILITY_STOP_LOADING,
                    payload: {},
                })
            } else {
                yield put({
                    type: visibilityHeader.VISIBILITY_STATUS_GET_RESPONSE,
                    payload: res.data ? res.data.visibilityStatus : [],
                })
            }
        } else {
            if (res.data.error) {
                NotificationManager.error(res.data.error)
            } else {
                NotificationManager.error(
                    'Internal application error. Please contact system administrator.'
                )
            }
        }
    } catch (error) {
        console.log(error)
    }
}
function* visibilityStatusSave() {
    yield takeEvery(
        visibilityHeader.VISIBILITY_STATUS_SAVE_REQUEST,
        saveVisibilityStatus
    )
}
function* requestUniqueProcessId() {
    try {
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        }

        var res = yield axios
            .request({
                method: 'GET',
                url: GET_UNIQUE_PROCESSED,
                headers: headers,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        //console.log('Response', res)

        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: visibilityHeader.VISIBILITY_HEADER_ERROR_STATUS,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }

        if (res.status >= 500) {
            //console.log('401')
            NotificationManager.error('Server error')
            yield put({
                type: visibilityHeader.VISIBILITY_STOP_LOADING,
                payload: {},
            })
            return
        }
        if (res.status === 504) {
            //console.log('401')
            NotificationManager.error('Server error')
            yield put({
                type: visibilityHeader.VISIBILITY_STOP_LOADING,
                payload: {},
            })
            return
        }
        if (res.status === 200) {
            //  console.log('200', res)
            if (res.data.status === 400) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: visibilityHeader.VISIBILITY_STOP_LOADING,
                    payload: {},
                })
            } else if (res.data.code == 404) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: visibilityHeader.VISIBILITY_STOP_LOADING,
                    payload: {},
                })
            } else if (res.data.status == 500) {
                NotificationManager.error('Server Error')

                yield put({
                    type: visibilityHeader.VISIBILITY_STOP_LOADING,
                    payload: {},
                })
            } else {
                yield put({
                    type: visibilityHeader.RESPONSE_UNIQUE_PROCESS_ID,
                    payload: res.data ? res.data : [],
                })
            }
        } else {
            if (res.data.error) {
                NotificationManager.error(res.data.error)
            } else {
                NotificationManager.error(
                    'Internal application error. Please contact system administrator.'
                )
            }
        }
    } catch (error) {
        console.log(error)
    }
}
function* getUniqueProcessId() {
    yield takeEvery(
        visibilityHeader.REQUEST_UNIQUE_PROCESS_ID,
        requestUniqueProcessId
    )
}
export default function* rootSaga() {
    yield all([
        getVisibilityHeaderDetails(),
        getEditDataById(),
        addVisivibilityHeaderData(),
        deleteVisibilityHeader(),
        updateVisibilityHeaderData(),
        getVisibilityHeader(),
        saveNewVisibility(),
        getvisibilityHeaderId(),
        saveEditVisiHeader(),
        deleteVisibilityHeaderSingle(),
        getVisibilityDetails(),
        saveVisibilityDetailsNew(),
        getVisiDetailsById(),
        saveEditDetails(),
        deleteVisibilityDetails(),
        getTemplateTableData(),
        getVisibilityDropDownId(),
        getVisibilityStartEndStatusList(),
        visibilityStatusSave(),
        getUniqueProcessId(),
    ])
}
