import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'

const VisibilityHeaderTable = Loadable(lazy(() => import('./VisibilityHeaderTable')))
// const CreateNewRule = Loadable(lazy(() => import('./CreateNewRule')))
// const PaginatedItems = Loadable(lazy(() => import('./PaginatedItems')))

const VisibilityRoutes = [
    
    {
        path:'/VisibilityHeaderTable',
        element: <VisibilityHeaderTable />
    },
    // {
    //     path:'/PaginatedItems',
    //     element: <PaginatedItems/>
    // },
    // {
    //     path:'/CreateNewRule',
    //     element: <CreateNewRule/>
    // }
    
]



export default VisibilityRoutes
