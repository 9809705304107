import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'

const JsonFileFormat = Loadable(lazy(() => import('./JsonFileFormat')));
const JsonRerender = Loadable(lazy(() => import('./JsonRerender')));
const CardStyles =Loadable(lazy(() => import('./CardStyles')));
const TableStyles =Loadable(lazy(() => import('./TableStyles')));
const GraphStyles = Loadable(lazy(() => import('./GraphStyles')));
const EmptyFile = Loadable(lazy(() => import('./EmptyFile')));

const JsonFileRoutes = [
    {
        path: '/JsonFileFormat',
        element: <JsonFileFormat />,
    },
    {
        path:'/JsonRerender',
        element:<JsonRerender/>
    },
    {
        path:'/CardStyles',
        element:<CardStyles/>

    },
    {
        path:'/TableStyles',
        element:<TableStyles/>

    },
    {
        path:'/GraphStyles',
        element:<GraphStyles/>
    },
    {
        path:'/EmptyFile',
        element:<EmptyFile/>
    }

]

export default JsonFileRoutes
