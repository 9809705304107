import { ruleConstant } from '../../constant/ActionTypes'

import {
    TAG_VALUE_ERROR,
    DESCRIPTION_ERROR,
    RULE_NAME_ERROR,
    TAG_VALUE_ERROR_ONLY_NUMERIC,
    TAG_VALUE_ERROR_ONLY_STRING,
} from '../../constant/ErrorConstant'
export const ruleAction = {
    //===============================Role List ================
    ruleDialogOpenClose,
    openAddRuleDialog,
    closeAddNewRule,
    getAllRuleList,
    ruleGetLocalData,
    ruleInputChange,
    postAddNewRule,
    getSingleRuleRecord,
    ruleDeleteById,
    updateRuleRecord,
    handleNewRule,
    closeruleDialogOpenClose,
    tagValueInputChange,
}
function tagValueInputChange(props) {
    let tagValueError = ''
    if (props.value) {
        var re = /^[0-9]+$/
        var isValidNumber = re.test(props.value)
        if (
            props.operator == 'LESS_THAN' ||
            props.operator == 'GREATER_THAN' ||
            props.operator == 'LESS_THAN_EQUAL_TO' ||
            props.operator == 'GREATER_THAN_EQUAL_TO'
        ) {
            if (!isValidNumber) {
                var re = /^[ tT0-9 :-]*$/
                var re1 =
                    /^[0-9]{4}-((0[13578]|1[02])-(0[1-9]|[12][0-9]|3[01])|(0[469]|11)-(0[1-9]|[12][0-9]|30)|(02)-(0[1-9]|[12][0-9]))T(0[0-9]|1[0-9]|2[0-3]):(0[0-9]|[1-5][0-9]):(0[0-9]|[1-5][0-9])\.[0-9]{3}$/
                var isValid = re.test(props.value)
                //console.log('Validation error', props.value, isValid)
                if (!isValid) {
                    tagValueError = TAG_VALUE_ERROR_ONLY_NUMERIC
                }
            }
        } else if (props.operator == 'CONTAINS') {
            var re = /^[ A-Za-z*]*$/
            var isValid = re.test(props.value)
            if (!isValid) {
                tagValueError = TAG_VALUE_ERROR_ONLY_STRING
            }
        }

        /*if (!isValidNumber) {
            var req = /^[ tT0-9 :-]*$/
            var charValid = req.test(props.value)

            if (charValid) {
                //   var require= /\d{4}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[1-2]\d|3[0-1])T(?:[0-1]\d|2[0-3]):[0-5]\d:[0-5]\d/
                //   var  validTrue = require.test(props.value)
                //   if(!validTrue){
                //     tagValueError = TAG_VALUE_ERROR_ONLY_NUMERIC
                //   }
            } else {
                tagValueError = TAG_VALUE_ERROR_ONLY_NUMERIC
            }
        }*/
    }

    if (tagValueError) {
        return {
            type: ruleConstant.POST_DATA_VALIDATION_RULE,
            payload: {
                tagValueError,
            },
        }
    } else {
        return {
            type: ruleConstant.RULE_INPUT_INITIAL_CHANGE,
            payload: props,
        }
    }
}

function closeruleDialogOpenClose() {
    return {
        type: ruleConstant.CLOSE_RULE_DIALOG,
        payload: {},
    }
}
function closeAddNewRule() {
    return {
        type: ruleConstant.CLOSE_NEW_RULE,
        payload: {},
    }
}
function ruleDialogOpenClose() {
    return {
        type: ruleConstant.GET_RULE_DIALOG_OPEN,
        payload: {},
    }
}
function handleNewRule(props) {
    return {
        type: ruleConstant.NEW_RULE_ADD_DETAILS,
        payload: props,
    }
}

function openAddRuleDialog() {
    return {
        type: ruleConstant.ADD_RULE_OPEN_DIALOG,
        payload: {},
    }
}

function getAllRuleList() {
    return {
        type: ruleConstant.GET_ALL_RULE_LIST,
        payload: {},
    }
}

function ruleGetLocalData(props) {
    return {
        type: ruleConstant.GET_LOCAL_DATA_RULE,
        payload: props,
    }
}
function ruleInputChange(props) {
    return {
        type: ruleConstant.RULE_INPUT_INITIAL_CHANGE,
        payload: props,
    }
}

function postAddNewRule(props) {
    //console.log('Welcome', props)
    let ruleNameError = '',
        descriptionError = '',
        tagValueError = ''

    // if (!props.ruleName) {
    //     ruleNameError = RULE_NAME_ERROR
    // }
    // if (!props.description) {
    //     descriptionError = DESCRIPTION_ERROR
    // }
    if (!props.tagValue) {
        tagValueError = TAG_VALUE_ERROR
    } else if (props.tagValue) {
        var req = /^[0-9]+$/
        var isValidNumber = req.test(props.tagValue)
        if (props.operator == 'IS_EQUAL_TO') {
            //String, Number and Date
        } else if (
            props.operator == 'LESS_THAN' ||
            props.operator == 'GREATER_THAN_EQUAL_TO' ||
            props.operator == 'GREATER_THAN' ||
            props.operator == 'LESS_THAN_EQUAL_TO'
        ) {
            //Number Or Date
            if (!isValidNumber) {
                var re =
                    /\d{4}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[1-2]\d|3[0-1])T(?:[0-1]\d|2[0-3]):[0-5]\d:[0-5]\d/
                var isValid = re.test(props.tagValue)
                if (!isValid) {
                    tagValueError = TAG_VALUE_ERROR_ONLY_NUMERIC
                }
            }
        } else if (props.operator == 'NOT_EQUAL_TO') {
            //String, Number and Date
        } else if (props.operator == 'CONTAINS') {
            //String
            var re = /^[ A-Za-z*]*$/
            var isValid = re.test(props.tagValue)
            if (!isValid) {
                tagValueError = TAG_VALUE_ERROR_ONLY_STRING
            }
        }
        /* var req = /^[0-9]+$/
        var isValidNumber = req.test(props.tagValue)

        if (!isValidNumber) {
            var re =
                /\d{4}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[1-2]\d|3[0-1])T(?:[0-1]\d|2[0-3]):[0-5]\d:[0-5]\d/
            var isValid = re.test(props.tagValue)
            if (!isValid) {
                tagValueError = TAG_VALUE_ERROR_ONLY_NUMERIC
            }
        }
        // }
        */
    }

    if (ruleNameError || descriptionError || tagValueError) {
        return {
            type: ruleConstant.POST_DATA_VALIDATION_RULE,
            payload: {
                ruleNameError,
                descriptionError,
                tagValueError,
            },
        }
    } else {
        return {
            type: ruleConstant.POST_NEW_RULE_DATA,
            payload: props,
        }
    }
}

function getSingleRuleRecord(props) {
    return {
        type: ruleConstant.GET_RULE_RECORD_BY_RULEID,
        payload: props,
    }
}

function ruleDeleteById(props) {
    return {
        type: ruleConstant.RULE_DELETE_BY_RULEID,
        payload: props,
    }
}

function updateRuleRecord(props) {
    let ruleNameError = '',
        descriptionError = '',
        tagValueError = ''

    // if (!props.ruleName) {
    //     ruleNameError = RULE_NAME_ERROR
    // }
    // if (!props.description) {
    //     descriptionError = DESCRIPTION_ERROR
    // }
    if (!props.tagValue) {
        tagValueError = TAG_VALUE_ERROR
    } else if (props.tagValue) {
        var req = /^[0-9]+$/
        var isValidNumber = req.test(props.tagValue)
        if (props.operator == 'IS_EQUAL_TO') {
            //String, Number and Date
        } else if (
            props.operator == 'LESS_THAN' ||
            props.operator == 'GREATER_THAN_EQUAL_TO' ||
            props.operator == 'GREATER_THAN' ||
            props.operator == 'LESS_THAN_EQUAL_TO'
        ) {
            //Number Or Date
            if (!isValidNumber) {
                var re =
                    /\d{4}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[1-2]\d|3[0-1])T(?:[0-1]\d|2[0-3]):[0-5]\d:[0-5]\d/
                var isValid = re.test(props.tagValue)
                if (!isValid) {
                    tagValueError = TAG_VALUE_ERROR_ONLY_NUMERIC
                }
            }
        } else if (props.operator == 'NOT_EQUAL_TO') {
            //String, Number and Date
        } else if (props.operator == 'CONTAINS') {
            //String
            var re = /^[ A-Za-z*]*$/
            var isValid = re.test(props.tagValue)
            if (!isValid) {
                tagValueError = TAG_VALUE_ERROR_ONLY_STRING
            }
        }
        /*
       

        if (!isValidNumber) {
            var re =
                /\d{4}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[1-2]\d|3[0-1])T(?:[0-1]\d|2[0-3]):[0-5]\d:[0-5]\d/
            var isValid = re.test(props.tagValue)
            if (!isValid) {
                tagValueError = TAG_VALUE_ERROR_ONLY_NUMERIC
            }
        }*/
    }

    if (ruleNameError || descriptionError || tagValueError) {
        return {
            type: ruleConstant.POST_DATA_VALIDATION_RULE,
            payload: {
                ruleNameError,
                descriptionError,
                tagValueError,
            },
        }
    } else {
        return {
            type: ruleConstant.UPDATE_RULE_RECORD,
            payload: props,
        }
    }
}
