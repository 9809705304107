import React from 'react'
import { useSelector } from 'react-redux'
import { Container, hiddenElement } from 'app/constant/Common'
import { Breadcrumb } from 'app/components'
import { SimpleCard } from 'app/components'
import {
    Icon,
    Fab,
    CircularProgress,
    Grid,
    FormControl,
    Checkbox,
    Divider,
    Button,
    FormControlLabel,
    TextField,
    Box,
} from '@mui/material'
import { Link } from 'react-router-dom'
import { adminUserAction } from '../../redux/actions/AdminUserAction'
import { useDispatch } from 'react-redux'

export default function AddNewRole() {
    const dispatch = useDispatch()
    const AdminUserReducer = useSelector((state) => state.AdminUserReducer)
    return (
        <>
            <Grid container spacing={1}>
                <Grid item lg={6} md={6} sm={6} xs={6}>
                    <FormControl
                        style={{
                            fontSize: '15px',
                            paddingRight: '5px',
                            marginTop: '0px',
                        }}
                        className=""
                        fullWidth={true}
                    >
                        <TextField
                            fullWidth
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            label="Roles Name"
                            name="Roles"
                            // disabled={
                            //     adminInputChange.editGrpPage == true
                            //         ? true
                            //         : false
                            // }
                            className="mb-2 w-full"
                            style={{
                                width: '100%',
                                // marginBottom: '5px',
                            }}
                            onChange={(value) => {
                                dispatch(
                                    adminUserAction.inputChangeAdmin({
                                        props: 'newRoleName',
                                        value: value.target.value,
                                        error: 'newRoleNameError',
                                    })
                                )
                            }}
                            required
                            SelectProps={{ native: true }}
                            value={AdminUserReducer.newRoleName}
                            variant="outlined"
                        ></TextField>
                        <div className="mb-2 form_error">
                            {AdminUserReducer.newRoleNameError}
                        </div>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item lg={6} md={6} sm={6} xs={6}>
                    <FormControl
                        style={{
                            fontSize: '15px',
                            paddingRight: '5px',
                            marginTop: '0px',
                        }}
                        className=""
                        fullWidth={true}
                    >
                        <TextField
                            fullWidth
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            label="Description"
                            name="description"
                            className="mb-2 w-full"
                            style={{
                                width: '100%',
                                // marginBottom: '5px',
                            }}
                            multiline
                            rows={5}
                            onChange={(value) => {
                                dispatch(
                                    adminUserAction.inputChangeAdmin({
                                        props: 'newDescription',
                                        value: value.target.value,
                                        error: 'newDescriptionError',
                                    })
                                )
                            }}
                            SelectProps={{ native: true }}
                            value={AdminUserReducer.newDescription}
                            variant="outlined"
                        ></TextField>
                        <div className="mb-2 form_error">
                            {AdminUserReducer.newDescriptionError}
                        </div>
                    </FormControl>
                </Grid>
            </Grid>
        </>
    )
}
