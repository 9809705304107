import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import AddNewUser from './AddNewUser'
import { Button, Icon, CircularProgress, Grid } from '@mui/material'
import { Link } from 'react-router-dom'
import { SimpleCard } from 'matx'
import { useNavigate } from 'react-router-dom'
import EditUserDetails from './EditUserDetails'
import UserListTableNew from './UserListTableNew'
import { adminUserAction } from '../../redux/actions/AdminUserAction'
import {reportUserConstant} from '../../redux/actions/ReportAction'
import { Container } from '../../constant/Common'
import { Breadcrumb } from 'app/components'
export default function ManageAdminPage() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const AdminPageManage = useSelector((state) => state.AdminUserReducer)
    const ReportReducers = useSelector((state) => state.ReportReducers)
    useEffect(() => {
        dispatch(adminUserAction.makeInitialAdminUserEmpty())
    }, [])

    // const handleAddProduct = () => {
    //     // navigate('/AddGroupName')
    // }

    return (
        <>
            <Container>
                <div className="breadcrumb">
                <Breadcrumb
                    routeSegments={[
                         { name: 'Admin Settings', path:''},
                        {
                            name: 'User List',
                        },
                    ]}
                />
                </div>
                
                {AdminPageManage.loader && (
                    <div className="loader-view  loader">
                        <CircularProgress size={54} />
                    </div>
                )}
                <div>
                    {/* {AdminPageManage.addNewUserPage == true ? (
                        <EditUserDetails style={{marginTop:'30px'}}/>
                    ) : ( */}
                        <UserListTableNew />
                    {/* )} */}
                </div>
            </Container>
        </>
    )
}
