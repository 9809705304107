import { all, call, put, takeEvery, getContext } from 'redux-saga/effects'
import { lookupType,visibilityHeader } from '../../constant/ActionTypes'
import axios from 'axios'
import {
    API_URL,
    headers,
    headers1,
    LOOKUP_SAVE,
    LOOKUP_GET,
    LOOKUP_HEADER_EDIT,
    LOOKUP_DETAILS_LIST,
    LOOKUP_DETAIL_BY_ID,
    ADD_LOOKUP_DETAILS_DATA,
    DELETE_LOOKUP_DETAILS,
    EDIT_SAVE_LOOKUP_DETAILS,
    TEMPLATE_LIST,
    TEMPLATE_EDIT_BY_ID,
    DELETE_TEMPLATE_DATA,
    ADD_TEMPLATE,
    generalFunction,
    EDIT_SAVE_TEMPLATE_DATA,
} from '../../constant/Common'
import { API } from '../../constant/ActionTypes'
import { NotificationManager } from 'react-notifications'

import { LOCATION_CHANGE, push } from 'react-router-redux'

function* lookupSave(payload) {
    let prop = payload.payload.props
    //     let form = new FormData()
    //    // form.append('formMode', prop.formModes)
    //console.log(payload)
    let mode = payload.payload.mode
    const requestData = {
        groupId: prop.groupId,
        visibilityId: prop.visibilityId,
        status: 1,
    }
    //console.log(requestData)
    try {
        var res = yield axios
            .post(API_URL.api + LOOKUP_SAVE, requestData, {
                headers: headers,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        if (res.status == 200) {
            if (res.data.status == 409) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: lookupType.LOOK_UP_SAVE_FAILED,
                    payload: {}
                })
            } else if (res.data.status == 500) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: lookupType.LOOK_UP_SAVE_FAILED,
                    payload: {}
                })
            } else if (res.data.status == 400) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: lookupType.LOOK_UP_SAVE_FAILED,
                    payload: {}
                })
            } else {
                NotificationManager.success('Saved SuccessFully')
                yield put({
                    type: lookupType.LOOKUP_SAVE_RESPONSE,
                    payload: {
                        mode: mode,
                        groupId: res.data.groupId,
                        visibilityId: res.data.visibilityId,
                        tagName: res.data.tagName,
                        tagPosition: res.data.tagPosition,
                    },
                })
            }
        } else {
            NotificationManager.error(res.error)
        }
    } catch (e) {
        console.log(e)
    }
}
function* saveTemplate(payload) {
    let prop = payload.payload
    /*   let form = new FormData()
    form.append('formMode', prop.formModes)
*/
    //console.log(prop)
    yield put({
        type: lookupType.LOOKUP_TEMPLATE_SAVE_RESPONSE,
        payload: {
            templateName: prop.props.templateName,
            templateFile: '',
            templateId: prop.props.templateId,
        },
    })
}
function* getLookupList(payload) {
    let props = payload.payload.props
    try {
        var res = yield axios
            .get(API_URL.api + LOOKUP_GET, {
                headers: headers,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        //console.log(res)
        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: lookupType.LOOKUP_HEADER_DELETE_ERROR,
                payload: { error: 'Internal application error. Please contact system administrator.' },
            })
            return
        }
      
        if (res.status === 200) {
            if (res.data.status == 500) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: lookupType.LOOK_UP_SAVE_FAILED,
                    payload: {},
                })
            } else {
                yield put({
                    type: lookupType.LOOKUP_LIST_RESPONSE,
                    payload: res.data,
                })
            }
        } else {
            yield put({
                type: lookupType.LOOKUP_LIST_ERROR,
                payload: res.data,
            })
            NotificationManager.error(res.data)
        }
    } catch (e) {
        console.log(e)
    }
}
function* getLookUpEdit(payload) {
    let prop = payload.payload.props
    //https://l275s5zw99.execute-api.ap-south-1.amazonaws.com/TST/fillitto/visibility/master/lookup/header/id?groupId=0&visibilityId=MACYSORDERPROCESS2
    //let parm = 'groupId=' + prop.groupId + '&visibilityId=' + prop.visibilityId
    let parm = prop.groupId

    try {
        var res = yield axios
            .get(API_URL.api + LOOKUP_HEADER_EDIT + parm, {
                headers: headers,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        //console.log(res)
        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: lookupType.LOOKUP_HEADER_DELETE_ERROR,
                payload: { error: 'Internal application error. Please contact system administrator.' },
            })
            return
        }

        if (res.status === 200) {
            if (res.data.status == 500) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: lookupType.LOOKUP_EDIT_ERROR,
                    payload: res.data,
                })
            } else if (res.data.status == 400) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: lookupType.LOOKUP_EDIT_ERROR,
                    payload: res.data,
                })
            } else if (res.data.status == 404) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: lookupType.LOOKUP_EDIT_ERROR,
                    payload: res.data,
                })
            } else {
                yield put({
                    type: lookupType.LOOKUP_EDIT_RESPONSE,
                    payload: res.data,
                })
            }
        } else {
            yield put({
                type: lookupType.LOOKUP_EDIT_ERROR,
                payload: res.data,
            })
            NotificationManager.error(res.data.error)
        }
    } catch (e) {
        console.log(e)
    }
}

function* deleteLookupHeader(payload) {
    let prop = payload.payload.props
    //https://l275s5zw99.execute-api.ap-south-1.amazonaws.com/TST/fillitto/visibility/master/lookup/header/id?groupId=0&visibilityId=MACYSORDERPROCESS2
    let parm = prop.groupId // + '&visibilityId=' + prop.visibilityId
    try {
        var res = yield axios
            .delete(API_URL.api + LOOKUP_HEADER_EDIT + parm, {
                headers: headers,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        //console.log(res)
        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: lookupType.LOOKUP_HEADER_DELETE_ERROR,
                payload: { error: 'Internal application error. Please contact system administrator.' },
            })
            return
        }

        if (res.status === 200) {
            //console.log('res.data', res.data)
            if (res.data.code === 200) {
                NotificationManager.success(res.data.message)
                yield put({
                    type: lookupType.LOOKUP_HEADER_DELETE_RESPONSE,
                    payload: {
                        groupId: prop.groupId,
                        visibilityId: prop.visibilityId,
                    },
                })
            } else if (res.data.status == 400) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: lookupType.LOOK_UP_SAVE_FAILED,
                    payload: {}
                })
            } else if (res.data.status == 404) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: lookupType.LOOK_UP_SAVE_FAILED,
                    payload: {}
                })
            } else {
                NotificationManager.error('Internal application error. Please contact system administrator.')
                yield put({
                    type: lookupType.LOOK_UP_SAVE_FAILED,
                    payload: {}
                })
            }
        } else if (res.data.status == 400) {
            NotificationManager.error(res.data.error)
            yield put({
                type: lookupType.LOOK_UP_SAVE_FAILED,
                payload: {}
            })
        } else if (res.data.status == 404) {
            NotificationManager.error(res.data.error)
            yield put({
                type: lookupType.LOOK_UP_SAVE_FAILED,
                payload: {}
            })
        } else {
            NotificationManager.error('Internal application error. Please contact system administrator.')
        }
    } catch (e) {
        console.log(e)
    }
}

//======================================================== look up details Saga =========================================================

// ============================================ get All List of LookUp details ===========================================

function* getListLookupDetails() {
    try {
        var res = yield axios
            .request({
                method: 'get',
                url: API_URL.api + LOOKUP_DETAILS_LIST,
                headers: headers,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        //console.log('response', res)
        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: lookupType.LOOKUP_HEADER_DELETE_ERROR,
                payload: { error: 'Internal application error. Please contact system administrator.' },
            })
            return
        }
        if (res.status == 200) {
            if (res.data.status == 500) {
                NotificationManager.error(res.data.error)
            } else {
                yield put({
                    type: lookupType.DISPLAY_ALL_LOOKUP_DETAILS,
                    payload: res.data,
                })
            }
        }
    } catch (e) {
        console.log(e)
    }
}

function* getLookUpDetails() {
    yield takeEvery(lookupType.GET_LOOKUP_DETAILS_LIST, getListLookupDetails)
}

// ============================================ get List of LookUp details by ID ===========================================

function* getDetailsById(payload) {
    //console.log('fvfdvfdvdv', payload.payload.generatedID)
    try {
        var res = yield axios
            .request({
                method: 'get',
                url:
                    API_URL.api +
                    LOOKUP_DETAIL_BY_ID +
                    payload.payload.generatedID,
                headers: headers,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        //console.log('response', res)
        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: lookupType.LOOKUP_HEADER_DELETE_ERROR,
                payload: { error: 'Internal application error. Please contact system administrator.' },
            })
            return
        }

        if (res.status == 200) {
            if (res.data.status == 500) {
                NotificationManager.error(res.data.error)
            } else {
                yield put({
                    type: lookupType.DISPLAY_EDITED_DATA,
                    payload: res.data,
                })
            }
        }
    } catch (e) {
        console.log(e)
    }
}
function* getLookUpDetailsById() {
    yield takeEvery(lookupType.GET_LOOKUP_EDIT_DETAILS, getDetailsById)
}

// ============================================ Add New LookUp details ===========================================

function* addNewLookupDetails(payload) {
    let prop = payload.payload.props
    let mode = payload.payload.mode
    var req = JSON.stringify({
        groupId: prop.groupId,
        tag: prop.tagName,
        position: prop.tagPosition,
    })
    try {
        var res = yield axios
            .request({
                method: 'post',
                url: API_URL.api + ADD_LOOKUP_DETAILS_DATA,
                headers: headers,
                data: req,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: lookupType.LOOKUP_HEADER_DELETE_ERROR,
                payload: { error: 'Internal application error. Please contact system administrator.' },
            })
            return
        }

        if (res.status == 200) {
            if (res.data.status == 409) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: lookupType.LOOK_UP_SAVE_FAILED,
                    payload: {}
                })
            } else if (res.data.status == 500) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: lookupType.LOOK_UP_SAVE_FAILED,
                    payload: {}
                })
            } else if (res.data.status == 400) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: lookupType.LOOK_UP_SAVE_FAILED,
                    payload: {}
                })
            } else if (res.data.generatedID > 0) {
                NotificationManager.success('Successfully Added')
                yield put({
                    type: lookupType.LOOKUP_SAVE_RESPONSE,
                    payload: {
                        mode: mode,
                        groupId: res.data.groupId,
                        tagName: res.data.tag,
                        tagPosition: res.data.position,
                        generatedID: res.data.generatedID,
                    },
                })
            } else {
                NotificationManager.error(res.data.error)
            }
        } else {
            NotificationManager.error('Internal application error. Please contact system administrator.')
        }
    } catch (e) {
        console.log(e)
    }
}
function* addLookupDetails() {
    yield takeEvery(lookupType.ADD_LOOKUP_DETAILS, addNewLookupDetails)
}

// ============================================ Delete LookUp details ===========================================

function* deleteLookupRecord(payload) {
    //console.log('dfvdfvfv', payload)
    try {
        var res = yield axios
            .request({
                method: 'delete',
                url:
                    API_URL.api +
                    DELETE_LOOKUP_DETAILS +
                    payload.payload.generatedID,
                headers: headers,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        //console.log('response', res)
        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: lookupType.LOOKUP_HEADER_DELETE_ERROR,
                payload: { error: 'Internal application error. Please contact system administrator.' },
            })
            return
        }

        if (res.status == 200) {
            if (res.data.status == 500) {
                NotificationManager.error(res.data.error)
            }else if(res.data.status == 400) {
                NotificationManager.error(res.data.error)
            }else if (res.data.status == 409){
                NotificationManager.error(res.data.error)
            }
            else {
                NotificationManager.success(res.data.message)
                yield put({
                    type: lookupType.DELETED_SHOW_LIST,
                    payload: payload.payload.generatedID,
                })
            }
        } else {
            NotificationManager.error('Internal application error. Please contact system administrator.')
        }
    } catch (e) {
        console.log(e)
    }
}
function* deleteLookupDetails() {
    yield takeEvery(lookupType.DELETE_LOOKUP_DETAILS, deleteLookupRecord)
}

// ============================================ Update LookUp details ===========================================

function* editSaveLookup(payload) {
    var prop = payload.payload
    var req = JSON.stringify({
        groupId: prop.groupId,
        tag: prop.tagName,
        position: prop.tagPosition,
    })
    try {
        var res = yield axios
            .request({
                method: 'put',
                url: API_URL.api + EDIT_SAVE_LOOKUP_DETAILS + prop.generatedID,
                headers: headers,
                data: req,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        //console.log('response', res)
        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: lookupType.LOOKUP_HEADER_DELETE_ERROR,
                payload: { error: 'Internal application error. Please contact system administrator.' },
            })
            return
        }
        if (res.status == 200) {
            if (res.data.status == 500) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: lookupType.LOOK_UP_SAVE_FAILED,
                    payload: {},
                })
            } else if (res.data.status == 400) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: lookupType.LOOK_UP_SAVE_FAILED,
                    payload: {},
                })
            } else if (res.data.status == 404) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: lookupType.LOOK_UP_SAVE_FAILED,
                    payload: {},
                })
            } else {
                NotificationManager.success('successfully Updated')
                yield put({
                    type: lookupType.DISPLAY_EDIT_DATA_LOOKUP,
                    payload: res.data,
                })
            }
        } else {
            NotificationManager.error('Internal application error. Please contact system administrator.')
        }
    } catch (e) {
        console.log(e)
    }
}

//======================================================== Template Saga =========================================================

// ============================================ get All Template ===========================================

function* getTemplateDetails() {
    try {
        var res = yield axios
            .request({
                method: 'get',
                url: API_URL.api + TEMPLATE_LIST,
                headers: headers,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        //console.log('response', res)
        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: lookupType.LOOKUP_HEADER_DELETE_ERROR,
                payload: { error: 'Internal application error. Please contact system administrator.' },
            })
            return
        }
        if (res.status == 200) {
            if (res.data.status == 500) {
                NotificationManager.error(res.data.error)
            } else {
                yield put({
                    type: lookupType.DISPLAY_ALL_TEMPLATE_LIST,
                    payload: res.data,
                })
            }
        } else {
            NotificationManager.error('Internal application error. Please contact system administrator.')
        }
    } catch (e) {
        console.log(e)
    }
}

function* getAllTemplate() {
    yield takeEvery(lookupType.GET_ALL_TEMPLATE_DETAILS, getTemplateDetails)
}

// ============================================ get Template By Id ===========================================

function* getTemplatebyId(payload) {
    try {
        var res = yield axios
            .request({
                method: 'get',
                url:
                    API_URL.api +
                    TEMPLATE_EDIT_BY_ID +
                    payload.payload.templateName,
                headers: headers,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        //console.log('response', res)
        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: lookupType.LOOKUP_HEADER_DELETE_ERROR,
                payload: { error: 'Internal application error. Please contact system administrator.' },
            })
            return
        }

        if (res.status == 200) {
            if (res.data.status == 500) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: lookupType.LOOK_UP_SAVE_FAILED,
                    payload: {}
                })
            } else {
                yield put({
                    type: lookupType.DISPLAY_TEMPLATE_BY_ID,
                    payload: res.data,
                })
            }
        } else {
            NotificationManager.error('Internal application error. Please contact system administrator.')
            yield put({
                type: lookupType.LOOK_UP_SAVE_FAILED,
                payload: {}
            })
        }
    } catch (e) {
        console.log(e)
    }
}
function* getTemplateId() {
    yield takeEvery(lookupType.GET_EDITABLE_TEMPLATE_BY_ID, getTemplatebyId)
}
// ============================================ Delete Template ===========================================

function* deleteTemplateData(payload) {
    //console.log('dfvdfvfv', payload)
    try {
        var res = yield axios
            .request({
                method: 'delete',
                url:
                    API_URL.api +
                    DELETE_TEMPLATE_DATA +
                    payload.payload.templateName,
                headers: headers,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        //console.log('response', res)
        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: lookupType.LOOKUP_HEADER_DELETE_ERROR,
                payload: { error: 'Internal application error. Please contact system administrator.' },
            })
            return
        }

        if (res.status == 200) {
            if (res.data.status == 400) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: lookupType.LOOK_UP_SAVE_FAILED,
                    payload: {}
                })
            } else if (res.data.status == 500) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: lookupType.LOOK_UP_SAVE_FAILED,
                    payload: {}
                })
            } else if (res.data.status == 404) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: lookupType.LOOK_UP_SAVE_FAILED,
                    payload: {}
                })
            } else {
                yield put({
                    type: lookupType.LOOKUP_TEMPLATE_DELETE_RESPONSE,
                    payload: { templateName: payload.payload.templateName },
                })
                NotificationManager.success(res.data.message)
            }
        } else {
            NotificationManager.error('Internal application error. Please contact system administrator.')
            yield put({
                type: lookupType.LOOK_UP_SAVE_FAILED,
                payload: {}
            })
        }
    } catch (e) {
        console.log(e)
    }
}
function* deleteTemplate() {
    yield takeEvery(lookupType.DELETE_TEMPLATE_DATA, deleteTemplateData)
}

// ============================================ Add New Template ===========================================

function* addNewTemplate(payload) {
    var generatedId = generalFunction.randomGeneratedString(16)
    var extName = payload.payload.props.templateFile.name
    var extensionValue = extName.split('.')
    var extLength = extensionValue.length
    var extFileName = extensionValue[extLength - 1]
    let form = new FormData()
    form.append('templateName', payload.payload.props.templateName)
    //form.append('fileName', generatedId + '.' + extFileName)
    form.append('fileName', extName)
    form.append('file', payload.payload.props.templateFile)
    try {
        var res = yield axios
            .request({
                method: 'post',
                url: API_URL.api + ADD_TEMPLATE,
                data: form,
                headers: headers,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: lookupType.LOOKUP_HEADER_DELETE_ERROR,
                payload: { error: 'Internal application error. Please contact system administrator.' },
            })
            return
        }

        if (res.status == 200) {
            if (res.data.status == 409) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: lookupType.LOOK_UP_SAVE_FAILED,
                    payload: {}
                })
            } else if (res.data.status == 500) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: lookupType.LOOK_UP_SAVE_FAILED,
                    payload: {}
                })
            } else {
                NotificationManager.success('Successfully Added')
                yield put({
                    type: lookupType.TEMPLATE_SAVE_DISPLAY,
                    payload: res.data,
                })
                yield put({
                    type: visibilityHeader.CLOSE_HEADER_TEMPLATE,
                    payload: payload.payload.props.templateName,
                })
            }
        } else {
            if (res.data.status != '') {
                NotificationManager.error(res.data.error)
                yield put({
                    type: lookupType.LOOK_UP_SAVE_FAILED,
                    payload: {}
                })
            } else {
                NotificationManager.error('Internal application error. Please contact system administrator.')
                yield put({
                    type: lookupType.LOOK_UP_SAVE_FAILED,
                    payload: {}
                })
            }
        }
    } catch (e) {
        console.log(e)
    }
}
function* addTemplateData() {
    yield takeEvery(lookupType.SAVE_TEMPLATE_DATA, addNewTemplate)
}
// ============================================ Update Template ===========================================

function* updateTemplate(payload) {
    var generatedId = generalFunction.randomGeneratedString(16)
    var extName = payload.payload.templateFile.name
    var extensionValue = extName.split('.')
    var extLength = extensionValue.length
    var extFileName = extensionValue[extLength - 1]
    let form = new FormData()
    form.append('templateName', payload.payload.templateName)
    //form.append('fileName', generatedId + '.' + extFileName)
    form.append('fileName', extName)
    form.append('file', payload.payload.templateFile)
    try {
        var res = yield axios
            .request({
                method: 'put',
                url:
                    API_URL.api +
                    EDIT_SAVE_TEMPLATE_DATA +
                    payload.payload.templateName,
                data: form,
                headers: headers,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        //console.log('response', res)
        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: lookupType.LOOKUP_HEADER_DELETE_ERROR,
                payload: { error: 'Internal application error. Please contact system administrator.' },
            })
            return
        }
        if (res.status == 200) {
            NotificationManager.success('successfully Updated')
            yield put({
                type: lookupType.DISPLAY_EDIT_TEMPLATE_DATA,
                payload: res.data,
            })
        }
        if (res.data.status == 400) {
            NotificationManager.error(res.data.error)
            yield put({
                type: lookupType.LOOK_UP_SAVE_FAILED,
                payload: {}
            })
        }
    } catch (e) {
        console.log(e)
    }
}
function* updateTemplateData() {
    yield takeEvery(lookupType.EDITED_SAVE_TEMPLATE_DATA, updateTemplate)
}

function* updateLookupDetails() {
    yield takeEvery(lookupType.SAVE_EDIT_LOOKUP_DETAILS, editSaveLookup)
}
function* actionLookupSaveWatcher() {
    yield takeEvery(lookupType.LOOK_UP_SAVE, lookupSave)
}
function* actionTemplateSaveWatcher() {
    yield takeEvery(lookupType.LOOKUP_TEMPLATE_SAVE_REQUEST, saveTemplate)
}
function* actionLookupGetList() {
    yield takeEvery(lookupType.LOOKUP_LIST_REQUEST, getLookupList)
}
function* actionLookupEdit() {
    yield takeEvery(lookupType.LOOKUP_EDIT_REQUEST, getLookUpEdit)
}
function* actionLookUpDelete() {
    yield takeEvery(lookupType.LOOKUP_HEADER_DELETE_REQUEST, deleteLookupHeader)
}
export default function* rootSaga() {
    yield all([
        actionLookupGetList(),
        actionLookupSaveWatcher(),
        actionTemplateSaveWatcher(),
        actionLookupEdit(),
        actionLookUpDelete(),

        getLookUpDetails(),
        getLookUpDetailsById(),
        addLookupDetails(),
        deleteLookupDetails(),
        updateLookupDetails(),

        getAllTemplate(),
        getTemplateId(),
        deleteTemplate(),
        addTemplateData(),
        updateTemplateData(),
    ])
}
