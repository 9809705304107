import { visibilityChange } from '../../constant/ActionTypes'
export const visibility = {
    visibilityInputChange,
    closePopUp,
    openPopUp,
    saveTheNewData,
    getEditRecord,
    emptyDialogContent,
    deleteTheElement,
    editSaveData,
    getVisivibiltyDetails,
    addOpenVisiblePanel,
    processDurationChange,
    startDialogClose,
    addStartStatus,
    initialStartEndStatus
}

function openPopUp(mode, visibilityId) {
    return {
        type: visibilityChange.DIALOG_POPUP_OPEN,
        payload: { popupOpenMode: mode, visibilityId: visibilityId },
    }
}
function closePopUp() {
    return {
        type: visibilityChange.DIALOG_POPUP_CLOSE,
        payload: {},
    }
}
function visibilityInputChange(props) {
    return {
        type: visibilityChange.VISIBILITY_INPUT_CHANGE,
        payload: props,
    }
}
function saveTheNewData(props) {
    //console.log(props)
    let endStatusError = '',
        sev1highDurationError = '',
        sev1midDurationError = '',
        sev1LowDurationError = '',
        startStatusError = '',
        visibilityIdError = '',
        processDurationError = '',
        detailAlertModeError = '',
        detailAlertInfoError = '',
        detailsTemplateNameError = ''
    if (!props.visibilityId) {
        visibilityIdError = 'Please Select the Id'
    }
    if (!props.detailAlertMode) {
        detailAlertModeError = 'Enter the Alert Mode'
    }
    if (!props.detailAlertInfo) {
        detailAlertInfoError = 'Enter the Alert Info'
    }
    if (props.detailAlertInfo) {
        if (props.detailAlertMode == 'email') {
            var re = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/
            var isValid = re.test(props.detailAlertInfo)
            if (!isValid) {
                detailAlertInfoError = 'Enter the valid email'
            }
        } else if (props.detailAlertMode == 'text') {
            var re = /^([+]?\d{1,3}[- ]?)?\d{10}$/
            var isValid = re.test(props.detailAlertInfo)
            if (!isValid) {
                detailAlertInfoError = 'Enter the valid mobile no'
            }
        }
    }
    if (!props.DetailsTemplateName) {
        detailsTemplateNameError = 'Enter the Template Name'
    }
    if (!props.startStatus) {
        startStatusError = 'Please Select Start Status'
    }
    if (!props.endStatus) {
        endStatusError = 'Please Select End Status'
    }
    if (
        visibilityIdError ||
        startStatusError ||
        endStatusError ||
        detailAlertModeError ||
        detailAlertInfoError ||
        detailsTemplateNameError
    ) {
        return {
            type: visibilityChange.VALIDATION_ERROR,
            payload: {
                visibilityIdError,
                startStatusError,
                endStatusError,
                detailAlertModeError,
                detailsTemplateNameError,
                detailAlertInfoError,
            },
        }
    } else {
        let processDur =
            parseInt(props.processDurationHours) * 60 * 60 +
            parseInt(props.processDurationMinute) * 60 +
            parseInt(props.processDurationSecond)
        if (processDur == 0) {
            return {
                type: visibilityChange.VALIDATION_ERROR,
                payload: {
                    visibilityIdError,
                    startStatusError,
                    processDurationError: 'Select Valid duration',
                },
            }
        }
        let lowDur =
            parseInt(props.sev1LowDurationHours) * 60 * 60 +
            parseInt(props.sev1LowDurationMinute) * 60 +
            parseInt(props.sev1LowDurationSecond)
        if (lowDur == 0) {
            return {
                type: visibilityChange.VALIDATION_ERROR,
                payload: {
                    visibilityIdError,
                    startStatusError,
                    sev1LowDurationError: 'Select Valid duration',
                },
            }
        }
        let medDur =
            parseInt(props.sev1MidDurationHours) * 60 * 60 +
            parseInt(props.sev1MidDurationMinute) * 60 +
            parseInt(props.sev1MidDurationSecond)
        if (medDur == 0) {
            return {
                type: visibilityChange.VALIDATION_ERROR,
                payload: {
                    visibilityIdError,
                    startStatusError,
                    sev1midDurationError: 'Select Valid duration',
                },
            }
        }
        let highDur =
            parseInt(props.sev1HighDurationHours) * 60 * 60 +
            parseInt(props.sev1HighDurationMinute) * 60 +
            parseInt(props.sev1HighDurationSecond)
        if (highDur == 0) {
            return {
                type: visibilityChange.VALIDATION_ERROR,
                payload: {
                    visibilityIdError,
                    startStatusError,
                    sev1highDurationError: 'Select Valid duration',
                },
            }
        }
        //console.log(processDur, lowDur, medDur, highDur)
        if (lowDur && medDur && highDur) {
            return {
                type: visibilityChange.SAVE_THE_VISIBILITY_DATA,
                payload: props,
            }
        } else {
            return {
                type: visibilityChange.VALIDATION_ERROR,
                payload: {
                    visibilityIdError,
                    startStatusError,
                    processDurationError: 'Select Valid duration',
                },
            }
        }
    }
}
function getEditRecord(props) {
    return {
        type: visibilityChange.GET_EDIT_RECORDS,
        payload: props,
    }
}
function emptyDialogContent() {
    return {
        type: visibilityChange.EMPTY_INITIAL_DIALOG_CONTENT,
        payload: {},
    }
}
function deleteTheElement(props) {
    return {
        type: visibilityChange.DELETE_THE_VISIBILITY,
        payload: props,
    }
}
function editSaveData(props) {
    let endStatusError = '',
        sev1highDurationError = '',
        sev1midDurationError = '',
        sev1LowDurationError = '',
        startStatusError = '',
        visibilityIdError = '',
        processDurationError = ''

    if (!props.visibilityId) {
        visibilityIdError = 'Please Select the Id'
    }
    if (!props.startStatus) {
        startStatusError = 'Please Select startStatus'
    }
    if (visibilityIdError || startStatusError) {
        return {
            type: visibilityChange.VALIDATION_ERROR,
            payload: {
                visibilityIdError,
                startStatusError,
            },
        }
    }
    let processDur =
        parseInt(props.processDurationHours) * 60 * 60 +
        parseInt(props.processDurationMinute) * 60 +
        parseInt(props.processDurationSecond)
    let lowDur =
        parseInt(props.sev1LowDurationHours) * 60 * 60 +
        parseInt(props.sev1LowDurationMinute) * 60 +
        parseInt(props.sev1LowDurationSecond)
    let medDur =
        parseInt(props.sev1MidDurationHours) * 60 * 60 +
        parseInt(props.sev1MidDurationMinute) * 60 +
        parseInt(props.sev1MidDurationSecond)
    let highDur =
        parseInt(props.sev1HighDurationHours) * 60 * 60 +
        parseInt(props.sev1HighDurationMinute) * 60 +
        parseInt(props.sev1HighDurationSecond)

    //console.log("processDur",processDur, lowDur, medDur, highDur)
    if (lowDur && medDur && highDur) {
        //console.log("lowwww", lowDur, medDur, highDur)
        return {
            type: visibilityChange.EDIT_SAVE_DATA,
            payload: props,
        }
    } else {
        return {
            type: visibilityChange.VALIDATION_ERROR,
            payload: {
                visibilityIdError,
                startStatusError,
                processDurationError: 'Select Valid duration',
            },
        }
    }
}
function getVisivibiltyDetails(props) {
    return {
        type: visibilityChange.GET_VISIBILITY_DETAILS,
        payload: props,
    }
}

function addOpenVisiblePanel(props) {
    return {
        type: visibilityChange.ADD_OPEN_VISIBILITYDETAILS,
        payload: props,
    }
}
function processDurationChange(props) {
    return {
        type: visibilityChange.CHANGES_PROCESS_DURATION,
        payload: props,
    }
}

function startDialogClose() {
    return {
        type: visibilityChange.CLOSE_DIALOG_START,
        payload: {},
    }
}

function addStartStatus(props) {
    return {
        type: visibilityChange.ADD_NEW_START_STATUS,
        payload: props,
    }
}

function initialStartEndStatus() {
    return {
        type: visibilityChange.INITIAL_START_END_EMPTY,
        payload: {},
    }
}
