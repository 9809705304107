import { all, call, put, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { NotificationManager } from 'react-notifications'
import Moment from 'moment'
import { visibility } from '../actions/VisibilityDetailsAction'
import { visibilityChange } from 'app/constant/ActionTypes'
import {
    API_URL,
    headers,
    DETAILS_ALL,
    GET_EDIT_VISIBILITY_DETAILS,
    ADD_VISIBILITY_DETAILS,
    DELETE_DETAILS_DATA,
    UPDATE_DETAILS_DATA,
} from '../../constant/Common'

function changeDateFormat(dates) {
    const newDate = new Date(dates)
    return Moment(newDate).format('YYYY-MM-DD')
}

// ================================================= get All visibility Details for Table =========================================
function* getVisibilityData(props) {
    try {
        var res = yield axios
            .request({
                method: 'get',
                url: API_URL.api + DETAILS_ALL,
                headers: headers,
                // data: req,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: visibilityChange.VISIBILITY_DETAILS_ERROR_STATUS,
                payload: { error: 'Internal application error. Please contact system administrator.' },
            })
            return
        }

        if (res.status === 200) {
            if (res.data.status == 500) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: visibilityChange.VISIBILITY_DETAIL_OFF_LOADER,
                    payload: {},
                })
            } else {
                yield put({
                    type: visibilityChange.DISPLAY_VISIBILITY_DETAILS,
                    payload: res.data,
                })
            }
        } else {
            NotificationManager.error(res.message)
            yield put({
                type: visibilityChange.VISIBILITY_DETAIL_OFF_LOADER,
                payload: {},
            })
        }
    } catch (error) {}
}

function* getVisibilityDetails() {
    yield takeEvery(visibilityChange.GET_VISIBILITY_DETAILS, getVisibilityData)
}

// =================================================== get Edit Visibility Details Data By ID ================================
function* getEditDetails(payload) {
    try {
        var res = yield axios
            .request({
                method: 'get',
                url: API_URL.api + GET_EDIT_VISIBILITY_DETAILS,
                params: {
                    visibiltyID: payload.payload.visibilityId,
                    startStatus: payload.payload.startStatus,
                },
                headers: headers,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        //console.log('Response', res)

        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: visibilityChange.VISIBILITY_DETAILS_ERROR_STATUS,
                payload: { error: 'Internal application error. Please contact system administrator.' },
            })
            return
        }

        if (res.status === 200) {
            if (res.data.status == 500) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: visibilityChange.VISIBILITY_DETAIL_OFF_LOADER,
                    payload: {},
                })
            } else {
                yield put({
                    type: visibilityChange.DISPLAY_EDIT_DETAILS_RECORD,
                    payload: res.data,
                })
            }
        } else {
            NotificationManager.error(res.data)
            yield put({
                type: visibilityChange.VISIBILITY_DETAIL_OFF_LOADER,
                payload: {},
            })
        }
    } catch (error) {
        console.log(error)
    }
}

function* getVisibilityDetailsById() {
    yield takeEvery(visibilityChange.GET_EDIT_RECORDS, getEditDetails)
}

// =================================================== Add Visibility Details Data ================================
function* addNewVisibilityDetails(payload) {
    var prop = payload.payload
    //console.log("addNewVisibilityDetails",payload.payload)

    var processDuration =
        prop.processDurationHours +
        ':' +
        prop.processDurationMinute +
        ':' +
        prop.processDurationSecond
    var severity1Low =
        prop.sev1LowDurationHours +
        ':' +
        prop.sev1LowDurationMinute +
        ':' +
        prop.sev1LowDurationSecond
    var severity2Medium =
        prop.sev1MidDurationHours +
        ':' +
        prop.sev1MidDurationMinute +
        ':' +
        prop.sev1MidDurationSecond
    var severity3High =
        prop.sev1HighDurationHours +
        ':' +
        prop.sev1HighDurationMinute +
        ':' +
        prop.sev1HighDurationSecond
    try {
        var req = JSON.stringify({
            visibilityId: prop.visibilityId,
            startStatus: prop.startStatus,
            endStatus: prop.endStatus,
            processDuration: processDuration,
            severity1Low: severity1Low,
            severity2Medium: severity2Medium,
            severity3High: severity3High,
            createdBy: prop.userId.name,
            alertMode: prop.detailAlertMode,
            alertInfo:
                prop.detailAlertMode == 'both' ? prop.detailAlertInfo : '',
            alertEmail:
                prop.detailAlertMode == 'email' ? prop.detailAlertInfo : '',
            alertText:
                prop.detailAlertMode == 'text' ? prop.detailAlertInfo : '',
            templateName: prop.DetailsTemplateName,
            createdDate: changeDateFormat(new Date()),
            modifiedBy: prop.userId.name,
            modifiedDate: changeDateFormat(new Date()),
        })
        var res = yield axios
            .request({
                method: 'post',
                url: API_URL.api + ADD_VISIBILITY_DETAILS,
                headers: headers,
                data: req,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        //console.log('Response', res)

        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: visibilityChange.VISIBILITY_DETAILS_ERROR_STATUS,
                payload: { error: 'Internal application error. Please contact system administrator.' },
            })
            return
        }
        if (res.status === 200) {
            if (res.data.status == 500) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: visibilityChange.VISIBILITY_DETAIL_OFF_LOADER,
                    payload: {},
                })
            } else if (res.data.status == 409) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: visibilityChange.VISIBILITY_DETAIL_OFF_LOADER,
                    payload: {},
                })
            } else if (res.data.status == 404) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: visibilityChange.VISIBILITY_DETAIL_OFF_LOADER,
                    payload: {},
                })
            } else if (res.data.status == 400) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: visibilityChange.VISIBILITY_DETAIL_OFF_LOADER,
                    payload: {},
                })
            } else {
                NotificationManager.success('Added Successfully')

                yield put({
                    type: visibilityChange.APPEND_ADDED_DETAILS,
                    payload: res.data,
                })
            }
        } else {
            NotificationManager.error('Internal application error. Please contact system administrator. ,Try again')
            yield put({
                type: visibilityChange.VISIBILITY_DETAIL_OFF_LOADER,
                payload: {},
            })
        }
    } catch (error) {
        console.log(error)
    }
}

function* addVisibilityDeatils() {
    yield takeEvery(
        visibilityChange.SAVE_THE_VISIBILITY_DATA,
        addNewVisibilityDetails
    )
}
// =================================================== Delete Visibility Details Data By ID ================================
function* deleteVisibilityDetailsData(payload) {
    var parameters = {
        visibiltyID: payload.payload.visibilityId,
        startStatus: payload.payload.startStatus,
    }
    try {
        var res = yield axios
            .request({
                method: 'delete',
                url: API_URL.api + DELETE_DETAILS_DATA,
                params: parameters,
                headers: headers,
                // data: req,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        //console.log('Response', res)

        if (res.status === 401) {
            yield put({
                type: visibilityChange.VISIBILITY_DETAILS_ERROR_STATUS,
                payload: { error: 'Internal application error. Please contact system administrator.' },
            })
            return
        }

        if (res.status === 200) {
            if (res.data.status == 500) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: visibilityChange.VISIBILITY_DETAIL_OFF_LOADER,
                    payload: {},
                })
            } else {
                NotificationManager.success(res.data.message)
                yield call(getVisibilityData)
                yield put({
                    type: visibilityChange.DELETE_DETAILS_RECORD,
                    payload: parameters,
                })
            }
        } else {
            NotificationManager.error(res.data)
            yield put({
                type: visibilityChange.VISIBILITY_DETAIL_OFF_LOADER,
                payload: {},
            })
        }
    } catch (error) {
        console.log(error)
    }
}

function* deleteVisibilityDetails() {
    yield takeEvery(
        visibilityChange.DELETE_THE_VISIBILITY,
        deleteVisibilityDetailsData
    )
}

// =================================================== Update the edited Visibility Details Data ================================
function* updateVisibilityDetails(payload) {
    var prop = payload.payload
    var processDuration =
        prop.processDurationHours +
        ':' +
        prop.processDurationMinute +
        ':' +
        prop.processDurationSecond
    var severity1Low =
        prop.sev1LowDurationHours +
        ':' +
        prop.sev1LowDurationMinute +
        ':' +
        prop.sev1LowDurationSecond
    var severity2Medium =
        prop.sev1MidDurationHours +
        ':' +
        prop.sev1MidDurationMinute +
        ':' +
        prop.sev1MidDurationSecond
    var severity3High =
        prop.sev1HighDurationHours +
        ':' +
        prop.sev1HighDurationMinute +
        ':' +
        prop.sev1HighDurationSecond
    try {
        var req = JSON.stringify({
            visibilityId: prop.visibilityId,
            startStatus: prop.startStatus,
            endStatus: prop.endStatus,
            processDuration: processDuration,
            severity1Low: severity1Low,
            severity2Medium: severity2Medium,
            severity3High: severity3High,
            createdBy: prop.userId.name,
            alertMode: prop.detailAlertMode,
            //alertInfo: prop.detailAlertInfo,
            alertInfo:
                prop.detailAlertMode == 'both' ? prop.detailAlertInfo : '',
            alertEmail:
                prop.detailAlertMode == 'email' ? prop.detailAlertInfo : '',
            alertText:
                prop.detailAlertMode == 'text' ? prop.detailAlertInfo : '',
            templateName: prop.DetailsTemplateName,
            createdDate: changeDateFormat(new Date()),
            modifiedBy: prop.userId.name,
            modifiedDate: changeDateFormat(new Date()),
        })
        var res = yield axios
            .request({
                method: 'put',
                url: API_URL.api + UPDATE_DETAILS_DATA,
                params: {
                    visibiltyID: payload.payload.visibilityId,
                    startStatus: payload.payload.startStatus,
                },
                headers: headers,
                data: req,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        //console.log('Response', res)

        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: visibilityChange.VISIBILITY_DETAILS_ERROR_STATUS,
                payload: { error: 'Internal application error. Please contact system administrator.' },
            })
            return
        }

        if (res.status === 200) {
            if (res.data.status == 500) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: visibilityChange.VISIBILITY_ERROR_DATA_DISABLED,
                    payload: {},
                })
            } else if (res.data.status == 400) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: visibilityChange.VISIBILITY_ERROR_DATA_DISABLED,
                    payload: {},
                })
            } else {
                NotificationManager.success('successFully Updated')
                yield call(getVisibilityData)
                //   yield put({
                //       type: visibilityChange.APPEND_EDITED_DATA,
                //       payload: res.data,
                //   })
            }
        } else {
            NotificationManager.error(res.message)
            yield put({
                type: visibilityChange.VISIBILITY_ERROR_DATA_DISABLED,
                payload: {},
            })
        }
    } catch (error) {
        console.log(error)
    }
}

function* updateVisibilityDetailsData() {
    yield takeEvery(visibilityChange.EDIT_SAVE_DATA, updateVisibilityDetails)
}

export default function* rootSaga() {
    yield all([
        getVisibilityDetails(),
        getVisibilityDetailsById(),
        addVisibilityDeatils(),
        deleteVisibilityDetails(),
        updateVisibilityDetailsData(),
    ])
}
