import { all, call, put, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { NotificationManager } from 'react-notifications'
import Moment from 'moment'
import { processCriteriaType } from '../../constant/ActionTypes'
import { useNavigate } from 'react-router-dom'
import {
    headers,
    GET_PROCESS_API,
    SAVE_NEW_PROCESS,
    SAVE_EDIT_PROCESS,
    GET_DETAILS_AND_RULES_BY_ID,
    UPDATE_DETAIL_RULE_BY_ID,
    DETAILS_PROCESS,
    DETAIL_SEARCH_BY_NAME,
    PROCESS_DETAIL_ROLL,
    EDIT_SAVE_TRANSACTION_RULE,
    GET_ID,
    UPDATE_BASE_URL,
    DETAIL_SEARCH_PROCESS,
    ADD_DETAILS,
    UPDATE_DETAILS_AND_RULE,
    ADD_DETAIL,
    ADD_RULES,
} from '../../constant/Common'
import { InputGroup } from 'react-bootstrap'

function changeDateFormat(dates) {
    const newDate = new Date(dates)
    return Moment(newDate).format('YYYY-MM-DD')
}
function getJwtToken() {
    //  const sessionLoginToken = useSelector((state)=>state.login);
    var abc = localStorage.getItem('accessToken')
    //  console.log("Getting the Token",sessionLoginToken);
    //sessionStorage.getItem("tokenId")
    return abc
}
//   ======================================================Save Confirm Code ====================================
function* getProcessTableData(payload) {
    // var props = payload.payload
    try {
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        }
        var res = yield axios
            .request({
                method: 'get',
                url: UPDATE_BASE_URL + GET_PROCESS_API,
                headers: headers,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })

        if (res.status === 401) {
            //console.log('401')
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: processCriteriaType.PROCESS_CRITERIA_AUTH_ERROR,
                payload: { error: 'Internal application error. Please contact system administrator.' },
            })
            return
        }
        if (res.status === 403) {
            //console.log('401')
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: processCriteriaType.PROCESS_CRITERIA_AUTH_ERROR,
                payload: { error: 'Internal application error. Please contact system administrator.' },
            })
            return
        }

        if (res.status === 200) {
            if (res.data.status === 404) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: processCriteriaType.PROCESS_CRITERIA_LOADING_STOP,
                    payload:{},
                })
            } else if (res.data.status >= 500) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: processCriteriaType.PROCESS_CRITERIA_LOADING_STOP,
                    payload:{},
                })
            } else {
                yield put({
                    type: processCriteriaType.DISPLAY_PROCESS_TABLE_DATA,
                    payload: res.data,
                })
                yield put({
                    type: processCriteriaType.DISPLAY_PROCESS_DATA,
                    payload: res.data,
                })
            }
        } else {
            if (res.data.error) {
                NotificationManager.error(res.data.error)
            } else {
                NotificationManager.error('Internal application error. Please contact system administrator.')
            }
        }
    } catch (error) {
        console.log(error)
    }
}

function* processCriteriaData() {
    yield takeEvery(
        processCriteriaType.GET_PROCESS_CRITERIA_TABLE,
        getProcessTableData
    )
}

//==========================================post new process criteria data==================================

function* saveCriteriaProcess(payload) {
    var props = payload.payload
    // console.log('processCriteria', props.modifiedBy)
    try {
        var req = JSON.stringify({
            processCriteriaName: props.processCriteriaName,
            processType: props.processType,
            processId: props.processId,
            status: props.status,
            miscFlag1: props.miscFlag1,
            miscFlag2: props.miscFlag2,
            miscFlag3: props.miscFlag3,
            miscFlag4: props.miscFlag4,
            miscFlag5: props.miscFlag5,
            miscField1: props.miscField1,
            miscField2: props.miscField2,
            miscField3: props.miscField3,
            miscField4: props.miscField4,
            modifiedBy: 0,
        })
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        }
        var res = yield axios
            .request({
                method: 'post',
                url: UPDATE_BASE_URL + SAVE_NEW_PROCESS,
                headers: headers,
                data: req,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })

        if (res.status === 401) {
            //console.log('401')
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: processCriteriaType.PROCESS_CRITERIA_AUTH_ERROR,
                payload: { error: 'Internal application error. Please contact system administrator.' },
            })
            return
        }
        if (res.status === 403) {
            //console.log('401')
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: processCriteriaType.PROCESS_CRITERIA_AUTH_ERROR,
                payload: { error: 'Internal application error. Please contact system administrator.' },
            })
            return
        }
        if (res.status === 200) {
            //.log('ressss', res)
            if (res.data.status === 400) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: processCriteriaType.PROCESS_CRITERIA_LOADING_STOP,
                    payload:{},
                })
            } else if (res.data.status >= 500) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: processCriteriaType.PROCESS_CRITERIA_LOADING_STOP,
                    payload:{},
                })
            } else {
                NotificationManager.success(
                    'Record has been successfully created'
                )
               // yield call(getProcessTableData)
                yield put({
                    type:processCriteriaType.GET_PROCESS_CRITERIA_TABLE,
                    payload: {},
                })
                // props.navigate('./ProcessCriteriaTabe')/
                yield put({
                    type: processCriteriaType.RESPONSE_SAVE_CRITERIA,
                    payload: {},
                })
            }
        } else {
            if (res.data.error) {
                NotificationManager.error(res.data.error)
            } else {
                NotificationManager.error('Internal application error. Please contact system administrator.')
            }
        }
    } catch (error) {
        console.log(error)
    }
}

function* saveProcessCriteria() {
    yield takeEvery(
        processCriteriaType.REQUEST_SAVE_CRITERIA,
        saveCriteriaProcess
    )
}

//====================================================== update process Criteria ====================================
function* editedSaveCriteriaSubmit(payload) {
    var props = payload.payload
    console.log('Payload Props', props.modifiedBy.name)
    try {
        let req = JSON.stringify({
            processCriteriaName: props.processCriteriaName,
            processType: props.processType,
            processId: props.processId,
            status: props.status,
            miscFlag1: props.miscFlag1,
            miscFlag2: props.miscFlag2,
            miscFlag3: props.miscFlag3,
            miscFlag4: props.miscFlag4,
            miscFlag5: props.miscFlag5,
            miscField1: props.miscField1,
            miscField2: props.miscField2,
            miscField3: props.miscField3,
            miscField4: props.miscField4,
            modifiedBy: props.modifiedBy.name,
        })
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        }
        var res = yield axios
            .request({
                method: 'PUT',
                url:
                    UPDATE_BASE_URL +
                    SAVE_EDIT_PROCESS +
                    // GET_ID +
                    props.generatedId,
                headers: headers,
                data: req,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        if (res.status === 401) {
            //console.log('401')
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: processCriteriaType.PROCESS_CRITERIA_AUTH_ERROR,
                payload: { error: 'Internal application error. Please contact system administrator.' },
            })
            return
        }
        if (res.status === 403) {
            //console.log('401')
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: processCriteriaType.PROCESS_CRITERIA_AUTH_ERROR,
                payload: { error: 'Internal application error. Please contact system administrator.' },
            })
            return
        }
        //console.log('fvvdfvfdvd', res)
        if (res.status === 200) {
            if (res.data.status === 400) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: processCriteriaType.PROCESS_CRITERIA_LOADING_STOP,
                    payload:{},
                })
            } else {
                NotificationManager.success('Successfully updated')
                // yield call(getRoles)
                yield put({
                    type: processCriteriaType.GET_PROCESS_CRITERIA_TABLE,
                    payload: {},
                })
                yield put({
                    type: processCriteriaType.RESPONSE_EDIT_SAVE_CRITERIA,
                    payload: {},
                })
            }
            //props.navigate('/GroupListView')
        } else {
            NotificationManager.error('Internal application error. Please contact system administrator.')
        }
    } catch (error) {
        console.log(error)
    }
}

function* editSaveCriteria() {
    yield takeEvery(
        processCriteriaType.REQUEST_EDIT_SAVE_CRITERIA,
        editedSaveCriteriaSubmit
    )
}

//======================================================Delete the process criteria table ====================================
function* deleteSingleProcessCriteria(payload) {
    var props = payload.payload
    // console.log('Payload delete Props', props)
    try {
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        }
        var res = yield axios
            .request({
                method: 'delete',
                url:
                    UPDATE_BASE_URL +
                    SAVE_EDIT_PROCESS +
                    //  GET_ID +
                    props.id,
                headers: headers,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        if (res.status === 401) {
            //console.log('401')
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: processCriteriaType.PROCESS_CRITERIA_AUTH_ERROR,
                payload: { error: 'Internal application error. Please contact system administrator.' },
            })
            return
        }
        if (res.status === 403) {
            //console.log('401')
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: processCriteriaType.PROCESS_CRITERIA_AUTH_ERROR,
                payload: { error: 'Internal application error. Please contact system administrator.' },
            })
            return
        }
        //console.log('fvvdfvfdvd', res)
        if (res.status === 200) {
            if (res.data.status === 400) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: processCriteriaType.PROCESS_CRITERIA_LOADING_STOP,
                    payload:{},
                })
            } else if (res.data.code == 200) {
                NotificationManager.success(res.data.message)
                yield call(getProcessTableData)
                yield put({
                    type: processCriteriaType.DELETE_RESPONSE_PROCESS_CRITERIA,
                    payload: {},
                })
            }
        } else {
            NotificationManager.error('Internal application error. Please contact system administrator.')
        }
    } catch (error) {
        console.log(error)
    }
}

function* deleteProcessCriteriaSingle() {
    yield takeEvery(
        processCriteriaType.DELETE_REQUEST_PROCESS_CRITERIA,
        deleteSingleProcessCriteria
    )
}

//==========================================get process criteria table data==================================

function* getProcessTableDetails(payload) {
    var props = payload.payload
    console.log('get process details tablesaga', props)
    // const value = () => {
    //     if (payload.payload.processCriteria == []) {
    //         return 0
    //     } else {
    //         return payload.payload.processCriteria.id
    //     }
    // }
    try {
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        }
        var res = yield axios
            .request({
                method: 'GET',
                url:
                    UPDATE_BASE_URL +
                    DETAIL_SEARCH_PROCESS +
                    props +
                    ADD_DETAILS,
                // params: {
                //     processCritera: payload.payload.processCriteria,
                // },
                headers: headers,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })

        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: processCriteriaType.PROCESS_CRITERIA_AUTH_ERROR,
                payload: { error: 'Internal application error. Please contact system administrator.' },
            })
            return
        }
        if (res.status === 403) {
            //console.log('401')
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: processCriteriaType.PROCESS_CRITERIA_AUTH_ERROR,
                payload: { error: 'Internal application error. Please contact system administrator.' },
            })
            return
        }

        if (res.status === 200) {
            console.log('responseblackqueryparam', res)
            yield put({
                type: processCriteriaType.DISPLAY_PROCESS_TABLE_DETAILS,
                payload: res.data,
            })
            // yield put({
            //     type: processCriteriaType.DISPLAY_PROCESS_DETAILS,
            //     payload: res.data,
            // })
        } else {
            NotificationManager.error('Internal application error. Please contact system administrator.')
        }
    } catch (error) {
        console.log(error)
    }
}

function* processCriteriaDetails() {
    yield takeEvery(
        processCriteriaType.GET_PROCESS_CRITERIA_DETAILS,
        getProcessTableDetails
    )
}

//==========================================post new process criteria details==================================

function* saveDetailsCriteriaProcess(payload) {
    var props = payload.payload
    console.log('processCriteriaDetails', props.processCriteriaId)
    try {
        var req = JSON.stringify({
            processCriteriaName: props.processCriteriaName,
            tag: props.detailsTag,
            position: props.detailsPosition,
            miscFlag1: props.detailsMiscFlag1,
            miscFlag2: props.detailsMiscFlag2,
            miscFlag3: props.detailsMiscFlag3,
            miscFlag4: props.detailsMiscFlag4,
            miscFlag5: props.detailsMiscFlag5,
            miscField1: props.detailsMiscField1,
            miscField2: props.detailsMiscField2,
            miscField3: props.detailsMiscField3,
            miscField4: props.detailsMiscField4,
            modifiedBy: 0,
        })
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        }
        var res = yield axios
            .request({
                method: 'post',
                url:
                    UPDATE_BASE_URL +
                    SAVE_EDIT_PROCESS +
                    props.generatedId +
                    '/details',
                headers: headers,
                data: req,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        if (res.status === 401) {
            //console.log('401')
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: processCriteriaType.PROCESS_CRITERIA_AUTH_ERROR,
                payload: { error: 'Internal application error. Please contact system administrator.' },
            })
            return
        }
        if (res.status === 403) {
            //console.log('401')
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: processCriteriaType.PROCESS_CRITERIA_AUTH_ERROR,
                payload: { error: 'Internal application error. Please contact system administrator.' },
            })
            return
        }
        if (res.status === 200) {
            //.log('ressss', res)
            if (res.data.status === 400) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: processCriteriaType.PROCESS_CRITERIA_LOADING_STOP,
                    payload:{},
                })
            } else {
                NotificationManager.success(
                    'Record has been successfully created'
                )
                yield put({
                    type: processCriteriaType.GET_PROCESS_CRITERIA_DETAILS,
                    payload: props.processCriteriaId,
                })
                yield put({
                    type: processCriteriaType.RESPONSE_SAVE_DETAILS_CRITERIA,
                    payload: res.data,
                })
            }
        } else {
            NotificationManager.error('Internal application error. Please contact system administrator.')
        }
    } catch (error) {
        console.log(error)
    }
}

function* saveProcessCriteriaDetails() {
    yield takeEvery(
        processCriteriaType.REQUEST_NEW_DETAILS_CRITERIA,
        saveDetailsCriteriaProcess
    )
}

//==========================================get process criteria Single Details data==================================

function* getProcessDetailsById(payload) {
    var props = payload.payload
    console.log('sagaprocessCriteria', props)
    try {
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        }
        var res = yield axios
            .request({
                method: 'get',
                url:
                    UPDATE_BASE_URL +
                    SAVE_EDIT_PROCESS +
                    // + GET_ID
                    props.id,
                headers: headers,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })

        if (res.status === 401) {
            //console.log('401')
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: processCriteriaType.PROCESS_CRITERIA_AUTH_ERROR,
                payload: { error: 'Internal application error. Please contact system administrator.' },
            })
            return
        }
        if (res.status === 403) {
            //console.log('401')
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: processCriteriaType.PROCESS_CRITERIA_AUTH_ERROR,
                payload: { error: 'Internal application error. Please contact system administrator.' },
            })
            return
        }

        if (res.status === 200) {
            console.log('responseblackqueryparam', res)
            yield put({
                type: processCriteriaType.DISPLAY_SINGLE_DETAILS_TABLE,
                payload: res.data,
            })
            if (res.data.status === 404) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: processCriteriaType.PROCESS_CRITERIA_LOADING_STOP,
                    payload:{},
                })
            }
            // yield put({
            //     type: processCriteriaType.DISPLAY_PROCESS_DETAILS,
            //     payload: res.data,
            // })
        } else {
            NotificationManager.error('Internal application error. Please contact system administrator.')
        }
    } catch (error) {
        console.log(error)
    }
}

function* processCriteriaDetailsById() {
    yield takeEvery(
        processCriteriaType.UPDATE_SINGLE_PROCESS,
        getProcessDetailsById
    )
}

//======================================================Delete the Rule process details ====================================
function* deleteRuleProcessDetails(payload) {
    var props = payload.payload
    console.log('Payload delete Props', props)
    try {
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        }
        var res = yield axios
            .request({
                method: 'delete',
                url:
                    UPDATE_BASE_URL +
                    DETAIL_SEARCH_PROCESS +
                    props.processGeneratedId +
                    ADD_DETAIL +
                    props.processDetailsId,
                // params: {
                //     processCriteriaDetailsId: props.processId,
                //     ruleId: props.ruleUid,
                // },
                headers: headers,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        if (res.status === 401) {
            //console.log('401')
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: processCriteriaType.PROCESS_CRITERIA_AUTH_ERROR,
                payload: { error: 'Internal application error. Please contact system administrator.' },
            })
            return
        }
        if (res.status === 403) {
            //console.log('401')
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: processCriteriaType.PROCESS_CRITERIA_AUTH_ERROR,
                payload: { error: 'Internal application error. Please contact system administrator.' },
            })
            return
        }
        //console.log('fvvdfvfdvd', res)
        if (res.status === 200) {
            if (res.data.status === 400) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: processCriteriaType.PROCESS_CRITERIA_LOADING_STOP,
                    payload:{},
                })
            } else if (res.data.code == 200) {
                NotificationManager.success(res.data.message)
                yield put({
                    type: processCriteriaType.DELETE_RULEPROCESS_RESPONSE,
                    payload: {},
                })
                yield put({
                    type: processCriteriaType.GET_PROCESS_CRITERIA_DETAILS,
                    payload: props.processGeneratedId,
                })
            }
        } else {
            if (res.data.error) {
                NotificationManager.error(res.data.error)
            } else {
                NotificationManager.error('Internal application error. Please contact system administrator.')
            }
        }
    } catch (error) {
        console.log(error)
    }
}

function* deleteRuleProcess() {
    yield takeEvery(
        processCriteriaType.DELETE_RULEPROCESS_REQUEST,
        deleteRuleProcessDetails
    )
}
// function* createRuleDetail(payload) {
//     var props = payload.payload
//     try {
//         let headers = {
//             'Content-Type': 'application/json;charset=UTF-8',
//             Accept: 'application/json',
//             Authorization: `Bearer ${getJwtToken()}`,
//         }
// }
// }
// function* createRuleData()
//     yield takeEvery(
//         processCriteriaType.REQUEST_RULE_DATA,
//         createRuleDetail()
//     )
// }
function* getRuleDetails(payload) {
    var props = payload
    try {
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        }
        var res = yield axios
            .request({
                method: 'GET',
                url: UPDATE_BASE_URL + PROCESS_DETAIL_ROLL,
                headers: headers,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        console.log('Response', res)
        if (res.status === 401) {
            //console.log('401')
            NotificationManager.error('Unauthorized Access')
            // yield put({
            //     type: processCriteriaType.PROCESS_CRITERIA_AUTH_ERROR,
            //     payload: { error: 'Internal application error. Please contact system administrator.' },
            // })
            return
        }
        if (res.status === 403) {
            //console.log('401')
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: processCriteriaType.PROCESS_CRITERIA_AUTH_ERROR,
                payload: { error: 'Internal application error. Please contact system administrator.' },
            })
            return
        }

        if (res.status === 200) {
            if (res.data.status === 400) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: processCriteriaType.PROCESS_CRITERIA_LOADING_STOP,
                    payload:{},
                })
            } else if (res.data.code == 200) {
                NotificationManager.success(res.data.message)

                // console.log("payloadPassing",res.data.filter((item) => item.processCriteria == props));
                // console.log("payloadPassing",res.data.filter((item) => item.processCriteria == props));
                yield put({
                    type: processCriteriaType.RESPONSE_RULE_DATA,
                    // payload: res.data.filter((item) => item.processCriteria == props.processCriteria && item.tagName == props.detailsTag),
                    payload: res.data,
                })
            }
        } else {
            if (res.data.error) {
                NotificationManager.error(res.data.error)
            } else {
                NotificationManager.error('Internal application error. Please contact system administrator.')
            }
        }
    } catch (error) {
        console.log(error)
    }
}
// function* getRuleData(){
//     console.log("INSIDE THE SAGE")
//     yield takeEvery(
//         processCriteriaType.EDIT_REQUEST_RULE_DATA,
//         getRuleDetails
//     )
// }
function* saveEditiedTransaction(payload) {
    console.log('saveEditiedTransaction.PAYLOAD', payload)
    let props = payload.payload

    const val = () => {
        if (props.ruleGeneratedId == '') {
            return 0
        } else {
            return props.ruleGeneratedId
        }
    }
    try {
        var req = JSON.stringify({
            processCriteriaName: props.processCriteriaName,
            tag: props.detailsTag,
            position: props.detailsPosition,
            miscFlag1: props.detailsMiscFlag1,
            miscFlag2: props.detailsMiscFlag2,
            miscFlag3: props.detailsMiscFlag3,
            miscFlag4: props.detailsMiscFlag4,
            miscFlag5: props.detailsMiscFlag5,
            miscField1: props.detailsMiscField1,
            miscField2: props.detailsMiscField2,
            miscField3: props.detailsMiscField3,
            miscField4: props.detailsMiscField4,
            modifiedBy: props.modifiedBy.name,
            transactionRule: null,
        })
        if (props.ruleGeneratedId != '') {
            req = JSON.stringify({
                processCriteriaName: props.processCriteriaName,
                tag: props.detailsTag,
                position: props.detailsPosition,
                miscFlag1: props.detailsMiscFlag1,
                miscFlag2: props.detailsMiscFlag2,
                miscFlag3: props.detailsMiscFlag3,
                miscFlag4: props.detailsMiscFlag4,
                miscFlag5: props.detailsMiscFlag5,
                miscField1: props.detailsMiscField1,
                miscField2: props.detailsMiscField2,
                miscField3: props.detailsMiscField3,
                miscField4: props.detailsMiscField4,
                modifiedBy: props.modifiedBy.name,
                transactionRule: {
                    ruleName: props.ruleName,
                    description: props.descriptionRule,
                    status:
                        props.ruleStatus == 'ACTIVE' ? 'ACTIVE' : 'INACTIVE',
                    processCriteriaName: props.processCriteriaName,
                    tagName: props.ruleTagName,
                    tagValue: props.tagValue,
                    operator: props.operator,
                    miscFlag2: props.ruleMiscFlag2,
                    miscFlag1: props.ruleMiscFlag1,
                    miscFlag3: props.ruleMiscFlag3,
                    miscFlag4: props.ruleMiscFlag4,
                    miscFlag5: props.ruleMiscFlag5,
                    miscField1: props.ruleMiscField1,
                    miscField2: props.ruleMiscField2,
                    miscField3: props.ruleMiscField3,
                    miscField4: props.ruleMiscField4,
                    modifiedBy: props.modifiedBy.name,
                },
            })
        }
        // var req = JSON.stringify({
        //     ruleName: props.ruleName,
        //     description: props.descriptionRule,
        //     status: props.ruleStatus,
        //     processCriteria: props.processCriteria,
        //     tagName: props.ruleTagName,
        //     tagValue: props.tagValue,
        //     operator: props.operator,
        // })
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        }

        var res = yield axios
            .request({
                method: 'PUT',
                url:
                    UPDATE_BASE_URL +
                    SAVE_NEW_PROCESS +
                    ADD_DETAIL +
                    props.detailGeneratedId,
                //  + ADD_DETAILS,
                // params: {
                //     processCriteriaDetailsId: props.detailGeneratedId,
                //     ruleId: val(),
                // },
                headers: headers,
                data: req,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
       
        if (res.status === 401) {
            //console.log('401')
            NotificationManager.error('Unauthorized Access')
            // yield put({
            //     type: processCriteriaType.PROCESS_CRITERIA_AUTH_ERROR,
            //     payload: { error: 'Internal application error. Please contact system administrator.' },
            // })
            return
        }
        if (res.status === 403) {
            //console.log('401')
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: processCriteriaType.PROCESS_CRITERIA_AUTH_ERROR,
                payload: { error: 'Internal application error. Please contact system administrator.' },
            })
            return
        }
        if (res.status === 400) {
            {
                if (res.data.status === 400) {
                    NotificationManager.error(res.status.error)
                }
            }
        }
        //console.log('fvvdfvfdvd', res)
        if (res.status === 200) {
            if (res.data.status === 400) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: processCriteriaType.PROCESS_CRITERIA_LOADING_STOP,
                    payload:{},
                })
            } else {
                NotificationManager.success('Successfully updated')
                // yield call(getProcessTableDetails)
                yield put({
                    type: processCriteriaType.RESPONSE_SAVE_EDIT_TRANSACTION,
                    payload: {},
                })
            }
            //props.navigate('/GroupListView')
            //Call And refresh table Content
            yield put({
                type: processCriteriaType.GET_PROCESS_CRITERIA_DETAILS,
                payload: props.processCriteriaId,
            })
        } else {
            if (res.data.error) {
                NotificationManager.error(res.data.error)
            } else {
                NotificationManager.error('Internal application error. Please contact system administrator.')
            }
        }
    } catch (error) {
        console.log(error)
    }
}
function* saveEditTransaction() {
    console.log('saveEditTransaction')
    yield takeEvery(
        processCriteriaType.REQUEST_SAVE_EDIT_TRANSACTION,
        saveEditiedTransaction
    )
}

function* deleteRuleTransaction(payload) {
    console.log('saveEditiedTransaction.PAYLOAD', payload)
    let props = payload.payload
    try {
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        }

        var res = yield axios
            .request({
                method: 'DELETE',
                url:
                    UPDATE_BASE_URL +
                    SAVE_NEW_PROCESS +
                    ADD_DETAIL +
                    props.detailsGeneratedId +
                    ADD_RULES +
                    props.ruleGeneratedId,
                headers: headers,
                // data: req,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })

        if (res.status === 401) {
            //console.log('401')
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: processCriteriaType.PROCESS_CRITERIA_AUTH_ERROR,
                payload: { error: 'Internal application error. Please contact system administrator.' },
            })
            return
        }
        if (res.status === 403) {
            //console.log('401')
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: processCriteriaType.PROCESS_CRITERIA_AUTH_ERROR,
                payload: { error: 'Internal application error. Please contact system administrator.' },
            })
            return
        }
        if (res.status === 200) {
            if (res.data.status === 400) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: processCriteriaType.PROCESS_CRITERIA_LOADING_STOP,
                    payload:{},
                })
            } else if (res.data.code === 200) {
                NotificationManager.success(res.data.message)
                // yield call(getRoles)
                yield put({
                    type: processCriteriaType.RESPONSE_DELETE_RULE_TRANSACTION,
                    payload: {},
                })
                yield put({
                    type: processCriteriaType.GET_PROCESS_CRITERIA_DETAILS,
                    payload: props.processCriteriaId,
                })
            }
            //props.navigate('/GroupListView')
        } else {
            if (res.data.error) {
                NotificationManager.error(res.data.error)
            } else {
                NotificationManager.error('Internal application error. Please contact system administrator.')
            }
        }
    } catch (error) {
        console.log(error)
    }
}
function* deleteEditRuleTransaction() {
    // console.log('saveEditTransaction')
    yield takeEvery(
        processCriteriaType.REQUEST_DELETE_RULE_TRANSACTION,
        deleteRuleTransaction
    )
}

//==========================================get process criteria Single Details data==================================

function* getEditRuleDetails(payload) {
    var props = payload.payload
    console.log('sagaprocessCriteria', props)
    const val = () => {
        if (props.ruleGeneratedId == '' || props.ruleGeneratedId == null) {
            return 0
        } else {
            return props.ruleGeneratedId
            //return props.generatedId.generatedID
        }
    }
    try {
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
            Authorization: `Bearer ${getJwtToken()}`,
        }
        var res = yield axios
            .request({
                method: 'get',
                url:
                    UPDATE_BASE_URL +
                    UPDATE_DETAILS_AND_RULE +
                    props.detailGeneratedId,
                // '/id',
                // params: {
                //     processCriteraDetailsId: props.detailGeneratedId,
                //     ruleId: val(),
                // },
                headers: headers,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })

        if (res.status === 401) {
            //console.log('401')
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: processCriteriaType.PROCESS_CRITERIA_AUTH_ERROR,
                payload: { error: 'Internal application error. Please contact system administrator.' },
            })
            return
        }
        if (res.status === 403) {
            //console.log('401')
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: processCriteriaType.PROCESS_CRITERIA_AUTH_ERROR,
                payload: { error: 'Internal application error. Please contact system administrator.' },
            })
            return
        }

        if (res.status === 200) {
            // if (res.data.code === 200) {
            console.log('responseblackqueryparam', res)
            //  yield call(getProcessTableDetails)
            yield put({
                type: processCriteriaType.DISPLAY_DETAILS_AND_RULE,
                payload: res.data,
            })
        } else if (res.data.status === 400) {
            NotificationManager.error(res.data.error)
            yield put({
                type: processCriteriaType.PROCESS_CRITERIA_LOADING_STOP,
                payload:{},
            })
        } else {
            if (res.data.error) {
                NotificationManager.error(res.data.error)
            } else {
                NotificationManager.error('Internal application error. Please contact system administrator.')
            }
        }
    } catch (error) {
        console.log(error)
    }
}

function* getEditRuleAndDetail() {
    yield takeEvery(
        processCriteriaType.GET_RULE_AND_DETAILS_EDIT,
        getEditRuleDetails
    )
}


export default function* rootSaga() {
    yield all([
        processCriteriaData(),
        processCriteriaDetailsById(),
        saveProcessCriteriaDetails(),
        editSaveCriteria(),
        deleteProcessCriteriaSingle(),
        processCriteriaDetails(),
        saveProcessCriteria(),
        deleteRuleProcess(),
        saveEditTransaction(),
        deleteEditRuleTransaction(),
        getEditRuleAndDetail(),
        // getRuleData(),
        // createRuleData(),
    ])
}
