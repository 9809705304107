import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'
import { authRoles } from '../../auth/authRoles'

const Analytics = Loadable(lazy(() => import('./Analytics')))
const NewDashBoard = Loadable(lazy(() => import('./DashBoardNew')))
const DashboardNewGrid = Loadable(lazy(() => import('./DashBoardNewGrid')))
const Lookup = Loadable(lazy(() => import('../LookupDetails/LookupHeader')))
const MainDashboard = Loadable(lazy(() => import('./MainDashboard')))
const dashboardRoutes = [
    {
        path: '/dashboard/default',
        //element: <NewDashBoard />,
        element: <DashboardNewGrid />,
        //element: <Lookup />,
        auth: authRoles.admin,
    },
    {
        path: '/dashboard/default1',
        element: <DashboardNewGrid />,
        //element: <Lookup />,
        auth: authRoles.admin,
    },
    {
        path: '/MainDashboard',
        element:<MainDashboard/>
    }
]

export default dashboardRoutes
