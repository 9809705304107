import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'

const LookupHeader = Loadable(lazy(() => import('./LookupHeader')))
const LookupDetails = Loadable(lazy(() => import('./LookupDetails')))
const TemplateDetails = Loadable(lazy(() => import('./TemplateDetails')))
const TabFile = Loadable(lazy(()=>import('./TabFile')))
const MaterialTableDesign = Loadable(lazy(()=> import('./MaterialTableDesign')))
const LookupRoutes = [
    {
        path: '/lookupHeader',
        element: <LookupHeader />,
        menuId: 2,
      
    },
    {
        path: '/lookupDetail',
        element: <LookupDetails />,
        menuId: 3,
    },
    {
        path: '/tempDetails',
        element: <TemplateDetails />,
        menuId: 4,
    },
    {
        path: '/TabFile',
        element: <TabFile />,
        // menuId: 5,
    },
    {
        path:'/MaterialTableDesign',
        element:<MaterialTableDesign/>
    }
]

// const menus = localStorage.getItem("menuId");
// if (menus != null){
// var LookupRoutes = LookupRoutes1.filter(function (element) {
//   return menus.includes(element.menuId);
// });
// }else{
//   var LookupRoutes= [];
// }
export default LookupRoutes
