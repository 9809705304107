import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'

const TemplateManagementTable = Loadable(lazy(() => import('./TemplateManagementTable')))
const TemplateTable = Loadable(lazy(()=> import('./TemplateTable')))
const TemplateManagementRoutes = [
    
    {
        path:'/TemplateManagementTable',
        element: <TemplateManagementTable />
    },
    {
        path:'/TemplateTable',
        element:<TemplateTable/>
    }
    
]



export default TemplateManagementRoutes
