import { all, call, put, takeEvery, getContext } from 'redux-saga/effects'
import { templateActionTypes } from '../../constant/ActionTypes'
import axios from 'axios'
import {
    API_URL,
    headers,
    generalFunction,
    EDIT_SAVE_TEMPLATE_DATA,
    TRANS_LIST,
    TRANS_FILE_UPLOAD,
    UPDATE_BASE_URL,
    TEMPLATE_LIST,
    VISI_TEMPLATE_TABLE,
    TEMPLATE,
} from '../../constant/Common'
import { NotificationManager } from 'react-notifications'
import { LOCATION_CHANGE, push } from 'react-router-redux'

function getJwtToken() {
    //  const sessionLoginToken = useSelector((state)=>state.login);
    var abc = localStorage.getItem('accessToken')
    //  console.log("Getting the Token",sessionLoginToken);
    //sessionStorage.getItem("tokenId")
    return abc
}
function* getTemplateTableData(prop) {
    try {
        var res = yield axios
            .request({
                method: 'get',
                url: UPDATE_BASE_URL + TEMPLATE_LIST,
                headers: headers,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: templateActionTypes.TEMPLATE_AUTH_ERROR,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status === 403) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: templateActionTypes.TEMPLATE_AUTH_ERROR,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status >= 500) {
            //console.log('401')
            NotificationManager.error('Server error')
            return
        }
        if (res.status == 200) {
            if (res.data.status == 400) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: templateActionTypes.TEMPLATE_LOADING_STOP,
                    payload: {},
                })
            } else if (res.data.status == 404) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: templateActionTypes.TEMPLATE_LOADING_STOP,
                    payload: {},
                })
            } else if (res.data.status == 500) {
                NotificationManager.error('Server Error')
                yield put({
                    type: templateActionTypes.TEMPLATE_LOADING_STOP,
                    payload: {},
                })
            } else {
                yield put({
                    type: templateActionTypes.DISPLAY_TEMPLATE_TABLE_DATA,
                    payload: res.data,
                })
                yield put({
                    type: templateActionTypes.DISPLAY_TEMPLATE_TABLE_LIST,
                    payload: res.data,
                })
            }
        } else {
            NotificationManager.error(
                'Internal application error. Please contact system administrator.'
            )
        }
    } catch (e) {
        console.log(e)
    }
}

function* getTemplateTableList() {
    yield takeEvery(
        templateActionTypes.GET_TEMPLATE_TABLE_DATA,
        getTemplateTableData
    )
}

//==========================================post new process criteria data==================================

function* saveNewTemplateData(payload) {
    var props = payload.payload
    console.log('saveNewTemplateData', props)

    let formData = new FormData()
    formData.append('file', props.templateFileUpload)
    formData.append('templateName', props.templateName)
    formData.append('fileName', props.templateFileUpload.name)
    formData.append('miscField1', props.templateMiscField1)
    formData.append('miscField2', props.templateMiscField2)
    formData.append('miscField3', props.templateMiscField3)
    formData.append('miscField4', props.templateMiscField4)
    formData.append('miscFlag1', props.templateMiscFlag1)
    formData.append('miscFlag2', props.templateMiscFlag2)
    formData.append('miscFlag3', props.templateMiscFlag3)
    formData.append('miscFlag4', props.templateMiscFlag4)
    formData.append('miscFlag5', props.templateMiscFlag5)
    formData.append('modifiedBy', 0)

    try {
        var res = yield axios({
            method: 'post',
            url: UPDATE_BASE_URL + VISI_TEMPLATE_TABLE,
            data: formData,
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                Authorization: `Bearer ${getJwtToken()}`,
            },
        })
            // var res = axios
            //     .post(UPDATE_BASE_URL + VISI_TEMPLATE_TABLE, formData, {
            //         headers: {
            //             Authorization: `Bearer ${getJwtToken()}`,
            //             'Content-Type': "multipart/form-data",
            //             Accept:"multipart/form-data"
            //         },
            //     })

            .then((res) => {
                return res
            })
            .catch((err) => {
                return err.res
            })

        if (res.status === 401) {
            //console.log('401')
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: templateActionTypes.TEMPLATE_AUTH_ERROR,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status === 403) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: templateActionTypes.TEMPLATE_AUTH_ERROR,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status >= 500) {
            //console.log('401')
            NotificationManager.error('Server error')
            return
        }
        if (res.status === 200) {
            //.log('ressss', res)
            if (res.data.status === 400) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: templateActionTypes.TEMPLATE_LOADING_STOP,
                    payload: {},
                })
            } else if (res.data.status == 404) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: templateActionTypes.TEMPLATE_LOADING_STOP,
                    payload: {},
                })
            } else if (res.data.status == 500) {
                NotificationManager.error('Server Error')
                yield put({
                    type: templateActionTypes.TEMPLATE_LOADING_STOP,
                    payload: {},
                })
            } else {
                NotificationManager.success(
                    'Record has been successfully created'
                )
                yield put({
                    type: templateActionTypes.GET_TEMPLATE_TABLE_DATA,
                    payload: {},
                })
                yield put({
                    type: templateActionTypes.RESPONSE_SAVE_NEW_TEMPLATE,
                    payload: {},
                })
            }
        } else {
            NotificationManager.error(
                'Internal application error. Please contact system administrator.'
            )
        }
    } catch (e) {
        console.log(e)
    }
}

function* saveNewTemplate() {
    yield takeEvery(
        templateActionTypes.REQUEST_SAVE_NEW_TEMPLATE,
        saveNewTemplateData
    )
}

//===================================delete template id=========================================
function* deleteTemplateTableData(payload) {
    var props = payload.payload
    try {
        var res = yield axios
            .request({
                method: 'DELETE',
                url: UPDATE_BASE_URL + TEMPLATE + props.id,
                headers: headers,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: templateActionTypes.TEMPLATE_AUTH_ERROR,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status === 403) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: templateActionTypes.TEMPLATE_AUTH_ERROR,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status >= 500) {
            //console.log('401')
            NotificationManager.error('Server error')
            return
        }
        if (res.status == 200) {
            if (res.data.status == 400) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: templateActionTypes.TEMPLATE_LOADING_STOP,
                    payload: {},
                })
            } else if (res.data.status == 404) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: templateActionTypes.TEMPLATE_LOADING_STOP,
                    payload: {},
                })
            } else if (res.data.status == 500) {
                NotificationManager.error('Server Error')
                yield put({
                    type: templateActionTypes.TEMPLATE_LOADING_STOP,
                    payload: {},
                })
            } else if (res.data.code == 200) {
                NotificationManager.success(res.data.message)
                yield put({
                    type: templateActionTypes.GET_TEMPLATE_TABLE_DATA,
                    payload: {},
                })
                yield put({
                    type: templateActionTypes.DELETE_TEMPLATE_RESPONSE,
                    payload: res.data,
                })
            }
        } else {
            NotificationManager.error(
                'Internal application error. Please contact system administrator.'
            )
        }
    } catch (e) {
        console.log(e)
    }
}

function* deleteTemplateTableList() {
    yield takeEvery(
        templateActionTypes.DELETE_TEMPLATE_REQUEST,
        deleteTemplateTableData
    )
}

function* editTemplateData(payload) {
    var props = payload.payload
    console.log('propsTemplategetId', props)
    try {
        var res = yield axios
            .request({
                method: 'GET',
                url: UPDATE_BASE_URL + TEMPLATE + props.id,
                headers: headers,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: templateActionTypes.TEMPLATE_AUTH_ERROR,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status === 403) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: templateActionTypes.TEMPLATE_AUTH_ERROR,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status >= 500) {
            //console.log('401')
            NotificationManager.error('Server error')
            return
        }
        if (res.status == 200) {
            if (res.data.status == 400) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: templateActionTypes.TEMPLATE_LOADING_STOP,
                    payload: {},
                })
            } else if (res.data.status == 404) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: templateActionTypes.TEMPLATE_LOADING_STOP,
                    payload: {},
                })
            } else if (res.data.status == 500) {
                NotificationManager.error('Server Error')
                yield put({
                    type: templateActionTypes.TEMPLATE_LOADING_STOP,
                    payload: {},
                })
            } else {
                //  NotificationManager.success(res.data.message)
                yield put({
                    type: templateActionTypes.EDIT_TEMPLATE_RESPONSE,
                    payload: res.data,
                })
            }
        } else {
            NotificationManager.error(
                'Internal application error. Please contact system administrator.'
            )
        }
    } catch (e) {
        console.log(e)
    }
}

function* editTemplateRow() {
    yield takeEvery(templateActionTypes.EDIT_TEMPLATE_REQUEST, editTemplateData)
}

function* saveEditemplateData(payload) {
    var props = payload.payload
    console.log('saveNewTemplateData', props)

    let formData = new FormData()
    formData.append('file', props.templateFileUpload)
    formData.append('templateName', props.templateName)
    formData.append('fileName', props.templateFileUpload.name)
    formData.append(
        'miscField1',
        props.templateMiscField1 ? props.templateMiscField1 : ''
    )
    formData.append(
        'miscField2',
        props.templateMiscField2 ? props.templateMiscField2 : ''
    )
    formData.append(
        'miscField3',
        props.templateMiscField3 ? props.templateMiscField3 : ''
    )
    formData.append(
        'miscField4',
        props.templateMiscField4 ? props.templateMiscField4 : ''
    )
    formData.append('miscFlag1', props.templateMiscFlag1)
    formData.append('miscFlag2', props.templateMiscFlag2)
    formData.append('miscFlag3', props.templateMiscFlag3)
    formData.append('miscFlag4', props.templateMiscFlag4)
    formData.append('miscFlag5', props.templateMiscFlag5)
    formData.append('modifiedBy', props.userId.name)

    try {
        var res = yield axios({
            method: 'PUT',
            url: UPDATE_BASE_URL + TEMPLATE + props.id,
            data: formData,
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                Authorization: `Bearer ${getJwtToken()}`,
            },
        })
            .then((res) => {
                return res
            })
            .catch((err) => {
                return err.res
            })

        if (res.status === 401) {
            //console.log('401')
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: templateActionTypes.TEMPLATE_AUTH_ERROR,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status === 403) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: templateActionTypes.TEMPLATE_AUTH_ERROR,
                payload: {
                    error: 'Internal application error. Please contact system administrator.',
                },
            })
            return
        }
        if (res.status >= 500) {
            //console.log('401')
            yield put({
                type: templateActionTypes.TEMPLATE_LOADING_STOP,
                payload: {},
            })
            NotificationManager.error('Server error')
            return
        }
        if (res.status === 200) {
            //.log('ressss', res)
            if (res.data.status === 400) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: templateActionTypes.TEMPLATE_LOADING_STOP,
                    payload: {},
                })
            } else if (res.data.status == 404) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: templateActionTypes.TEMPLATE_LOADING_STOP,
                    payload: {},
                })
            } else if (res.data.status == 500) {
                NotificationManager.error('Server Error')
                yield put({
                    type: templateActionTypes.TEMPLATE_LOADING_STOP,
                    payload: {},
                })
            } else {
                NotificationManager.success(
                    'Record has been successfully updated'
                )
                yield put({
                    type: templateActionTypes.GET_TEMPLATE_TABLE_DATA,
                    payload: {},
                })

                yield put({
                    type: templateActionTypes.RESPONSE_SAVE_EDIT_TEMPLATE,
                    payload: res.data,
                })
            }
        } else {
            NotificationManager.error(
                'Internal application error. Please contact system administrator.'
            )
        }
    } catch (e) {
        console.log(e)
    }
}

function* saveEditTemplate() {
    yield takeEvery(
        templateActionTypes.REQUEST_SAVE_EDIT_TEMPLATE,
        saveEditemplateData
    )
}
export default function* rootSaga() {
    yield all([
        getTemplateTableList(),
        saveNewTemplate(),
        deleteTemplateTableList(),
        editTemplateRow(),
        saveEditTemplate(),
    ])
}
