import React, { useEffect, Fragment } from 'react'
import { Container, hiddenElement } from 'app/constant/Common'
import { Breadcrumb } from 'app/components'
import { SimpleCard } from 'matx'
import {
    Icon,
    Fab,
    CircularProgress,
    Grid,
    Button,
    Card,
    Box,
    Dialog,
    DialogTitle,
    Typography,
} from '@mui/material'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import MaterialTable from 'material-table'
import AddNewUser from './AddNewUser'
// import AddNewUserNew from './AddNewUserNew'
import AddNewRole from './AddNewRole'
import { useDispatch, useSelector } from 'react-redux'
import { adminUserAction } from '../../redux/actions/AdminUserAction'
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt'
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import ExportFile from './ExportFile'
import { useState } from 'react'
import EditUserDetails from './EditUserDetails'
import _ from 'lodash'
import './AdminStyle.css'
import { reportUserConstant } from '../../redux/actions/ReportAction'
import { hiddenTopElement, ContainerNew } from '../../constant/Common'

export default function UserListTableNew() {
    const hiddenValue = hiddenTopElement('5', '6')
    const hideValue = hiddenValue.hideEditDelete
    const Title = ({ children }) => (
        <div className="title" style={{ alignItems: 'center' }}>
            {children}
        </div>
    )
    // console.log('hideValueuser', hideValue)

    const dispatch = useDispatch()
    const navigate = useNavigate()
    let sample = false

    const ReportReducers = useSelector((state) => state.ReportReducers)

    useEffect(() => {
        dispatch(adminUserAction.closeUserPopup(false))
        dispatch(adminUserAction.getAllUserRoles())
        dispatch(reportUserConstant.getReportAllData())
    }, [])

    const AdminUserReducer = useSelector((state) => state.AdminUserReducer)
    // console.log('list user', AdminUserReducer.adminRolesList)

    const [api, getApi] = useState([])
    const [search, setSearch] = useState('')
    const [filteredData, setFilteredData] = useState([api])
    const [certain, setCertain] = useState('')
    const [PopupOpen, setPopupOpen] = useState(false)
    const [pagination, getPagination] = useState([])
    const [currentPage, getCurrentPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [resolution, setResolution] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    })
    const popupHandle = (value) => {
        // console.log('roleValue', value)
        setPopupOpen(true)
        dispatch(adminUserAction.popUserPopup(true))
        dispatch(adminUserAction.getUniqueGroups())

        // dispatch(
        //     adminUserAction.getEditUser({
        //         userName: rowData,
        //         // navigate: navigate,
        //     })
        // )
    }
    const popupClose = () => {
        setPopupOpen(false)
    }
    const searchHandling = (event) => {
        if (event.target.value != '') {
            setSearch(event.target.value)
            const searchresult =
                AdminUserReducer.adminRolesList &&
                AdminUserReducer.adminRolesList.filter(
                    (data) =>
                        (data.userName &&
                            data.userName
                                .toLowerCase()
                                .includes(search.toLowerCase())) ||
                        (data.gender &&
                            data.gender
                                .toLowerCase()
                                .includes(search.toLowerCase())) ||
                        (data.email &&
                            data.email
                                .toLowerCase()
                                .includes(search.toLowerCase())) ||
                        (data.groupName &&
                            data.groupName
                                .toLowerCase()
                                .includes(search.toLowerCase())) ||
                        (data.phone &&
                            data.phone
                                .toString()
                                .includes(search.toLowerCase())) ||
                        (data.address &&
                            data.address
                                .toLowerCase()
                                .includes(search.toLowerCase())) ||
                        (data.status &&
                            data.status
                                .toLowerCase()
                                .includes(search.toLowerCase()))
                )
            setFilteredData(searchresult)
        } else {
            setSearch(event.target.value)
            setFilteredData(AdminUserReducer.adminRolesList)
        }
    }

    const pagingValue = (e) => {
        console.log('e', e)
        setPageSize(e)

        const paginationRecord = _(AdminUserReducer.adminRolesList)
            .slice(0)
            .take(e)
            .value()

        dispatch(adminUserAction.getInitialUserData(paginationRecord))
    }

    const paginationPage = (page) => {
        getCurrentPage(page)
        const startIndex = (page - 1) * pageSize
        const paginationRecord = _(AdminUserReducer.adminRolesList)
            .slice(startIndex)
            .take(pageSize)
            .value()

        dispatch(adminUserAction.getInitialUserData(paginationRecord))
    }
    console.log('countuser', AdminUserReducer.adminRolesList, pageSize)

    const pageCount = AdminUserReducer.adminRolesList
        ? Math.ceil(AdminUserReducer.adminRolesList.length / pageSize)
        : 0
    if (pageCount === 1 && pageCount > AdminUserReducer.adminRolesList.length)
        return null
    const pages = _.range(1, pageCount + 1)

    const enableDisableUser = (e, rowData) => {
        //this.setState({ open: true });
        confirmAlert({
            title: rowData.userStatus + ' User',
            message: 'Are you sure want to Change ?',
            // onKeypressEscape: true,
            closeOnClickOutside: true,
            closeOnEscape: true,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        if (rowData.userStatus == 'CONFIRMED') {
                            dispatch(
                                adminUserAction.disableUser({
                                    userName: rowData,
                                    modifyBy: AdminUserReducer.userId,
                                })
                            )
                        } else {
                            dispatch(
                                adminUserAction.enableUser({
                                    userName: rowData,
                                    modifyBy: AdminUserReducer.userId,
                                })
                            )
                        }
                    },
                },
                {
                    label: 'No',
                },
            ],
        })
    }
    const addNew = () => {
        dispatch(adminUserAction.getAddNewUserPage())
        dispatch(adminUserAction.getAllGroups())
    }
    const handleClose = () => {
        dispatch(adminUserAction.closePopUp())
    }
    const getEditHandle = (e, rowData) => {
        dispatch(adminUserAction.getAllGroups())
        dispatch(
            adminUserAction.getEditUser({
                userName: rowData,
                navigate: navigate,
            })
        )
    }

    const deleteHandle = (e, rowData) => {
        // console.log('rowdata', rowData)
        dispatch(adminUserAction.deleteTheUser({ rowData }))
    }
    return (
        <>
            <Grid container spacing={3}>
                <Grid>
                    <div
                        style={{
                            alignItems: 'left',
                            float: 'left',
                            marginTop: '0.2rem',
                            marginLeft: '8px',
                        }}
                    >
                        <Link to="/AdminUserList">
                            <Button
                                sx={{ mx: 2, my: 2 }}
                                variant="contained"
                                color="primary"
                            >
                                User List
                            </Button>
                        </Link>
                        <Link to="/GroupList">
                            <Button
                                sx={{ mx: 2, my: 2 }}
                                variant="contained"
                                color="primary"
                            >
                                Group List
                            </Button>
                        </Link>
                    </div>
                </Grid>
            </Grid>
            <Grid
                className="focus"
                container
                spacing={3}
                style={{
                    marginTop: '-11px',
                    marginLeft: '-23px',
                }}
            >
                <Grid item sx>
                    <Button
                        onClick={() => addNew(true)}
                        variant="contained"
                        disabled={hideValue}
                    >
                        Add Users
                    </Button>
                </Grid>
                <Grid item md={3} className="form-group">
                    <input
                        type="search"
                        class="form-control"
                        id="formGroupExampleInput"
                        placeholder="Search"
                        aria-label="Search"
                        aria-describedby="search-addon"
                        value={search}
                        onChange={(event) => searchHandling(event)}
                    />
                </Grid>
                <Grid item lg={6} style={{ marginLeft: '13%'}}>
                    <ExportFile details={AdminUserReducer.adminRolesList} />
                </Grid>
            </Grid>

            {/* ===========================================================Material Table to show the Visibility Header Details================================================== */}

            <div
                style={{ display: 'flex', marginLeft:'-17px' }}
            >
                <ContainerNew>
                    <table
                        className="table table-bordered"
                        style={{ width: 'auto', backgroundColor: 'white' }}
                    >
                        <thead>
                            <tr
                                className="tableheader"
                                style={{
                                    backgroundColor: '#B9BFBE',
                                    fontSize: '15px',
                                    color: 'black',
                                }}
                            >
                                <th style={{ width: 300 }}>UserName ​</th>
                                {AdminUserReducer.userPopupOpen == false && (
                                    <>
                                        {/* <th style={{ width: 300 }}>
                                                    Id
                                                </th> */}
                                        <th style={{ width: 300 }}>Gender</th>
                                        <th style={{ width: 300 }}>Email</th>
                                        <th style={{ width: 300 }}>Group</th>
                                        <th style={{ width: 300 }}>Phone</th>
                                        <th style={{ width: 300 }}>Address</th>
                                        <th style={{ width: 300 }}>Status</th>
                                    </>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {search.length > 0
                                ? filteredData.map((value) => {
                                      return (
                                          <tr
                                              className={
                                                  certain == value.userName &&
                                                  AdminUserReducer.userPopupOpen ==
                                                      true &&
                                                  'BorderStyle'
                                              }
                                              style={{
                                                  fontSize: '13px',
                                              }}
                                              key={value._id}
                                          >
                                              <td className="username">
                                                  <div id="Table Index Container">
                                                      <a
                                                          className="text-decoration-none"
                                                          htef="#"
                                                          style={{
                                                              cursor: 'pointer',
                                                          }}
                                                          onClick={() => {
                                                              popupHandle()
                                                              setCertain(
                                                                  value.userName
                                                              )
                                                          }}
                                                      >
                                                          {value.userName}
                                                      </a>
                                                  </div>
                                              </td>
                                              {AdminUserReducer.userPopupOpen ==
                                                  false && (
                                                  <>
                                                      {/* <td>
                                                                  {
                                                                      value.generatedID
                                                                  }
                                                              </td> */}
                                                      <td>{value.gender}</td>
                                                      <td>{value.email}</td>
                                                      <td>{value.groupName}</td>
                                                      <td>{value.phone}</td>
                                                      <td>{value.address}</td>
                                                      <td>{value.status}</td>
                                                  </>
                                              )}
                                          </tr>
                                      )
                                  })
                                : AdminUserReducer.getUserPagination &&
                                  AdminUserReducer.getUserPagination.map(
                                      (value) => {
                                          return (
                                              <tr
                                                  className={
                                                      certain ==
                                                          value.userName &&
                                                      AdminUserReducer.userPopupOpen ==
                                                          true &&
                                                      'BorderStyle'
                                                  }
                                                  style={{
                                                      fontSize: '13px',
                                                  }}
                                                  key={value._id}
                                              >
                                                  <td>
                                                      <div id="Table Index Container">
                                                          <a
                                                              className="text-decoration-none"
                                                              htef="#"
                                                              style={{
                                                                  cursor: 'pointer',
                                                              }}
                                                              onClick={() => {
                                                                  popupHandle()
                                                                  setCertain(
                                                                      value.userName
                                                                  )
                                                                  dispatch(
                                                                      adminUserAction.getEditUser(
                                                                          value
                                                                      )
                                                                  )
                                                              }}
                                                          >
                                                              {value.userName}
                                                          </a>
                                                      </div>
                                                  </td>
                                                  {AdminUserReducer.userPopupOpen ==
                                                      false && (
                                                      <>
                                                          {/* <td>
                                                                      {
                                                                          value.generatedID
                                                                      }
                                                                  </td> */}
                                                          <td>
                                                              {value.gender}
                                                          </td>
                                                          <td>{value.email}</td>
                                                          <td>
                                                              {value.groupName}
                                                          </td>
                                                          <td>{value.phone}</td>
                                                          <td>
                                                              {value.address}
                                                          </td>
                                                          <td>
                                                              {value.status}
                                                          </td>
                                                      </>
                                                  )}
                                              </tr>
                                          )
                                      }
                                  )}
                        </tbody>
                    </table>
                </ContainerNew>
                {AdminUserReducer.userPopupOpen == true && (
                    <div
                        className="tab-pane fade show active"
                        style={{
                            width: 850,
                            height: 400,
                            marginTop: '1.2rem',
                            marginLeft: '-2.5rem',
                        }}
                    >
                        <EditUserDetails close={popupClose} value={certain} />
                    </div>
                )}
            </div>
            {AdminUserReducer.userPopupOpen != true && (
                <Grid
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: '-20px',
                    }}
                    style={{ marginLeft: '4.6px' }}
                >
                    <div style={{ display: 'flex' }}>
                        <Typography sx={{ paddingTop: 1, marginRight: 3 }}>
                            Show
                        </Typography>
                        <select
                            value={pageSize}
                            onChange={(e) => pagingValue(e.target.value)}
                            className="form-select"
                            aria-label="sample"
                            style={{
                                height: 'fit-content',
                                width: 'fit-content',
                            }}
                        >
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                        <Typography sx={{ paddingTop: 1, marginLeft: 1.5 }}>
                            Entries
                        </Typography>
                    </div>
                    <div>
                        <nav className="d-flex justify-content-center">
                            <ul
                                className="pagination"
                                style={{ marginRight: '1.1rem' }}
                            >
                                <li
                                    onClick={() => paginationPage(1)}
                                    class="page-item"
                                >
                                    <a
                                        class="page-link"
                                        href="#"
                                        aria-label="Previous"
                                    >
                                        <span aria-hidden="true">&laquo;</span>
                                    </a>
                                </li>
                                {pages.map((page) => {
                                    return (
                                        <li
                                            onClick={(e) =>
                                                paginationPage(page)
                                            }
                                            className={
                                                page === currentPage
                                                    ? 'page-item active'
                                                    : 'page-item'
                                            }
                                        >
                                            <p className="page-link">{page}</p>
                                        </li>
                                    )
                                })}
                                <li
                                    onClick={() => paginationPage(pageCount)}
                                    class="page-item"
                                >
                                    <a
                                        class="page-link"
                                        href="#"
                                        aria-label="Next"
                                    >
                                        <span aria-hidden="true">&raquo;</span>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </Grid>
            )}
            {/* </SimpleCard> */}

            <Dialog
                onClose={handleClose}
                open={AdminUserReducer.addNewUserPage}
                fullWidth
                maxWidth="sm"
            >
                <Title>Add User</Title>
                <AddNewUser />
            </Dialog>
            {/* </ContainerNew> */}
        </>
    )
}
