export const navigations = [
    {
        name: 'Dashboard',
        path: '/dashboard/default',
        icon: 'dashboard',
        menuId: ['12'],
    },
    // {
    //     name: 'Admin User',
    //     path: '/AdminUserList',
    //     icon: 'person',
    //     menuId: ['1', '2'],
    // },
    {
        name: 'Visibility',
        icon: 'upload',
        children: [
            {
                name: 'Lookup Master',
                path: '/lookupHeader',
                icon: 'report',
                menuId: ['5', '6'],
            },
            {
                name: 'Visibility Master',
                path: '/VisibilityHeader',
                icon: 'upload',
                menuId: ['3', '4'],
            },
            {
                name: 'Template master',
                path: '/tempDetails',
                icon: 'home',
                menuId: ['7', '8'],
            },
            // {
            //     name: 'Transaction File',
            //     path: '/transUp',
            //     icon: 'upload',
            //     menuId: '2',
            // },
            {
                name: 'Transaction Details',
                path: '/transDetail',
                icon: 'upload',
                menuId: '3',
            },
        ],
    },
    // {
    //     name: 'Lookup Master',
    //     path: '/lookupHeader',
    //     icon: 'report',
    //     menuId: ['5', '6'],
    // },
    // {
    //     name: 'Visibility Master',
    //     path: '/VisibilityHeader',
    //     icon: 'upload',
    //     menuId: ['3', '4'],
    // },
    // {
    //     name: 'Template master',
    //     path: '/tempDetails',
    //     icon: 'home',
    //     menuId: ['7', '8'],
    // },

    // {
    //     name: 'Transaction',
    //     icon: 'upload',
    //     children: [
    //         {
    //             name: 'Transaction File',
    //             path: '/transUp',
    //             menuId: '2',
    //         },
    //         {
    //             name: 'Transaction Details',
    //             path: '/transDetail',
    //             menuId: '3',
    //         },
    //     ],
    // },
    {
        name: 'Report Execution',
        path: '/ReportExecution',
        icon: 'report',
        menuId: ['13', '14'],
    },
]
