import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'

const VisiblityDetails = Loadable(lazy(() => import('./VisiblityDetails')))
const VisibilityHeader = Loadable(lazy(() => import('./VisibilityHeaderTable')))
const ProcessCriteria = Loadable(lazy(() => import('./AddProcessCriteria')))
const ApplicationParameter = Loadable(lazy(()=> import('./ApplicationParameter')))

const VisiblityRoutes = [
    // {
    //     path: '/VisibilityDetails',
    //     element: <VisiblityDetails />,
    //     menuId: 5,
    // },
    // {
    //     path: '/VisibilityHeader',
    //     element: <VisibilityHeader />,
    //     menuId: 6,
    // },
    {
        path:'/AddProcessCriteria',
        element: <ProcessCriteria />
    },
    {
        path:'/ApplicationParameter',
        element:<ApplicationParameter/>
    }
]

// const menus = localStorage.getItem("menuId");
// if (menus != null){
// var VisiblityRoutes = VisiblityRoutes1.filter(function (element) {
//   return menus.includes(element.menuId);
// });
// }else{
//   var VisiblityRoutes= [];
// }

export default VisiblityRoutes
