import { processCriteriaType } from '../../constant/ActionTypes'
import { MISCFIELD_4_ERROR } from '../../constant/ErrorConstant'
export const processCritAction = {
    openCriteriaPopup,
    menuTabPopUp,
    closeCriteriaPopup,
    openDetailPanel,
    closeDetailsPopUp,
    openEditDetailPanel,
    getProcessCriteriaTable,
    getInitialProcessCriteria,
    makeInitialProcessEmpty,
    inputChangeProcess,
    saveNewCriteria,
    infoDetailOpen,
    getDetailsAndRuleEdit,
    saveEditCriteria,
    updateSingleCriteria,
    detailTableHide,
    getTransactionRecord,
    deleteSingleProcessData,
    getProcessCriteriaDetails,
    getEditDetailsRecord,
    makeInitialDetailsPopupEmpty,
    saveNewDetailsProcess,
    deleteRuleProcessData,
    // editRuleData,
    getEditTransactionRedord,
    saveEditTransaction,
    deleteRuleData,
    openRulePanel,
    processDetailUndo,
    detailFieldDisable,
    infoDetailData,
}
function openCriteriaPopup() {
    console.log('action')
    return {
        type: processCriteriaType.OPEN_CRITERIA_POPUP,
        payload: {},
    }
}
function inputChangeProcess(props) {
    return {
        type: processCriteriaType.INPUT_CHANGE_PROCESS,
        payload: props,
    }
}
function closeCriteriaPopup() {
    return {
        type: processCriteriaType.CRITERIA_CLOSE_POPUP,
        payload: {},
    }
}
function openDetailPanel() {
    return {
        type: processCriteriaType.OPEN_ADD_DETAIL_POPUP,
        payload: {},
    }
}
function closeDetailsPopUp(props) {
    return {
        type: processCriteriaType.CLOSE_DETAILS_POPUP,
        payload: props,
    }
}
function openEditDetailPanel() {
    return {
        type: processCriteriaType.OPEN_EDIT_DETAIL_POPUP,
        payload: {},
    }
}
function getProcessCriteriaTable() {
    return {
        type: processCriteriaType.GET_PROCESS_CRITERIA_TABLE,
        payload: {},
    }
}
function getInitialProcessCriteria(props) {
    return {
        type: processCriteriaType.GET_INITIAL_CRITERIA,
        payload: props,
    }
}
function makeInitialProcessEmpty(props) {
    return {
        type: processCriteriaType.MAKE_INITIAL_PROCESS_EMPTY,
        payload: props,
    }
}
function saveNewCriteria(props) {
    let //processCriteriaError = '',
        processCriteriaNameError = '',
        processIdError = '',
        processTypeError = '',
        miscField4Error = ''
    // if (!props.processCriteria) {
    //     processCriteriaError = 'Enter Process Criteria'
    // }
    if (!props.processCriteriaName) {
        processCriteriaNameError = 'Enter Process Criteria'
    }
    if (!props.processId) {
        processIdError = 'Enter Process Id'
    }
    if (!props.processType) {
        processTypeError = 'Enter process type'
    }

    if (props.miscField4) {
        var miscField = /^[-+]?[0-9]+\.[0-9]+$/
        console.log('miscField', miscField)
        var isValidMiscField = miscField.test(props.miscField4)
        if (!isValidMiscField) {
            miscField4Error = 'Enter Valid Misc field'
        }
    }
    if (
        processCriteriaNameError ||
        processIdError ||
        processTypeError ||
        miscField4Error
    ) {
        return {
            type: processCriteriaType.VALIDATION_PROCESS_CRITERIA,
            payload: {
                processCriteriaNameError,
                miscField4Error,
                processIdError,
                processTypeError,
            },
        }
    } else {
        return {
            type: processCriteriaType.REQUEST_SAVE_CRITERIA,
            payload: props,
        }
    }
}
function saveEditCriteria(props) {
    console.log('INSIDE THE CRITERIA ACTION', props)
    let processCriteriaNameError = '',
        processIdError = '',
        processTypeError = '',
        statusError = '',
        miscField4Error = ''

    if (!props.processCriteriaName) {
        processCriteriaNameError = 'Enter Process Criteria'
    }
    if (!props.processId) {
        processIdError = 'Enter Process Id'
    }
    if (!props.processType) {
        processTypeError = 'Enter process type'
    }
    if (!props.status) {
        statusError = 'Choose Status'
    }
    if (props.miscField4) {
        var miscField = /^[+]?[0-9]+\.[0-9]+$/
        console.log('miscField', miscField)
        var isValidMiscField = miscField.test(props.miscField4)
        if (!isValidMiscField) {
            miscField4Error = 'Enter Valid Misc field'
        }
    }
    if (
        processCriteriaNameError ||
        processIdError ||
        processTypeError ||
        statusError ||
        miscField4Error
    ) {
        return {
            type: processCriteriaType.VALIDATION_PROCESS_CRITERIA,
            payload: {
                processCriteriaNameError,
                processIdError,
                processTypeError,
                statusError,
                miscField4Error,
            },
        }
    } else {
        return {
            type: processCriteriaType.REQUEST_EDIT_SAVE_CRITERIA,
            payload: props,
        }
    }
}
function updateSingleCriteria(props) {
    console.log('actionvalue', props)
    return {
        type: processCriteriaType.UPDATE_SINGLE_PROCESS,
        payload: props,
    }
}
function deleteSingleProcessData(props) {
    return {
        type: processCriteriaType.DELETE_REQUEST_PROCESS_CRITERIA,
        payload: props,
    }
}
function getProcessCriteriaDetails(props) {
    return {
        type: processCriteriaType.GET_PROCESS_CRITERIA_DETAILS,
        payload: props,
    }
}
function getEditDetailsRecord(props) {
    return {
        type: processCriteriaType.GET_EDIT_PROCESS_DETAILS_RULE,
        payload: props,
    }
}
function getEditTransactionRedord(props) {
    return {
        type: processCriteriaType.EDIT_REQUEST_TRANSACTION_RULE_DATA,
        payload: props,
    }
}
function makeInitialDetailsPopupEmpty(props) {
    return {
        type: processCriteriaType.GET_DETAILS_INITIAL_EMPTY,
        payload: props,
    }
}
function saveNewDetailsProcess(props) {
    let processCriteriaNameError = '',
        detailsTagError = '',
        detailsPositionError = '',
        detailsMiscField4Error = '',
        ruleNameError = ''
    if (!props.ruleName) {
        //ruleNameError = 'Enter Rule Name'
    }
    if (!props.processCriteriaName) {
        processCriteriaNameError = 'Enter Process Criteria'
    }

    if (!props.detailsTag) {
        detailsTagError = 'Enter Tag'
    }

    if (!props.detailsPosition) {
        detailsPositionError = 'Select position'
    }
    if (props.detailsMiscField4) {
        var detailsMiscField = /^[-+]?[0-9]+\.[0-9]+$/
        // console.log('miscField',detailsMiscField)
        var isValidMiscField = detailsMiscField.test(props.detailsMiscField4)
        if (!isValidMiscField) {
            detailsMiscField4Error = 'Enter Valid Misc field'
        }
    }

    if (
        processCriteriaNameError ||
        detailsTagError ||
        detailsPositionError ||
        detailsMiscField4Error
    ) {
        return {
            type: processCriteriaType.VALIDATION_PROCESS_DETAILS,
            payload: {
                processCriteriaNameError,
                detailsTagError,
                detailsPositionError,
                detailsMiscField4Error,
            },
        }
    } else {
        return {
            type: processCriteriaType.REQUEST_NEW_DETAILS_CRITERIA,
            payload: props,
        }
    }
}
function deleteRuleProcessData(props) {
    return {
        type: processCriteriaType.DELETE_RULEPROCESS_REQUEST,
        payload: props,
    }
}
function menuTabPopUp(props) {
    return {
        type: processCriteriaType.MENU_TAB_POPUP,
        payload: props,
    }
}
function detailTableHide(props) {
    return {
        type: processCriteriaType.DETAIL_TABLE_OPEN,
        payload: props,
    }
}
// function saveNewRuleData(props) {
//     let descriptionRuleError = '',
//         tagNameRuleError = '',
//         tagValueRuleError = '',

//     if (!props.descriptionRule) {
//         descriptionRuleError = 'Enter Description'
//     }
//     if (!props.tagNameRule) {
//         tagNameRuleError = 'Enter Tag Name'
//     }
//     if (!props.tagValueRule) {
//         tagValueRuleError = 'Enter valid tag Value'
//     }
//     if (
//         descriptionRuleError ||
//         tagNameRuleError ||
//         operatorError ||
//         tagValueRuleError
//     ) {
//         return {
//             type: processCriteriaType.VALIDATE_RULE_DATA,

//             payload: {
//                 descriptionRuleError,
//                 tagNameRuleError,
//                 operatorError,
//                 tagValueRuleError,
//             },
//         }
//     } else {
//         return {
//             type: processCriteriaType.REQUEST_RULE_DATA,
//             payload: props,
//         }
//     }
// }
// function editRuleData(props) {
//     return {
//         type: processCriteriaType.EDIT_REQUEST_RULE_DATA,
//         payload: props,
//     }

// }
function getTransactionRecord(props) {
    return {
        type: processCriteriaType.GET_TRANSACTION_RECORD,
        payload: props,
    }
}
// function saveEditTransaction(props){
//     let descriptionRuleError = '',
//         tagNameRuleError = '',
//         tagValueRuleError = '',
//     if (!props.descriptionRule) {
//         descriptionRuleError = 'Enter Description'
//     }
//     if (!props.tagNameRule) {
//         tagNameRuleError = 'Enter Tag Name'
//     }
//     if (!props.tagValueRule) {
//         tagValueRuleError = 'Enter valid tag Value'
//     }
//     return{
//         type: processCriteriaType.SAVE_EDIT_TRANSACTION,
//         payload: props
//     }
// }
function saveEditTransaction(props) {
    console.log('INSIDE THE ACTION', props)
    let tagNameRuleError = '',
        tagValueRuleError = '',
        ruleMiscField4Error = '',
        detailsMiscField4Error = '',
        ruleNameError = ''

    if (!props.ruleName && props.openRulePanel) {
        ruleNameError = 'Enter Rule Name'
    }
    if (!props.ruleTagName && props.openRulePanel) {
        tagNameRuleError = 'Enter Tag Name'
    }
    if (!props.tagValue && props.openRulePanel) {
        tagValueRuleError = 'Enter valid tag Value'
    }
    if (props.ruleMiscField4) {
        var ruleMiscField = /^[-+]?[0-9]+\.[0-9]+$/
        var isValidMiscField = ruleMiscField.test(props.ruleMiscField4)
        if (!isValidMiscField) {
            ruleMiscField4Error = 'Enter Valid Misc field'
        }
    }
    if (props.detailsMiscField4) {
        var detailsMiscField = /^[-+]?[0-9]+\.[0-9]+$/
        // console.log('miscField',detailsMiscField)
        var isValidMiscField = detailsMiscField.test(props.detailsMiscField4)
        if (!isValidMiscField) {
            detailsMiscField4Error = 'Enter Valid Misc field'
        }
    }
    if (
        tagNameRuleError ||
        tagValueRuleError ||
        ruleMiscField4Error ||
        detailsMiscField4Error ||
        ruleNameError
    ) {
        return {
            type: processCriteriaType.VALIDATE_RULE_DATA,

            payload: {
                tagNameRuleError,
                tagValueRuleError,
                ruleMiscField4Error,
                detailsMiscField4Error,
                ruleNameError,
            },
        }
    } else {
        return {
            type: processCriteriaType.REQUEST_SAVE_EDIT_TRANSACTION,
            payload: props,
        }
    }
}
function deleteRuleData(props) {
    console.log('deleteRule', props)
    return {
        type: processCriteriaType.REQUEST_DELETE_RULE_TRANSACTION,
        payload: props,
    }
}
function openRulePanel(props) {
    return {
        type: processCriteriaType.RULE_BUTTON_ACTION,
        payload: props,
    }
}
function getDetailsAndRuleEdit(props) {
    return {
        type: processCriteriaType.GET_RULE_AND_DETAILS_EDIT,
        payload: props,
    }
}
function processDetailUndo(props) {
    return {
        type: processCriteriaType.GET_RULE_AND_DETAILS_EDIT,
        payload: props,
    }
}
function detailFieldDisable(props) {
    return {
        type: processCriteriaType.DETAIL_FIELD_DISABLE,
        payload: props,
    }
}
function infoDetailOpen(props) {
    return {
        type: processCriteriaType.INFO_DETAIL_PANEL_OPEN,
        payload: props,
    }
}
function infoDetailData(props) {
    return {
        type: processCriteriaType.INFO_DETAIL_DATA,
        payload: props,
    }
}
