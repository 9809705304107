import React, { useEffect } from 'react'
import { Container, hiddenElement } from 'app/constant/Common'
import { Breadcrumb } from 'app/components'
import { SimpleCard } from 'matx'
import {
    Icon,
    Fab,
    DialogContent,
    Button,
    CircularProgress,
} from '@mui/material'
import { Link } from 'react-router-dom'
import { Dialog } from '@mui/material'
import { DialogTitle, Grid } from '@mui/material'
// import AddGroupNameEdit from './AddGroupNameEdit'
import AddGroupName from './AddGroupName'
// import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import MaterialTable from 'material-table'
// import AddNewUser from './AddNewUser'
import { useDispatch, useSelector } from 'react-redux'
import { adminUserAction } from '../../redux/actions/AdminUserAction'
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt'
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt'
import { useNavigate } from 'react-router-dom'
import ManageAdminPage from './ManageAdminPage'
import { lookupAction } from '../../redux/actions/LookupAction'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import RoleList from './RoleList'
export default function GroupListView() {
    // const hiddenValue = hiddenElement("1")

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const AdminUserReducer = useSelector((state) => state.AdminUserReducer)
    const list = useSelector((state) => state.lookupReducer)
    //console.log('AdminUserReducer', AdminUserReducer)

    // useEffect(() => {
    //     dispatch(adminUserAction.getAllGroups())
    //      dispatch(adminUserAction.getRoles())
    // }, [])
    const columns = [
        // {
        //     title: 'Sno',
        //     render: (rowData) => {
        //         return <span>{rowData.tableData.id + 1}</span>
        //     },
        // },
        { title: 'Group Name', field: 'groupName' },
        
        { title: 'Description', field: 'description' },
        { title: 'Roles List', field: 'roleList' },
        // { title: 'Last Name', field: 'lastName' },
        // { title: 'sequence', field: 'sequence' },
        // { title: 'userRole', field: 'userRole' },
        // {
        //     title: 'User Status',
        //     field: 'userStatus',
        // },
    ]
    const dataList =  AdminUserReducer.groupListTable.result && AdminUserReducer.groupListTable.result.map((item) => ({
        groupName: item.groupName,
        roleList: item.roleNames.map((item) =>item && <li>{item.name}</li>),
        description: item.description,
    }))

    // const dataList = AdminUserReducer.adminRolesList && AdminUserReducer.adminRolesList.map((item)=>({
    //     userName:item.userName,
    //     userStatus:item.userStatus +'D',
    //     firstName:item.firstName,
    //     lastName:item.lastName
    // }))
    // const enableDisableUser = (e, rowData) => {
    //     //this.setState({ open: true });
    //     confirmAlert({
    //         title: rowData.userStatus + ' User',
    //         message: 'Are you sure want to Change ?',
    //         // onKeypressEscape: true,
    //         closeOnClickOutside: true,
    //         closeOnEscape: true,
    //         buttons: [
    //             {
    //                 label: 'Yes',
    //                 onClick: () => {
    //                     if (rowData.userStatus == 'ENABLED') {
    //                         dispatch(
    //                             adminUserAction.disableUser({
    //                                 userName: rowData,
    //                                 modifyBy: AdminUserReducer.userId,
    //                             })
    //                         )
    //                     } else {
    //                         dispatch(
    //                             adminUserAction.enableUser({
    //                                 userName: rowData,
    //                                 modifyBy: AdminUserReducer.userId,
    //                             })
    //                         )
    //                     }
    //                 },
    //             },
    //             {
    //                 label: 'No',
    //             },
    //         ],
    //     })
    // }
    const addNew = () => {
        ;<Link to="/AddGroupName">
            <Button></Button>
        </Link>
        dispatch(adminUserAction.resetNewGroup())
    }
    const handleAddProduct = () => {
        //console.log('moving')
        navigate('/ManageAdminPage')
    }

    const getEditHandle = (e, rowData) => {
        //console.log('clicked', rowData)
        let editGroupName = rowData.groupName
        let editGroupDescription = rowData.description
        let groupRoleList = AdminUserReducer.groupListTable
            .filter((res) => res.groupName == editGroupName)
            .reduce((it) => it)
        //console.log('groupRoleList', groupRoleList.roles)

        //let editRoles = rowData.roleList.map((item) => item.props.children)

        //console.log('row', rowData.roleList, editRoles)
        let finalGroupRole = groupRoleList.roles.map((itm) => {
            return { ...itm, value: true }
        })
        navigate('/AddGroupName')
        dispatch(
            adminUserAction.getEditGroupUserData({
                editGroupName,
                description: editGroupDescription,
                editRoles: finalGroupRole,
            })
        )
    }
    const deleteHandle = (e, rowData) => {
        let deleteGrpName = rowData.groupName
        dispatch(adminUserAction.deleteTableGrpUser({ deleteGrpName }))
    }
    const handleClose = () => {
        //console.log('handleClose')
        // setOpen(false);
        dispatch(lookupAction.closeHeaderPopUp())
        dispatch(lookupAction.closeDetailsPopUp())
    }
    const setOpen = (e, rowData) => {
        //console.log('getSingleRowGrp', rowData)
        let role = rowData.roleList.map((item) => item.props.children)
        //console.log('getSingle', role)

        dispatch(lookupAction.openHeaderPopUp())
        dispatch(adminUserAction.sendGrpRowData(role))
        //  dispatch(adminUserAction.openAdminRolePopUp());
    }

    return (
        <Container>
            {AdminUserReducer.loader && (
                <div className="loader-view  loader">
                    <CircularProgress size={54} />
                </div>
            )}
            <Grid container spacing={3}>
                <Grid item xs>
                    <div item xs>
                        <Breadcrumb
                            routeSegments={[
                                // { name: 'Home', path: '/' },
                                {
                                    name: 'Group List',
                                },
                            ]}
                        />
                    </div>
                </Grid>
                <Grid item xs>
                    <div style={{ alignItems: 'center', float: 'right' }}>
                    <Link to="/RoleTable">
                            <Button
                                sx={{ mx: 2, my: 2 }}
                                // sx={propStyle}
                                variant="contained"
                                color="primary"
                                // onClick={handleAddProduct}
                            >
                                {/* <Icon sx={{ mr: 1 }} fontSize="small">
                        shopping_cart
                    </Icon> */}
                               Add role
                            </Button>
                        </Link>
                        <Link to="/AdminUserList">
                            <Button
                                sx={{ mx: 2, my: 2 }}
                                // sx={propStyle}
                                variant="contained"
                                color="primary"
                                // onClick={handleAddProduct}
                            >
                                {/* <Icon sx={{ mr: 1 }} fontSize="small">
                        shopping_cart
                    </Icon> */}
                                User List
                            </Button>
                        </Link>
                        <Link to="/GroupList">
                            <Button
                                sx={{ mx: 2, my: 2 }}
                                // sx={propStyle}
                                variant="contained"
                                color="primary"
                                // onClick={handleAddProduct}
                            >
                                {/* <Icon sx={{ mr: 1 }} fontSize="small">
                        shopping_cart
                    </Icon> */}
                                Group List
                            </Button>
                        </Link>
                       
                    </div>
                </Grid>
            </Grid>
            {/* ===========================================================Material Table to show the Visibility Header Details================================================== */}
            <MaterialTable
                // onRowClick={(event, rowData, togglePanel) => togglePanel()}
                style={{ paddingLeft: '15px', marginTop: '20px' }}
                title="Group List"
                columns={columns}
                data={dataList}
                options={{
                    actionsColumnIndex: -1,
                    //  detailPanelType: "single"
                }}
                actions={[
                    // (rowData) => {
                    //     let active = rowData.userStatus == 'ENABLED'
                    //     return {
                    //         icon: () =>
                    //             active ? (
                    //                 <ThumbUpOffAltIcon
                    //                     style={{ color: 'blue' }}
                    //                 />
                    //             ) : (
                    //                 <ThumbDownOffAltIcon
                    //                     style={{ color: 'red' }}
                    //                 />
                    //             ),
                    //             hidden: hiddenValue.hideEditDelete == false ? false : true,
                    //         tooltip: 'Enable/Disable User',
                    //         onClick: (e, rowData) =>
                    //             enableDisableUser(e, rowData),
                    //     }
                    // },
                    // (rowData) => {
                    //     return {
                    //         icon: () => (
                    //             <RemoveRedEyeIcon style={{ color: 'blue' }} />
                    //         ),
                    //         // hidden: hiddenValue.hideEditDelete == false ? false : true,
                    //         tooltip: 'Roles List',
                    //         onClick: (e, rowData) => setOpen(e, rowData),
                    //     }
                    // },
                    (rowData) => {
                        return {
                            icon: 'edit',
                            //hidden :rowData.userStatus != 'ENABLED' && hiddenValue.hideEditDelete == false ? true : false,
                            iconProps: {
                                style: { fontSize: '18px', color: 'green' },
                            },
                            tooltip: 'Edit Group',
                         //   onClick: (e, rowData) => getEditHandle(e, rowData),
                        }
                    },
                    {
                        icon: 'delete',
                        //    hidden: hiddenValue.hideEditDelete == false ? false : true,
                        iconProps: {
                            style: { fontSize: '18px', color: 'red' },
                        },
                        tooltip: 'Delete Group',
                        onClick: (e, rowData) => deleteHandle(e, rowData),
                    },
                ]}
                // detailPanel={[
                //     {
                //         icon: () => <></>,
                //         render:(rowData) => {
                //             return (
                //                 <VisibilityHeaderDetailPanel
                //               props= {rowData}
                //               />

                //             )
                //           }
                //      }]
                //     }
            />
            {/* { hiddenValue.hideEditDelete == false && */}
            <Dialog
                onClose={handleClose}
                open={list.headerPopup}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle id="responsive-dialog-title">
                    <RoleList />
                </DialogTitle>
            </Dialog>
            {/* <SimpleCard
                style={{
                    marginTop: '20px',
                    paddingBottom: '20px',
                    paddingLeft: '20px',
                }}
            > */}
                <div>
                    {/* ============================================ Button For PopUp visibility Header======================================= = */}
                    <div
                        style={{
                            bottom: '0px',
                            right: '0px',
                            float: 'right',
                            position: 'fixed',
                            marginRight: '50px',
                            marginBottom: '30px',
                            zIndex: 1000,
                        }}
                    >
                        <Link to="/AddGroupName">
                            <Fab
                                color="primary"
                                aria-label="Add"
                                className="button"
                                onClick={() => addNew()}
                            >
                                <Icon style={{ bottom: '0', right: '0' }}>
                                    add_circle
                                </Icon>
                            </Fab>
                        </Link>
                    </div>
                </div>
            {/* </SimpleCard> */}
            {/* } */}
        </Container>
    )
}
