import { all, call, put, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { NotificationManager } from 'react-notifications'
import Moment from 'moment'
import { ruleConstant } from '../../constant/ActionTypes'
import {
    API_URL,
    headers,
    GET_ALL_RULES,
    POST_NEW_RULE,
    GET_RULES_BY_ID,
    DELETE_RULE_BY_ID,
    UPDATE_THE_RULE_RECORD,
} from '../../constant/Common'

function changeDateFormat(dates) {
    const newDate = new Date(dates)
    return Moment(newDate).format('YYYY-MM-DD')
}

//   ====================================================== Get All Rules ====================================
function* getRuleList(payload) {
    try {
        var res = yield axios
            .request({
                method: 'get',
                url: API_URL.api + GET_ALL_RULES,
                headers: headers,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: ruleConstant.RULE_ERROR,
                payload: { error: 'Internal application error. Please contact system administrator.' },
            })
            return
        }

        if (res.status === 200) {
            if (res.data != '') {
                yield put({
                    type: ruleConstant.DISPLAY_ALL_RULES_LIST,
                    payload: res.data,
                })
            }
        } else {
            NotificationManager.error('Internal application error. Please contact system administrator.')
        }
    } catch (error) {
        console.log(error)
    }
}

function* getAllRule() {
    yield takeEvery(ruleConstant.GET_ALL_RULE_LIST, getRuleList)
}

//   ====================================================== Add New Rule ====================================
function* postNewRule(payload) {
    var props = payload.payload

    var req = JSON.stringify({
        // ruleName:props.ruleName,
        description: props.description,
        status: props.ruleStatus,
        groupId: props.ruleGroupId,
        tagName: props.ruleTagName,
        tagValue: props.tagValue,
        operator: props.operator,
        modifiedBy: props.userId.name,
        modifiedDate: '',
    })
    try {
        var res = yield axios
            .request({
                method: 'post',
                url: API_URL.api + POST_NEW_RULE,
                headers: headers,
                data: req,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: ruleConstant.RULE_ERROR,
                payload: { error: 'Internal application error. Please contact system administrator.' },
            })
            return
        }

        if (res.status == 200) {
            if (res.data.status == 409) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: ruleConstant.RULE_DATA_FAILED,
                    payload: {},
                })
            } else if (res.data.status == 500) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: ruleConstant.RULE_DATA_FAILED,
                    payload: {},
                })
            } else if (res.data.status == 400) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: ruleConstant.RULE_DATA_FAILED,
                    payload: {},
                })
            } else {
                NotificationManager.success('Saved SuccessFully')
                yield call(getRuleList)
                yield put({
                    type: ruleConstant.ADDING_NEW_RULE_SUCCESS,
                    payload: res.data,
                })
            }
        } else {
            NotificationManager.error(res.error)
        }
    } catch (error) {
        console.log(error)
    }
}

function* postAddRule() {
    yield takeEvery(ruleConstant.POST_NEW_RULE_DATA, postNewRule)
}

//   ====================================================== Get Rules By RuleID ====================================
function* getRuleById(payload) {
    var parameters = {
        ruleId: payload.payload.ruleID,
    }
    try {
        var res = yield axios
            .request({
                method: 'get',
                url: API_URL.api + GET_RULES_BY_ID,
                params: parameters,
                headers: headers,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: ruleConstant.RULE_ERROR,
                payload: { error: 'Internal application error. Please contact system administrator.' },
            })
            return
        }

        if (res.status === 200) {
            if (res.data != '') {
                yield put({
                    type: ruleConstant.DISPLAY_RECORD_BY_RULEID,
                    payload: res.data,
                })
            }
        } else {
            NotificationManager.error('Internal application error. Please contact system administrator.')
        }
    } catch (error) {
        console.log(error)
    }
}

function* getRecordByRuleId() {
    yield takeEvery(ruleConstant.GET_RULE_RECORD_BY_RULEID, getRuleById)
}

//   ====================================================== Delete Rules By RuleID ====================================
function* deleteRule(payload) {
    var parameters = {
        ruleId: payload.payload.ruleID,
    }
    try {
        var res = yield axios
            .request({
                method: 'delete',
                url: API_URL.api + DELETE_RULE_BY_ID,
                params: parameters,
                headers: headers,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: ruleConstant.RULE_ERROR,
                payload: { error: 'Internal application error. Please contact system administrator.' },
            })
            return
        }

        if (res.status == 200) {
            if (res.data.status == 409) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: ruleConstant.RULE_DATA_FAILED,
                    payload: {},
                })
            } else if (res.data.status == 500) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: ruleConstant.RULE_DATA_FAILED,
                    payload: {},
                })
            } else if (res.data.status == 400) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: ruleConstant.RULE_DATA_FAILED,
                    payload: {},
                })
            } else {
                NotificationManager.success('Deleted SuccessFully')
                yield call(getRuleList)
                yield put({
                    type: ruleConstant.DELETE_RULID_SUCCESS,
                    payload: {},
                })
            }
        } else {
            NotificationManager.error(res.error)
        }
    } catch (error) {
        console.log(error)
    }
}

function* deleteRuleByRuleId() {
    yield takeEvery(ruleConstant.RULE_DELETE_BY_RULEID, deleteRule)
}

//   ====================================================== Update Rule ====================================
function* updateRule(payload) {
    var props = payload.payload

    var parameters = {
        ruleId: props.ruleId,
    }

    var req = JSON.stringify({
       // ruleName: props.ruleName,
        description: props.description,
        status: props.ruleStatus,
        groupId: props.ruleGroupId,
        tagName: props.ruleTagName,
        tagValue: props.tagValue,
        operator: props.operator,
        modifiedBy: props.userId.name,
        modifiedDate: '',
    })
    try {
        var res = yield axios
            .request({
                method: 'put',
                url: API_URL.api + UPDATE_THE_RULE_RECORD,
                params: parameters,
                headers: headers,
                data: req,
            })
            .then((response) => {
                return response
            })
            .catch((err) => {
                return err.response
            })
        if (res.status === 401) {
            NotificationManager.error('Unauthorized Access')
            yield put({
                type: ruleConstant.RULE_ERROR,
                payload: { error: 'Internal application error. Please contact system administrator.' },
            })
            return
        }

        if (res.status == 200) {
            if (res.data.status == 409) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: ruleConstant.RULE_DATA_FAILED,
                    payload: {},
                })
            } else if (res.data.status == 500) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: ruleConstant.RULE_DATA_FAILED,
                    payload: {},
                })
            } else if (res.data.status == 400) {
                NotificationManager.error(res.data.error)
                yield put({
                    type: ruleConstant.RULE_DATA_FAILED,
                    payload: {},
                })
            } else {
                NotificationManager.success('Saved SuccessFully')
                yield call(getRuleList)
                yield put({
                    type: ruleConstant.UPDATED_RULE_SUCCESS,
                    payload: {},
                })
            }
        } else {
            NotificationManager.error(res.error)
        }
    } catch (error) {
        console.log(error)
    }
}

function* updateRuleByRuleId() {
    yield takeEvery(ruleConstant.UPDATE_RULE_RECORD, updateRule)
}

export default function* rootSaga() {
    yield all([
        getAllRule(),
        postAddRule(),
        getRecordByRuleId(),
        deleteRuleByRuleId(),
        updateRuleByRuleId(),
    ])
}
