import { ruleConstant } from '../../constant/ActionTypes'
const INITIAL_STATE = {
    loader: false,
    ruleDialogOpen: false,
    addNewRuleDialog: false,
    ruleGroupId:'',
    ruleTagName:"",
    allRulesList: [],
    ruleName:"",
    description:"",
    operator:"IS_EQUAL_TO",
    tagValue:"",
    ruleStatus:"ACTIVE",
    ruleId:'',
    userId: JSON.parse(localStorage.getItem('auth_user')),

    ruleNameError:"",
    descriptionError:"",
    operatorError:"",
    tagValueError:"",
}
export default (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case ruleConstant.GET_RULE_DIALOG_OPEN:
            return {
                ...state,
                loader: false,
                ruleDialogOpen: !state.ruleDialogOpen,
            }
case ruleConstant.GET_LOCAL_DATA_RULE:
    return{
        ...state,
        ruleGroupId : payload.groupId,
        ruleTagName : payload.tag,
       ruleDialogOpen: !state.ruleDialogOpen,
    }
    case ruleConstant.CLOSE_RULE_DIALOG:
        return{
            ...state,
            ruleDialogOpen:false
        }

    case ruleConstant.NEW_RULE_ADD_DETAILS:
        return{
            ...state,
            loader: true,
            ruleName:"",
            description:"",
            ruleGroupId:payload.groupId,
            ruleTagName:payload.tag,
            operator:"IS_EQUAL_TO",
            tagValue:"",
            ruleStatus:"ACTIVE",
            ruleId:'',
            addNewRuleDialog: !state.addNewRuleDialog,
        }
    case ruleConstant.POST_DATA_VALIDATION_RULE:
        return{
            ...state,
            loading:false,
            ruleNameError:payload.ruleNameError,
            descriptionError:payload.descriptionError,
            tagValueError:payload.tagValueError,
        }

        case ruleConstant.CLOSE_NEW_RULE:
            return{
                ...state,
                loader: false,
                addNewRuleDialog:false
            }
        case ruleConstant.ADD_RULE_OPEN_DIALOG:
            return {
                ...state,
                loader: true,
                ruleName:"",
                description:"",
                operator:"IS_EQUAL_TO",
                tagValue:"",
                ruleStatus:"ACTIVE",
                ruleId:'',
                addNewRuleDialog: !state.addNewRuleDialog,

                ruleNameError:"",
                descriptionError:"",
                operatorError:"",
                tagValueError:"",
            }

        case ruleConstant.GET_ALL_RULE_LIST:
            return {
                ...state,
                loader: true,
            }

        case ruleConstant.DISPLAY_ALL_RULES_LIST:
            return {
                ...state,
                loader: false,
                allRulesList: payload,
            }
            case ruleConstant.RULE_INPUT_INITIAL_CHANGE:
                return{
                    ...state,
                    loader:false,
                    [payload.props]: payload.value,
                    [payload.error]: '',
                }

                case ruleConstant.POST_NEW_RULE_DATA:
                    return{
                        ...state,
                        loader:true,
                    }
                    case ruleConstant.ADDING_NEW_RULE_SUCCESS:
                        return {
                            ...state,
                            loader:false,
                            addNewRuleDialog:false
                           // allRulesList : [...state.allRulesList , payload]
                        }

                        case ruleConstant.GET_RULE_RECORD_BY_RULEID:
                            return{
                                ...state,
                                loader:true,
                                ruleNameError:"",
                                descriptionError:"",
                                operatorError:"",
                                tagValueError:"",
                            }
                            case ruleConstant.DISPLAY_RECORD_BY_RULEID:
                                return{
                                    ...state,
                                    loader:false,
                                    ruleName:payload.ruleName,
                                    description:payload.description,
                                    operator:payload.operator,
                                    tagValue:payload.tagValue,
                                    ruleStatus:payload.status,
                                    ruleGroupId:payload.groupId,
                                    ruleTagName:payload.tagName,
                                    ruleId :payload.ruleID,
                                    addNewRuleDialog: !state.addNewRuleDialog,
                                }
                                case ruleConstant.RULE_DELETE_BY_RULEID:
                                    return{
                                        ...state,
                                        loader:false,
                                    }
                                    case ruleConstant.DELETE_RULID_SUCCESS:
                                        return{
                                            ...state,
                                            loader:false,
                                        }
                                        case ruleConstant.UPDATE_RULE_RECORD:
                                            return{
                                                ...state,
                                                loader:true,
                                            }
                                            case ruleConstant.UPDATED_RULE_SUCCESS:
                                                return{
                                                    ...state,
                                                    loader:false,
                                                    addNewRuleDialog:false
                                                }
        //================================= 401 Error Handling ==========================
        case ruleConstant.RULE_ERROR:
            return {
                ...state,
                loader: false,
            }

            case ruleConstant.RULE_DATA_FAILED:
                return{
                    ...state,
                    loader: false,
                }
        default: {
            return {
                ...state,
            }
        }
    }
}
