import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'

const ProcessCriteriaTable = Loadable(lazy(() => import('./ProcessCriteriaTable')))
const CreateNewRule = Loadable(lazy(() => import('./CreateNewRule')))
const PaginatedItems = Loadable(lazy(() => import('./PaginatedItems')))

const ProcessCriteriaRoutes = [
    
    {
        path:'/ProcessCriteriaTable',
        element: <ProcessCriteriaTable />
    },
    {
        path:'/PaginatedItems',
        element: <PaginatedItems/>
    },
    {
        path:'/CreateNewRule',
        element: <CreateNewRule/>
    }
    
]



export default ProcessCriteriaRoutes
