import { TrainRounded } from '@mui/icons-material'
import { reportInnerTableDetails } from '../../constant/ActionTypes'

import _ from 'lodash'
import dayjs from 'dayjs'

const INITIAL_STATE = {
    loading: false,
    reportId: 0,
    firstInnerData: [],
    secondInnerData: [],
    openDragSecondTablePopup: false,
    openDragTablePopup: true,
    rowData: '',
}
export default (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case reportInnerTableDetails.REPORT_INPUT_CHANGE:
            return {
                ...state,
                [payload.props]: payload.value,
                [payload.error]: '',
            }

        //Inner table SKU data
        case reportInnerTableDetails.GET_INNERTABLE_DATA:
            console.log('InnerDatalevel', payload)
            return {
                ...state,
                loading: true,
                rowData: payload.rowData,
                firstInnerData: payload.value,
                secondInnerData: payload.data.filter((itm) =>
                    itm.level > 2 ? itm.level : null
                ),
            }
        //main Parent table

        case reportInnerTableDetails.ON_DRAG_INNER_TABLE:
            let single = payload.e
            let old = payload.oldTable

            if (!single.destination) return

            let tempData = Array.from(old)
            let [source_data] = tempData.splice(single.source.index, 1)

            tempData.splice(single.destination.index, 0, source_data)
            let finalDattas = []

            tempData.map((item, index) => {
                state.firstInnerData.map((va) => {
                    if (item.tagName == va.tagName) {
                        index = index + 1
                        va.displaySequence =
                            va.displaySequence > 0
                                ? parseFloat(
                                      (
                                          state.rowData.displaySequence +
                                          '.' +
                                          index++
                                      ).toString()
                                  )
                                : va.displaySequence
                        va.parentSequence =
                            va.parentSequence == state.rowData.parentSequence
                                ? single.destination.index + 1
                                : va.parentSequence
                        finalDattas = [...finalDattas, va]
                    }
                })
                return {}
            })
            return {
                ...state,
                firstInnerData: finalDattas,
            }
        case reportInnerTableDetails.PASS_SEQUENCE_INNER_TABLE:
            console.log('innerSequence', payload)

            let affectedTag = payload.rowData.tagName
            let finalData = state.firstInnerData.map((val, index) => {
                if (val.tagName == affectedTag) {
                    return {
                        ...val,
                        isActive: payload.e ? '1' : '0',
                    }
                }
                return val
            })
            console.log(
                'finalData',
                finalData,
                affectedTag,
                state.firstInnerData
            )
            let finalActive = finalData.filter((e, index) => e.isActive != 0)
            console.log('finalActive', finalActive)
            let tempActive = []
            finalActive.map((val, index) => {
                // index = index + 1
                if (val.isActive != 0) {
                    tempActive = [
                        ...tempActive,
                        {
                            ...val,
                            isActive: 1,
                            //  index + 1,
                        },
                    ]
                }
                return val
            })
            let tempDatas = tempActive
                .filter((e) => e.isActive != 0)
                .sort((a, b) =>
                    a.displaySequence > b.displaySequence ? 1 : -1
                )

            let tempInactive = finalData.filter((e) => e.displaySequence == 0)
            finalData = [...tempDatas, ...tempInactive]
            finalData.map((item, index) => {
                item.displaySequence = index + 1
            })
            console.log('finalData', finalData)
            return {
                ...state,
                loading: true,
                firstInnerData: finalData,
            }
        case reportInnerTableDetails.COLUMN_DISPLAY_TABLE_INPUTCHANGE:
            console.log('Valuesss', payload)
            console.log('state.firstInnerData1', state.firstInnerData)
            let final_data = state.firstInnerData.map((val) => {
                if (val.tagName == payload.tagName) {
                    if (payload.fieldName == 'displayTagName') {
                        val.displayTagName = payload.value
                    } else if (payload.fieldName == 'formatInfo') {
                        val.formatInfo = payload.value
                    }
                }
                return val
            })
            console.log('finalDatass', final_data)
            console.log('state.firstInnerData2', state.firstInnerData)
            return {
                ...state,
                loading: true,
                firstInnerData: final_data,
            }
        case reportInnerTableDetails.OPEN_SECOND_POPUP:
            return {
                ...state,
                loading: true,
                openDragSecondTablePopup: payload,
            }
        default: {
            return {
                ...state,
            }
        }
    }
}
