import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'

const UserLoginProfile = Loadable(lazy(() => import('./UserLoginProfile')))
const UserLogin = Loadable(lazy(() => import('./UserLogin')))

const UserProfileRoutes = [
    {
        path: '/UserLoginProfile',
        element: <UserLoginProfile />,
        // menuId: 8,
    },
    {
        path: '/UserLogin',
        element: <UserLogin/>,
        // menuId: 8,
    },
]


export default UserProfileRoutes
